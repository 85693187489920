export class CamDisconnectByMinModel {
    total: number = 0;
    totalDisconnection: number = 0;
    date: number = 0;
}
export class ChartInfoModel {
    totalConnected: number = 0;
    totalDisconnected: number = 0;
    totalHavePackage: number = 0;
    totalNoHavePackage: number = 0;
    subAccountActive: number = 0;
    subAccountDeactivate: number = 0;
}
export class CameraDisconnectionModel {
    id: number = 0;
    name: string = '';
    number: number = 0;
    detail: DetailModel[] = [];
}
export class DetailModel {
    timeStart: number = 0;
    timeEnd: number = 0;
}
export class CameraDisconnectModel{
    cameraName: string ='';
    connectionStatus: string ='';
    timeDisconnected: number =0;
    bunchName: string = '';
    bunchId: number=0;
}