import {CameraModel} from './../../_models/camera.model';
import {FormBuilder, FormGroup} from '@angular/forms';
import {BunchModel} from './../../_models/bunch.model';
import {Component, Input, OnInit} from '@angular/core';
import {BunchService} from '../../_services/bunch.service';
import {AuthenticationService} from '../../_services/authentication.service';
import {FormValidateService} from '../../_services/form-validate.service';
import {AuthHepler} from '../../_helpers/auth.helper';
import {CookieService} from 'ngx-cookie-service';
import Swal from 'sweetalert2';
import {ObjectNodeBunch} from 'src/app/_models/object_tree_bunch';
import {TreeviewService} from 'src/app/_services/treeview.service';
import { AppComponent } from 'src/app/app.component';
import { LanguageModel } from 'src/app/_models/language.model';

declare var $: any;

@Component({
  selector: 'app-bunch',
  templateUrl: './bunch.component.html',
  styleUrls: ['./bunch.component.css']
})
export class BunchComponent extends AuthHepler implements OnInit {
  @Input() group: BunchModel;
  listBunch: BunchModel[];
  cloneDataInBunchTree: any[] = [];
  cloneDataTables: any[] = [];
  listCamNoBunch: CameraModel[];
  listCamInBunch: CameraModel[];
  selectedCamIds: any[] = [];
  reload:any = false;
  reloadTable:any = false;
  unSelectedCamIds: any[] = [];
  bunchBindingUpdate: any;
  bunchUpdate:any = null;
  loaded: boolean = false;
  term: string = '';
  order: string = 'name';
  idSelected: any = null;
  pageSize: number = 10;
  currentPage: number = 0;
  addGroupForm: FormGroup;
  langModel = new LanguageModel();
  title: string = 'Thêm nhóm camera';
  submit: string = 'Thêm nhóm';
  lengthPagination = 0;
  disableChoosePageSize = false;
  countAll: any;
  constructor(
    private _treeService: TreeviewService,
    private _bunchService: BunchService,
    public authenticationService: AuthenticationService,
    private _fb: FormBuilder,
    private _appComponent: AppComponent,
    public _groupService: FormValidateService, public cookie: CookieService) {
    super(_bunchService, authenticationService, cookie);
    this.group = new BunchModel();
  }

  // @ts-ignore
  ngOnInit(): Promise<void> {
    this.createForm();
    this.getAllCamNoBunch();
    this.findAll();
    //chuyển ngữ
    this._appComponent.translate.get('ad_add_bunch').subscribe((res: string) => {
      this.langModel.title = res;
    });
    this._appComponent.translate.get('li_add_group').subscribe((res: string) => {
      this.langModel.content = res;
    });

    
    this._appComponent.translate.get('li_notiffi').subscribe((res: string) => {
      this.langModel.title1 = res;
    });
    this._appComponent.translate.get('li_group_had_data').subscribe((res: string) => {
      this.langModel.content1 = res;
    });
    this._appComponent.translate.get('li_group_del').subscribe((res: string) => {
      this.langModel.title2 = res;
    });
    this._appComponent.translate.get('li_del').subscribe((res: string) => {
      this.langModel.content2 = res;
    });
    this._appComponent.translate.get('li_cancel').subscribe((res: string) => {
      this.langModel.title3 = res;
    });
    this._appComponent.translate.get('li_fail').subscribe((res: string) => {
      this.langModel.content3 = res;
    });
    this._appComponent.translate.get('li_ques_del').subscribe((res: string) => {
      this.langModel.title4 = res;
    });
    this._appComponent.translate.get('del_bunch_done').subscribe((res: string) => {
      this.langModel.content4 = res;
    });
    this._appComponent.translate.get('ad_search').subscribe((res: string) => {
      this.langModel.title5 = res;
    });
    this._appComponent.translate.get('li_edit_group').subscribe((res: string) => {
      this.langModel.content5 = res;
    });
    this._appComponent.translate.get('ad_update').subscribe((res: string) => {
      this.langModel.title6 = res;
    });
    this._appComponent.translate.get('li_not_bunch').subscribe((res: string) => {
      this.langModel.content6 = res;
    });
    this._appComponent.translate.get('li_update_bunch_succ').subscribe((res: string) => {
      this.langModel.content7 = res;
    });
  }
  
  createForm() {
    this.addGroupForm = this._fb.group({
      groupname: ['', this._groupService.groupCamValidate.nameValidate.validator]
    });
  }

   findAll() {
    this._bunchService.findAll()
      .subscribe(
        res => {
          this.listBunch = res.listBunch;
          this.countAll = this.listBunch.length;
          this.listBunch.sort((a, b) => {
            if(a.id > b.id) {
              return 1;
            } else if(a.id < b.id) {
              return -1;
            } else {
              return 0;
            }
          });
          this.loopMultiple(this.listBunch);
        },
        error => {
          if (error === 'Unauthorized') {
            this.unAuthHandle(() => this.findAll());
          } else {
            console.log(error);
          }
        },
        () => {
          this.loaded = true;
        });
  }

  remove(_bunch: any) {
    Swal.fire({
      title: this.langModel.title1,
      text: _bunch.listChild.length > 0 ? this.langModel.content1 : this.langModel.title2,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: this.langModel.content2,
      cancelButtonText: this.langModel.title3
    }).then(
      (result) => {
        if (result.value) {
          this._bunchService.remove(_bunch.bunch.id)
            .subscribe(rs => {
                Swal.fire(
                  this.langModel.title4,
                  this.langModel.content4,
                  'success'
                )
                  .then(() => {
                    this.reload = true;
                    this.ngOnInit()
                  })
              },
              err => {
                if (err === 'Unauthorized') {
                  this.unAuthHandle(() => this.remove(_bunch));
                } else {
                  Swal.fire({
                    icon: 'error',
                    title: this.langModel.content3,
                    text: this.langModel.content4
                  });
                }
              }
            )
        } else if (result.dismiss) {
        }
      })
  }

  getRemoveId($event: any) {
    this.remove($event);
  }

  getEditModal($event: any) {
    this.bunchUpdate = $event;
    this.editBunch($event);
  }


  createGroup() {
    if (this.group.id == undefined) {
      this._bunchService.addBunch(this.group.name, this.idSelected)
        .subscribe(res => {
            $('#addCameraGroup').modal('hide');

            this.selectedCamIds = [];
            let list = JSON.parse(localStorage.getItem('camSelected'));
            if (list != '') {
              list.forEach(item => {
                this.selectedCamIds.push(item.id);
              });
            }
            if (this.selectedCamIds.length > 0) {
              this.addCameraToBunch(res.bunch.id);
            }
            Swal.fire('Thêm nhóm', 'Tạo nhóm thành công!', 'success')
              .then(() => {
                this.reload = true;
                this.ngOnInit();
              });
          },
          err => {
            if (err === 'Unauthorized') {
              this.unAuthHandle(() => this.createGroup());
            } else {
              console.log(err);
            }
          })
    } else {
      this._bunchService.updateBunch(this.group.id, this.group.name, this.idSelected)
        .subscribe(
          res => {
            $('#addCameraGroup').modal('hide');
            console.log("res: ", res);

            this.selectedCamIds = [];
            let list = JSON.parse(localStorage.getItem('camSelected'));
            if (list != '') {
              list.forEach(item => {
                this.selectedCamIds.push(item.id);
              });
            }
            console.log("list camSelected: ", list);
            if (this.selectedCamIds.length > 0) {
              this.addCameraToBunch(this.group.id);
            }
            this.unSelectedCamIds = [];
            let list1 = JSON.parse(localStorage.getItem('camUnselected'));
            if (list1 != '') {
              list1.forEach(item1 => {
                this.unSelectedCamIds.push(item1.id);
              });
            }
            console.log("list unSelectedCamIds: ", this.unSelectedCamIds);
            if (this.unSelectedCamIds.length > 0) {
              this.removeCamInBunch(this.group.id);
            }
            Swal.fire(this.langModel.content5, this.langModel.content7, 'success')
              .then(() => {
                this.reload = true;
                this.ngOnInit();
              });
          },
          err => {
            if (err === 'Unauthorized') {
              this.unAuthHandle(() => this.createGroup());
            } else {
              console.log(err);
            }
          }
        )
    }
  }

  addCameraToBunch(_bunchId: number) {
    this._bunchService.getCameraInBunch(_bunchId).subscribe(res => {
        var lstCamInBunch = [];
        res.listCamera.forEach(item1 => {
          lstCamInBunch.push(item1.id.toString());
        });
        var lstAdd = this.selectedCamIds.filter(n => !lstCamInBunch.includes(n));
        this._bunchService.addCameraToBunch(_bunchId, lstAdd).subscribe(
          res => {
            console.log('add cam to bunch success');
          },
          err => {
            if (err === 'Unauthorized') {
              this.unAuthHandle(() => this.addCameraToBunch(_bunchId));
            } else {
              console.log('Lỗi add cam to bunch: ' + err);
            }
          }
        );
      },
      err => {
        console.log('Lỗi add cam to bunch: ' + err);
      })
  }

  removeCamInBunch(_bunchId: number) {
    this._bunchService.getAllCamNoBunch().subscribe(res => {
        var lstCamNoBunch = [];
        res.listCamera.forEach(item1 => {
          lstCamNoBunch.push(item1.id.toString());
        });
        var lstRemove = this.unSelectedCamIds.filter(n => !lstCamNoBunch.includes(n));
        this._bunchService.removeCameraInBunch(_bunchId, lstRemove).subscribe(
          res => {
            console.log('remove cam in bunch success');
          },
          err => {
            if (err === 'Unauthorized') {
              this.unAuthHandle(() => this.removeCamInBunch(_bunchId));
            } else {
              console.log('remove cam in bunch error' + err);
            }
          }
        );
      },
      err => {
        console.log('remove cam in bunch error' + err);
      })
  }

  getAllCamNoBunch() {
    //reset data
    this.title = this.langModel.title;
    this.submit = this.langModel.content;
    this.group = new BunchModel();
    this.bunchUpdate = null;
    localStorage.setItem('camSelected', JSON.stringify(''));
    $("#select-camera-group").empty();
    this._bunchService.getAllCamNoBunch()
      .subscribe(
        res => {
          this.listCamNoBunch = res.listCamera;
          if ($("#select-camera-group").length) {
            let settings = {
              dataArray: this.listCamNoBunch,
              itemName: "name",
              valueName: "id",
              searchPlaceholderText: this.langModel.title5,
              tabNameText: "",
              rightTabNameText: "",
              callable: function(items) {
                localStorage.setItem('camSelected', JSON.stringify(items));
              }
            };
            $("#select-camera-group").transfer(settings);
          }
        },
        error => {
          if (error === 'Unauthorized') {
            this.unAuthHandle(() => this.getAllCamNoBunch());
          } else {
            console.log(error);
          }
        });
  }

  editBunch(_bunchModel: BunchModel) {
    this.group = _bunchModel;
    this.title = this.langModel.content5;
    this.submit = this.langModel.title6;
    this._bunchService.getAllCamNoBunch()
      .subscribe(res => {
          this.listCamNoBunch = res.listCamera;
          this._bunchService.getCameraInBunch(_bunchModel.id).subscribe(
            res => {
              this.listCamInBunch = [] as CameraModel[];
              res.listCamera.forEach(element => {
                let cam = new CameraModel();
                cam.selected = true;
                cam.id = element.id;
                cam.name = element.name;
                this.listCamInBunch.push(cam);
              });
              localStorage.setItem('camSelected', JSON.stringify(this.listCamInBunch));
              localStorage.setItem('camUnselected', JSON.stringify(this.listCamNoBunch));

              let listTotal = this.listCamNoBunch.concat(this.listCamInBunch);
              $("#select-camera-group").empty();
              if ($("#select-camera-group").length) {
                let settings = {
                  dataArray: listTotal,
                  itemName: "name",
                  valueName: "id",
                  searchPlaceholderText: "Tìm kiếm",
                  tabNameText: "",
                  rightTabNameText: "",
                  callable: function(selected_items, unselected_items) {
                    localStorage.setItem('camSelected', JSON.stringify(selected_items));
                    localStorage.setItem('camUnselected', JSON.stringify(unselected_items));
                  }
                };
                $("#select-camera-group").transfer(settings);
              }
              $('#addCameraGroup').modal('show');
            },
            err => {
              if (err === 'Unauthorized') {
                this.unAuthHandle(() => this.editBunch(_bunchModel));
              } else {
                console.log(err);
              }
            }
          );
        },
        err => {
          if (err === 'Unauthorized') {
            this.unAuthHandle(() => this.editBunch(_bunchModel));
          } else {
            console.log(err);
          }
        });
  }

  //main function lap multiple
  async loopMultiple(listParent: any[]) {
    let i = 0;
    let listLength = listParent.length;
    var parent = [];
    while (i < listLength) {
      if (listParent[i].parentId == null) {
        var obj = new ObjectNodeBunch();
        obj.level = 0;
        obj.bunch = listParent[i];
        obj.isActive = true;
        this.getCameraInBunchMaptoObjectModel(obj);
        parent.push(obj);
      }
      i++;
    }
    parent.forEach(element => {
      listParent.splice(listParent.indexOf(element.bunch), 1);
    });
    this.lengthPagination = parent.length;
    await this.nodeStructer(parent);
  }

  nodeStructer(list: any[]) {
    var parent = [];
    var data = [];
    list.forEach((element, index) => {
      element.displayIndex = (index + 1) + ".";
      parent.push(element);
      data = this.sortingChildren(element);
      parent = parent.concat(data);
    });
    this.cloneDataTables = parent;

    //get data when first time
    let index = 0;
    this.listBunchPerPage = [];
    this.cloneDataTables.forEach(
      element => {
        if (index <= this.pageSize) {
          this.listBunchPerPage.push(element);
          if (element.level == 0) {
            index++;
          }
        }
      }
    );
    if (index == Number(this.pageSize) + 1) {
      this.listBunchPerPage.pop();
    }
  }

  sortingChildren(item: any) {
    var resoult = [];
    var data = [];
    this.listBunch.forEach(
      element => {
        if (element.parentId == item.bunch.id) {
          var bunch = new ObjectNodeBunch();
          bunch.level = item.level + 1;
          item.listChild.push(element.id);
          bunch.displayIndex = item.displayIndex + (item.listChild.indexOf(element.id) + 1) + ".";
          bunch.bunch = element;
          this.getCameraInBunchMaptoObjectModel(bunch);
          resoult.push(bunch);
          // this.bunchList.splice(this.bunchList.indexOf(element),1);
          data = this.sortingChildren(bunch);
          resoult = resoult.concat(data);
        }
      }
    )
    return resoult;
  }

  getIdSelected($event: any) {

    this.idSelected = $event;
  }


  actionActive(list: any[], $event, id: any) {
    list.forEach(
      element => {
        if (element.bunch.id == id || element.bunch.parentId == id) {
          element.listChild.forEach(subElement => {
            subElement.isActive = $event;
          });
          if (element.listChild.length > 0) {
            this.actionActive(element.listChild, $event, element.bunch.id);
          }
        }

      }
    )
  }

  getCameraInBunchMaptoObjectModel(_bunch: any) {
    this._treeService.getCameraInBunch(_bunch.bunch.id).subscribe(res => {
        _bunch.listCamera = res.listCamera;
      },
      err => {

        if (err === 'Unauthorized') {
          this.unAuthHandle(() => this.getCameraInBunchMaptoObjectModel(_bunch.bunch.id));
        } else {
          console.log("getCameraInBunch " + err);
        }
      });
  }

  getCameraTreeview() {
    this._treeService.getCameraTree()
      .subscribe(res => {
          this.listBunch = res.listBunch;
          this.loopMultiple(this.listBunch);
          // this.bunchList.forEach(element => {
          //   this.getCameraInBunch(element);
          // });
          if (res.listCamera.length > 0) {
            let bunch = new BunchModel();
            bunch.id = 0;
            bunch.name = this.langModel.content6;
            bunch.listCamera = res.listCamera;
            this.listBunch.push(bunch);
          }
        },
        err => {
          if (err === 'Unauthorized') {
            this.unAuthHandle(() => this.getCameraTreeview());
          } else {
            console.log("getCameraTreeView " + err);
          }
        })
  }

  exitModal() {
  }

  textSearch = "";
  cloneDataTablesWithSearch = [];
  disableClickBunch: boolean = false;
  listBunchPerPage = [];

  getChangesSearch($event: any) {
    this.textSearch = $event.target.value
    if ($event.target.value === "") {
      this.term = "";
      this.term = $event.target.value;
      this.reloadTable = true;
      this.ngOnInit();
      this.disableChoosePageSize = false;
      this.disableClickBunch = false;
    } else {
      this.term = $event.target.value;
      this.disableChoosePageSize = true;
      this.pageSize = 10;
      this.disableClickBunch = true;
      this.cloneDataTablesWithSearch = [];
      this.cloneDataTables.forEach(
        element => {
          if (element.bunch.name.toLowerCase().includes(this.term.toLocaleLowerCase())) {
            element.isActive = true;
            this.cloneDataTablesWithSearch.push(element);
          } else {
            element.isActive = false;
            element.isClick = false;
            let check = false;
            element.listCamera.forEach(cam => {
              if (cam.name.toLowerCase().includes(this.term.toLowerCase())) {
                element.isActive = true;
                element.isClick = true;
                check = true;
              }
            });
            if (check) {
              this.cloneDataTablesWithSearch.push(element);
            }
          }
        }
      );
      this.lengthPagination = this.cloneDataTablesWithSearch.length;

      let index = 0;
      this.listBunchPerPage = [];
      this.cloneDataTablesWithSearch.forEach(
        element => {
          if (index < this.pageSize) {
            this.listBunchPerPage.push(element);
            index++;
          }
        }
      );
    }
  }

  getPageSize($event: any) {
    this.pageSize = $event.target.value;
    let index = 0;
    this.listBunchPerPage = [];
    this.cloneDataTables.forEach(
      element => {
        if (index <= this.pageSize) {
          this.listBunchPerPage.push(element);
          if (element.level == 0) {
            index++;
          }
        }
      }
    );
    if (index == Number(this.pageSize) + 1) {
      this.listBunchPerPage.pop();
    }
  }

  onChangePage($event: any) {
    if (this.textSearch === "") {
      let itemIndex = Number($event) * this.pageSize;
      let listBunchOnPage = [];
      let i = 0;
      this.cloneDataTables.forEach(
        element => {
          if (element.level == 0) {
            i++;
          }
          if (i > itemIndex) {
            listBunchOnPage.push(element);
          }
        }
      );

      let index = 0;
      this.listBunchPerPage = [];
      listBunchOnPage.forEach(
        element => {
          if (index <= this.pageSize) {
            this.listBunchPerPage.push(element);
            if (element.level == 0) {
              index++;
            }
          }
        }
      );
      if (index == Number(this.pageSize) + 1) {
        this.listBunchPerPage.pop();
      }
    } else {
      let itemIndex = Number($event) * this.pageSize;
      let listBunchOnPage = [];
      for (var i = itemIndex; i < this.cloneDataTablesWithSearch.length; i++) {
        listBunchOnPage.push(this.cloneDataTablesWithSearch[i]);
      }
      let index = 0;
      this.listBunchPerPage = [];
      listBunchOnPage.forEach(
        element => {
          if (index <= this.pageSize) {
            this.listBunchPerPage.push(element);
            index++;
          }
        }
      );
      if (index == Number(this.pageSize) + 1) {
        this.listBunchPerPage.pop();
      }
    }

  }
}
