import { RecordModel } from './../../../_models/record.model';
import { Component, OnInit, Input, AfterViewInit, ElementRef, ViewChild } from '@angular/core';
import { CameraModel } from 'src/app/_models';
declare var $: any;
declare var videojs: any;
declare var document: any;

@Component({
  selector: 'app-multivideojs',
  templateUrl: './multivideojs.component.html',
  styleUrls: ['./multivideojs.component.css']
})
export class MultivideojsComponent implements OnInit, AfterViewInit {
  @ViewChild('videoElement') public videoElement: ElementRef;
  @Input() lstRecord: Array<RecordModel>;
  @Input() camera: CameraModel;
  loaded: boolean = false;
  isInit: boolean = false;
  isHls: boolean = false;
  player: any;
  isPlaying: boolean = false;
  constructor() { }

  ngOnInit() {
  }

  ngAfterViewInit() {
  }

  initPlayer() {
    this.isInit = true;
    let type = 'application/x-mpegURL';
    let techOrder = 'html5';
    const src = this.lstRecord[0].url;
    const myPlaylist = [];
    for (let i = 0; i < this.lstRecord.length; i++) {
      myPlaylist.push({
        sources: [{
          src: this.lstRecord[i].url,
          type: 'application/x-mpegURL'
        }
        ]
      });

    }
    this.player = videojs(`idMultiVideojs`,
      {
        techOrder: [techOrder],
        controls: false,
        preload: 'auto',
        fluid: true,
        sources: [{ src: src, type: type }]
      });
    this.loaded = true;
    this.player.playlist(myPlaylist);
    this.player.playlist.autoadvance(0);
    this.player.play();
  }
  autoNextURL(url, startTime) {
    this.player.src({ type: 'application/x-mpegURL', src: url });
    this.player.load();
    this.player.on('play', () => {
      this.player.currentTime(startTime);
    });
    this.player.play();
  }
  close() {
    this.isInit = false;
    this.player.dispose();
  }
  takeSnapshot() {
    const canvasElement = <HTMLCanvasElement>document.createElement('CANVAS');
    const objVideo = document.getElementById(this.videoElement.nativeElement.id);
    const context = canvasElement.getContext('2d');
    canvasElement.width = 1280;
    canvasElement.height = 768;
    context.fillRect(0, 0, 1280, 768);
    context.drawImage(objVideo, 0, 0, 1280, 768);
    const link = document.createElement('a');
    link.setAttribute('download', 'Snapshot.png');
    const dataURL = canvasElement.toDataURL();
    link.href = dataURL;
    document.body.appendChild(link);
    link.click();
  }
  z: any = 1;
  zoomInVideo() {
    if (this.z < 3.0) {
      this.z += 0.1;
    }
    this.player.zoomrotate({
      rotate: 0,
      zoom: this.z
    }
    );
  }
  zoomOutVideo() {
    if (this.z > 0.3) {
      this.z -= 0.1;
    }
    this.player.zoomrotate({
      rotate: 0,
      zoom: this.z
    }
    );
  }
  play(){
    this.player.play();
  }
  pause(){
    this.player.pause();
  }
}
