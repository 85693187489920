import {Component, DoCheck, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {element} from 'protractor';
import {BrowserStack} from 'protractor/built/driverProviders';
import {BunchModel} from 'src/app/_models';

@Component({
  selector: 'app-bunch-tables-action-group',
  templateUrl: './bunch-tables-action-group.component.html',
  styleUrls: ['./bunch-tables-action-group.component.css']
})
export class BunchTablesActionGroupComponent implements OnInit, DoCheck {

  @Input() listBunch: any[] = [];
  @Input() disableClickBunch: boolean = false;
  @Output() idRemove = new EventEmitter<any>();
  @Output() idEditBunch = new EventEmitter<any>();
  beforeId: any = null;
  @Input() searchKey = '';
  idSubItem: any = null;
  userListener: any[] = [];
  @Input() reload = false;
  listBunchPerPage: any[] = [];
  currentPage: number = 1;
  constructor() {
  }

  levelDisplay: any;

  ngOnInit(): void {

    this.reload = false;
  }

  ngDoCheck(): void {
    if (this.searchKey !== '') {
      this.findingBunch(this.listBunch);
    }
    if (this.reload) {
      this.ngOnInit();
    }
  }

  findingBunch(listFinding:any[]){
    this.listBunch.forEach(
      element => {
        if(element.bunch.name.toLowerCase().includes(this.searchKey.toLocaleLowerCase())){
          element.isActive = true;
        }
        else{
          element.isActive = false;
          element.isClick  = false;
          element.listCamera.forEach(cam => {
            if(cam.name.toLowerCase().includes(this.searchKey.toLowerCase())){
              element.isActive = true;
              element.isClick  = true;
            }
          });
        }
      }
    );
  }

  getSubIdparent($event) {
    this.idSubItem = $event.bunch.id;
    this.levelDisplay = $event.level;

  }

  getItemStyle(item) {
    var style = '';
    if (item.bunch.parentId > 0) {
      style += ' background-color: #f1f1f5; ';
    }
    return style;
  }


  getRemoveId($event: any) {

    this.idRemove.emit($event);
  }

  getEditModal($event: any) {
    var bunch = new BunchModel();
    bunch.id = $event.id;
    bunch.name = $event.name;
    bunch.parentId = $event.parentId;

    this.idEditBunch.emit(bunch);
  }


  getIsClick($event) {
    if ($event === true) {
      this.disableLevel();
    }
    this.disableChildren($event, this.idSubItem);
  }

  disableLevel() {
    this.listBunch.forEach(
      element => {
        if (element.level > this.levelDisplay) {
          element.isActive = false;
          element.isClick = false;
        } else {
          if (element.level == this.levelDisplay && element.bunch.id != this.idSubItem) {
            element.isClick = false;
          }
        }
      }
    );
  }

  disableChildren($event: any, id: any) {
    this.listBunch.forEach(
      element => {
        if (element.bunch.parentId == id) {
          element.isActive = $event;
          if ($event === false) {
            element.isClick = $event;
            this.disableChildren($event, element.bunch.id);
          }
        }
      }
    );
  }
}
