import { CameraService } from "./../../_services/camera.service";
import {
  AccountCameraModel,
  CameraModel,
  CameraType,
} from "./../../_models/camera.model";
import { FormBuilder, FormGroup } from "@angular/forms";
import { Component, DoCheck, Input, OnInit } from "@angular/core";
import { AuthenticationService } from "../../_services/authentication.service";
import { FormValidateService } from "../../_services/form-validate.service";

import { AuthHepler } from "../../_helpers/auth.helper";
import { CookieService } from "ngx-cookie-service";
import Swal from "sweetalert2";
import { TreeviewService } from "src/app/_services/treeview.service";
import { AccountModel, BunchModel } from "src/app/_models";
import { ObjectNodeBunch } from "src/app/_models/object_tree_bunch";
import { AppComponent } from "src/app/app.component";
import { LanguageModel } from "src/app/_models/language.model";

declare var $: any;
declare var videojs: any;
import * as _ from "lodash";
import { CopyPermissionService } from "src/app/_services";

@Component({
  selector: "app-camera",
  templateUrl: "./camera.component.html",
  styleUrls: ["./camera.component.css"],
})
export class CameraComponent extends AuthHepler implements OnInit, DoCheck {
  @Input() camera: CameraModel;
  levelDisplay: any;
  loaded: boolean = false;
  isOwner: boolean = false;
  bunchList: BunchModel[];
  term: string = "";
  reload: boolean = false;
  titleSelect: string = "Trạng thái Camera";
  order: string = "name";
  listData: any[] = [];
  tempData: any[] = [];
  langModel = new LanguageModel();
  listStatus = [
    { key: "ALL", value: "Tất cả trạng thái" },
    { key: "CONNECTED", value: "Hoạt động" },
    { key: "DISCONNECTED", value: "Không hoạt động" },
  ];
  idSubItem: any = null;
  filterStatus: any = null;
  pageSize: number = 10;
  currentPage: number = 0;
  pageSizes = [5, 10, 20, 50, 100, 1000];
  totalItems: any;
  addViettelCamForm: FormGroup;
  updateThirdForm: FormGroup;
  updateViettelForm: FormGroup;
  editViettelCamForm: FormGroup;
  addCamForm: FormGroup;
  configPTZForm: FormGroup;
  listCamera: CameraModel[];
  listTypeCam: CameraType[];
  protocol: string;
  webProtocol: string;
  title: string;
  beforeId: any = null;
  btnSubmit: string;
  isEditCamViettel: boolean;
  isViewStream: boolean = true;
  // player1: any;
  srcVideo: string;
  userListener: any[] = [];
  disableChoosePageSize = false;
  disableClickBunch: boolean = false;
  reloadTable: any = false;
  lengthPagination = 0;
  listBunchPerPage = [];
  listDataWithSearch = [];
  listAccCamera: AccountCameraModel[] = [];
  txtSearch = "";
  userLogin = "";

  constructor(
    private _treeService: TreeviewService,
    private _camService: CameraService,
    private _copyPerService: CopyPermissionService,
    private _fb: FormBuilder,
    public _camValidateService: FormValidateService,
    public authenticationService: AuthenticationService,
    private _appComponent: AppComponent,
    public cookie: CookieService
  ) {
    super(authenticationService, authenticationService, cookie);
  }

  ngOnInit(): void {
    this.createForm();
    this.camera = new CameraModel();
    this.getAllTypeCamera();
    this.getCameraTreeview();
    this.userLogin = JSON.parse(this.cookie.get("currentUser")).username;
    //#region Chuyển ngữ
    this._appComponent.translate
      .get("ad_add_viettel_cam")
      .subscribe((res: string) => {
        this.langModel.title = res;
      });
    this._appComponent.translate
      .get("li_add_vcam_succ")
      .subscribe((res: string) => {
        this.langModel.content = res;
      });
    this._appComponent.translate
      .get("li_err_add_cam")
      .subscribe((res: string) => {
        this.langModel.title1 = res;
      });
    this._appComponent.translate
      .get("li_err_support")
      .subscribe((res: string) => {
        this.langModel.content1 = res;
      });
    this._appComponent.translate
      .get("li_update_vcam")
      .subscribe((res: string) => {
        this.langModel.title2 = res;
      });
    this._appComponent.translate
      .get("li_update_vcam_succ")
      .subscribe((res: string) => {
        this.langModel.content2 = res;
      });
    this._appComponent.translate
      .get("ad_add_third_cam")
      .subscribe((res: string) => {
        this.langModel.title3 = res;
      });
    this._appComponent.translate
      .get("li_add_thirdcam_succ")
      .subscribe((res: string) => {
        this.langModel.content3 = res;
      });
    this._appComponent.translate
      .get("li_check_sln")
      .subscribe((res: string) => {
        this.langModel.title4 = res;
      });
    this._appComponent.translate
      .get("li_export_succ")
      .subscribe((res: string) => {
        this.langModel.content4 = res;
      });
    this._appComponent.translate
      .get("li_notiffi")
      .subscribe((res: string) => {
        this.langModel.title5 = res;
      });
    this._appComponent.translate
      .get("li_ques_del_cam")
      .subscribe((res: string) => {
        this.langModel.content5 = res;
      });
    this._appComponent.translate
      .get("li_del_camera")
      .subscribe((res: string) => {
        this.langModel.title6 = res;
      });
    this._appComponent.translate
      .get("li_del_camera_succ")
      .subscribe((res: string) => {
        this.langModel.content6 = res;
      });
    this._appComponent.translate.get("li_del").subscribe((res: string) => {
      this.langModel.title7 = res;
    });
    this._appComponent.translate
      .get("ad_update_thirdcam")
      .subscribe((res: string) => {
        this.langModel.content7 = res;
      });
    this._appComponent.translate
      .get("li_fail")
      .subscribe((res: string) => {
        this.langModel.title8 = res;
      });
    this._appComponent.translate
      .get("li_had_error")
      .subscribe((res: string) => {
        this.langModel.content8 = res;
      });
    this._appComponent.translate
      .get("li_change_protocol")
      .subscribe((res: string) => {
        this.langModel.title9 = res;
      });
    this._appComponent.translate
      .get("li_change_protocol_succ")
      .subscribe((res: string) => {
        this.langModel.content9 = res;
      });
    this._appComponent.translate
      .get("ad_update_thirdcam_succ")
      .subscribe((res: string) => {
        this.langModel.content10 = res;
      });
    this._appComponent.translate
      .get("ad_add_new_viettel")
      .subscribe((res: string) => {
        this.langModel.content11 = res;
      });
    this._appComponent.translate
      .get("ad_add_thirdcam")
      .subscribe((res: string) => {
        this.langModel.content12 = res;
      });
    this._appComponent.translate.get("ad_add").subscribe((res: string) => {
      this.langModel.content13 = res;
    });
    this._appComponent.translate
      .get("ad_status_cam")
      .subscribe((res: string) => {
        this.langModel.content14 = res;
      });
    this._appComponent.translate
      .get("li_warn")
      .subscribe((res: string) => {
        this.langModel.title15 = res;
      });
    this._appComponent.translate
      .get("ad_warn_passcam")
      .subscribe((res: string) => {
        this.langModel.content15 = res;
      });
    this._appComponent.translate
      .get("ad_edit_thirdcam")
      .subscribe((res: string) => {
        this.langModel.title10 = res;
      });

    this._appComponent.translate
      .get("li_all_status")
      .subscribe((res: string) => {
        this.langModel.title11 = res;
      });
    this._appComponent.translate
      .get("ad_activiti")
      .subscribe((res: string) => {
        this.langModel.title12 = res;
      });
    this._appComponent.translate
      .get("ad_not_activiti")
      .subscribe((res: string) => {
        this.langModel.title13 = res;
      });
    this._appComponent.translate
      .get("ad_update")
      .subscribe((res: string) => {
        this.langModel.title14 = res;
      });
    this.titleSelect = this.langModel.content14;
    //#endregion
    this.listStatus = [
      { key: "ALL", value: this.langModel.title11 },
      { key: "CONNECTED", value: this.langModel.title12 },
      { key: "DISCONNECTED", value: this.langModel.title13 },
    ];
  }

  ngDoCheck(): void {}

  loopDataChild(subId: any, data: any[]) {
    var indexAt = false;
    var arrayFisrt = [];
    var arrayLast = [];
    var subArray = [];
    var dataResult = [];
    if (data.length > 0) {
      data.map((element) => {
        if (!indexAt) {
          arrayFisrt.push(element);
          if (element.bunch.id == subId) {
            indexAt = true;
            subArray = element.listChild.map((subElement, index) => {
              subElement.displayIndex =
                element.displayIndex + (index + 1) + ".";
              return subElement;
            });
          } else {
            if (element.listChild.length > 0) {
              subArray = this.loopDataChild(subId, element.listChild);
            }
          }
        } else {
          arrayLast.push(element);
        }
      });
      dataResult = arrayFisrt.concat(subArray).concat(arrayLast);
    }
    return dataResult;
  }

  createForm() {
    this.updateViettelForm = this._fb.group({
      name: [
        "",
        this._camValidateService.CameraValidate.nameValidate.validator,
      ],
      cloudId: [""],
    });
    this.updateThirdForm = this._fb.group({
      name: [
        "",
        this._camValidateService.CameraValidate.nameValidate.validator,
      ],
      streamUrl: [
        "",
        this._camValidateService.CameraValidate.streamValidate.validator,
      ],
    });
    this.addViettelCamForm = this._fb.group({
      name: [
        "",
        this._camValidateService.CameraValidate.nameValidate.validator,
      ],
      cloudId: [
        "",
        this._camValidateService.CameraValidate.cloudIDValidate.validator,
      ],
      secureCode: [
        "",
        this._camValidateService.CameraValidate.codeValidate.validator,
      ],
    });
    this.editViettelCamForm = this._fb.group({
      name: [
        "",
        this._camValidateService.CameraValidate.nameValidate.validator,
      ],
    });
    this.addCamForm = this._fb.group({
      name: [
        "",
        this._camValidateService.CameraValidate.nameValidate.validator,
      ],
      streamUrl: [
        "",
        this._camValidateService.CameraValidate.streamValidate.validator,
      ],
    });
    this.configPTZForm = this._fb.group({
      name: [""],
      host: [""],
      port: [""],
      username: [""],
      password: [""],
    });
  }
  //#region Thêm, chỉnh sửa camera
  EditModal(_camera: CameraModel) {
    this.isOwnerCam(_camera.id);
    this.camera = _camera;
    this.isCheckedAll=true;
    this._camService.detailCamera(_camera.id).subscribe(
      (res) => {
        // 30-07-24
        this.camera = res.camera;
        this.camera.streamUrl = res.camera.stream.sourceUrl;
        if (res.camera.provider == "THIRD_PARTY") {
          $("#idTypeCam").val(res.camera.model.id);
          $("#updateThirdCam").modal("show");
        } else {
          $("#updateViettelCam").modal("show");
        }
        this.btnSubmit = this.langModel.title14;
      },
      (err) => {
        if (err === "Unauthorized") {
          this.unAuthHandle(() => this.EditModal(_camera));
        } else {
          Swal.fire(this.langModel.title5, this.langModel.content8, "error");
          console.log("Lỗi lấy chi tiết cam " + err);
        }
      }
    );
  }
  AddViettelCam() {
    this._camService.addCamViettel(this.camera).subscribe(
      (res) => {
        $("#addViettelCamera").modal("hide");
        Swal.fire(this.langModel.title, this.langModel.content, "success").then(
          () => this.getCameraTreeview()
        );
      },
      (err) => {
        Swal.fire(this.langModel.title1, this.langModel.content1, "error");
      }
    );
  }
  UpdateViettelCam() {
    //Lấy danh sách camera bỏ gán
    let lstACRemove = this.listAccCamera
      .filter((ac) => ac.checked === false)
      .map((ac) => ac.id);
    if (lstACRemove.length > 0) {
      Swal.fire({
        title: this.t('cam_update_viettel'),
        html:
          '<b>'+this.t('cam_question')+'<span style="color:#e03">' +
          this.camera.name +
          "</span>"+this.t('cam_question2')+"</b>",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#E03",
        confirmButtonText: "OK",
        cancelButtonText: "Hủy",
      }).then((result) => {
        if (result.value) {
          this._camService.updateCamViettel(this.camera).subscribe(
            (res) => {
              this._copyPerService.removeListCamera(lstACRemove).subscribe(
                (res1) => {
                  Swal.fire(
                    this.langModel.title2,
                    this.langModel.content2,
                    "success"
                  ).then(() => {
                    $("#updateViettelCam").modal("hide");
                    this.getCameraTreeview();
                  });
                },
                (err1) => {
                  Swal.fire(
                    this.t('cam_update_viettel'),
                    this.t("cam_error"),
                    "error"
                  );
                }
              );
            },
            (err) => {
              Swal.fire(
                this.langModel.title1,
                this.langModel.content1,
                "error"
              );
            }
          );
        } else if (result.dismiss) {
          this.getCameraTreeview();
        }
      });
    } else {
      this._camService.updateCamViettel(this.camera).subscribe(
        (res) => {
          $("#updateViettelCam").modal("hide");
          Swal.fire(
            this.langModel.title2,
            this.langModel.content2,
            "success"
          ).then(() => this.getCameraTreeview());
        },
        (err) => {
          Swal.fire(this.langModel.title1, this.langModel.content1, "error");
        }
      );
    }
  }
  AddThirdCam() {
    //Check luồng camera tối đa 1 kí tự @ và 3 kí tự :
    let url = this.camera.streamUrl;
    const count = (str, ch) => (str.match(new RegExp(ch, "g")) || []).length;
    let countChar1 = count(url, "@");
    let countChar2 = count(url, ":");
    if (countChar1 > 1 || countChar2 > 3) {
      Swal.fire(this.langModel.title15, this.langModel.content15, "warning");
    } else {
      let idCamera = $("#ipCamera").val();
      this.camera.typeCam = $("#camera-model").val();
      this._camService.addThirdCam(this.camera).subscribe(
        (res) => {
          $("#addCamera").modal("hide");
          Swal.fire(
            this.langModel.title3,
            this.langModel.content3,
            "success"
          ).then(() => {
            this.disableChoosePageSize = false;
            this.disableClickBunch = false;
            this.term = "";
            this.txtSearch = "";
            this.filterStatus = null;
            this.reload = true;
            this.ngOnInit();
          });
        },
        (err) => {
          Swal.fire(this.langModel.title1, this.langModel.content1, "error");
        }
      );
    }
  }
  UpdateThirdCam() {
    let url = this.camera.streamUrl;
    const count = (str, ch) => (str.match(new RegExp(ch, "g")) || []).length;
    let countChar1 = count(url, "@");
    let countChar2 = count(url, ":");
    if (countChar1 > 1 || countChar2 > 3) {
      Swal.fire(this.langModel.title15, this.langModel.content15, "warning");
    } else {
      let idCamera = $("#ipCamera").val();
      this.camera.typeCam = $("#idTypeCam").val();
      //Lấy danh sách camera bỏ gán
      let lstACRemove = this.listAccCamera
        .filter((ac) => ac.checked === false)
        .map((ac) => ac.id);
      if (lstACRemove.length > 0) {
        Swal.fire({
          title: this.t('cam_update_third'),
          html:
            '<b>'+this.t('cam_question')+'<span style="color:#e03">' +
            this.camera.name +
            "</span>"+this.t('cam_question2')+"</b>",
          icon: "question",
          showCancelButton: true,
          confirmButtonColor: "#E03",
          confirmButtonText: "OK",
          cancelButtonText: this.t('li_cancel'),
        }).then((result) => {
          if (result.value) {
            this._camService.updateThirdCam(this.camera).subscribe(
              (res) => {
                this._copyPerService.removeListCamera(lstACRemove).subscribe(
                  (res1) => {
                    Swal.fire(
                      this.langModel.content7,
                      this.langModel.content10,
                      "success"
                    ).then(() => {
                      $("#updateThirdCam").modal("hide");
                      this.disableChoosePageSize = false;
                      this.disableClickBunch = false;
                      this.term = "";
                      this.txtSearch = "";
                      this.filterStatus = null;
                      this.reload = true;
                      this.ngOnInit();
                    });
                  },
                  (err1) => {
                    Swal.fire(
                      this.t('cam_update_third'),
                      this.t('cam_error'),
                      "error"
                    );
                  }
                );
              },
              (err) => {
                Swal.fire(
                  this.langModel.title1,
                  this.langModel.content1,
                  "error"
                );
              }
            );
          } else if (result.dismiss) {
            this.getCameraTreeview();
          }
        });
      } else {
        this._camService.updateThirdCam(this.camera).subscribe(
          (res) => {
            Swal.fire(
              this.langModel.content7,
              this.langModel.content10,
              "success"
            ).then(() => {
              $("#updateThirdCam").modal("hide");
              this.disableChoosePageSize = false;
              this.disableClickBunch = false;
              this.term = "";
              this.txtSearch = "";
              this.filterStatus = null;
              this.reload = true;
              this.ngOnInit();
            });
          },
          (err) => {
            Swal.fire(this.langModel.title1, this.langModel.content1, "error");
          }
        );
      }
    }
  }
  isOwnerCam(_camId: any): boolean {
    this.isOwner = false;
    this._copyPerService.getOwnerCamera(_camId).subscribe(
      (res) => {
        this.getListAccount(_camId);
        if (this.userLogin === res.username) {
          this.isOwner = true;
          return true;
        } else {
          this.isOwner = false;
          return false;
        }
      },
      (err) => {
        console.log("Lỗi check owner Camera");
      }
    );
    return false;
  }
  //#endregion
  getAllTypeCamera() {
    this._camService.getAllModel().subscribe(
      (res) => {
        this.listTypeCam = res.data;
      },
      (err) => {
        if (err === "Unauthorized") {
          this.unAuthHandle(() => this.getAllTypeCamera());
        } else {
          console.log("Lỗi lấy danh sách cam ngoài " + err);
        }
      }
    );
  }
  removeCamera(_camId: number) {
    Swal.fire({
      title: this.langModel.title5,
      text: this.langModel.content5,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: this.langModel.title7,
    }).then((result) => {
      if (result.value) {
        this._camService.removeCamera(_camId).subscribe(
          (rs) => {
            Swal.fire(
              this.langModel.title6,
              this.langModel.content6,
              "success"
            ).then(() => {
              this.getCameraTreeview();
              this.getAllTypeCamera();
            });
          },
          (err) => {
            if (err === "Unauthorized") {
              this.unAuthHandle(() => this.removeCamera(_camId));
            } else {
              Swal.fire({
                icon: "error",
                title: this.langModel.title8,
                text: this.langModel.content8,
              });
            }
          }
        );
      } else if (result.dismiss) {
      }
    });
  }
  ApplyProtocol() {
    if (this.protocol != "" && this.protocol != undefined) {
      this._camService.changeStream(this.camera.id, this.protocol).subscribe(
        (res) => {
          $("#streamingProtocol").modal("hide");
          Swal.fire(
            this.langModel.title9,
            this.langModel.content9,
            "success"
          ).then(() => this.getCameraTreeview());
        },
        (err) => {
          if (err === "Unauthorized") {
            this.unAuthHandle(() => this.ApplyProtocol());
          } else {
            console.log("lỗi thay đổi giao thức stream " + err);
          }
        }
      );
    }
    if (this.webProtocol != "" && this.webProtocol != undefined) {
      this._camService
        .changeWebStream(this.camera.id, this.webProtocol)
        .subscribe(
          (res) => {
            $("#streamingProtocol").modal("hide");
            Swal.fire(
              this.langModel.title9,
              this.langModel.content9,
              "success"
            ).then(() => this.getCameraTreeview());
          },
          (err) => {
            if (err === "Unauthorized") {
              this.unAuthHandle(() => this.ApplyProtocol());
            } else {
              console.log("lỗi thay đổi giao thức stream " + err);
            }
          }
        );
    }
  }
  ShowModal(_idModal: string, _currCamera: CameraModel) {
    if (_currCamera != null) this.camera = _currCamera;
    else this.camera = new CameraModel();
    if (_idModal == "streamingProtocol") {
      if (this.camera.liveProtocol == "RTC") {
        $("#protocol-1").prop("checked", false);
        $("#protocol-2").prop("checked", true);
      } else if (this.camera.liveProtocol == "HLS") {
        $("#protocol-1").prop("checked", true);
        $("#protocol-2").prop("checked", false);
      } else {
        $("#protocol-1").prop("checked", false);
        $("#protocol-2").prop("checked", false);
      }
    } else if (_idModal == "addViettelCamera") {
      this.isEditCamViettel = false;
      this.title = this.langModel.content11;
    } else {
      this.isViewStream = true;
      this.title = this.langModel.content12;
    }
    this.btnSubmit = "Thêm";
    $("#" + _idModal).modal("show");
  }
  disableLevel() {
    this.listData.forEach((element) => {
      if (element.level > this.levelDisplay) {
        element.isActive = false;
        element.isClick = false;
      } else {
        if (
          element.level == this.levelDisplay &&
          element.bunch.id != this.idSubItem
        ) {
          element.isClick = false;
        }
      }
    });
  }
  disableChildren($event: any, id: any) {
    this.listData.forEach((element) => {
      if (element.bunch.parentId == id) {
        element.isActive = $event;
        if ($event === false) {
          element.isClick = $event;
          this.disableChildren($event, element.bunch.id);
        }
      }
    });
  }
  // StreamPreview() {
  //   this.isViewStream = true;
  //   this.srcVideo = $("#url-stream").val();
  //   if (this.player1 != undefined) {
  //     this.player1.src(this.srcVideo);
  //   }
  //   this.player1 = videojs(`videojs-preview`, {
  //     techOrder: ["flash"],
  //     autoplay: true,
  //     preload: "auto",
  //     fluid: true,
  //     sources: [{ src: this.srcVideo, type: "video/mp4" }],
  //   });
  //   this.isViewStream = false;
  //   this.player1.load();
  //   this.player1.play();
  //   this.player1.on("pause", () => {
  //     this.loaded = false;
  //     this.player1.one("play", () => {
  //       this.loaded = true;
  //       this.player1.load();
  //       this.player1.play();
  //     });
  //   });

  //   this.player1.on("seeking", () => {
  //     this.player1.load();
  //     this.player1.play();
  //   });
  // }
  configPTZ() {
    console.log("configPTZ");
    this._camService.ptzUpdate(this.camera).subscribe(
      (res) => {
        $("#configPTZ").modal("hide");
        Swal.fire("Cấu hình PTZ", "Cấu hình PTZ thành công!", "success").then(
          () => this.getCameraTreeview()
        );
      },
      (err) => {
        if (err === "Unauthorized") {
          this.unAuthHandle(() => this.configPTZ());
        } else {
          // console.log('lỗi cấu hình ptz ' + err);
        }
      }
    );
  }
  getCameraTreeview() {
    console.log("getCameraTreeview");
    this.userListener = [];
    this._treeService.getCameraTree().subscribe(
      (res) => {
        this.bunchList = res.listBunch;
        // this.bunchList.forEach(element => {
        //   this.getCameraInBunch(element);
        // });
        this.loopMultiple(this.bunchList);
        if (res.listCamera.length > 0) {
          var bunch = new ObjectNodeBunch();
          let bunchVariable = new BunchModel();
          bunchVariable.name = "Chưa Phân nhóm";
          bunchVariable.id = -1;
          // this.bunchList.push(bunchVariable);
          bunch.bunch = bunchVariable;
          bunch.listChild = [];
          bunch.isActive = true;
          bunch.level = 0;
          bunch.isClick = false;
          bunch.listCamera = res.listCamera.map((element) => {
            element.display = true;
            return element;
          });

          this.listData.push(bunch);
          this.totalItems = this.listData?.length;
          // this.paginator.totalItems = this.listData?.length;
          this.tempData = this.listData;
        }
        //gán danh sách camera cho listBunchPerPage để view
        let index = 0;
        this.listBunchPerPage = [];
        this.listData.forEach((element) => {
          if (index <= this.pageSize) {
            this.listBunchPerPage.push(element);
            if (element.level == 0) {
              index++;
            }
          }
        });
        if (index == Number(this.pageSize) + 1) {
          this.listBunchPerPage.pop();
        }
        // if(this.term !== "" || this.filterStatus !== null){
        //   this.DoSearch();
        // }
        if (this.currentPage != 0) {
          this.onChangePage(this.currentPage);
        }
        this.reload = false;
      },
      (err) => {
        if (err === "Unauthorized") {
          this.unAuthHandle(() => this.getCameraTreeview());
        } else {
          // console.log("getCameraTreeview " + err);
        }
      }
    );
  }
  loopMultiple(listParent: any[]) {
    console.log("loopMultiple");

    let i = 0;
    let listLength = listParent.length;
    var parent = [];
    while (i < listLength) {
      if (listParent[i].parentId == null) {
        var obj = new ObjectNodeBunch();
        obj.level = 0;
        obj.bunch = listParent[i];
        obj.isActive = true;
        this.getCameraInBunchMaptoObjectModel(obj);
        parent.push(obj);
      }
      i++;
    }
    parent.forEach((element) => {
      listParent.splice(listParent.indexOf(element.bunch), 1);
    });
    this.lengthPagination = parent.length;
    this.nodeStructer(parent);
  }
  nodeStructer(list: any[]) {
    var parent = [];
    var data = [];
    list.forEach((element) => {
      parent.push(element);
      data = this.sortingChildren(element);
      parent = parent.concat(data);
    });
    this.listData = parent;
  }
  sortingChildren(item) {
    var resoult = [];
    var data = [];
    this.bunchList.forEach((element) => {
      if (element.parentId == item.bunch.id) {
        var bunch = new ObjectNodeBunch();
        bunch.level = item.level + 1;
        bunch.bunch = element;
        item.listChild.push(element.id);
        this.getCameraInBunchMaptoObjectModel(bunch);
        resoult.push(bunch);
        // this.bunchList.splice(this.bunchList.indexOf(element),1);
        data = this.sortingChildren(bunch);
        resoult = resoult.concat(data);
      }
    });
    return resoult;
  }
  getCameraInBunchMaptoObjectModel(_bunch: any) {
    console.log("getCameraInBunchMaptoObjectModel");
    this._treeService.getCameraInBunch(_bunch.bunch.id).subscribe(
      (res) => {
        _bunch.listCamera = res.listCamera.map((element) => {
          element.display = true;
          return element;
        });
      },
      (err) => {
        if (err === "Unauthorized") {
          this.unAuthHandle(() =>
            this.getCameraInBunchMaptoObjectModel(_bunch.bunch.id)
          );
        } else {
          console.log("getCameraInBunch " + err);
        }
      }
    );
  }
  getShowModal($event: any) {
    console.log("getShowModal");

    var cam = new CameraModel();
    cam.id = $event.cam.id ? $event.cam.id : null;
    cam.name = $event.cam.name ? $event.cam.name : null;
    // cam.streamUrl = $event.cam.streamUrl ? $event.cam.streamUrl : null;
    cam.connectionStatus = $event.cam.connectionStatus
      ? $event.cam.connectionStatus
      : null;
    cam.cloudId = $event.cam.cloudId ? $event.cam.cloudId : null;
    cam.provider = $event.cam.provider ? $event.cam.provider : null;
    cam.host = $event.cam.host ? $event.cam.host : null;
    cam.port = $event.cam.port ? $event.cam.port : null;
    // cam.secureCode = $event.cam.secureCode ? $event.cam.secureCode : null;
    // cam.selected = $event.cam.selected ? $event.cam.selected : null;
    // cam.typeCam = $event.cam.typeCam ? $event.cam.typeCam : null;
    cam.username = $event.cam.username ? $event.cam.username : null;
    cam.password = $event.cam.password ? $event.cam.password : null;
    cam.liveProtocol = $event.cam.liveProtocol ? $event.cam.liveProtocol : null;
    // cam.dateExpiration = $event.cam.dateExpiration ? $event.cam.dateExpiration : null;
    // cam.state = $event.state ? $event.cam.state : 0;

    // var cam = $event;
    this.ShowModal($event.option, cam);
  }
  getRemoveId($event: any) {
    this.removeCamera($event);
  }
  getEditModal($event: any) {
    var cam = new CameraModel();
    cam.id = $event.id ? $event.id : null;
    cam.name = $event.name ? $event.name : null;
    cam.connectionStatus = $event.connectionStatus
      ? $event.connectionStatus
      : null;
    cam.cloudId = $event.cloudId ? $event.cloudId : null;
    cam.provider = $event.provider ? $event.provider : null;
    cam.host = $event.host ? $event.host : null;
    cam.port = $event.port ? $event.port : null;
    cam.username = $event.username ? $event.username : null;
    cam.password = $event.password ? $event.password : null;
    this.EditModal(cam);
  }
  getFilter($event: any) {
    console.log("getFilter");
    this.titleSelect = $event.value;
    if ($event.key === this.listStatus[0].key) {
      this.filterStatus = null;
      if (this.term === "") {
        this.reloadTable = true;
        this.ngOnInit();
        this.disableChoosePageSize = false;
        this.disableClickBunch = false;
      } else {
        this.DoSearch();
      }
    } else {
      this.filterStatus = $event.key;
      this.DoSearch();
    }
  }
  getSearching($event: any) {
    this.term = $event.target.value;
    if ($event.target.value === "") {
      this.ngOnInit();
      if (this.filterStatus === null) {
        this.reloadTable = true;
        this.ngOnInit();
        this.disableChoosePageSize = false;
        this.disableClickBunch = false;
      }
    } else {
      // this.term = $event.target.value;
      this.DoSearch();
    }
  }
  DoSearch() {
    console.log("DoSearch");
    console.log(this.listData);
    if (this.term === "" && this.filterStatus == null) {
      this.reloadTable = true;
      this.ngOnInit();
      this.disableChoosePageSize = false;
      this.disableClickBunch = false;
    } else {
      this.listDataWithSearch = [];
      this.disableChoosePageSize = true;
      this.pageSize = 10;
      this.disableClickBunch = true;
      //change Status
      if (this.term === "" && this.filterStatus != null) {
        this.listData.forEach((element) => {
          if (element.listCamera.length == 0) {
            element.isActive = false;
            element.isClick = false;
          } else {
            element.isActive = false;
            element.isClick = false;
            let check = false;
            element.listCamera.forEach((cam) => {
              if (cam.connectionStatus === this.filterStatus) {
                cam.display = true;
                element.isActive = true;
                element.isClick = true;
                check = true;
              } else {
                cam.display = false;
              }
            });
            if (check) {
              this.listDataWithSearch.push(element);
            }
          }
        });
      }
      //change Search
      if (this.term !== "" && this.filterStatus == null) {
        console.log(this.term);
        console.log(this.listData);

        this.listData.forEach((element) => {
          console.log(element);
          if (element.listCamera.length === 0) {
            console.log("element.listCamera.length == 0");
            element.isActive = false;
            element.isClick = false;
          } else {
            console.log("element.listCamera.length != 0");
            element.isActive = false;
            element.isClick = false;
            let check = false;
            element.listCamera.forEach((cam) => {
              if (
                cam.name.toLowerCase().includes(this.term.toLowerCase()) ||
                cam.cloudId.toLowerCase().includes(this.term.toLowerCase())
              ) {
                cam.display = true;
                element.isActive = true;
                element.isClick = true;
                check = true;
              } else {
                cam.display = false;
              }
            });
            if (check) {
              this.listDataWithSearch.push(element);
            }
          }
        });
      }
      //change Search and Status
      if (this.term !== "" && this.filterStatus != null) {
        this.listData.forEach((element) => {
          if (element.listCamera.length == 0) {
            element.isActive = false;
            element.isClick = false;
          } else {
            element.isActive = false;
            element.isClick = false;
            let check = false;
            element.listCamera.forEach((cam) => {
              if (
                (cam.name.toLowerCase().includes(this.term.toLowerCase()) ||
                  cam.cloudId
                    .toLowerCase()
                    .includes(this.term.toLowerCase())) &&
                cam.connectionStatus === this.filterStatus
              ) {
                cam.display = true;
                element.isActive = true;
                element.isClick = true;
                check = true;
              } else {
                cam.display = false;
              }
            });
            if (check) {
              this.listDataWithSearch.push(element);
            }
          }
        });
      }
      this.lengthPagination = this.listDataWithSearch.length;
      console.log(this.listDataWithSearch);

      let index = 0;
      this.listBunchPerPage = [];
      this.listDataWithSearch.forEach((element) => {
        if (index < this.pageSize) {
          this.listBunchPerPage.push(element);
          index++;
        }
      });
      console.log(this.listBunchPerPage);
    }
  }
  exportExcel() {
    this._camService.exportExcel().subscribe(
      (res) => {
        let blob = new Blob([res.blob()], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        let downloadURL = window.URL.createObjectURL(blob);
        let link = document.createElement("a");
        link.href = downloadURL;
        link.download = "danh_sach_camera.xlsx";
        link.click();
        Swal.fire({
          icon: "success",
          title: this.langModel.content4,
          showConfirmButton: false,
          timer: 1300,
        });
      },
      (error) => {
        if (error === "Unauthorized") {
          this.unAuthHandle(() => this.getCameraTreeview());
        } else {
          Swal.fire({
            icon: "error",
            title: this.langModel.content8,
            showConfirmButton: false,
            timer: 1300,
          });
          console.log(error);
        }
      }
    );
  }
  getPageSize($event: any) {
    console.log("getPageSize");
    // this.pageSize = $event.target.value;
    let index = 0;
    this.listBunchPerPage = [];
    this.listData.forEach((element) => {
      if (index <= this.pageSize) {
        this.listBunchPerPage.push(element);
        if (element.level == 0) {
          index++;
        }
      }
    });
    if (index == Number(this.pageSize) + 1) {
      this.listBunchPerPage.pop();
    }
  }
  onChangePage($event: any) {
    console.log("onChangePage");
    this.currentPage = Number($event);
    if (this.term === "" && this.filterStatus == null) {
      console.log(this.listData);
      let itemIndex = Number($event) * this.pageSize;
      let listBunchOnPage = [];
      let i = 0;
      this.listData.forEach((element) => {
        if (element.level == 0) {
          i++;
        }
        if (i > itemIndex) {
          listBunchOnPage.push(element);
        }
      });

      let index = 0;
      this.listBunchPerPage = [];
      listBunchOnPage.forEach((element) => {
        if (index <= this.pageSize) {
          this.listBunchPerPage.push(element);
          if (element.level == 0) {
            index++;
          }
        }
      });
      if (index == Number(this.pageSize) + 1) {
        this.listBunchPerPage.pop();
      }
    } else {
      let itemIndex = Number($event) * this.pageSize;
      let listBunchOnPage = [];
      for (var i = itemIndex; i < this.listDataWithSearch.length; i++) {
        listBunchOnPage.push(this.listDataWithSearch[i]);
      }
      let index = 0;
      this.listBunchPerPage = [];
      listBunchOnPage.forEach((element) => {
        if (index <= this.pageSize) {
          this.listBunchPerPage.push(element);
          index++;
        }
      });
      if (index == Number(this.pageSize) + 1) {
        this.listBunchPerPage.pop();
      }
    }
  }
  getListAccount(_camID: number) {
    this.listAccCamera= [];
    this._copyPerService.getListAccount(_camID).subscribe(
      (res) => {
        this.listAccCamera = res.accountAssignCamList.filter((c) => c.username !== this.userLogin);
        this.listAccCamera.forEach((item) => (item.checked = true));
      },
      (err) => {
        let errorBody = JSON.parse(err._body || "{}");
        if (errorBody.code === 401) {
          this.unAuthHandle(() => this.getListAccount(_camID));
        } else {
          console.log(
            "Lỗi việc lấy danh sách acc phân quyền: getListAccount " + err
          );
        }
      }
    );
  }
  //#region Checkbox người dùng
  isCheckedAll: boolean = true;
  areAllChecked(): boolean {
    return (
      this.listAccCamera.length > 0 &&
      this.listAccCamera.every((item) => item.checked)
    );
  }
  onSelectAllChange(event: any) {
    const isChecked = event.target.checked;
    this.listAccCamera.forEach((item) => (item.checked = isChecked));
  }
  onCheckboxChange(event: any) {
    //Hơi sida vì nó không binding two-way được nên phải manual
    const item = this.listAccCamera.find(
      (item) => item.username === event.target.id
    );
    if (item) {
      item.checked = event.target.checked;
    }
    // Cập nhật trạng thái của checkbox "Select All"
    if (this.listAccCamera.some((item) => item.checked !== true)) {
      // Nếu tất cả các checkbox đều được chọn, chọn checkbox "Select All"
      this.isCheckedAll = false;
    } else {
      // Nếu không phải tất cả đều được chọn, bỏ chọn checkbox "Select All"
      this.isCheckedAll = true;
    }
  }
  t(_key):string{
    return  this._appComponent.translate.instant(_key);
  }
  //#endregion
}
