import {Component, DoCheck, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.css']
})
export class PaginationComponent implements OnInit,OnChanges,DoCheck {

  @Input() maxsize:number;
  @Input() length:any;
  @Input() limit = 1;
  @Output() clickItem= new EventEmitter<any>();
  pages:Page[] = [];
  currentPage = 0;
  stylePreviousPage = "";
  styleNextPage = "";
  constructor() { }

  ngOnInit(): void {
    this.checkPreviousPage()
    this.checkNextPage()
  }

  ngDoCheck = (): void => {

  }
  ngOnChanges (changes: SimpleChanges): void {
    this.stylePreviousPage = "";
    this.styleNextPage = "";
    this.currentPage = 0;
    this.pages = [];
    for(let i = 0; i < this.length / this.limit;i++){
      var page = new Page();
      page.active = false;
      page.number = i;
      this.pages.push(page)
    }
    if(this.pages.length != 0){
      this.pages[0].active = true;
    }

    this.checkPreviousPage()
    this.checkNextPage()
  }

  getClickItem($event:any){
    this.currentPage = Number($event)
    this.pages.forEach(function (value) {
      value.active = false;
    });
    this.pages[$event].active = true;
    this.clickItem.emit($event);
    this.checkPreviousPage()
    this.checkNextPage()
  }

  nextPage() {
    if (this.styleNextPage !== "pointer-events: none; cursor: default;") {
      this.currentPage++;
      this.pages.forEach(function (value) {
        value.active = false;
      });
      this.pages[this.currentPage].active = true;
      this.clickItem.emit(this.currentPage);
    }
    this.checkNextPage()
    this.checkPreviousPage()
  }

  previousPage() {
    if (this.stylePreviousPage !== "pointer-events: none; cursor: default;") {
      this.currentPage--;
      this.pages.forEach(function (value) {
        value.active = false;
      });
      this.pages[this.currentPage].active = true;
      this.clickItem.emit(this.currentPage);
    }
    this.checkPreviousPage()
    this.checkNextPage()
  }

  checkPreviousPage(){
    if(this.currentPage === 0){
      this.stylePreviousPage = "pointer-events: none; cursor: default;";
    } else {
      this.stylePreviousPage = "";
    }
    // console.log(this.currentPage)
    // console.log("this.stylePreviousPage")
    // console.log(this.stylePreviousPage)
  }

  checkNextPage(){
    if(this.currentPage === Math.ceil(this.length / this.limit) - 1){
      this.styleNextPage = "pointer-events: none; cursor: default;";
    } else {
      this.styleNextPage = "";
    }
    // console.log(this.currentPage)
    // console.log("this.styleNextPage")
    // console.log(this.styleNextPage)
  }

}
class Page{
  number = 0;
  active = false;
}
