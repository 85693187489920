import { Injectable } from "@angular/core";
import { Http, URLSearchParams } from "@angular/http";
import { HttpClient } from "@angular/common/http";
import { ApiUrlService } from "./api-url.service";
import { BaseService } from "./base.service";
import { AuthenticationService } from "./authentication.service";
import { CookieService } from "ngx-cookie-service";

@Injectable()
export class RecordService extends BaseService {
  constructor(
    _http: Http,
    private http: HttpClient,
    _apiUrlService: ApiUrlService,
    public cookie: CookieService
  ) {
    super(_http, _apiUrlService, cookie);
  }

  getStream(recordId: number) {
    const params: URLSearchParams = new URLSearchParams();
    params.set("record-id", `${recordId}`);
    return this._http
      .get(
        this._apiUrlService.STREAM_PLAYBACK,
        this.authorizationOption().merge({ search: params })
      )
      .map(this.extractData)
      .catch(this.handleError);
  }
  public getUrlDownload(recordId: number) {
    const params: URLSearchParams = new URLSearchParams();
    params.set("record-id", `${recordId}`);
    return this._http
      .get(
        this._apiUrlService.STREAM_DOWNLOAD_RECORD,
        this.authorizationOption().merge({ search: params })
      )
      .map(this.extractData)
      .catch(this.handleError);
  }
  download(recordId: number) {
    this.getUrlDownload(recordId).subscribe((res) => {
      window.open(res.url, '_blank');
      // this.http.get(res.url, { responseType: "blob" }).subscribe((blob) => {
      //   const url = window.URL.createObjectURL(blob);
      //   const a = document.createElement("a");
      //   a.href = url;
      //   a.download = fileName;
      //   document.body.appendChild(a);
      //   a.click();
      //   document.body.removeChild(a);
      //   window.URL.revokeObjectURL(url);
      // });
    });
  }
}
