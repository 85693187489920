<div class="container-fluid p-2">
  <div class="page-title">
    Quản lý đăng ký faceID
  </div>
  <div class="page-action">
    <div class="row">
      <div class="col-12 col-sm-6">
        <div class="row">
          <div class="col-12 col-sm-6">
            <div class="search-form camera-search">
              <label for="search-camera-group" class="sr-only">Nhập từ khóa</label>
              <input type="text" class="form-control" id="search-user-face-id" placeholder="Tìm kiếm"
                style="border-radius: 10px 10px 10px 0px;"  name="keyWord"
                [(ngModel)]="keySearch">
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-6">
        <div class="camera-buttons">
          <button style="border-radius: unset" class="btn btn-primary btn-icon-text add-camera-group"
            data-toggle="modal" data-target="#addCameraGroup" (click)="openAddUserFaceIDModal()">
            <i class="icon icon-plus"></i>
            <span>Thêm FaceID</span>
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="page-table">
    <div class="container">
      <div class="table-responsive">
        <table class="table table-striped user-table">
          <thead>
            <tr class="header-table" style="padding:15px 0px 15px 0px; text-align: center;">
              <th scope="col">STT</th>
              <th scope="col" class="text-left">Họ và Tên</th>
              <th scope="col" class="text-left">Ảnh đăng kí</th>
              <th scope="col" class="text-left">Ngày đăng kí</th>
              <th scope="col" class="text-left">Trạng thái</th>
              <th scope="col">Thao tác</th>
            </tr>
          </thead>
          <tbody *ngIf="loading">
            <tr>
              <td colspan="7" class="text-center">
                <i class="fa fa-spinner fa-pulse"></i>
                <span>Đang tải dữ liệu...</span>
              </td>
            </tr>
          </tbody>
          <tbody *ngIf="!loading && listUsers">
            <tr
              *ngFor="let user of listUsers|search:{label:['name'],keyword:keySearch||''}| paginate: { itemsPerPage: limitPage || 10, currentPage: currPagination, totalItems: totalItems };let index=index;">
              <td>{{index + 1 + (limitPage * currentPage)}}</td>
              <td>{{user.name}}</td>
              <td>
                <img src={{user.data}} style="width: 88px; height: 127px" alt="" />
              </td>
              <td>{{user.dateCreated|date:'h:mm dd/MM/yyyy'}}</td>
              <td>
                <a href="javascript:;" style="margin:5px;" title="Kích hoạt Face ID" *ngIf="user.status.includes('INACTIVE');else elseBlock" (click)="updateStatusUser(user)">
                <i class="icon icon-extend"></i>
              </a>
              <ng-template #elseBlock>
                <a href="javascript:;" style="margin:5px;" title="Hủy kích hoạt Face ID" (click)="updateStatusUser(user)">
                <i class="icon icon-extend-active"></i>
              </a></ng-template>
              </td>
              
              <td>
                <nav class="action-list">
                  <a title="Chỉnh sửa FaceID" href="javascript:;" (click)="handleEditUserFaceID(user)">
                    <i class="icon icon-edit"></i>
                  </a>
                  <!-- <a title="Xóa FaceID" href="javascript:;" (click)="handleDeleteUser(user)">
                    <i class="icon icon-delete"></i>
                  </a> -->
                </nav>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="row no-gutters">
        <div class="col-6 text-left" style="display: flex;align-items: center;">
          <label>Số hàng hiển thị mỗi trang</label>
          <select class="border-control" [(ngModel)]="limitPage"
            style="padding: 0.375rem 1rem; margin-left: 0.5rem;cursor: pointer;" (change)="pageSizeChange()">
            <option *ngFor="let size of pageSizes" [ngValue]="size">
              {{ size }}
            </option>
          </select>
        </div>
        <div class="col-6 text-right">
          <div class="text-right">
            <nav class="nav-pagination">
              <pagination-template #p="paginationApi" (pageChange)="onPageChange($event)">
                <ul *ngIf="limitPage<=p.getTotalItems()" class="pagination">
                  <li class="page-item" [class.disabled]="p.isFirstPage()">
                    <a *ngIf="!p.isFirstPage()" class="page-link" (click)="p.previous()">
                      <i class="icon icon-arrow-left"></i></a>
                  </li>

                  <li class="page-item" [class.active]="p.getCurrent()===page.value" *ngFor="let page of p.pages">
                    <a class="page-link" (click)="p.setCurrent(page.value)">{{page.label}}</a>
                  </li>

                  <li class="page-item" [class.disabled]="p.isLastPage()">
                    <a *ngIf="!p.isLastPage()" class="page-link" (click)="p.next()">
                      <i class="icon icon-arrow-right"></i></a>
                  </li>
                </ul>
              </pagination-template>
            </nav>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Edit User face id modal -->
<div class="modal fade" id="updateUserFaceID" tabindex="-1" role="dialog" aria-labelledby="updateUserFaceID"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered user-modal" role="document">
    <div class="modal-content">
      <div class="modal-header bg-primary">
        <h5 class="modal-title" id="updateUserTitle">Chỉnh sửa FaceID</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <i class="icon icon-close"></i>
        </button>
      </div>
      <div class="modal-body">
        <form class="form" #editUserFaceIDForm="ngForm">
          <div class="row">
            <div class="col-12 col-sm-6">
              <div class="form-group">
                <label class="control-label" for="username">
                  Họ và tên
                  <span class="required">(<span class="asterisk">*</span>)</span>
                </label>
                <input name="editedUserName" type="text" class="form-control" [(ngModel)]="editedUserName">
              </div>
            </div>
            <div class="col-12 col-sm-6">
              <div class="form-group">
                <div style="margin-left: 3rem">
                  <label class="control-label" for="username">
                    Role
                    <span class="required">(<span class="asterisk">*</span>)</span>
                  </label>
                  <select name="userRole" [(ngModel)]="editedUserRoleId"
                    style="padding: 0.375rem 1rem; width: 100%; height: 3rem; border: 1px solid #E5E5E5; background-color: #fff;"
                    class="border-control">
                    <option *ngFor="let role of listRole" value="{{role.id}}" >{{role.detail}}</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <label class="control-label" for="username">
              Ảnh
              <span class="required">(<span class="asterisk">*</span>)</span>
            </label>
            <input name="editedImg" id="ipfile2" type="file" class="form-control" (change)="onFileChange($event)"
              [(ngModel)]="editedImg" style="visibility:hidden;">
            <div class="choose-file-container">
              <button class="choose-image-btn" onclick="document.getElementById('ipfile2').click()">
                <div>
                  <i class="icon take-photo-icon"></i>
                  <span>Chọn ảnh</span>
                </div>
              </button>
              <div class="img-container">
                <img [src]="editedImg" *ngIf="editedImg" style=" width:88px; height: 8rem;">
              </div>
            </div>
          </div>
          <div class="form-action">
            <button class="btn btn-outline-secondary mr-3" type="button" data-dismiss="modal">
              Hủy bỏ
            </button>
            <button class="btn btn-primary" (click)="updateFaceID()">Lưu</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<!-- Add user face id modal -->
<div class="modal fade" id="addUserFaceId" tabindex="-1" role="dialog" aria-labelledby="addUserFaceID"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered user-modal" role="document">
    <div class="modal-content">
      <div class="modal-header bg-primary">
        <h5 class="modal-title" id="addUserFaceIDTitle">Thêm FaceID</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <i class="icon icon-close"></i>
        </button>
      </div>
      <div class="modal-body">
        <form class="form" #addUserFaceIDForm="ngForm">
          <div class="row">
            <div class="col-12 col-sm-6">
              <div class="form-group">
                <label class="control-label" for="username">
                  Họ và tên
                  <span class="required">(<span class="asterisk">*</span>)</span>
                </label>
                <input name="userName" type="text" class="form-control" [(ngModel)]="userName">
              </div>
            </div>
            <div class="col-12 col-sm-6">
              <div class="form-group">
                <div style="margin-left: 3rem">
                  <label class="control-label" for="username">
                    Role
                    <span class="required">(<span class="asterisk">*</span>)</span>
                  </label>
                  <select name="userRole" [(ngModel)]="userRoleId"
                    style="padding: 0.375rem 1rem; width: 100%; height: 3rem; border: 1px solid #E5E5E5; background-color: #fff;"
                    class="border-control">
                    <option *ngFor="let role of listRole" value="{{role.id}}" >{{role.detail}}</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <label class="control-label" for="username">
              Ảnh
              <span class="required">(<span class="asterisk">*</span>)</span>
            </label>
            <input name="registeredImageUrls" id="ipfile" type="file" class="form-control" (change)="onFileChange($event)"
              [(ngModel)]="imageSrc" style="visibility:hidden;">
            <div class="choose-file-container">
              <button class="choose-image-btn" onclick="document.getElementById('ipfile').click()">
                <div>
                  <i class="icon take-photo-icon"></i>
                  <span>Chọn ảnh</span>
                </div>
              </button>
              <div class="img-container">
                <img [src]="imageSrc" *ngIf="imageSrc" style=" width:88px; height: 8rem">
              </div>
            </div>

          </div>
          <div class="form-action">
            <button class="btn btn-outline-secondary mr-3" type="button" (click)="onCloseModal()">
              Hủy bỏ
            </button>
            <button class="btn btn-primary" (click)="addFaceID()">Lưu</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>