<header class="landing-header">
  <nav class="navbar navbar-expand-xl navbar-dark p-0">
    <div class="container-fluid">
      <div class="row w-100">
        <div class="col-12 col-xl-2">
          <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#idcMenu"
            aria-controls="idcMenu" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="brand-logo">
            <a href="/home">
              <img class="logo" src="assets/logo camera.svg" alt="Cloud Camera" />
            </a>
          </div>
        </div>
        <div class="col-12 col-xl-10 clearfix">
          <div class="collapse navbar-collapse" id="idcMenu">
            <ul>
              <li  [class.active]="scroll >= 0 && scroll < 160">
                <a [routerLink]="['/home/']" fragment="sectionBanners" translate>{{'ldp_homepage'}}
                </a>
              </li>
              <li  [class.active]="scroll >= 160 && scroll < 280">
                <a [routerLink]="['/home/']" fragment="sectionFeatures" translate>{{'ldp_function'}}
                </a>
              </li>
              <li  [class.active]="scroll >= 280 && scroll < 400">
                <a [routerLink]="['/home/']" fragment="sectionSolutions" translate>{{'ldp_solution'}}
                </a>
              </li>
              <li  [class.active]="scroll >= 800 && scroll < 900">
                <a [routerLink]="['/home/']" fragment="sectionDownload" translate>{{'ldp_download'}}
                </a>
              </li>
              <li  [class.active]="scroll >= 900 && scroll < 1000">
                <a [routerLink]="['/home/']" fragment="sectionManual" translate>{{'ldp_Document'}}
                </a>
              </li>
              <li  [class.active]="scroll >= 1000 && scroll < 1100">
                <a [routerLink]="['/home/']" fragment="sectionContact" translate>{{'ldp_contact'}}
                </a>
              </li>
            </ul>
            <div class="user-menu">
              <a *ngIf="!isLogIn && isConfirm || isLogIn && !isConfirm" class="btn-text" routerLink="/register">
                <span class="login">{{'ldp_register'|translate}}</span></a>
              <button class="btn btn-primary" routerLink="/login">{{'ldp_login'|translate}}</button>
              <!-- <button *ngIf="isLogIn && !isConfirm" class="btn btn-primary" routerLink="/twofactor">{{'ldp_login'|translate}}</button> -->
              <!-- <button *ngIf="isLogIn" class="btn btn-primary" routerLink="/liveview" translate>{{'ldp_manage_camera'}}</button> -->
                <div class="dropdown dropdown-language">
                  <a class="dropdown-toggle" href="javascript:;" role="button" id="dropdownLanguage"
                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <img *ngIf="iconLang=='vi'" class="mr-1" src="assets/landing/icons/vietnam.svg" alt="Vietnamese" />
                    <img *ngIf="iconLang=='en'" class="mr-1" src="assets/landing/icons/US.svg" alt="US" />
                  </a>
                  <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownLanguage">
                    <a href="javascript:;" class="dropdown-item" (click)="switchLanguage('en')" translate>{{'en'}}</a>
                      <a href="javascript:;" class="dropdown-item" (click)="switchLanguage('vi')" translate>{{'vi'}}</a>
                  </div>
                </div> 
            </div>
          </div>
        </div>
      </div>
    </div>
  </nav>
</header>