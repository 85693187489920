import Swal from 'sweetalert2';
import { Subject } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { IntrusionScenarioCameraModel, TimeRepeatModel, WeekTimeIntrusionModel } from 'src/app/_models/ai-camera';
import { AiCameraIntrusionService } from 'src/app/_services/ai-camera-intrusion.service';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

declare var moment: any;

@Component({
  selector: 'app-register-areas',
  templateUrl: './register-areas.component.html',
  styleUrls: ['./register-areas.component.css'],
})
export class RegisterAreasComponent implements OnInit {
  loading: boolean = false;
  items: IntrusionScenarioCameraModel[];

  searchTxt: string = '';
  searchTxt$ = new Subject<string>();

  currentPage: number = 1;
  pageSize: number = 10;
  pageSizes = [5, 10, 20, 50, 100, 1000];
  totalItems: number = 0;
  previewItem: IntrusionScenarioCameraModel;

  dayOptions = [
    { id: "mon", text: "Thứ 2" },
    { id: "tue", text: "Thứ 3" },
    { id: "wed", text: "Thứ 4" },
    { id: "thur", text: "Thứ 5" },
    { id: "fri", text: "Thứ 6" },
    { id: "sat", text: "Thứ 7" },
    { id: "sun", text: "Chủ nhật" },
  ];

  constructor(private _intrusionService: AiCameraIntrusionService) { }

  ngOnInit(): void {
    this.searchTxt$.pipe(
      debounceTime(1000),
      distinctUntilChanged()
    ).subscribe((text) => {
      this.findAll();
    })

    this.findAll();
  }

  findAll() {
    this.loading = true;
    this._intrusionService.findIntrusionScenarioCameras(21, this.searchTxt, this.currentPage - 1, this.pageSize).subscribe((res) => {
      this.items = res.data;
      this.totalItems = res.totalElements;
    },
      (err) => {
        Swal.fire('Đã có lỗi xảy ra', 'Đã có lỗi xảy ra!', 'error');
      },
      () => {
        this.loading = false;
      }
    );
  }

  displayRepeats(item: IntrusionScenarioCameraModel): string {
    if (item.weekTimeIntrusionKeeping) {
      const repeats = this.convertToTimeRepeats(item.weekTimeIntrusionKeeping);
      if (repeats.length > 0) {
        return repeats.map((x) => {
          const selectedDays = x.selected.map((y) => this.dayOptions.find((z) => z.id === y).text).join(', ');
          return `${selectedDays} từ ${x.firstTime} đến ${x.lastTime}`;
        }).join('\n');
      }
    }

    return 'Chưa thiết lập';
  }

  convertToTimeRepeats(data: WeekTimeIntrusionModel): TimeRepeatModel[] {
    const options = Object.values(data).reduce((acc, curr) => acc.concat(curr), [])
      .filter((x, i, arr) => arr.findIndex((y) => y.first === x.first && y.last === x.last) === i);

    return options.reduce((acc, curr) => {
      const firstTime = this.convertMsToTime(curr.first);
      const lastTime = this.convertMsToTime(curr.last);
      const day = Math.floor(curr.first / (1000 * 60 * 60 * 24)) % 7;
      const weekDay = this.dayOptions[day].id;
      // if pair of first and last time is existed, we update the selected days
      const existed = acc.find((x) => x.firstTime === firstTime && x.lastTime === lastTime);
      if (existed) {
        existed.selected.push(weekDay);
      } else {
        acc.push({ firstTime, lastTime, selected: [weekDay] });
      }

      return acc;
    }, []);
  }

  convertMsToTime(ms) {
    const hours = Math.floor(ms / (1000 * 60 * 60) % 24).toString().padStart(2, '0');
    const minutes = Math.floor(ms / (1000 * 60) % 60).toString().padStart(2, '0');
    const seconds = Math.floor(ms / 1000 % 60).toString().padStart(2, '0');
    return `${hours}:${minutes}:${seconds}`;
  }

  getPolygons(item: IntrusionScenarioCameraModel): number[][] {
    return item.rois.map((x) => x.value)
  }

  onSearchChange(value: string): void {
    this.currentPage = 1;
    this.searchTxt$.next(value);
  }

  onPageChange(event: any) {
    this.currentPage = event;
    this.findAll();
  }

  onLimitChange() {
    this.currentPage = 1;
    this.findAll();
  }

  openModal(item: IntrusionScenarioCameraModel): void {
    this.previewItem = item;
  }

  closeModal(): void {
    this.previewItem = null;
  }
}
