import { Component, OnInit, Input, Output } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { UserModel } from "../_models/user.model";
import {
  FormGroup,
  Validators,
  FormBuilder,
  FormControl,
} from "@angular/forms";
import { FormValidateService } from "../_services/form-validate.service";
import { AuthenticationService } from "../_services/authentication.service";
import { AccountService } from "../_services/account.service";
import { DeviceIdGenerateHelper } from "../_helpers/device-id-generate.helper";
import { AccountModel } from "../_models/account.model";
import { CookieService } from "ngx-cookie-service";
import Swal from "sweetalert2";
import { NotificationsService } from "angular2-notifications";
import { NotifiModel } from "../_models/notifi.model";
import { NotifiService } from "../_services/notifi.service";
import { LanguageModel } from "../_models/language.model";
import { AppComponent } from "../app.component";
import { EncryptPasswordService, UserService } from "../_services";
declare var $: any;

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"],
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  twoFactorForm: FormGroup;
  changePasswordForm: FormGroup;
  @Input() usernameLogin: string;
  @Input() passwordLogin: string;
  @Input() password: string;
  @Input() oldPassword: string;
  @Output() data: any;
  loading = false;
  isLogin = false;
  isConfirmCaptcha: boolean = false;
  returnUrl: string;
  numFailed: number = 0;
  isTwoFactor: boolean = false;
  langModel = new LanguageModel();
  currAccount: AccountModel = new AccountModel();
  secretCode = "";
  qrCode = "";
  otpCode = "";

  constructor(
    private _route: ActivatedRoute,
    public _formValidateService: FormValidateService,
    private _encryptPasswordService: EncryptPasswordService,
    private _accountService: AccountService,
    private _encryptService: EncryptPasswordService,
    private _userService: UserService,
    private _router: Router,
    private _fb: FormBuilder,
    private _authenticationService: AuthenticationService,
    private cookie: CookieService,
    public authenticationSevice: AuthenticationService,
    private _appComponent: AppComponent
  ) {}

  ngOnInit(): void {
    this.createForm();
    this.numFailed = JSON.parse(localStorage.getItem("numFailed"));
    if (this.numFailed == null) this.numFailed = 0;
    this.returnUrl = this._route.snapshot.queryParams["returnUrl"] || "";
    this.isLogin = this._authenticationService.isLogined();
    if (this.isLogin) {
      if (this.returnUrl == "") this._router.navigate(["/liveview"]);
      else this._router.navigate([this.returnUrl]);
    } else {
      this._router.navigate(["/login"]);
    }

    if ($(".form-group-password").length) {
      $(".icon-eye").on("click", function () {
        $(this).toggleClass("closed");
        var input = $("#" + $(this).attr("data-for"));
        if ($(this).hasClass("closed")) {
          input.attr("type", "text");
        } else {
          input.attr("type", "password");
        }
      });
    }
    //chuyển ngữ
    this._appComponent.translate
      .get("li_failed")
      .subscribe((res: string) => {
        this.langModel.title = res;
      });
    this._appComponent.translate
      .get("li_check")
      .subscribe((res: string) => {
        this.langModel.content = res;
      });
    this._appComponent.translate
      .get("li_available_other_device")
      .subscribe((res: string) => {
        this.langModel.content1 = res;
      });
    this._appComponent.translate
      .get("li_lock_account")
      .subscribe((res: string) => {
        this.langModel.content2 = res;
      });

    this._appComponent.translate
      .get("li_cancel")
      .subscribe((res: string) => {
        this.langModel.title7 = res;
      });
    this._appComponent.translate.get("li_ok").subscribe((res: string) => {
      this.langModel.title8 = res;
    });
    this._appComponent.translate
      .get("li_off_2factor_succ")
      .subscribe((res: string) => {
        this.langModel.content3 = res;
      });
    this._appComponent.translate
      .get("li_notiffi")
      .subscribe((res: string) => {
        this.langModel.title4 = res;
      });

    this._appComponent.translate
      .get("li_register_succ")
      .subscribe((res: string) => {
        this.langModel.title5 = res;
      });
    this._appComponent.translate
      .get("li_register_2factor_succ")
      .subscribe((res: string) => {
        this.langModel.content5 = res;
        this._appComponent.translate
          .get("li_register_failed")
          .subscribe((res: string) => {
            this.langModel.title6 = res;
          });
        this._appComponent.translate
          .get("li_2factor_failed")
          .subscribe((res: string) => {
            this.langModel.content6 = res;
          });
      });
    this._appComponent.translate
      .get("li_confirm")
      .subscribe((res: string) => {
        this.langModel.title9 = res;
      });

    this._appComponent.translate.get("").subscribe((res: string) => {
      this.langModel.title10 = res;
    });
    this._appComponent.translate.get("").subscribe((res: string) => {
      this.langModel.content10 = res;
    });

    
    this._appComponent.translate
    .get("otp_title")
    .subscribe((res: string) => {
      this.langModel.content7 = res;
    });
    this._appComponent.translate
    .get("otpcontent")
    .subscribe((res: string) => {
      this.langModel.content8 = res;
    });
  }
  createForm() {
    this.loginForm = this._fb.group({
      username: [
        "",
        this._formValidateService.LoginValidate.usernameValidate.validator,
      ],
      password: [
        "",
        this._formValidateService.LoginValidate.passwordValidate.validator,
      ],
    });
    this.twoFactorForm = this._fb.group({
      secretCode: [""],
      otpCode: [
        "",
        this._formValidateService.twoFactorValidate.otpCodeValidate.validator,
      ],
    });
    this.changePasswordForm = this._fb.group({
      oldPassword: [
        "",
        this._formValidateService.customerFormValidate.oldPasswordValidate
          .validator,
      ],
      matchingPassword: this._fb.group(
        {
          password: [
            "",
            this._formValidateService.customerFormValidate.passwordValidate
              .validator,
          ],
          passwordConfirm: [""],
        },
        {
          validator:
            this._formValidateService.customerFormValidate.matchingPassword
              .validator,
        }
      ),
    });
  }

  login() {
    this.cookie.set("twofa", "");
    this.loading = true;
    const deviceId = DeviceIdGenerateHelper.guid();
    const passwordEncrypt = this._encryptPasswordService.encryptSHA512(
      this.usernameLogin,
      this.passwordLogin
    );
    this._authenticationService
      .login(this.usernameLogin, passwordEncrypt, deviceId)
      .subscribe(
        (res) => {
          if (res.tFAStatus == "ACTIVE") {
            this.cookie.set(
              "InfoTFA",
              JSON.stringify({
                username: this.usernameLogin,
                password: passwordEncrypt,
                deviceId: deviceId,
              })
            );
            this._router.navigate(["/twofactor"]);
          } else {
            // Chưa kích hoạt twofactor
            this.cookie.set(
              "currentUser",
              JSON.stringify({
                username: this.usernameLogin,
                accessToken: res.accessToken,
                refreshToken: res.refreshToken,
                deviceId: deviceId,
              })
            );
            const username = JSON.parse(
              this.cookie.get("currentUser")
            ).username;
            this.loading = false;
            this.numFailed = 0;
            localStorage.setItem("numFailed", JSON.stringify(this.numFailed));
            //Check nếu vừ yêu cầu reset mật khẩu
            this._accountService.getDetail().subscribe(
              (res1) => {
                this.currAccount = res1["account"] as AccountModel;
                if (this.currAccount.requireResetPassword == "ACTIVE") {
                  $("#changePasswordModal").modal("show");
                } else {
                  $("#confirmFactorModal").modal("show");
                }
              },
              (err) => {
                console.log(err);
              }
            );
          }
        },
        (error) => {
          this.loading = false;
          this.numFailed++;
          localStorage.setItem("numFailed", JSON.stringify(this.numFailed));
          if (error === "Unauthorized") {
            Swal.fire(this.langModel.title, this.langModel.content, "error");
          } else if (error === "Conflict") {
            Swal.fire(this.langModel.title, this.langModel.content1, "error");
          } else if (error === "Inactive") {
            Swal.fire(this.langModel.title, this.langModel.content2, "error");
          }
        }
      );
  }
  hideTwoFactor() {
    this.cookie.set("twofa", "true");
    this._router.navigate(["/liveview"]);
    $("#confirmFactorModal").modal("hide");
  }
  closeModal(id) {
    this.isTwoFactor = false;
    $("#" + id).modal("hide");
  }
  EnableTwoFactor(idHide) {
    $("#" + idHide).modal("hide");
    this.ShowConfirmTwoFactor();
  }
  ShowConfirmTwoFactor() {
    //Check xem tài khoản đã bật xác thực hai lớp chưa, tạm fix = true
    this.isTwoFactor = true;
    const username = JSON.parse(this.cookie.get("currentUser")).username;
    if (this.isTwoFactor === true) {
      //Show modal xác thực hai lớp
      if (
        this.currAccount.secretKey == null ||
        this.currAccount.secretKey == "" ||
        this.currAccount.qrcode == null ||
        this.currAccount.qrcode == ""
      ) {
        this._authenticationService.genKey().subscribe((res) => {
          this.secretCode = res.secretkey;
          this._authenticationService
            .getqrCode(username, res.secretkey)
            .subscribe((res1) => {
              this.qrCode = "data:image/png;base64," + res1.base64Encoded;
            });
        });
      } else {
        this.secretCode = this.currAccount.secretKey;
        this.qrCode = this.currAccount.qrcode.replace(/\./gi, "+");
      }
      this.otpCode = "";
      $("#twoFactorModal").modal("show");
    } else {
      Swal.fire({
        title: this.langModel.title9,
        text: this.langModel.title4,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: this.langModel.title8,
        cancelButtonText: this.langModel.title7,
      }).then((result) => {
        if (result.value) {
          this.authenticationSevice
            .DoTwoFactor(username, "UNACTIVE", this.otpCode)
            .subscribe(
              (res2) => {
                Swal.fire(
                  this.langModel.title4,
                  this.langModel.content3,
                  "success"
                );
              },
              (err2) => {
                console.log("Lỗi tắt tính năng xác thực hai lớp");
              }
            );
          this.isTwoFactor = false;
        } else if (result.dismiss) {
          this.isTwoFactor = true;
        }
      });
    }
  }
  resolved(captchaResponse: string) {
    if (captchaResponse.length == 0) {
      this.isConfirmCaptcha = false;
    } else {
      this.isConfirmCaptcha = true;
    }
  }
  updatePassword() {
    const username = JSON.parse(this.cookie.get("currentUser")).username;
    const oldPassword = this._encryptService.encryptSHA512(
      username,
      this.oldPassword
    );
    const newPassword = this._encryptService.encryptSHA512(
      username,
      this.password
    );
    this._userService.changePassword(oldPassword, newPassword).subscribe(
      (res) => {
        $("#changePasswordModal").modal("hide");
        Swal.fire(
          "Thành công",
          "Bạn đã thay đổi mật khẩu thành công",
          "success"
        );
      },
      (err) => {
        if (err === "Invalid data") {
          Swal.fire(
            "Thất bại",
            "Có lỗi xảy ra, vui lòng kiểm tra lại",
            "error"
          );
        } else {
          console.log(err);
        }
      }
    );
  }
  controlPassword() {
    let typePass = $("#passLogin").prop("type");
    if (typePass == "password") {
      $("#passLogin").attr("type", "text");
      $("#iconPass").removeClass("icon icon-eye");
      $("#iconPass").addClass("icon icon-eye open closed");
    } else {
      $("#passLogin").attr("type", "password");
      $("#iconPass").removeClass("icon icon-eye open closed");
      $("#iconPass").addClass("icon icon-eye");
    }
  }
  hideShowPass() {
    var typeText = $("#passLogin").attr("type");
    if (typeText == "text") {
      $("#passLogin").attr("type", "password");
    } else $("#passLogin").attr("type", "text");
  }

  registerFactor() {
    const username = JSON.parse(this.cookie.get("currentUser")).username;
    let deviceID = DeviceIdGenerateHelper.guid();
    const passwordEncrypt = this._encryptPasswordService.encryptSHA512(
      this.usernameLogin,
      this.passwordLogin
    );
    //check điều kiện secretkey vs qrcode đã tồn tại chưa
    if (
      this.currAccount.secretKey == null ||
      this.currAccount.secretKey == ""
    ) {
      this.authenticationSevice
        .WriteSecretKey(username, this.secretCode)
        .subscribe(
          (res1) => {
            if (
              this.currAccount.qrcode == null ||
              this.currAccount.qrcode == ""
            ) {
              let qrCodeSave = this.qrCode.replace(/\+/gi, ".");
              this.authenticationSevice
                .WriteQrcode(username, qrCodeSave)
                .subscribe(
                  (res2) => {
                    this.exeRegister(username);
                  },
                  (err2) => {
                    Swal.fire(
                      "Lỗi xác thực hai lớp",
                      "Lỗi đăng ký xác thực hai lớp: " + err2,
                      "error"
                    );
                    console.log("Lỗi ghi qrcode vào database");
                  }
                );
            }
          },
          (err1) => {
            Swal.fire(
              "Lỗi xác thực hai lớp",
              "Lỗi đăng ký xác thực hai lớp: " + err1,
              "error"
            );
            console.log("Lỗi ghi secretKey vào database");
          }
        );
    } else {
      this._accountService.getDetail().subscribe(
        (res1) => {
          this.currAccount = res1["account"] as AccountModel;
          if (this.currAccount.twoFactor == "UNACTIVE" || this.currAccount.twoFactor == null) {
            this.exeRegister(username);
          } else {
            Swal.fire(
              this.langModel.title5,
              this.langModel.content5,
              "success"
            );
            $("#twoFactorModal").modal("hide");
          }
        },
        (err1) => {}
      );
    }
  }
  exeRegister(username) {
    this.authenticationSevice
      .DoTwoFactor(username, "ACTIVE", this.otpCode)
      .subscribe(
        (res1) => {
          Swal.fire(this.langModel.title5, this.langModel.content5, "success");
          $("#twoFactorModal").modal("hide");
        },
        (error) => {
          Swal.fire(
            this.langModel.content7,
            this.langModel.content8,
            "error"
          );
          console.log("Lỗi kích hoạt xác thực hai lớp");
        }
      );
  }
}
