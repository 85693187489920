import { CameraModel } from "./../_models/camera.model";
import { Injectable } from "@angular/core";
import { Http, URLSearchParams } from "@angular/http";
import { ApiUrlService } from "./api-url.service";
import { BaseService } from "./base.service";
import { CookieService } from "ngx-cookie-service";

@Injectable({
  providedIn: "root",
})
export class CameraService extends BaseService {
  constructor(
    _http: Http,
    _apiUrlService: ApiUrlService,
    public cookie: CookieService
  ) {
    super(_http, _apiUrlService, cookie);
  }
  getStreamUrl(_cameraId: number) {
    const params: URLSearchParams = new URLSearchParams();
    params.set("camera-id", `${_cameraId}`);
    return this._http
      .get(
        this._apiUrlService.STREAM_LIVE,
        this.authorizationOption().merge({ search: params })
      )
      .map(this.extractData)
      .catch(this.handleError);
  }
  getInfoMetaCam(_cameraId: number) {
    const params: URLSearchParams = new URLSearchParams();
    params.set("camera-id", `${_cameraId}`);
    return this._http
      .get(
        this._apiUrlService.infoMetaCam,
        this.authorizationOption().merge({ search: params })
      )
      .map(this.extractData)
      .catch(this.handleError);
  }
  getStreamUrlRTC(_cameraId: number) {
    const params: URLSearchParams = new URLSearchParams();
    params.set("camera-id", `${_cameraId}`);

    return this._http
      .get(
        this._apiUrlService.STREAM_LIVE_RTC,
        this.authorizationOption().merge({ search: params })
      )
      .map(this.extractData)
      .catch(this.handleError);
  }
  getListRecord(_cameraId: number, startTime: number, endTime: number) {
    const params: URLSearchParams = new URLSearchParams();
    params.set("camera-id", `${_cameraId}`);
    params.set("start-time", `${startTime}`);
    params.set("end-time", `${endTime}`);

    return this._http
      .get(
        this._apiUrlService.STREAM_SEARCH_RECORD,
        this.authorizationOption().merge({ search: params })
      )
      .map(this.extractData)
      .catch(this.handleError);
  }
  findAll() {
    return this._http
      .get(this._apiUrlService.CAMERA_FIND_ALL, this.authorizationOption())
      .map(this.extractData)
      .catch(this.handleError);
  }
  addCamViettel(_camera: CameraModel) {
    return this._http
      .post(
        this._apiUrlService.CAMERA_ADD,
        {
          name: _camera.name,
          cloudId: _camera.cloudId,
          secureCode: _camera.secureCode,
        },
        this.authorizationOption()
      )
      .map(this.extractData)
      .catch(this.handleError);
  }
  addThirdCam(_camera: CameraModel) {
    return this._http
      .post(
        this._apiUrlService.CAMERA_ADD_THIRD_PARTY,
        {
          name: _camera.name,
          modelId: _camera.typeCam,
          sourceStream: _camera.streamUrl,
        },
        this.authorizationOption()
      )
      .map(this.extractData)
      .catch(this.handleError);
  }
  updateCamViettel(_camera: CameraModel) {
    return this._http
      .post(
        this._apiUrlService.CAMERA_UPDATE,
        { name: _camera.name, cameraId: _camera.id },
        this.authorizationOption()
      )
      .map(this.extractData)
      .catch(this.handleError);
  }
  updateThirdCam(_camera: CameraModel) {
    return this._http
      .post(
        this._apiUrlService.CAMERA_UPDATE_THIRD_PARTY,
        {
          cameraId: _camera.id,
          modelId: _camera.typeCam,
          sourceStream: _camera.streamUrl,
          name: _camera.name,
        },
        this.authorizationOption()
      )
      .map(this.extractData)
      .catch(this.handleError);
  }
  getAllModel() {
    return this._http
      .get(this._apiUrlService.CAMERA_FILTER, this.authorizationOption())
      .map(this.extractData)
      .catch(this.handleError);
  }
  removeCamera(_cameraId: number) {
    const params: URLSearchParams = new URLSearchParams();
    params.set("camera-id", `${_cameraId}`);
    return this._http
      .get(
        this._apiUrlService.CAMERA_REMOVE,
        this.authorizationOption().merge({ search: params })
      )
      .map(this.extractData)
      .catch(this.handleError);
  }
  changeStream(_cameraId: number, _streamProtocol: string) {
    const params: URLSearchParams = new URLSearchParams();
    params.set("camera-id", `${_cameraId}`);
    params.set("streaming-protocol", _streamProtocol);
    return this._http
      .get(
        this._apiUrlService.CAMERA_CHANGE_STREAM,
        this.authorizationOption().merge({ search: params })
      )
      .map(this.extractData)
      .catch(this.handleError);
  }
  changeWebStream(_cameraId: number, _streamProtocol: string) {
    const params: URLSearchParams = new URLSearchParams();
    params.set("camera-id", `${_cameraId}`);
    params.set("live-protocol", _streamProtocol);
    return this._http
      .get(
        this._apiUrlService.change_protocol,
        this.authorizationOption().merge({ search: params })
      )
      .map(this.extractData)
      .catch(this.handleError);
  }
  detailCamera(_cameraId: number) {
    const params: URLSearchParams = new URLSearchParams();
    params.set("camera-id", `${_cameraId}`);
    return this._http
      .get(
        this._apiUrlService.CAMERA_detail_camera,
        this.authorizationOption().merge({ search: params })
      )
      .map(this.extractData)
      .catch(this.handleError);
  }
  ptzUpdate(_camera: CameraModel) {
    return this._http
      .post(
        this._apiUrlService.CAMERA_ptz_update,
        {
          cameraId: _camera.id,
          host: _camera.host,
          port: _camera.port,
          username: _camera.username,
          password: _camera.password,
        },
        this.authorizationOption()
      )
      .map(this.extractData)
      .catch(this.handleError);
  }

  exportExcel() {
    return this._http
      .get(this._apiUrlService.CAMERA_EXPORT_EXCEL, this.exportFileOption())
      .catch(this.handleError);
  }
  getCameraDisconnect(_timeDate: number) {
    const params: URLSearchParams = new URLSearchParams();
    params.set("time-date", `${_timeDate}`);
    return this._http
      .get(
        this._apiUrlService.find_all_disconnect_cam,
        this.authorizationOption().merge({ search: params })
      )
      .map(this.extractData)
      .catch(this.handleError);
  }
}
