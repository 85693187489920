import { Injectable } from '@angular/core';
import { Http } from '@angular/http';
import { BaseService } from '../_services/base.service';
import { ApiUrlService } from '../_services/api-url.service';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class FirebaseService extends BaseService {
  constructor(public _apiUrl: ApiUrlService, public _http: Http, public cookie: CookieService) {
    super(_http, _apiUrl, cookie);
  }

  subscribeToTopic(token: string) {
    return this._http.post(this._apiUrl.FIREBASE_SUBSCRIBE_TOPIC, { token }, this.authorizationOption())
      .map(this.extractData)
      .catch(this.handleError);
  }

  unsubscribeFromTopic(token: string) {
    return this._http.post(this._apiUrl.FIREBASE_UNSUBSCRIBE_TOPIC, { token }, this.authorizationOption())
      .map(this.extractData)
      .catch(this.handleError);
  }
}
