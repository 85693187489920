import {Component, Input, OnInit, OnChanges, SimpleChanges, DoCheck, Output, EventEmitter, HostListener, ElementRef} from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { AuthHepler } from 'src/app/_helpers/auth.helper';
import { ObjectNodeBunch } from 'src/app/_models/object_tree_bunch';
import { AuthenticationService } from 'src/app/_services';
import { BunchService } from 'src/app/_services/bunch.service';
import { TreeviewService } from 'src/app/_services/treeview.service';
import {element} from "protractor";

@Component({
  selector: 'app-bunchtree',
  templateUrl: './bunchtree.component.html',
  styleUrls: ['./bunchtree.component.css']
})
export class BunchtreeComponent extends AuthHepler implements OnInit,OnChanges,DoCheck {

   listBunch: any[] = [];
  @Input() bunchUpdate: any = null;
  @Output() eventSelected = new EventEmitter<any>();
  @Input() reload = true;
  listParentData: any;
  levelDisplay = "";
  optionSelected: any = 'Chọn nhóm';
  idParent: any = null;
  idSubItem:  any = null;
  beforeId: any = null;
  styleItems: String;
  styleIconsBinding: any;
  userListener: any[] = [];
  searchKey: any = "";
  isLoaded = false;
  constructor(
    private _treeService: TreeviewService,
    private _bunchService: BunchService,
    public authenticationService: AuthenticationService,
    public cookie: CookieService) {
    super(_bunchService, authenticationService, cookie);
  }

  ngOnChanges(changes: SimpleChanges): void {
         if(this.bunchUpdate != null){

            this.finding(this.listParentData);
           console.log("Lítpảent: ",this.listParentData)
          this.eventSelected.emit(this.idParent);
      }

  }

  ngDoCheck(): void {
    if(this.reload){
      this.ngOnInit();
      this.styleItems = "display: none";
    }
 }

  findAll() {
    this._bunchService.findAll()
      .subscribe(
        res => {
          this.listBunch = res.listBunch;
          this.listBunch = res.listBunch;
          this.listBunch.sort((a, b) => {
            if(a.id > b.id) {
              return 1;
            } else if(a.id < b.id) {
              return -1;
            } else {
              return 0;
            }
          });
         this.loopMultiple(this.listBunch);
        },
        error => {
          if (error === 'Unauthorized') {
            this.unAuthHandle(() => this.findAll());
          }
          else {
            // console.log(error);
          }
        })

  }


  loopMultiple(listParent: any[]){
    let i = 0;
    let listLength = listParent.length;
    var parent  = [];
    while(i < listLength){
      if(listParent[i].parentId == null){
        var obj = new ObjectNodeBunch();
        obj.level = 0;
        obj.bunch = listParent[i];
        obj.isActive = true;
        this.getCameraInBunchMaptoObjectModel(obj);
        parent.push(obj);
      }
      i++;
    }
    parent.forEach(element => {
      listParent.splice(listParent.indexOf(element.bunch),1);
    });
    this.nodeStructer(parent);

  }
  nodeStructer(list:any[]){
    var parent = [];
    var data = [];
    list.forEach(element => {
      parent.push(element);
      data = this.sortingChildren(element);
      parent = parent.concat(data);
    });
    this.listParentData = parent;
  }
  sortingChildren(item){
    var resoult = [];
    var data = [];
    this.listBunch.forEach(
      element => {
        if(element.parentId == item.bunch.id){
          var bunch = new ObjectNodeBunch();
          bunch.level = item.level + 1;
          item.listChild.push(element.id);
          bunch.bunch = element;
          this.getCameraInBunchMaptoObjectModel(bunch);
          resoult.push(bunch);
          // this.bunchList.splice(this.bunchList.indexOf(element),1);
          data = this.sortingChildren(bunch);
          resoult = resoult.concat(data);

        }
      }
    )
    return resoult;
  }


  finding(listData: any){
    if(this.bunchUpdate.parentId != null){
      listData.forEach(
        element => {
          if(element.bunch.id == this.bunchUpdate.parentId){
            element.isActive = true;
            this.optionSelected = element.bunch.name;
            this.idParent = element.bunch.id;
          }
          else{
            if(
              element.bunch.id == this.bunchUpdate.id
            ){
              element.isActive = false
            }
          }
      })
    }
    // listData.forEach(element => {
    //   if(element.bunch.id == this.bunchUpdate.parentId){
    //     this.optionSelected = element.bunch.name;
    //     this.idParent = element.bunch.id;
    //     element.isActive = false;
    //     }
    //   else{
    //     if(element.bunch.id == this.bunchUpdate.id){
    //       element.isActive = false;
    //     }
    //
    //
    //
    //
    //
    //   }
    // });
  }


  getCameraInBunchMaptoObjectModel(_bunch: any) {
    this._treeService.getCameraInBunch(_bunch.bunch.id).subscribe(res => {
      _bunch.listCamera = res.listCamera;
    },
      err => {

        if (err === 'Unauthorized') {
          this.unAuthHandle(() => this.getCameraInBunchMaptoObjectModel(_bunch.bunch.id));
        }
        else {
          // console.log("getCameraInBunch " + err);
        }
      });
  }

  ngOnInit(): void {
    this.optionSelected = "Chọn nhóm";
    this.styleItems = "display: none";
    this.findAll();
    this.reload = false;

  }

  parentSelected($event:any){
    this.idParent = $event.target.id ?  $event.target.id : null;
    this.optionSelected = ($event.target.innerText === 'Bỏ qua') ? "Chọn nhóm" : $event.target.innerText;
    this.styleItems = "display: none";
    this.eventSelected.emit(this.idParent);
  }
  activeListItems(){
    if(this.styleItems === "display: none"){
      this.styleItems = "";
    }
    else if ( this.styleItems = "") {
      this.styleItems = "display: none";
    }
  }

  //algrothim for sorted items
  getChildeEvent($event){
    this.idParent = $event;
  }


  getSubIdparent($event){
    this.idSubItem = $event.bunch.id;
    this.levelDisplay = $event.level
  }
  getIsClick($event){
    if($event === true){
      this.disableLevel();
    }
    this.disableChildren($event,this.idSubItem);
  }
  disableLevel(){
    this.listParentData.forEach(
      element => {
        if(element.level > this.levelDisplay){
          element.isActive = false;
          element.isClick = false;
        }else{
          if(element.level == this.levelDisplay && element.bunch.id != this.idSubItem){
            element.isClick = false;
          }
        }
      }
    )
  }
  disableChildren($event:any,id:any){
    this.listParentData.forEach(
      element => {
        if(element.bunch.parentId == id){
          element.isActive = $event;
          if($event === false){
            element.isClick = $event;
            this.disableChildren($event,element.bunch.id);
          }
        }
      }
    )
  }

  private wasInside = false;

  @HostListener('click')
  clickInside() {
    this.wasInside = true;
  }

  @HostListener('document:click')
  clickout() {
    if (!this.wasInside) {
      this.styleItems = "display: none";
    }
    this.wasInside = false;
  }


}
