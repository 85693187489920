import { Component, OnInit, Renderer2 } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { AppComponent } from 'src/app/app.component';
declare var $: any;

@Component({
  selector: 'app-home-header',
  templateUrl: './home-header.component.html',
  styleUrls: ['./home-header.component.css']
})
export class HomeHeaderComponent implements OnInit {
  isLogIn: boolean;
  isConfirm: boolean;
  iconLang: string='vi';
  public sections = 4;
  public scroll;

  constructor(private renderer: Renderer2,private appComponent : AppComponent,
    private cookie: CookieService) { }

  ngOnInit(): void {
    if (this.cookie.get('currentUser') != '')
      this.isLogIn = true;
    else this.isLogIn = false;
    //check đã xác thực hai lớp chưa
    if (this.cookie.get('passTwoFactor') == 'true') {
      this.isConfirm = true;
    }
    else this.isConfirm = false;

    this.renderer.listen(window, 'scroll', ($event) => {
      this.scroll = (window.scrollY / this.sections);
    });
    if ($('.carousel').length) {
      $('.carousel').swipe({
        swipe: function (event, direction, distance, duration, fingerCount, fingerData) {
          if (direction == 'left') $(this).carousel('next');
          if (direction == 'right') $(this).carousel('prev');
        },
        allowPageScroll: "vertical"
      });
    }
    //check curr language
    
    let defLang = this.cookie.get('language');
    this.switchLanguage(defLang);
  }
  ScrollSection(id) {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView();
    }
  }
  switchLanguage(_lang){
    this.iconLang= _lang;
    this.cookie.set('language',_lang);
    this.appComponent.switchLanguage(_lang);
  }
}
