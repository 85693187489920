import { Component, OnInit } from "@angular/core";
import { AIRole, AiCameraManageUserFace, UserFaceID } from "../../../_models/ai-camera";
import { AiCameraFaceManagementService } from "../../../_services/ai-camera-face-management.service";
import { AuthenticationService } from "../../../_services/authentication.service";
import { CookieService } from "ngx-cookie-service";
import { AuthHepler } from "../../../_helpers/auth.helper";
import { FormGroup, Validators, FormBuilder } from "@angular/forms";
import { NgForm } from "@angular/forms";
import Swal from "sweetalert2";
import { error } from "console";
import { AiCameraEventService } from "src/app/_services/ai-camera-event.service";
declare var $: any;
declare var moment: any;

@Component({
  selector: "app-register-face-id",
  templateUrl: "./register-face-id.component.html",
  styleUrls: ["./register-face-id.component.css"],
})
export class RegisterFaceIDComponent extends AuthHepler implements OnInit {
  loading: boolean = true;
  listUsers: UserFaceID[];
  listRole: AIRole[];
  editedUser: AiCameraManageUserFace;
  disableChoosePageSize: boolean = false;
  keySearch: string = "";
  addUserFaceIDForm: FormGroup;
  editUserFaceIDForm: FormGroup;
  fileName: any;

  pageSizes = [5, 10, 20, 50, 100, 1000];
  currentPage: number = 0;
  currPagination: number = 0;
  listUsersWithSearch: any[] = [];

  userName: string;
  imageSrc: string;
  userRoleId: number = 1;

  editUserId: number = 1;
  editedUserName: string;
  editedImg: string;
  editedUserRoleId: number = 5;
  lstImageFace: string[];
  currScenario: number = 0;
  limitPage: number = 10;
  totalItems: number = 0;
  // public newUser: AiCameraManageUserFace = new AiCameraManageUserFace;

  constructor(
    private userFaceIDService: AiCameraFaceManagementService,
    public authenticationService: AuthenticationService,
    private _aiService: AiCameraEventService,
    public cookie: CookieService,
    private _formBuilder: FormBuilder
  ) {
    super(userFaceIDService, authenticationService, cookie);
  }

  ngOnInit(): void {
    this.GetSceranio();
    this.GetListRole();
    this.FindAllFaceId();
  }

  // createForm() {
  //   this.addUserFaceIDForm = this._formBuilder.group({
  //     newUserName: ["", [Validators.required, Validators.minLength(3)]],
  //   });
  //   this.editUserFaceIDForm = this._formBuilder.group({
  //     editedUserName: [""],
  //   });
  // }

  // loginAdmin() {

  // }

  // getPageSize($event: number) {
  //   this.currentPage = 0;
  //   this.listUsersWithSearch = this.paginateWithSearch(
  //     this.listUsers,
  //     this.keySearch
  //   );
  // }

  // onChangePage($event: number) {
  //   this.currentPage = Number($event);

  //   this.listUsersWithSearch = this.paginateWithSearch(
  //     this.listUsers,
  //     this.keySearch
  //   );
  // }



  // paginate(array, page_size: number, page_number: number) {
  //   return array.slice(page_number * page_size, (page_number + 1) * page_size);
  // }
  // handleKeyWordBeforeSearch(str: string) {
  //   str = str.replace(/[àáạảãâầấậẩẫăằắặẳẵ]/g, "a");
  //   str = str.replace(/[èéẹẻẽêềếệểễ]/g, "e");
  //   str = str.replace(/[ìíịỉĩ]/g, "i");
  //   str = str.replace(/[òóọỏõôồốộổỗơờớợởỡ]/g, "o");
  //   str = str.replace(/[ùúụủũưừứựửữ]/g, "u");
  //   str = str.replace(/[ỳýỵỷỹ]/g, "y");
  //   str = str.replace(/đ/g, "d");
  //   str = str.replace(/[ÀÁẠẢÃÂẦẤẬẨẪĂẰẮẶẲẴ]/g, "A");
  //   str = str.replace(/[ÈÉẸẺẼÊỀẾỆỂỄ]/g, "E");
  //   str = str.replace(/[ÌÍỊỈĨ]/g, "I");
  //   str = str.replace(/[ÒÓỌỎÕÔỒỐỘỔỖƠỜỚỢỞỠ]/g, "O");
  //   str = str.replace(/[ÙÚỤỦŨƯỪỨỰỬỮ]/g, "U");
  //   str = str.replace(/[ỲÝỴỶỸ]/g, "Y");
  //   str = str.replace(/Đ/g, "D");
  //   // Some system encode vietnamese combining accent as individual utf-8 characters
  //   // Một vài bộ encode coi các dấu mũ, dấu chữ như một kí tự riêng biệt nên thêm hai dòng này
  //   str = str.replace(/[\u0300\u0301\u0303\u0309\u0323]/g, ""); // ̀ ́ ̃ ̉ ̣  huyền, sắc, ngã, hỏi, nặng
  //   str = str.replace(/[\u0306\u031B]/g, ""); //  ̆ ̛  , Ă, Ơ, Ư
  //   // no-misleading-character-class
  //   str = str.replace(/\u02C6/g, ""); // ˆ , Â, Ê
  //   // Remove extra spaces
  //   // Bỏ các khoảng trắng liền nhau
  //   str = str.replace(/ + /g, " ");
  //   str = str.trim();
  //   return str.toLowerCase();
  // }

  // paginateWithSearch(array, text: string) {
  //   const keyWordAfterFilter = this.handleKeyWordBeforeSearch(text || "");
  //   const filteredArray = array.filter((item) =>
  //     item.userName.toLowerCase().includes(keyWordAfterFilter)
  //   );
  //   return filteredArray;
  // }

  // formatResponseEvent(users) {
  //   return users.map((item) => {
  //     return {
  //       ...item,
  //       registeredImageUrls:
  //         item.registeredImageUrls[item.registeredImageUrls.length - 1],
  //     };
  //   });
  // }

  // createNewUserFaceID() {
  //   const filterUserName = this.userName?.replace(/\s/g, "");
  //   const date = new Date();
  //   const components = [
  //     filterUserName,
  //     date.getMonth(),
  //     date.getDate(),
  //     date.getHours(),
  //     date.getMinutes(),
  //     date.getSeconds(),
  //     date.getMilliseconds(),
  //   ];
  //   const userId = components.join("");
  //   const registeredImageUrl = this.imageSrc?.substr(
  //     this.imageSrc.indexOf(",") + 1
  //   );

  // }

  // updateUserFaceID() {
  //   let base64regex =
  //     /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/;
  //   const registeredImageUrl = this.editedImg?.substr(
  //     this.editedImg.indexOf(",") + 1
  //   );
  //   if (base64regex.test(registeredImageUrl)) {
  //   } else {

  //   }
  // }



  // displayIssuedDate(issuedDate: number) {
  //   return moment(issuedDate * 1000, "x").format("DD/MM/YYYY hh:mm A");
  // }

GetSceranio(){
  this._aiService.getAllScenario().subscribe(
    res => {
      res.forEach(sce => {
        if(sce.name=="Chấm công nhân viên")
        this.currScenario = sce.id;
      });
    }),
    (error)=>{console.log(error)}

}
  //21/08/2023 code by LeMui
  getCustomerRole(event: any) {
    this.userRoleId = event.target.value;
  }
  GetListRole() {
    this.userFaceIDService.getListRole().subscribe(
      (res) => {
        this.listRole = res;
      },
      (error) => { console.log(error) }
    );
  }
  FindAllFaceId() {
    this.loading = true;
    this.userFaceIDService.findAllFaceID(this.limitPage, this.currentPage).subscribe(
      res => {
        this.listUsers = res.data;
        this.totalItems = res.totalElements;
      },
      err => {
        Swal.fire("Đã có lỗi xảy ra", "Đã có lỗi xảy ra!", "error");
      },
      () => {
        this.loading = false;
      }
    );
  }
  getChangeSearch() {
    // this.keySearch = $event.target.value;
    this.userFaceIDService.searchFaceID(this.keySearch, 10, 0).subscribe(
      res => {
        this.listUsers = res.data;
      },
      err => {
        Swal.fire("Đã có lỗi xảy ra", "Đã có lỗi xảy ra!", "error");
      },
      () => {
        this.loading = false;
      }
    );
  }
  openAddUserFaceIDModal() {
    $("#addUserFaceId").modal("show");
  }
  onCloseModal() {
    $("#addUserFaceId").modal("hide");
    this.userName = "";
    this.imageSrc = "";
  }
  addFaceID() {
    this.lstImageFace = [];
    const registeredImageUrl = this.imageSrc?.substr(
      this.imageSrc.indexOf(",") + 1
    );
    this.lstImageFace.push(registeredImageUrl);
    this.userFaceIDService.addNewFaceId(this.currScenario, this.userName, this.userRoleId, this.lstImageFace).subscribe(
      (res) => {
        this.FindAllFaceId();
        this.onCloseModal();
      },
      (error) => {
        console.log(error);
      }
    );
  }
  onFileChange($event: any) {
    const reader = new FileReader();
    if ($event.target.files && $event.target.files.length) {
      const file = $event.target.files[0];
      reader.onloadend = () => {
        this.imageSrc = reader.result as string;
        this.editedImg = reader.result as string;
      };
      reader.readAsDataURL(file);
    }
  }
  onPageChange(event: any){
    this.currPagination = event;
    this.currentPage = this.currPagination -1;
    this.FindAllFaceId();
  }

  handleEditUserFaceID($event: any) {
    this.editedUser = $event;
    this.editedUserName = $event.name;
    this.editedImg = $event.data[0];
    this.editedUserRoleId = $event.roleId;
    this.editUserId = $event.id;
    $("#updateUserFaceID").modal("show");
  }
  updateFaceID() {
    this.lstImageFace = [];
    let base64regex = /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/;
    const registeredImageUrl = this.editedImg?.substr(
      this.editedImg.indexOf(",") + 1
    );
    if (base64regex.test(registeredImageUrl)) {
      this.lstImageFace.push(registeredImageUrl);
      this.userFaceIDService
        .updateFaceId(
          this.currScenario,
          this.editedUserName,
          this.editedUserRoleId,
          this.editUserId,
          this.lstImageFace
        )
        .subscribe(
          (res) => {
            Swal.fire(
              "Thành công",
              "Cập nhật Face Id thành công!",
              "success"
            );
            $("#updateUserFaceID").modal("hide");
            this.FindAllFaceId();
          },
          (err) => Swal.fire("Đã có lỗi xảy ra", "Đã có lỗi xảy ra!", "error")
        );
    } else {
      this.userFaceIDService.updateFaceId(
        this.currScenario,
        this.editedUserName,
        this.editedUserRoleId,
        this.editUserId,
        this.lstImageFace
      )
        .subscribe(
          (res) => {
            Swal.fire(
              "Cập nhật Face Id thành công!",
              "Cập nhật Face Id thành công!",
              "success"
            );
            $("#updateUserFaceID").modal("hide");
            this.FindAllFaceId();
          },
          (err) => Swal.fire("Đã có lỗi xảy ra", "Đã có lỗi xảy ra!", "error")
        );
    }


  }
  updateStatusUser($event: any) {
    let status = $event.status;
    if (status.toString() == "INACTIVE") {
      Swal.fire({
        title: 'Xác nhận thay đổi trạng thái FaceID',
        text: "Bạn chắc chắn muốn bật FaceID này không?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#E03',
        confirmButtonText: 'Xác nhận',
        cancelButtonText:'Hủy'
      }).then(
        (result) => {
          if (result.value) {
            this.userFaceIDService.updateStatusFaceId(this.currScenario, 'ACTIVE', $event.id).subscribe(
              (res) => {
                Swal.fire(
                  "Bật Face ID",
                  "Bật Face ID thành công!",
                  "success"
                );
                this.FindAllFaceId();
              },
              (err) => {
                Swal.fire("Đã có lỗi xảy ra", "Đã có lỗi xảy ra!", "error");
                this.FindAllFaceId();
              }
            );
          } else if (result.dismiss) {
            this.FindAllFaceId();
          }
        })
    }
    else {
      Swal.fire({
        title: 'Cập nhật trạng thái',
        text: "Bạn chắc chắn muốn khóa FaceID này?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#E03',
        confirmButtonText: 'Xác nhận',
        cancelButtonText:'Hủy'
      }).then(
        (result) => {
          if (result.value) {
            this.userFaceIDService.updateStatusFaceId(1, 'INACTIVE', $event.id).subscribe(
              (res) => {
                Swal.fire(
                  "Tắt Face ID",
                  "Tắt Face ID thành công!",
                  "success"
                );
                this.FindAllFaceId();
              },
              (err) => Swal.fire("Đã có lỗi xảy ra", "Đã có lỗi xảy ra!", "error")
            );
          } else if (result.dismiss) {
          }
        })
    }
  }
  pageSizeChange(){
    this.currentPage = 0;
    this.FindAllFaceId();
  }
}
