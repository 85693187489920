<div class="table-responsive" *ngIf="lstCamera.length>0">
    <table class="table" style="margin-bottom:unset">
        <thead>
            <tr class="header-table" style="text-align: center;">
                <th scope="col" style="width: 1%;"></th>
                <th scope="col" style="width: 2%;">STT</th>
                <th scope="col" class="text-left">Tên camera</th>
                <th scope="col" class="text-center">Cloud ID</th>
                <th scope="col" class="text-center">Trạng thái</th>
                <th scope="col" class="text-center">Trạng thái AI</th>
            </tr>
        </thead>
        <!-- <tbody *ngIf="loading">
            <tr>
                <td colspan="7" class="text-center">
                    <i class="fa fa-spinner fa-pulse"></i>
                    <span>Đang tải dữ liệu...</span>
                </td>
            </tr>
        </tbody> -->
        <tbody>
            <tr *ngFor="let cam of lstCamera; let i = index">
                <td class="text-left" style="display: flex;">
                    <input id="cam_{{cam.id}}" class="gr_{{groupCam.id}}" type="checkbox" name="cb_RegisAI" (change)="changeAssignCam($event)" style="width: 18px; height: 18px;">
                </td>
                <td class="text-center">{{i+1}} {{cam.checked}}</td>
                <td>{{cam.name}}</td>
                <td class="text-center">{{cam.cloudId}}</td>
                <td class="text-center">
                <div *ngIf="cam.connectionStatus=='CONNECTED'">
                    <span class="camera-status active"></span> Hoạt động
                  </div>
                  <div *ngIf="cam.connectionStatus=='DISCONNECTED'">
                    <span class="camera-status inactive"></span> Không hoạt động
                  </div>
                </td>
                <td class="text-center">
                <div *ngIf="cam.connectionStatus=='CONNECTED'">
                    <span class="camera-status active"></span> Hoạt động
                  </div>
                  <div *ngIf="cam.connectionStatus=='DISCONNECTED'">
                    <span class="camera-status inactive"></span> Không hoạt động
                  </div>
                </td>
            </tr>
        </tbody>
    </table>
</div>