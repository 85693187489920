import { CameraService } from './../../_services/camera.service';
import { CameraModel } from './../../_models/camera.model';
import { BunchModel } from './../../_models/bunch.model';
import { CameraTreeModel } from './../../_models/cameratree.model';
import { AuthenticationService } from './../../_services/authentication.service';
import { AuthHepler } from './../../_helpers/auth.helper';
import { TreeviewService } from './../../_services/treeview.service';
import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import Swal from 'sweetalert2';
import { ObjectNodeBunch } from 'src/app/_models/object_tree_bunch';
import { forEach, indexOf } from 'lodash';
import { element } from 'protractor';
import { LanguageModel } from 'src/app/_models/language.model';
import { AppComponent } from 'src/app/app.component';
declare var $: any;

@Component({
  selector: 'app-tree-liveview',
  templateUrl: './tree-liveview.component.html',
  styleUrls: ['./tree-liveview.component.css']
})

export class TreeLiveviewComponent extends AuthHepler implements OnInit {
  cameraTreeModel: CameraTreeModel;
  bunchList: BunchModel[];
  search =  "";
  @Input() listCamera: CameraModel[];
  @Output() onPlay: EventEmitter<CameraModel> = new EventEmitter<CameraModel>();
  @Output() onClear = new EventEmitter();
  langModel = new LanguageModel();
  cloneData: any;

  cameraSelected: CameraModel;
  term: string = '';
  constructor(private _treeService: TreeviewService,
    private _cameraService: CameraService,
    private _authenticationService: AuthenticationService,
    private _appComponent:AppComponent,
    public cookie: CookieService) {
    super(_treeService, _authenticationService, cookie);
  }

  ngOnInit(): void {
    $('.sidebar-toggle').on('click', e => {
      e.preventDefault();
      $('.sidebar-wrapper').toggleClass('collapsed');
    });
    this.getCameraTreeview();
    //chuyển ngữ
    this._appComponent.translate.get('li_notiffi').subscribe((res: string) => {
      this.langModel.title = res;
    });
    this._appComponent.translate.get('ad_maxium_cam').subscribe((res: string) => {
      this.langModel.content = res;
    });
    this._appComponent.translate.get('ad_buy_pack').subscribe((res: string) => {
      this.langModel.title1 = res;
    });
  }
  cameraTrackById(index, camera: CameraModel) {
    return camera.id;
  }
  getCameraTreeview() {
    this._treeService.getCameraTree()
      .subscribe(res => {
        this.bunchList = res.listBunch;
        //testing structer 
        this.loopMultiple(this.bunchList);
        if (res.listCamera.length > 0) {
          var bunch = new ObjectNodeBunch();
          let bunchVariable = new BunchModel();
          bunchVariable.name = "Chưa Phân nhóm"
          bunchVariable.id = -1;
          bunch.bunch = bunchVariable;
          bunch.listChild = [];
          bunch.isActive = true;
          bunch.listCamera = res.listCamera.map(
            element => element
          );
          
          this.cloneData.push(bunch);
        }
      },
        err => {
          if (err === 'Unauthorized') {
            this.unAuthHandle(() => this.getCameraTreeview());
          }
          else {
            // console.log("getCameraTreeview " + err);
          }
        })
  }
  getCameraInBunch(_bunch: BunchModel) {
    this._treeService.getCameraInBunch(_bunch.id).subscribe(res => {
      _bunch.listCamera = res.listCamera;
    },
      err => {

        if (err === 'Unauthorized') {
          this.unAuthHandle(() => this.getCameraInBunch(_bunch));
        }
        else {
          console.log("getCameraInBunch " + err);
        }
      });
  }

  getCameraInBunchMaptoObjectModel(_bunch: any) {
    this._treeService.getCameraInBunch(_bunch.bunch.id).subscribe(res => {
      _bunch.listCamera = res.listCamera;
    },
      err => {

        if (err === 'Unauthorized') {
          this.unAuthHandle(() => this.getCameraInBunch(_bunch.bunch.id));
        }
        else {
          console.log("getCameraInBunch " + err);
        }
      });
  }

  liveView(cam: any) {
    this.cameraSelected = new CameraModel();
    this.cameraSelected.id = cam.id;
    this.cameraSelected.name = cam.name;
    this.cameraSelected.bunchId = cam.bunchId;
    this.cameraSelected.streamUrl = cam.streamUrl;
    this.cameraSelected.connectionStatus = cam.connectionStatus;
    this.cameraSelected.cloudId = cam.cloudId;
    this.cameraSelected.provider = cam.provider;
    this.cameraSelected.host = cam.host;
    this.cameraSelected.port = cam.port;
    this.cameraSelected.secureCode = cam.secureCode;
    this.cameraSelected.selected = cam.selected;
    this.cameraSelected.typeCam = cam.typeCam;
    this.cameraSelected.username = cam.username;
    this.cameraSelected.password = cam.password;
    this.cameraSelected.dateExpiration = cam.dateExpiration;
    this.cameraSelected.package = cam.package;
    this.cameraSelected.state = cam.state ? cam.state : 0;
    if(cam.liveProtocol=='RTC'){
      this._cameraService.getStreamUrlRTC(cam.id)
        .subscribe(
          res => {
            cam.streamUrl = res.url;
            cam.StreamFileWowza = res.StreamFileWowza;
          },
          error => {
            // console.log(error);
            if (error.status === 'Payment Required') {
              Swal.fire(this.langModel.title, this.langModel.title1, "warning");
            }
            else if (error === 'Unauthorized') {
              this.unAuthHandle(() => this.liveView(cam));
            }
            else {
              console.log("liveview " + error);
            }
          }, () => this.onPlay.emit(cam));
    }
    else{
      this._cameraService.getStreamUrl(cam.id)
        .subscribe(
          res => {
            cam.streamUrl = res.url;
          },
          error => {
            // console.log(error);
            if (error.status === 'Payment Required') {
              Swal.fire(this.langModel.title, this.langModel.title1, "warning");
            }
            else if (error === 'Unauthorized') {
              this.unAuthHandle(() => this.liveView(cam));
            }
            else {
              console.log("liveview " + error);
            }
          }, () => this.onPlay.emit(cam));
    }
  }
  bunchLiveView(_bunch: any) {
    if (_bunch.listCamera.length > 16) {
      Swal.fire(this.langModel.title, this.langModel.content, 'warning');
    }
    else {
      if (_bunch?.listCamera?.length > 0) {
        //Clear list cam hiện tại
        this.onClear.emit();
        _bunch.listCamera.forEach(cam => {
          this.liveView(cam);
        });
      }
    }
  }

  loopMultiple(listParent: any[]){
    let i = 0;
    let listLength = listParent.length;
    var parent  = [];
    while(i < listLength){
      if(listParent[i].parentId == null){
        var obj = new ObjectNodeBunch();
        obj.level = 0;
        obj.bunch = listParent[i];
        obj.isActive = true;
        this.getCameraInBunchMaptoObjectModel(obj);
        parent.push(obj);
      }
      i++;
    }
    parent.forEach(element => {
      listParent.splice(listParent.indexOf(element.bunch),1);
    });
    this.nodeStructer(parent);
    
  }

  getBunchLive($event){
    this.bunchLiveView($event);
  }
  getCameraLive($event){
    this.liveView($event);
  }
  nodeStructer(list:any[]){
    var parent = [];
    var data = [];
    list.forEach(element => {
      parent.push(element);
      data = this.sortingChildren(element);
      parent = parent.concat(data);
    });
    this.cloneData = parent;
  }
  sortingChildren(item){
    var resoult = [];
    var data = [];
    this.bunchList.forEach(
      element => {
        if(element.parentId == item.bunch.id){
          var bunch = new ObjectNodeBunch();
          bunch.level = item.level + 1;
          bunch.bunch = element;
          this.getCameraInBunchMaptoObjectModel(bunch);
          resoult.push(bunch);
          // this.bunchList.splice(this.bunchList.indexOf(element),1);
          data = this.sortingChildren(bunch);
          resoult = resoult.concat(data);
          
        }
      }
    )    
    return resoult;
  }
  searching($event:any){
    if($event.target.value === ""){
      this.ngOnInit();
    }
      this.search = $event.target.value;
    
  }
}
