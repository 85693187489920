import { Component, DoCheck, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-tree-play-back-group',
  templateUrl: './tree-play-back-group.component.html',
  styleUrls: ['./tree-play-back-group.component.css']
})
export class TreePlayBackGroupComponent implements OnInit, DoCheck {
  @Input() listBunch: any[] = [];
  @Input() search = "";
  @Output() camera = new EventEmitter<any>();
  listParentData: any;
  levelDisplay: any;
  idParent: any;
  idSubItem: any = null;
  beforeId: any = null;
  styleItems: String;
  styleIconsBinding: any;
  userListener: any[] = [];
  constructor() { }

  ngOnInit(): void {
  }


  ngDoCheck(): void {
    if (this.search !== "") {

      this.listBunch.forEach(
        element => {
          if (element.listCamera.filter(c => c.name.toString().toUpperCase().includes(this.search.toUpperCase())).length > 0) {
            element.isActive = true;
          }
          else {
            element.isActive = false;
          }
        }
      )
    }
  }


  getSubIdparent($event) {
    this.idSubItem = $event.bunch.id;
    this.levelDisplay = $event.level;
  }

  getIsClick($event) {
    if ($event === true) {
      this.disableLevel();
    }
    this.disableChildren($event, this.idSubItem);
  }
  disableChildren($event: any, id: any) {
    this.listBunch.forEach(
      element => {
        if (element.bunch.parentId == id) {
          element.isActive = $event;
          if ($event === false) {
            element.isClick = $event;
            this.disableChildren($event, element.bunch.id);
          }
        }
      }
    )
  }
  disableLevel() {
    this.listBunch.forEach(
      element => {
        if (element.level > this.levelDisplay) {
          element.isActive = false;
          element.isClick = false;
        } else {
          if (element.level == this.levelDisplay && element.bunch.id != this.idSubItem) {
            element.isClick = false;
          }
          if (element.level < this.levelDisplay) {
            element.isClick = false;
          }
        }
      }
    )
  }


  disPlayIsClick(listData: any[]) {
    listData.forEach(element => {
      element.isActive = false;
      element.isClick = false;
      if (element.listChild.length > 0) {
        this.disPlayIsClick(element.listChild);
      }
    });
  }
  parentSelected($event: any) {
    this.idParent = $event.target.id ? $event.target.id : null;
    this.idSubItem = this.idParent;
  }

  getCameraLive($event) {
    this.camera.emit($event);
  }

  // clickCamera($event:any){
  //   this.camera.emit($event);
  // }

}
