import { Component, Input, OnInit , DoCheck, Output, EventEmitter } from '@angular/core';


@Component({
  selector: 'app-tree-view-group',
  templateUrl: './tree-view-group.component.html',
  styleUrls: ['./tree-view-group.component.css']
})
export class TreeViewGroupComponent implements OnInit,DoCheck {
  @Input() listBunch: any[] = [];
  @Input() search = "";
  levelDisplay: any;
  @Output() camera = new EventEmitter  <any>();
  @Output() bunchOutput = new EventEmitter<any>();
  listParentData: any;
  idParent: any;
  idSubItem:  any = null;
  beforeId: any = null;
  styleItems: String;
  styleIconsBinding: any;
  userListener: any[] = [];
  constructor() { }

ngDoCheck(): void {
   if(this.search !== ""){
     this.listBunch.forEach(
       element => {
        if (element.listCamera.filter(c => c.name.toString().toUpperCase().includes(this.search.toUpperCase())).length > 0) {  element.isActive = true;
        }
        else{
          element.isActive = false;
        }
       }
     )
   }
 
}
  ngOnInit(): void {
  }

  getSubIdparent($event){
    this.idSubItem = $event.bunch.id;    
    this.levelDisplay = $event.level; 
    
  }

  getIsClick($event){    
    if($event === true){
      this.disableLevel();
    }   
    this.disableChildren($event,this.idSubItem);
  }


  disableChildren($event:any,id:any){
    this.listBunch.forEach(
      element => {
        if(element.bunch.parentId == id){
          element.isActive = $event;
          if($event === false){
            element.isClick = $event;
            this.disableChildren($event,element.bunch.id);
          }
        }
      }
    )
  }

  disableLevel(){
    this.listBunch.forEach(
      element => {
        if(element.level > this.levelDisplay){
          element.isActive = false;
          element.isClick = false;
        }else{
          if(element.level == this.levelDisplay && element.bunch.id != this.idSubItem){
            element.isClick = false;
          }
          if(element.level < this.levelDisplay){
            element.isClick = false;
          }
        }
      }
    )
  }

  parentSelected($event:any){
    this.idParent = $event.target.id ?  $event.target.id : null;
    this.idSubItem = this.idParent;
  }
  getBunchLive($event){
    this.bunchOutput.emit($event);
  }

  getCameraLive($event){
    this.camera.emit($event);
  }
}
