import { Component, Input, OnInit, Output, EventEmitter,  DoCheck } from '@angular/core';

@Component({
  selector: 'app-bunch-tree-item',
  templateUrl: './bunch-tree-item.component.html',
  styleUrls: ['../bunchtree/bunchtree.component.css']
})
export class BunchTreeItemComponent implements OnInit,DoCheck {

  @Input() bunch: any;
  @Output() eventChild = new EventEmitter<any>();
  @Output() eventSubChild  = new EventEmitter<any>();
  @Output() eventIsClick = new EventEmitter<any>();
  isClick = false;
  subItem : any[] = [];
  iconStyle = "background-image: url('../../assets/svg/ic-plus.svg');";
  constructor() { }
  ngDoCheck(){
    if(this.bunch.isClick == false){
      this.iconStyle = "background-image: url('../../assets/svg/ic-plus.svg');";
    }
    else{
     this.iconStyle = "background-image: url('../../assets/svg/ic-minus.svg');";
    }
  }
  ngOnInit(): void {
   
  }


  selected($event:any){    
    this.eventChild.emit($event);
  }

  clickChildItem(){
    this.bunch.isClick = !this.bunch.isClick;    
    this.eventSubChild.emit(this.bunch);
    this.eventIsClick.emit(this.bunch.isClick);
  }
  displayLevel(){      
    var paddingString = "margin-left: " + this.bunch.level * 30 + "px;";              
    return paddingString;
  }

}
