<div class="container-fluid p-2">
  <div class="page-title px-3 py-2">Đăng ký camera cho kịch bản</div>
  <hr />
  <div class="page-action px-2">
    <div class="font-weight-bold mb-3">
      {{ "ad_choose_timespan" | translate }}
    </div>
    <weekly-time-range title="Chọn khoảng thời gian" [(repeats)]="weekTimeRepeats" [dayOptions]="dayOptions"></weekly-time-range>
    <app-polygon-draw *ngIf="imageUrl" [imageUrl]="imageUrl" title="Chọn khu vực" [polygons]="polygons" (polygonsChange)="onPolygonsChange($event)"></app-polygon-draw>
    <div class="form-action d-flex justify-content-end mt-3">
      <a class="btn btn-outline-secondary mr-3" type="button" routerLink="/aicamera/register-areas">{{ "li_cancel" | translate }}</a>
      <button class="btn btn-primary m-0" type="button" (click)="onSave()">
        {{ "li_save" | translate }}
      </button>
    </div>
  </div>
</div>
