<div class="container-fluid p-2">
    <div class="page-title">
      Đăng ký camera cho kịch bản
    </div>
    <div class="page-action">
        <div class="row">
            <div class="col-12">
                <div class="d-flex">
                    <div class="search-form event-search mr-3">
                        <label for="search-camera" class="sr-only">Nhập từ khóa tìm kiếm</label>
                        <input type="text" class="form-control border-control" id="search-camera"
                            placeholder="Nhập từ khóa tìm kiếm" [(ngModel)]="txtSearch">
                    </div>
                </div>
            </div>
        </div>
        <div class="page-table" style="padding-left: 0;">
            <div class="container">
                <div class="table-responsive">
                    <table class="table table-striped user-table">
                        <thead>
                            <tr class="header-table" style="padding:15px 0px 15px 0px; text-align: center;">
                                <th scope="col">STT</th>
                                <th scope="col" class="text-left">Tên kịch bản</th>
                                <th scope="col" class="text-center">Số camera</th>
                                <th scope="col" class="text-center">Thao tác</th>
                            </tr>
                        </thead>
                        <tbody *ngIf="loading">
                            <tr>
                                <td colspan="7" class="text-center">
                                    <i class="fa fa-spinner fa-pulse"></i>
                                    <span>Đang tải dữ liệu...</span>
                                </td>
                            </tr>
                        </tbody>
                        <tbody *ngIf="!loading && lstScenario">
                            <tr
                                *ngFor="let sce of lstScenario|search:{label:['name','cloudid'],keyword:txtSearch||''}|paginate: { itemsPerPage: pageSize || 10, currentPage: currentPage, totalItems: lstScenario.length }; let index=index;">
                                <td class="text-center">{{index + 1 + (pageSize * currentPage)}}</td>
                                <td class="text-left">{{sce.name}}</td>
                                <td class="text-center">{{sce.numberCamera}}</td>
                                <td>
                                    <nav class="action-list text-center">
                                        <a href="javascript:;" title="Thay đổi kịch bản AI" routerLink="/aicamera/register-scenarios/{{sce.id}}">
                                            <i class="icon icon-edit"></i>
                                        </a><a *ngIf="sce.id==1" href="javascript:;" title="Cấu hình thời gian"
                                            (click)="showModal('mSetDay')">
                                            <i class="icon icon-configtime"></i>
                                        </a>
                                    </nav>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="row no-gutters">
                    <div class="col-6 text-left" style="display: flex;align-items: center;">
                        <label>Số hàng hiển thị mỗi trang</label>
                        <select class="border-control" [(ngModel)]="pageSize"
                            style="padding: 0.375rem 1rem; margin-left: 0.5rem; cursor: pointer;"
                            (change)="onChangePageSize($event)">
                            <option *ngFor="let size of pageSizes" [ngValue]="size">
                                {{ size }}
                            </option>
                        </select>
                    </div>
                    <div class="col-6 text-right">
                        <div class="text-right">
                            <nav class="nav-pagination">
                                <pagination-template #p="paginationApi" (pageChange)="currentPage = $event;">
                                    <ul *ngIf="pageSize<=p.getTotalItems()" class="pagination">
                                        <li class="page-item" [class.disabled]="p.isFirstPage()">
                                            <a *ngIf="!p.isFirstPage()" class="page-link" (click)="p.previous()">
                                                <i class="icon icon-arrow-left"></i></a>
                                        </li>

                                        <li class="page-item" [class.active]="p.getCurrent()===page.value"
                                            *ngFor="let page of p.pages">
                                            <a class="page-link" (click)="p.setCurrent(page.value)">{{page.label}}</a>
                                        </li>

                                        <li class="page-item" [class.disabled]="p.isLastPage()">
                                            <a *ngIf="!p.isLastPage()" class="page-link" (click)="p.next()">
                                                <i class="icon icon-arrow-right"></i></a>
                                        </li>
                                    </ul>
                                </pagination-template>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </div>



        <div class="modal fade" id="mdConfigTime" tabindex="-1" role="dialog" aria-labelledby="addUserTitle"
            aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered user-modal" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 style="margin-bottom:unset; color: #000;
                                    font-family: Sarabun;
                                    font-size: 17px;
                                    font-style: normal;
                                    font-weight: 600;
                                    line-height: normal;">Cấu hình thời gian chấm công</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <i class="icon icon-close" style="background-color: #ee0033;"></i>
                        </button>
                    </div>
                    <div class="modal-body" style="display: flex;
                                                    flex-direction: column;
                                                    align-items: flex-start;
                                                    gap: 32px;
                                                    align-self: stretch;">
                        <div style="display: flex;
                                    padding-right: 0px;
                                    justify-content: space-between;
                                    align-items: center;
                                    align-self: stretch;">
                            <div style="color: var(--home-black-90, #262626);
                                        font-family: Sarabun;
                                        font-size: 16px;
                                        font-style: normal;
                                        font-weight: 600;
                                        line-height: normal;">Khoảng thời gian ghi nhận chấm công</div>
                        </div>
                        <div style="display: flex;
                                    align-items: center;
                                    gap: 32px;
                                    align-self: stretch;">
                            <div style="display: inline-flex;border-radius: 10px 10px 10px 0px;
                                        border: 1px solid var(--home-black-10, #E5E5E5);
                                        height: 44px;
                                        padding: 10px 16px;
                                        align-items: center;
                                        gap: 10px;
                                        flex-shrink: 0;">
                                <svg xmlns="http://www.w3.org/2000/svg" width="23" height="24" viewBox="0 0 23 24"
                                    fill="none">
                                    <path
                                        d="M20.9467 3.58347H16.6342V1.94062C16.6342 1.82767 16.5418 1.73526 16.4289 1.73526H14.9914C14.8784 1.73526 14.786 1.82767 14.786 1.94062V3.58347H8.21457V1.94062C8.21457 1.82767 8.12215 1.73526 8.00921 1.73526H6.57171C6.45876 1.73526 6.36635 1.82767 6.36635 1.94062V3.58347H2.05385C1.5995 3.58347 1.23242 3.95055 1.23242 4.4049V21.4495C1.23242 21.9039 1.5995 22.271 2.05385 22.271H20.9467C21.4011 22.271 21.7681 21.9039 21.7681 21.4495V4.4049C21.7681 3.95055 21.4011 3.58347 20.9467 3.58347ZM19.9199 20.4228H3.08064V10.6683H19.9199V20.4228ZM3.08064 8.92276V5.43169H6.36635V6.66383C6.36635 6.77678 6.45876 6.86919 6.57171 6.86919H8.00921C8.12215 6.86919 8.21457 6.77678 8.21457 6.66383V5.43169H14.786V6.66383C14.786 6.77678 14.8784 6.86919 14.9914 6.86919H16.4289C16.5418 6.86919 16.6342 6.77678 16.6342 6.66383V5.43169H19.9199V8.92276H3.08064Z"
                                        fill="#B3B3B3" />
                                </svg>
                                <!--Thiết lập giờ-->
                                <div style="color: var(--home-black-90, #262626);
                                            font-family: Sarabun;
                                            font-size: 16px;
                                            font-style: normal;
                                            font-weight: 300;
                                            line-height: normal; cursor: pointer;" (click)="showModal('mSetTime')">
                                    08:00:00 - 17:29:59</div>
                            </div>
                            <!--Thiết lập thứ-->
                            <div style="display: flex;
                            width: 300px;
                            height: 44px;
                            padding: 10px 16px;
                            justify-content: space-between;
                            align-items: center;
                            flex-shrink: 0;border-radius: 10px 10px 10px 0px;
                            border: 1px solid var(--home-black-10, #E5E5E5);">
                                <div style="color: var(--home-black-90, #262626);
                                            font-family: Sarabun;
                                            font-size: 16px;
                                            font-style: normal;
                                            font-weight: 300;
                                            line-height: normal; cursor: pointer;" (click)="showModal('mSetDay')">Thứ
                                    2, Thứ 3, Thứ 4, Thứ 5, Thứ 7</div>
                                <svg xmlns="http://www.w3.org/2000/svg" width="10" height="8" viewBox="0 0 10 8"
                                    fill="none">
                                    <path d="M5 8L0.669873 0.499999L9.33013 0.5L5 8Z" fill="#D9D9D9" />
                                </svg>
                            </div>
                            <svg style="cursor:pointer" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                viewBox="0 0 24 24" fill="none">
                                <g clip-path="url(#clip0_4923_24836)">
                                    <path
                                        d="M12 0C9.62663 0 7.30655 0.703788 5.33316 2.02236C3.35977 3.34094 1.8217 5.21509 0.913451 7.4078C0.00519937 9.60051 -0.232441 12.0133 0.230582 14.3411C0.693605 16.6689 1.83649 18.8071 3.51472 20.4853C5.19295 22.1635 7.33115 23.3064 9.65892 23.7694C11.9867 24.2324 14.3995 23.9948 16.5922 23.0866C18.7849 22.1783 20.6591 20.6402 21.9776 18.6668C23.2962 16.6935 24 14.3734 24 12C23.9965 8.81847 22.7311 5.76824 20.4814 3.51856C18.2318 1.26887 15.1815 0.0034741 12 0ZM12 22.875C9.84913 22.875 7.74656 22.2372 5.95818 21.0422C4.16979 19.8473 2.77592 18.1488 1.95282 16.1617C1.12971 14.1745 0.914351 11.9879 1.33397 9.87839C1.75358 7.76885 2.78932 5.83111 4.31022 4.31021C5.83112 2.78932 7.76886 1.75357 9.8784 1.33396C11.9879 0.914346 14.1745 1.12971 16.1617 1.95281C18.1488 2.77591 19.8473 4.16979 21.0422 5.95817C22.2372 7.74656 22.875 9.84913 22.875 12C22.8715 14.8832 21.7247 17.6472 19.686 19.6859C17.6472 21.7247 14.8832 22.8715 12 22.875Z"
                                        fill="#B3B3B3" />
                                    <path
                                        d="M16.875 11.4375H7.125C6.97582 11.4375 6.83274 11.4968 6.72725 11.6023C6.62176 11.7077 6.5625 11.8508 6.5625 12C6.5625 12.1492 6.62176 12.2923 6.72725 12.3977C6.83274 12.5032 6.97582 12.5625 7.125 12.5625H16.875C17.0242 12.5625 17.1673 12.5032 17.2727 12.3977C17.3782 12.2923 17.4375 12.1492 17.4375 12C17.4375 11.8508 17.3782 11.7077 17.2727 11.6023C17.1673 11.4968 17.0242 11.4375 16.875 11.4375Z"
                                        fill="#B3B3B3" />
                                </g>
                                <defs>
                                    <clipPath id="clip0_4923_24836">
                                        <rect width="24" height="24" fill="white" />
                                    </clipPath>
                                </defs>
                            </svg>
                        </div>

                        <div style="display: flex;
                            align-items: center;
                            gap: 10px;
                            align-self: stretch;">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                style="cursor: pointer;" fill="none">
                                <g clip-path="url(#clip0_4923_24851)">
                                    <path
                                        d="M12 0C9.62663 0 7.30655 0.703788 5.33316 2.02236C3.35977 3.34094 1.8217 5.21509 0.913451 7.4078C0.00519937 9.60051 -0.232441 12.0133 0.230582 14.3411C0.693605 16.6689 1.83649 18.8071 3.51472 20.4853C5.19295 22.1635 7.33115 23.3064 9.65892 23.7694C11.9867 24.2324 14.3995 23.9948 16.5922 23.0866C18.7849 22.1783 20.6591 20.6402 21.9776 18.6668C23.2962 16.6935 24 14.3734 24 12C23.9965 8.81847 22.7311 5.76824 20.4814 3.51856C18.2318 1.26887 15.1815 0.0034741 12 0ZM12 22.875C9.84913 22.875 7.74656 22.2372 5.95818 21.0422C4.16979 19.8473 2.77592 18.1488 1.95282 16.1617C1.12971 14.1745 0.914351 11.9879 1.33397 9.87839C1.75358 7.76885 2.78932 5.83111 4.31022 4.31021C5.83112 2.78932 7.76886 1.75357 9.8784 1.33396C11.9879 0.914346 14.1745 1.12971 16.1617 1.95281C18.1488 2.77591 19.8473 4.16979 21.0422 5.95817C22.2372 7.74656 22.875 9.84913 22.875 12C22.8715 14.8832 21.7247 17.6472 19.686 19.6859C17.6472 21.7247 14.8832 22.8715 12 22.875Z"
                                        fill="#B3B3B3" />
                                    <path
                                        d="M16.875 11.4375H7.125C6.97582 11.4375 6.83274 11.4968 6.72725 11.6023C6.62176 11.7077 6.5625 11.8508 6.5625 12C6.5625 12.1492 6.62176 12.2923 6.72725 12.3977C6.83274 12.5032 6.97582 12.5625 7.125 12.5625H16.875C17.0242 12.5625 17.1673 12.5032 17.2727 12.3977C17.3782 12.2923 17.4375 12.1492 17.4375 12C17.4375 11.8508 17.3782 11.7077 17.2727 11.6023C17.1673 11.4968 17.0242 11.4375 16.875 11.4375Z"
                                        fill="#B3B3B3" />
                                    <path
                                        d="M11.4375 7.125V16.875C11.4375 17.0242 11.4968 17.1673 11.6023 17.2727C11.7077 17.3782 11.8508 17.4375 12 17.4375C12.1492 17.4375 12.2923 17.3782 12.3977 17.2727C12.5032 17.1673 12.5625 17.0242 12.5625 16.875V7.125C12.5625 6.97582 12.5032 6.83274 12.3977 6.72725C12.2923 6.62176 12.1492 6.5625 12 6.5625C11.8508 6.5625 11.7077 6.62176 11.6023 6.72725C11.4968 6.83274 11.4375 6.97582 11.4375 7.125Z"
                                        fill="#B3B3B3" />
                                </g>
                                <defs>
                                    <clipPath id="clip0_4923_24851">
                                        <rect width="24" height="24" fill="white" />
                                    </clipPath>
                                </defs>
                            </svg>
                            <div style="color: var(--home-black-90, #262626);
                                    font-family: Sarabun;
                                    font-size: 16px;
                                    font-style: normal;
                                    font-weight: 300;
                                    line-height: normal;">Thêm thời gian</div>
                        </div>
                        <div style="display: flex;
                                    justify-content: flex-end;
                                    align-items: center;
                                    gap: 16px;
                                    align-self: stretch;">
                            <div style="display: flex;
                            justify-content: flex-end;
                            align-items: center;
                            gap: 16px;
                            align-self: stretch;">
                                <div data-dismiss="modal" style="display: flex;border-radius: 2px;
                                border: 1px solid #000; cursor: pointer;
                                width: 128px;
                                height: 44px;
                                padding: 8px 16px;
                                flex-direction: column;
                                justify-content: center;
                                align-items: center;">Hủy</div>
                                <div style="display: flex;
                                            width: 128px; cursor: pointer;
                                            height: 44px;
                                            padding: 8px 16px;
                                            flex-direction: column;
                                            justify-content: center;
                                            align-items: center;border-radius: 2px;
                                            background: var(--Viettel, #E03);color: white;
                                            box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.04);">
                                    Lưu</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal fade" id="mSetTime" tabindex="-1" role="dialog" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content" style="border: 1px solid black;">
                    <div class="modal-header bg-primary">
                        <h5 class="modal-title">Thiết lập thời gian chấm công</h5>
                        <button type="button" class="close" aria-label="Close" (click)="closeModal('mSetTime')">
                            <i class="icon icon-close"></i>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div style="display: flex; gap: 20px; justify-content: center;">
                            <i style="width: 35px; height: 35px;" class="icon icon-clock"></i>
                            <div class="form-control-time">
                                <select class="select-time d-inline-block cb-time">
                                    <option *ngFor="let h of hours; let i = index" [value]="h" [selected]="i == 0">
                                        {{h}}</option>
                                </select>
                            </div>
                            <div class="form-control-time">
                                <select class="select-time d-inline-block cb-time">
                                    <option *ngFor="let m of minutes; let i= index" [value]="m" [selected]="i == 0">
                                        {{m}}</option>
                                </select>
                            </div>
                            <div class="form-control-time">
                                <select class="select-time d-inline-block cb-time">
                                    <option *ngFor="let s of seconds; let i= index" [value]="s" [selected]="i == 0">
                                        {{s}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="form-action" style="margin-top: 1.5rem;">
                            <button class="btn btn-outline-secondary mr-3" type="button"
                                (click)="closeModal('mSetTime')">Đồng ý</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <div class="modal fade" id="mSetDay" tabindex="-1" role="dialog" aria-hidden="true">
            <div class="modal-dialog modal-lg" role="document">
                <div class="modal-content" style="border: 1px solid black;">
                    <div class="modal-header bg-primary" style="justify-content: center;">
                        <h5 class="modal-title" style="font-size: 17; font-weight: 600;">Cấu hình thời gian chấm công
                        </h5>
                        <button type="button" class="close" aria-label="Close" (click)="closeModal('mSetDay')">
                            <i class="icon icon-close"></i>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div style="display: flex; flex-direction: column; gap: 15px;">
                            <div class="col-12" style="display: flex; gap: 20px;">
                                <div class="col-2">
                                    <input id="ipMon" name="mon" type="checkbox" checked
                                        style="margin: 0.5rem; width: 20px; height: 20px; cursor: pointer;"
                                        *ngIf="timeConfig.mon; else configMon">
                                    <ng-template #configMon>
                                        <input id="ipMon" name="mon" type="checkbox"
                                            style="margin: 0.5rem; width: 20px; height: 20px; cursor: pointer;"></ng-template>
                                    <label for="mon" style="font-weight: 600;">Thứ 2</label>
                                </div>
                                <div class="col-5" style="display: flex; gap: 10px; justify-content: left;">
                                    <!--Chọn giờ-->
                                    <label style="margin-top: 10px;font-weight: 600;">Bắt đầu: </label>
                                    <select id="sMonhFirst" class="cb-time" *ngIf="timeConfig.mon; else hopMon">
                                        <option *ngFor="let h of hours; let i = index" [value]="h"
                                            [selected]="i == timeConfig.mon.hFirst">
                                            {{h}}</option>
                                    </select>
                                    <ng-template #hopMon>
                                        <select id="sMonhFirst" class="cb-time">
                                            <option *ngFor="let h of hours; let i = index" [value]="h"
                                                [selected]="i == 0">
                                                {{h}}</option>
                                        </select>
                                    </ng-template>
                                    <select id="sMonmFirst" class="cb-time" *ngIf="timeConfig.mon; else mopMon">
                                        <option *ngFor="let m of minutes; let i= index" [value]="m"
                                            [selected]="i == timeConfig.mon.mFirst">
                                            {{m}}</option>
                                    </select>
                                    <ng-template #mopMon>
                                        <select id="sMonmFirst" class="cb-time">
                                            <option *ngFor="let m of minutes; let i= index" [value]="m"
                                                [selected]="i == 0">
                                                {{m}}</option>
                                        </select>
                                    </ng-template>
                                    <select id="sMonsFirst" class="cb-time" *ngIf="timeConfig.mon; else sopMon">
                                        <option *ngFor="let s of seconds; let i= index" [value]="s" [selected]="i == timeConfig.mon.sFirst">
                                            {{s}}</option>
                                    </select>
                                    <ng-template #sopMon>
                                        <select id="sMonsFirst" class="cb-time">
                                            <option *ngFor="let s of seconds; let i= index" [value]="s"
                                                [selected]="i == 0">
                                                {{s}}</option>
                                        </select></ng-template>
                                </div>
                                <div class="col-5" style="display: flex; gap: 10px; justify-content: left;"
                                    *ngIf="timeConfig.mon; else lastMon">
                                    <!--Chọn giờ-->
                                    <label style="margin-top: 10px;font-weight: 600;">Kết thúc: </label>
                                    <select id="sMonhLast" class="cb-time">
                                        <option *ngFor="let h of hours; let i = index" [value]="h"
                                            [selected]="i == timeConfig.mon.hLast">
                                            {{h}}</option>
                                    </select>
                                    <select id="sMonmLast" class="cb-time">
                                        <option *ngFor="let m of minutes; let i= index" [value]="m"
                                            [selected]="i == timeConfig.mon.mLast">
                                            {{m}}</option>
                                    </select>
                                    <select id="sMonsLast" class="cb-time">
                                        <option *ngFor="let s of seconds; let i= index" [value]="s"
                                            [selected]="i == timeConfig.mon.sLast">
                                            {{s}}</option>
                                    </select>
                                </div>
                                <ng-template #lastMon>
                                    <div class="col-5" style="display: flex; gap: 10px; justify-content: left;">
                                        <!--Chọn giờ-->
                                        <label style="margin-top: 10px;font-weight: 600;">Kết thúc: </label>
                                        <select id="sMonhLast" class="cb-time">
                                            <option *ngFor="let h of hours; let i = index" [value]="h"
                                                [selected]="i == 0">
                                                {{h}}</option>
                                        </select>
                                        <select id="sMonmLast"t class="cb-time">
                                            <option *ngFor="let m of minutes; let i= index" [value]="m"
                                                [selected]="i == 0">
                                                {{m}}</option>
                                        </select>
                                        <select id="sMonsLast" class="cb-time">
                                            <option *ngFor="let s of seconds; let i= index" [value]="s"
                                                [selected]="i == 0">
                                                {{s}}</option>
                                        </select>
                                    </div>
                                </ng-template>
                            </div>

                            <div class="col-12" style="display: flex; gap: 20px;">
                                <div class="col-2">
                                    <input id="ipTue" name="tue" type="checkbox" checked
                                        style="margin: 0.5rem; width: 20px; height: 20px; cursor: pointer;"
                                        *ngIf="timeConfig.tue; else configTue">
                                    <ng-template #configTue>
                                        <input id="ipTue" name="tue" type="checkbox"
                                            style="margin: 0.5rem; width: 20px; height: 20px; cursor: pointer;"></ng-template>
                                    <label for="tue" style="font-weight: 600;">Thứ 3</label>
                                </div>
                                <div class="col-5" style="display: flex; gap: 10px; justify-content: left;"
                                    *ngIf="timeConfig.tue; else firstTue">
                                    <!--Chọn giờ-->
                                    <label style="margin-top: 10px;font-weight: 600;">Bắt đầu: </label>
                                    <select id="sTuehFirst" class="cb-time">
                                        <option *ngFor="let h of hours; let i = index" [value]="h"
                                            [selected]="i == timeConfig.tue.hFirst">
                                            {{h}}</option>
                                    </select>
                                    <select id="sTuemFirst" class="cb-time">
                                        <option *ngFor="let m of minutes; let i= index" [value]="m"
                                            [selected]="i == timeConfig.tue.mFirst">
                                            {{m}}</option>
                                    </select>
                                    <select id="sTuesFirst" class="cb-time">
                                        <option *ngFor="let s of seconds; let i= index" [value]="s"
                                            [selected]="i == timeConfig.tue.sFirst">
                                            {{s}}</option>
                                    </select>
                                </div>
                                <ng-template #firstTue>
                                    <div class="col-5" style="display: flex; gap: 10px; justify-content: left;">
                                        <!--Chọn giờ-->
                                        <label style="margin-top: 10px;font-weight: 600;">Bắt đầu: </label>
                                        <select id="sTuehFirst" class="cb-time">
                                            <option *ngFor="let h of hours; let i = index" [value]="h"
                                                [selected]="i == 0">
                                                {{h}}</option>
                                        </select>
                                        <select id="sTuemFirst" class="cb-time">
                                            <option *ngFor="let m of minutes; let i= index" [value]="m"
                                                [selected]="i == 0">
                                                {{m}}</option>
                                        </select>
                                        <select id="sTuesFirst" class="cb-time">
                                            <option *ngFor="let s of seconds; let i= index" [value]="s"
                                                [selected]="i == 0">
                                                {{s}}</option>
                                        </select>
                                    </div>
                                </ng-template>
                                <div class="col-5" style="display: flex; gap: 10px; justify-content: left;"
                                    *ngIf="timeConfig.tue; else lastTue">
                                    <!--Chọn giờ-->
                                    <label style="margin-top: 10px;font-weight: 600;">Kết thúc: </label>
                                    <select id="sTuehLast" class="cb-time">
                                        <option *ngFor="let h of hours; let i = index" [value]="h"
                                            [selected]="i == timeConfig.tue.hLast">
                                            {{h}}</option>
                                    </select>
                                    <select id="sTuemLast" class="cb-time">
                                        <option *ngFor="let m of minutes; let i= index" [value]="m"
                                            [selected]="i == timeConfig.tue.mLast">
                                            {{m}}</option>
                                    </select>
                                    <select id="sTuesLast" class="cb-time">
                                        <option *ngFor="let s of seconds; let i= index" [value]="s"
                                            [selected]="i == timeConfig.tue.sLast">
                                            {{s}}</option>
                                    </select>
                                </div>
                                <ng-template #lastTue>

                                    <div class="col-5" style="display: flex; gap: 10px; justify-content: left;">
                                        <!--Chọn giờ-->
                                        <label style="margin-top: 10px;font-weight: 600;">Kết thúc: </label>
                                        <select id="sTuehLast" class="cb-time">
                                            <option *ngFor="let h of hours; let i = index" [value]="h"
                                                [selected]="i == 0">
                                                {{h}}</option>
                                        </select>
                                        <select id="sTuemLast" class="cb-time">
                                            <option *ngFor="let m of minutes; let i= index" [value]="m"
                                                [selected]="i == 0">
                                                {{m}}</option>
                                        </select>
                                        <select id="sTuesLast" class="cb-time">
                                            <option *ngFor="let s of seconds; let i= index" [value]="s"
                                                [selected]="i == 0">
                                                {{s}}</option>
                                        </select>
                                    </div>
                                </ng-template>
                            </div>

                            <div class="col-12" style="display: flex; gap: 20px;">
                                <div class="col-2">
                                    <input  id="ipWed" name="wed" type="checkbox" checked
                                        style="margin: 0.5rem; width: 20px; height: 20px; cursor: pointer;"
                                        *ngIf="timeConfig.wed; else configWed">
                                    <ng-template #configWed>
                                        <input id="ipWed" name="wed" type="checkbox"
                                            style="margin: 0.5rem; width: 20px; height: 20px; cursor: pointer;"
                                            #configWeb></ng-template>
                                    <label for="wed" style="font-weight: 600;">Thứ 4</label>
                                </div>
                                <div class="col-5" style="display: flex; gap: 10px; justify-content: left;"
                                    *ngIf="timeConfig.wed; else firstWed">
                                    <!--Chọn giờ-->
                                    <label style="margin-top: 10px;font-weight: 600;">Bắt đầu: </label>
                                    <select id="sWedhFirst" class="cb-time">
                                        <option *ngFor="let h of hours; let i = index" [value]="h"
                                            [selected]="i == timeConfig.wed.hFirst">
                                            {{h}}</option>
                                    </select>
                                    <select id="sWedmFirst" class="cb-time">
                                        <option *ngFor="let m of minutes; let i= index" [value]="m"
                                            [selected]="i == timeConfig.wed.mFirst">
                                            {{m}}</option>
                                    </select>
                                    <select id="sWedsFirst" class="cb-time">
                                        <option *ngFor="let s of seconds; let i= index" [value]="s"
                                            [selected]="i == timeConfig.wed.sFirst">
                                            {{s}}</option>
                                    </select>
                                </div>
                                <ng-template #firstWed>
                                    <div class="col-5" style="display: flex; gap: 10px; justify-content: left;">
                                        <!--Chọn giờ-->
                                        <label style="margin-top: 10px;font-weight: 600;">Bắt đầu: </label>
                                        <select id="sWedhFirst" class="cb-time">
                                            <option *ngFor="let h of hours; let i = index" [value]="h"
                                                [selected]="i == 0">
                                                {{h}}</option>
                                        </select>
                                        <select id="sWedmFirst" class="cb-time">
                                            <option *ngFor="let m of minutes; let i= index" [value]="m"
                                                [selected]="i == 0">
                                                {{m}}</option>
                                        </select>
                                        <select id="sWedsFirst" class="cb-time">
                                            <option *ngFor="let s of seconds; let i= index" [value]="s"
                                                [selected]="i == 0">
                                                {{s}}</option>
                                        </select>
                                    </div>
                                </ng-template>
                                <div class="col-5" style="display: flex; gap: 10px; justify-content: left;"
                                    *ngIf="timeConfig.wed; else wedLast">
                                    <!--Chọn giờ-->
                                    <label style="margin-top: 10px;font-weight: 600;">Kết thúc: </label>
                                    <select id="sWedhLast" class="cb-time">
                                        <option *ngFor="let h of hours; let i = index" [value]="h"
                                            [selected]="i == timeConfig.wed.hLast">
                                            {{h}}</option>
                                    </select>
                                    <select id="sWedmLast" class="cb-time">
                                        <option *ngFor="let m of minutes; let i= index" [value]="m"
                                            [selected]="i == timeConfig.wed.mLast">
                                            {{m}}</option>
                                    </select>
                                    <select id="sWedsLast" class="cb-time">
                                        <option *ngFor="let s of seconds; let i= index" [value]="s"
                                            [selected]="i == timeConfig.wed.sLast">
                                            {{s}}</option>
                                    </select>
                                </div>
                                <ng-template #wedLast>
                                    <div class="col-5" style="display: flex; gap: 10px; justify-content: left;">
                                        <!--Chọn giờ-->
                                        <label style="margin-top: 10px;font-weight: 600;">Kết thúc: </label>
                                        <select id="sWedhLast" class="cb-time">
                                            <option *ngFor="let h of hours; let i = index" [value]="h"
                                                [selected]="i == 0">
                                                {{h}}</option>
                                        </select>
                                        <select id="sWedmLast" class="cb-time">
                                            <option *ngFor="let m of minutes; let i= index" [value]="m"
                                                [selected]="i == 0">
                                                {{m}}</option>
                                        </select>
                                        <select id="sWedsLast" class="cb-time">
                                            <option *ngFor="let s of seconds; let i= index" [value]="s"
                                                [selected]="i == 0">
                                                {{s}}</option>
                                        </select>
                                    </div>
                                </ng-template>
                            </div>

                            <div class="col-12" style="display: flex; gap: 20px;">
                                <div class="col-2">

                                    <input id="ipThur" name="thur" type="checkbox" checked
                                        style="margin: 0.5rem; width: 20px; height: 20px; cursor: pointer;"
                                        *ngIf="timeConfig.thur; else configThur">
                                    <ng-template #configThur>
                                        <input id="ipThur" name="thur" type="checkbox"
                                            style="margin: 0.5rem; width: 20px; height: 20px; cursor: pointer;"
                                            #configThur></ng-template>
                                    <label for="thur" style="font-weight: 600;">Thứ 5</label>
                                </div>
                                <div class="col-5" style="display: flex; gap: 10px; justify-content: left;" *ngIf="timeConfig.thur;else thurFirt">
                                    <!--Chọn giờ-->
                                    <label style="margin-top: 10px;font-weight: 600;">Bắt đầu: </label>
                                    <select id="sThurhFirst" class="cb-time">
                                        <option *ngFor="let h of hours; let i = index" [value]="h" [selected]="i == timeConfig.thur.hFirst">
                                            {{h}}</option>
                                    </select>
                                    <select id="sThurmFirst" class="cb-time">
                                        <option *ngFor="let m of minutes; let i= index" [value]="m" [selected]="i == timeConfig.thur.mFirst">
                                            {{m}}</option>
                                    </select>
                                    <select id="sThursFirst" class="cb-time">
                                        <option *ngFor="let s of seconds; let i= index" [value]="s" [selected]="i == timeConfig.thur.sFirst">
                                            {{s}}</option>
                                    </select>
                                </div>
                                <ng-template #thurFirt><div class="col-5" style="display: flex; gap: 10px; justify-content: left;">
                                    <!--Chọn giờ-->
                                    <label style="margin-top: 10px;font-weight: 600;">Bắt đầu: </label>
                                    <select id="sThurhFirst" class="cb-time">
                                        <option *ngFor="let h of hours; let i = index" [value]="h" [selected]="i == 0">
                                            {{h}}</option>
                                    </select>
                                    <select id="sThurmFirst" class="cb-time">
                                        <option *ngFor="let m of minutes; let i= index" [value]="m" [selected]="i == 0">
                                            {{m}}</option>
                                    </select>
                                    <select id="sThursFirst" class="cb-time">
                                        <option *ngFor="let s of seconds; let i= index" [value]="s" [selected]="i == 0">
                                            {{s}}</option>
                                    </select>
                                </div></ng-template>
                                <div class="col-5" style="display: flex; gap: 10px; justify-content: left;" *ngIf="timeConfig.thur; else thurLast">
                                    <!--Chọn giờ-->
                                    <label style="margin-top: 10px;font-weight: 600;">Kết thúc: </label>
                                    <select id="sThurhLast" class="cb-time">
                                        <option *ngFor="let h of hours; let i = index" [value]="h" [selected]="i == timeConfig.thur.hLast">
                                            {{h}}</option>
                                    </select>
                                    <select id="sThurmLast" class="cb-time">
                                        <option *ngFor="let m of minutes; let i= index" [value]="m" [selected]="i == timeConfig.thur.mLast">
                                            {{m}}</option>
                                    </select>
                                    <select id="sThursLast" class="cb-time">
                                        <option *ngFor="let s of seconds; let i= index" [value]="s" [selected]="i == timeConfig.thur.sLast">
                                            {{s}}</option>
                                    </select>
                                </div>
                                <ng-template #thurLast>
                                    <div class="col-5" style="display: flex; gap: 10px; justify-content: left;">
                                        <!--Chọn giờ-->
                                        <label style="margin-top: 10px;font-weight: 600;">Kết thúc: </label>
                                        <select id="sThurhLast" class="cb-time">
                                            <option *ngFor="let h of hours; let i = index" [value]="h" [selected]="i == 0">
                                                {{h}}</option>
                                        </select>
                                        <select id="sThurmLast" class="cb-time">
                                            <option *ngFor="let m of minutes; let i= index" [value]="m" [selected]="i == 0">
                                                {{m}}</option>
                                        </select>
                                        <select id="sThursLast" class="cb-time">
                                            <option *ngFor="let s of seconds; let i= index" [value]="s" [selected]="i == 0">
                                                {{s}}</option>
                                        </select>
                                    </div>
                                </ng-template>
                            </div>

                            <div class="col-12" style="display: flex; gap: 20px;">
                                <div class="col-2">
                                    <input id="ipFri" name="fri" type="checkbox" checked
                                        style="margin: 0.5rem; width: 20px; height: 20px; cursor: pointer;"
                                        *ngIf="timeConfig.fri; else configFri">
                                    <ng-template #configFri>
                                        <input id="ipFri" name="fri" type="checkbox"
                                            style="margin: 0.5rem; width: 20px; height: 20px; cursor: pointer;"
                                            #configFri></ng-template>
                                    <label for="fri" style="font-weight: 600;">Thứ 6</label>
                                </div>
                                <div class="col-5" style="display: flex; gap: 10px; justify-content: left;" *ngIf="timeConfig.fri; else friFirst">
                                    <!--Chọn giờ-->
                                    <label style="margin-top: 10px;font-weight: 600;">Bắt đầu: </label>
                                    <select id="sFrihFirst" class="cb-time">
                                        <option *ngFor="let h of hours; let i = index" [value]="h" [selected]="i == timeConfig.fri.hFirst">
                                            {{h}}</option>
                                    </select>
                                    <select id="sFrimFirst" class="cb-time">
                                        <option *ngFor="let m of minutes; let i= index" [value]="m" [selected]="i == timeConfig.fri.mFirst">
                                            {{m}}</option>
                                    </select>
                                    <select id="sFrisFirst" class="cb-time">
                                        <option *ngFor="let s of seconds; let i= index" [value]="s" [selected]="i == timeConfig.fri.sFirst">
                                            {{s}}</option>
                                    </select>
                                </div>
                                <ng-template #friFirst><div class="col-5" style="display: flex; gap: 10px; justify-content: left;">
                                    <!--Chọn giờ-->
                                    <label style="margin-top: 10px;font-weight: 600;">Bắt đầu: </label>
                                    <select id="sFrihFirst" class="cb-time">
                                        <option *ngFor="let h of hours; let i = index" [value]="h" [selected]="i == 0">
                                            {{h}}</option>
                                    </select>
                                    <select id="sFrimFirst" class="cb-time">
                                        <option *ngFor="let m of minutes; let i= index" [value]="m" [selected]="i == 0">
                                            {{m}}</option>
                                    </select>
                                    <select id="sFrisFirst" class="cb-time">
                                        <option *ngFor="let s of seconds; let i= index" [value]="s" [selected]="i == 0">
                                            {{s}}</option>
                                    </select>
                                </div></ng-template>
                                <div class="col-5" style="display: flex; gap: 10px; justify-content: left;" *ngIf="timeConfig.fri;else friLast">
                                    <!--Chọn giờ-->
                                    <label style="margin-top: 10px;font-weight: 600;">Kết thúc: </label>
                                    <select id="sFrihLast" class="cb-time">
                                        <option *ngFor="let h of hours; let i = index" [value]="h" [selected]="i == timeConfig.fri.hLast">
                                            {{h}}</option>
                                    </select>
                                    <select id="sFrimLast" class="cb-time">
                                        <option *ngFor="let m of minutes; let i= index" [value]="m" [selected]="i == timeConfig.fri.mLast">
                                            {{m}}</option>
                                    </select>
                                    <select id="sFrisLast" class="cb-time">
                                        <option *ngFor="let s of seconds; let i= index" [value]="s" [selected]="i == timeConfig.fri.sLast">
                                            {{s}}</option>
                                    </select>
                                </div>
                                <ng-template #friLast>
                                    <div class="col-5" style="display: flex; gap: 10px; justify-content: left;">
                                        <!--Chọn giờ-->
                                        <label style="margin-top: 10px;font-weight: 600;">Kết thúc: </label>
                                        <select id="sFrihLast" class="cb-time">
                                            <option *ngFor="let h of hours; let i = index" [value]="h" [selected]="i == 0">
                                                {{h}}</option>
                                        </select>
                                        <select id="sFrimLast" class="cb-time">
                                            <option *ngFor="let m of minutes; let i= index" [value]="m" [selected]="i == 0">
                                                {{m}}</option>
                                        </select>
                                        <select id="sFrisLast" class="cb-time">
                                            <option *ngFor="let s of seconds; let i= index" [value]="s" [selected]="i == 0">
                                                {{s}}</option>
                                        </select>
                                    </div>
                                </ng-template>
                            </div>

                            <div class="col-12" style="display: flex; gap: 20px;">
                                <div class="col-2">

                                    <input id="ipSat" name="sat" type="checkbox" checked
                                        style="margin: 0.5rem; width: 20px; height: 20px; cursor: pointer;"
                                        *ngIf="timeConfig.sat; else configSat">
                                    <ng-template #configSat>
                                        <input id="ipSat" name="sat" type="checkbox"
                                            style="margin: 0.5rem; width: 20px; height: 20px; cursor: pointer;"
                                            #configSat></ng-template>
                                    <label for="sat" style="font-weight: 600;">Thứ 7</label>
                                </div>
                                <div class="col-5" style="display: flex; gap: 10px; justify-content: left;" *ngIf="timeConfig.sat; else satFirst">
                                    <!--Chọn giờ-->
                                    <label style="margin-top: 10px;font-weight: 600;">Bắt đầu: </label>
                                    <select id="sSathFirst" class="cb-time">
                                        <option *ngFor="let h of hours; let i = index" [value]="h" [selected]="i == timeConfig.sat.hFirst">
                                            {{h}}</option>
                                    </select>
                                    <select id="sSatmFirst" class="cb-time">
                                        <option *ngFor="let m of minutes; let i= index" [value]="m" [selected]="i == timeConfig.sat.mFirst">
                                            {{m}}</option>
                                    </select>
                                    <select id="sSatsFirst" class="cb-time">
                                        <option *ngFor="let s of seconds; let i= index" [value]="s" [selected]="i == timeConfig.sat.sFirst">
                                            {{s}}</option>
                                    </select>
                                </div>
                                <ng-template #satFirst>
                                    <div class="col-5" style="display: flex; gap: 10px; justify-content: left;">
                                    <!--Chọn giờ-->
                                    <label style="margin-top: 10px;font-weight: 600;">Bắt đầu: </label>
                                    <select id="sSathFirst" class="cb-time">
                                        <option *ngFor="let h of hours; let i = index" [value]="h" [selected]="i == 0">
                                            {{h}}</option>
                                    </select>
                                    <select id="sSatmFirst" class="cb-time">
                                        <option *ngFor="let m of minutes; let i= index" [value]="m" [selected]="i == 0">
                                            {{m}}</option>
                                    </select>
                                    <select id="sSatsFirst" class="cb-time">
                                        <option *ngFor="let s of seconds; let i= index" [value]="s" [selected]="i == 0">
                                            {{s}}</option>
                                    </select>
                                </div></ng-template>
                                <div class="col-5" style="display: flex; gap: 10px; justify-content: left;" *ngIf="timeConfig.sat;else satLast">
                                    <!--Chọn giờ-->
                                    <label style="margin-top: 10px;font-weight: 600;">Kết thúc: </label>
                                    <select id="sSathLast" class="cb-time">
                                        <option *ngFor="let h of hours; let i = index" [value]="h" [selected]="i == timeConfig.sat.hLast">
                                            {{h}}</option>
                                    </select>
                                    <select id="sSatmLast" class="cb-time">
                                        <option *ngFor="let m of minutes; let i= index" [value]="m" [selected]="i == timeConfig.sat.mLast">
                                            {{m}}</option>
                                    </select>
                                    <select id="sSatsLast" class="cb-time">
                                        <option *ngFor="let s of seconds; let i= index" [value]="s" [selected]="i == timeConfig.sat.sLast">
                                            {{s}}</option>
                                    </select>
                                </div>
                                <ng-template #satLast>
                                    <div class="col-5" style="display: flex; gap: 10px; justify-content: left;">
                                        <!--Chọn giờ-->
                                        <label style="margin-top: 10px;font-weight: 600;">Kết thúc: </label>
                                        <select id="sSathLast" class="cb-time">
                                            <option *ngFor="let h of hours; let i = index" [value]="h" [selected]="i == 0">
                                                {{h}}</option>
                                        </select>
                                        <select id="sSatmLast" class="cb-time">
                                            <option *ngFor="let m of minutes; let i= index" [value]="m" [selected]="i == 0">
                                                {{m}}</option>
                                        </select>
                                        <select id="sSatsLast" class="cb-time">
                                            <option *ngFor="let s of seconds; let i= index" [value]="s" [selected]="i == 0">
                                                {{s}}</option>
                                        </select>
                                    </div>
                                </ng-template>
                            </div>

                            <div class="col-12" style="display: flex; gap: 20px;">
                                <div class="col-2">
                                    <input id="ipSun" name="sun" type="checkbox" checked
                                        style="margin: 0.5rem; width: 20px; height: 20px; cursor: pointer;"
                                        *ngIf="timeConfig.sun; else configSun">
                                    <ng-template #configSun>
                                        <input id="ipSun" name="sun" type="checkbox"
                                            style="margin: 0.5rem; width: 20px; height: 20px; cursor: pointer;"
                                            #configSun></ng-template>
                                    <label for="sun" style="font-weight: 600;">Chủ nhật</label>
                                </div>
                                <div class="col-5" style="display: flex; gap: 10px; justify-content: left;" *ngIf="timeConfig.sun;else sunFirst">
                                    <!--Chọn giờ-->
                                    <label style="margin-top: 10px;font-weight: 600;">Bắt đầu: </label>
                                    <select id="sSunhFirst" class="cb-time">
                                        <option *ngFor="let h of hours; let i = index" [value]="h" [selected]="i == timeConfig.sun.hFirst">
                                            {{h}}</option>
                                    </select>
                                    <select id="sSunmFirst" class="cb-time">
                                        <option *ngFor="let m of minutes; let i= index" [value]="m" [selected]="i == timeConfig.sun.mFirst">
                                            {{m}}</option>
                                    </select>
                                    <select id="sSunsFirst" class="cb-time">
                                        <option *ngFor="let s of seconds; let i= index" [value]="s" [selected]="i == timeConfig.sun.sFirst">
                                            {{s}}</option>
                                    </select>
                                </div>
                                <ng-template #sunFirst><div class="col-5" style="display: flex; gap: 10px; justify-content: left;">
                                    <!--Chọn giờ-->
                                    <label style="margin-top: 10px;font-weight: 600;">Bắt đầu: </label>
                                    <select id="sSunhFirst" class="cb-time">
                                        <option *ngFor="let h of hours; let i = index" [value]="h" [selected]="i == 0">
                                            {{h}}</option>
                                    </select>
                                    <select id="sSunmFirst" class="cb-time">
                                        <option *ngFor="let m of minutes; let i= index" [value]="m" [selected]="i == 0">
                                            {{m}}</option>
                                    </select>
                                    <select id="sSunsFirst" class="cb-time">
                                        <option *ngFor="let s of seconds; let i= index" [value]="s" [selected]="i == 0">
                                            {{s}}</option>
                                    </select>
                                </div></ng-template>
                                <div class="col-5" style="display: flex; gap: 10px; justify-content: left;" *ngIf="timeConfig.sun;else sunLast">
                                    <!--Chọn giờ-->
                                    <label style="margin-top: 10px;font-weight: 600;">Kết thúc: </label>
                                    <select id="sSunhLast" class="cb-time">
                                        <option *ngFor="let h of hours; let i = index" [value]="h" [selected]="i == timeConfig.sun.hLast">
                                            {{h}}</option>
                                    </select>
                                    <select id="sSunmLast" class="cb-time">
                                        <option *ngFor="let m of minutes; let i= index" [value]="m" [selected]="i == timeConfig.sun.mLast">
                                            {{m}}</option>
                                    </select>
                                    <select id="sSunsLast" class="cb-time">
                                        <option *ngFor="let s of seconds; let i= index" [value]="s" [selected]="i == timeConfig.sun.sLast">
                                            {{s}}</option>
                                    </select>
                                </div>
                                <ng-template #sunLast><div class="col-5" style="display: flex; gap: 10px; justify-content: left;">
                                    <!--Chọn giờ-->
                                    <label style="margin-top: 10px;font-weight: 600;">Kết thúc: </label>
                                    <select id="sSunhLast" class="cb-time">
                                        <option *ngFor="let h of hours; let i = index" [value]="h" [selected]="i == 0">
                                            {{h}}</option>
                                    </select>
                                    <select id="sSunmLast" class="cb-time">
                                        <option *ngFor="let m of minutes; let i= index" [value]="m" [selected]="i == 0">
                                            {{m}}</option>
                                    </select>
                                    <select id="sSunsLast" class="cb-time">
                                        <option *ngFor="let s of seconds; let i= index" [value]="s" [selected]="i == 0">
                                            {{s}}</option>
                                    </select>
                                </div></ng-template>
                            </div>


                        </div>
                        <div class="form-action" style="margin-top: 1.5rem;text-align: center;">
                            <button class="btn btn-outline-secondary mr-3" style="border-radius: unset;" type="button"
                                data-dismiss="modal">Hủy bỏ</button>
                            <button type="button" class="btn btn-primary" (click)="SaveConfigTime()">Lưu</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
