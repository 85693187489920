import { Component, Input, OnInit } from '@angular/core';
import { AICameraModel } from 'src/app/_models/ai-camera';

@Component({
  selector: 'app-config-camera-list',
  templateUrl: './config-camera-list.component.html',
  styleUrls: ['./config-camera-list.component.css']
})
export class ConfigCameraListComponent implements OnInit {
  @Input() cameras: AICameraModel[] = [];

  constructor() { }

  ngOnInit(): void {
  }

  // onSelectChange(event: any, cameraId: number) {
  //   const camera = this.cameras.find(c => c.cameraId === cameraId);
  //   if (camera) {
  //     camera.isActiveNotification = event.target.checked;
  //   }
  // }
}
