import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { AiCameraEventService } from 'src/app/_services/ai-camera-event.service';
import { GroupCamera } from '../register-for-scenario.component';
import { ScenarioModel } from 'src/app/_models/ai-camera';
declare var $: any;

@Component({
  selector: 'app-camera-for-scenario',
  templateUrl: './camera-for-scenario.component.html',
  styleUrls: ['./camera-for-scenario.component.css']
})
export class CameraForScenarioComponent implements OnInit {

  lstCamera: CameraScript[] = [];
  lstCamId: number[] = [];
  checked: boolean = false;
  @Input() groupCam: GroupCamera;
  @Input() scenarioModel: ScenarioModel;
  @Input() lstIdLoad: number[];
  constructor(private _aiService: AiCameraEventService) { }

  ngOnInit(): void {
    if (this.groupCam != null)
      this.getListCameraScript(this.groupCam.id);
  }
  changeAssignCam(event: any) {
    const isChecked = (<HTMLInputElement>event.target).checked;
    let lstClassName = $('.' + event.target.className);
    let numChecked = 0;
    for (let i = 0; i < lstClassName.length; i++) {
      if (lstClassName[i].checked.toString() == 'true')
        numChecked++;
    }
    if (isChecked == true) {
      if (lstClassName.length == numChecked + 1) {
        $('#ip' + event.target.className).prop('checked', true);
      }
    }
    else {
      if (lstClassName.length == numChecked + 1) {
        $('#ip' + event.target.className).prop('checked', false);
      }
    }
  }
  getListCameraScript(_groupId) {
    if (_groupId == 0) {
      this._aiService.getAllCamNoBunch().subscribe(
        res => {
          // res.forEach(elm => {
          //   let c = new CameraScript();
          //   c.id = elm.id;
          //   c.name = elm.name;
          //   c.cloudId = elm.cloudId;
          //   c.connectionStatus = elm.connectionStatus;
          //   c.checked = false;



          // this.lstCamera.push(c);
          // });
          this.lstCamera = res;
          // this.lstCamera.forEach(
          //   obj => {
          //     this.lstIdLoad.forEach(
          //       camId => {
          //         if (obj.id == camId)
          //           $('#cam_' + obj.id).prop('checked', true);
          //       }
          //     )
          //   }
          // )
        },
        err => {
          console.log(err);
        }
      );
    }
    else {
      this._aiService.getCamByBunch(_groupId).subscribe(
        res => {
          this.lstCamera = res;
        },
        err => {
          console.log(err);
        }
      )
    }
  }

}
export class CameraScript {
  id: number = 0;
  name: string = "";
  cloudId: string = "";
  secureCode: string = "";
  deviceSerial: string = "";
  dateCreated: string = "";
  deviceStatus: string = "";
  connectionStatus: string = "";
  provider: string = "";
  checked: boolean = false;

  scenarioId: number = 0;
  scenarioName: string = "";
  aiStatus: string = "";
  statusExecute: string = "";
  bunchId: number = 0;
  bunchName: string = "";
}
