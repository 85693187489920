import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class EncryptPasswordService {

  constructor() { }
  encryptSHA512(username: string, passwordPlaintext: string) {
    const encrypted = CryptoJS.SHA512(username.toLocaleLowerCase() + passwordPlaintext).toString(CryptoJS.enc.Hex);
    return encrypted;
  }
}
