<div class="container-fluid p-0">
  <app-header></app-header>
  <div class="page-container page-camera">
    <div class="container">
      <div class="page-action">
        <div class="row">
          <div class="col-12 col-sm-4">
            <div class="row">
              <div class="search-form camera-search" style="width: 80%;">
                <input type="text" class="form-control border-control" id="search-camera"
                  placeholder="{{'ad_search_camera'|translate}}" [(ngModel)]="term">
              </div>
            </div>
          </div>
          <div class="col-12 col-sm-3">
            <div class="filter-dropdown camera-filter" style="margin-left: 10px;">
              <div class="btn-group dropdown">
                <!-- <div class="col-12">
                  <input id="ipBunch" type="text" list="bunchs" class="form-control border-control" [(ngModel)]="currBunch"
                    placeholder="Nhập hoặc chọn nhóm camera" style="cursor: pointer;" />
                  <datalist id="bunchs">
                    <option *ngFor="let bunch of listBunch" value="{{bunch.name}}">
                    </option>
                  </datalist>
                </div> -->


                <button class="btn dropdown-toggle border-control" type="button" data-toggle="dropdown"
                  aria-expanded="false">
                  <span class="title" style="font-size: unset;">{{titleBunch}}</span>
                </button>
                <ul class="dropdown-menu" role="menu">
                  <li *ngFor="let bunch of listBunch"><a href="javascript:;"
                      (click)="getFilterBunch(bunch)">{{bunch.name}}</a></li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-12 col-sm-2">
            <input id="selectTime" type="datetime-local" class="form-control border-control" [(ngModel)]="timeSearch">
          </div>
          <div class="col-12 col-sm-3">
            <div class="camera-buttons">
              <button class="btn btn-primary btn-icon-text add-camera" (click)="searchCamWithTime()">
                <span>{{'cam_filter_camera'|translate}}</span>
              </button>
              <button class="btn btn-success btn-icon-text export-excel" *ngIf="lstCameraOff.length>0" (click)="exportExcel()">
                <i class="icon-excel"></i> <span>{{'ad_export'|translate}}</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="page-table">
        <div>
          <div class="row no-gutters">
            <div class="col-12 col-sm-6">
              <div style="font-weight: bold;">{{'cam_list_camera'|translate}} <span
                  style="color: #e03;">({{lstCameraOff.length}})</span></div>
            </div>
            <div class="col-12 col-sm-6 text-right">
              <select (change)="pageSize = $event.target.value" class="d-inline-block"
                style="padding: 0.375rem 1rem;">
                <option value="5">{{'ad_view'|translate}} 5</option>
                <option value="10" selected>{{'ad_view'|translate}} 10</option>
                <option value="20">{{'ad_view'|translate}} 20</option>
                <option value="50">{{'ad_view'|translate}} 50</option>
                <option value="100">{{'ad_view'|translate}} 100</option>
                <option value="1000">{{'ad_view'|translate}} 1000</option>
              </select>
            </div>
          </div>
          <hr />
          <div class="container">
            <div class="table-responsive">
              <table class="table table-striped user-table">
                <thead>
                  <tr class="header-table" style="padding:15px 0px 15px 0px; text-align: center;">
                    <th scope="col">{{'ad_stt'|translate}}</th>
                    <th scope="col">{{'ad_camera_name'|translate}}</th>
                    <th scope="col">Cloud ID</th>
                    <th scope="col">{{'ad_BUNCH'|translate}}</th>
                    <th scope="col">{{'ad_status'|translate}}</th>
                  </tr>
                </thead>
                <tbody *ngIf="lstCameraOff">
                  <tr
                    *ngFor="let _cam of lstCameraOff|search:{label:['name','bunchName','status'],keyword:term||''}|orderBy : order||'name'| paginate: { itemsPerPage: pageSize || 10, currentPage: currentPage, totalItems: lstCameraOff.length }; let i=index;">
                    <td>{{ pageSize*(currentPage-1)+i+1 || i+1 }}</td>
                    <td style="text-align: center;">{{_cam.name}}</td>
                    <td style="text-align: center;">{{_cam.cloudId}}</td>
                    <td style="text-align: center;">{{_cam.bunchName}}</td>
                    <td style="text-align: center;">{{_cam.status}}</td>
                  </tr>
                </tbody>
              </table>
            </div>

          </div>
        </div>

        <div class="text-center">
          <nav class="nav-pagination">
            <pagination-template #p="paginationApi" (pageChange)="currentPage = $event;">
              <ul *ngIf="pageSize<=p.getTotalItems()" class="pagination">
                <li class="page-item" [class.disabled]="p.isFirstPage()">
                  <a *ngIf="!p.isFirstPage()" class="page-link" (click)="p.previous()">
                    <i class="icon icon-arrow-left"></i></a>
                </li>

                <li class="page-item" [class.active]="p.getCurrent()===page.value" *ngFor="let page of p.pages">
                  <a class="page-link" (click)="p.setCurrent(page.value)">{{page.label}}</a>
                </li>

                <li class="page-item" [class.disabled]="p.isLastPage()">
                  <a *ngIf="!p.isLastPage()" class="page-link" (click)="p.next()">
                    <i class="icon icon-arrow-right"></i></a>
                </li>
              </ul>
            </pagination-template>
          </nav>
        </div>
      </div>
    </div>
  </div>
  <footer class="idc-footer">
    <p class="copyright">{{'ldp_copyright'|translate}}</p>
  </footer>
</div>