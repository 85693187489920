import Swal from 'sweetalert2';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  IntrusionScenarioCameraModel,
  PolygonModel,
  ScenarioCameraModel,
  TimeRepeatModel,
  WeekTimeIntrusionModel,
} from 'src/app/_models/ai-camera';
import { AiCameraIntrusionService } from 'src/app/_services/ai-camera-intrusion.service';

declare var moment: any;

@Component({
  selector: 'app-register-area',
  templateUrl: './register-area.component.html',
  styleUrls: ['./register-area.component.css'],
})
export class RegisterAreaComponent implements OnInit {
  loading: boolean = false;

  imageUrl: string;
  scenarioCamera: ScenarioCameraModel;
  weekTimeRepeats: TimeRepeatModel[];
  polygons: number[][] = [];

  dayOptions = [
    { id: "mon", text: "Thứ 2" },
    { id: "tue", text: "Thứ 3" },
    { id: "wed", text: "Thứ 4" },
    { id: "thur", text: "Thứ 5" },
    { id: "fri", text: "Thứ 6" },
    { id: "sat", text: "Thứ 7" },
    { id: "sun", text: "Chủ nhật" },
  ];

  constructor(
    private route: ActivatedRoute,
    private _intrusionService: AiCameraIntrusionService
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.getRegisterArea(params['id']);
    });
  }

  getRegisterArea(id: number) {
    this.loading = true;

    this._intrusionService
      .findIntrusionScenarioCamera(id)
      .subscribe(
        (res) => {
          this.loading = false;
          this.reflectResponse(res);
        },
        () => {
          Swal.fire('Đã có lỗi xảy ra', 'Đã có lỗi xảy ra!', 'error');
        },
        () => {
          this.loading = false;
        }
      );
  }

  reflectResponse(data?: IntrusionScenarioCameraModel): void {
    if (!data) {
      Swal.fire('Đã có lỗi xảy ra', 'Đã có lỗi xảy ra!', 'error');
      return;
    }

    this.imageUrl = data.image;
    this.scenarioCamera = data.scenarioCamera;
    this.polygons = data.rois.map((x) => x.value);
    this.weekTimeRepeats = this.convertToTimeRepeats(data.weekTimeIntrusionKeeping);
  }

  convertToTimeRepeats(data: WeekTimeIntrusionModel): TimeRepeatModel[] {
    const options = Object.values(data).reduce((acc, curr) => acc.concat(curr), [])
      .filter((x, i, arr) => arr.findIndex((y) => y.first === x.first && y.last === x.last) === i);

    return options.reduce((acc, curr) => {
      const firstTime = this.convertMsToTime(curr.first);
      const lastTime = this.convertMsToTime(curr.last);
      const day = Math.floor(curr.first / (1000 * 60 * 60 * 24)) % 7;
      const weekDay = this.dayOptions[day].id;
      // if pair of first and last time is existed, we update the selected days
      const existed = acc.find((x) => x.firstTime === firstTime && x.lastTime === lastTime);
      if (existed) {
        existed.selected.push(weekDay);
      } else {
        acc.push({ firstTime, lastTime, selected: [weekDay] });
      }

      return acc;
    }, []);
  }

  areTimesOverlapping(weekTimeIntrusion: WeekTimeIntrusionModel): boolean {
    // Iterate over each selected day
    for (const day of Object.keys(weekTimeIntrusion)) {
      const intervals = weekTimeIntrusion[day];

      // Sort intervals by start time
      intervals.sort((a, b) => a.first - b.first);

      // Check for overlapping intervals
      for (let i = 0; i < intervals.length - 1; i++) {
        const currentInterval = intervals[i];
        const nextInterval = intervals[i + 1];

        // Check if current interval overlaps with the next one
        if (currentInterval.last > nextInterval.first) {
          return true; // Overlapping times found
        }
      }
    }

    return false; // No overlapping times found
  }

  convertToWeekTimeIntrusion(repeats: TimeRepeatModel[]): WeekTimeIntrusionModel {
    return repeats.reduce((acc, curr) => {
      curr.selected.forEach((day) => {
        const dayIndex = this.dayOptions.findIndex((x) => x.id === day);
        const first = this.convertTimeToMs(curr.firstTime) + dayIndex * 24 * 60 * 60 * 1000;
        const last = this.convertTimeToMs(curr.lastTime) + dayIndex * 24 * 60 * 60 * 1000;

        if (!acc[day]) {
          acc[day] = [{ first, last }];
        } else {
          acc[day].push({ first, last });
        }
      });

      return acc;
    }, {});
  }

  convertMsToTime(ms) {
    const hours = Math.floor(ms / (1000 * 60 * 60) % 24).toString().padStart(2, '0');
    const minutes = Math.floor(ms / (1000 * 60) % 60).toString().padStart(2, '0');
    const seconds = Math.floor(ms / 1000 % 60).toString().padStart(2, '0');
    return `${hours}:${minutes}:${seconds}`;
  }

  convertTimeToMs(time: string) {
    const [hours, minutes, seconds] = time.split(':').map(Number);
    return hours * 60 * 60 * 1000 + minutes * 60 * 1000 + seconds * 1000;
  }

  onPolygonsChange(updated: [number, number][]): void {
    this.polygons = updated;
  }

  onSave(): void {
    const weekTimeIntrusionKeeping = this.convertToWeekTimeIntrusion(this.weekTimeRepeats);

    if(this.areTimesOverlapping(weekTimeIntrusionKeeping)) {
      Swal.fire('Thời gian không hợp lệ', 'Các khoảng thời gian không được chồng chéo!', 'error');
      return;
    }

    const payload = {
      scenarioId: this.scenarioCamera.scenarioId,
      cameraId: this.scenarioCamera.cameraId,
      rois: this.polygons.map((value): PolygonModel => ({ value })),
      weekTimeIntrusionKeeping: weekTimeIntrusionKeeping,
    };

    this.loading = true;
    this._intrusionService.updateIntrusionScenarioCamera(payload).subscribe(
      (res) => {
        this.loading = false;
        Swal.fire('Thành công', 'Cập nhật thành công!', 'success');
      },
      () => {
        Swal.fire('Đã có lỗi xảy ra', 'Đã có lỗi xảy ra!', 'error');
      },
      () => {
        this.loading = false;
      }
    );
  }
}
