<div class="page-container page-login">
    <div class="container">
      <div class="row">
        <div class="col-lg-6"></div>
        <div class="col-lg-6">
          <div class="card login-block">
            <h2 class="card-title">{{'li_active'|translate}}</h2>
            <form class="form">
              <div class="form-group mb-0 form-group-username">
                <div class="alert alert-warning text-center" *ngIf="showload == false"> 
                    <i class="fa fa-cogs fa-spin">&nbsp;</i>{{'li_sys_checking'|translate}}
                </div>
                <div class="alert alert-success text-center" *ngIf="showload == true">
                  {{'li_active_success'|translate}}
                </div>
              </div>
              <div *ngIf="showload == false">
                <button class="text-center w-100 btn btn-primary btn-login" disabled>{{'li_LOGIN'|translate}}</button>
              </div>
              <div *ngIf="showload == true">
                <button class="text-center w-100 btn btn-primary btn-login" routerLink="/login">{{'li_LOGIN'|translate}}</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
