import { CameraService } from './../../_services/camera.service';
import { CameraModel } from './../../_models/camera.model';
import { BunchModel } from './../../_models/bunch.model';
import { CameraTreeModel } from './../../_models/cameratree.model';
import { AuthenticationService } from './../../_services/authentication.service';
import { AuthHepler } from './../../_helpers/auth.helper';
import { TreeviewService } from './../../_services/treeview.service';
import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import Swal from 'sweetalert2';
import { ObjectNodeBunch } from 'src/app/_models/object_tree_bunch';
declare var $: any;

@Component({
  selector: 'app-tree-playback',
  templateUrl: './tree-playback.component.html',
  styleUrls: ['./tree-playback.component.css']
})
export class TreePlaybackComponent extends AuthHepler implements OnInit {
  cameraTreeModel: CameraTreeModel;
  bunchList: BunchModel[];
  @Input() listCamera: CameraModel[];
  @Output() onPlay: EventEmitter<CameraModel> = new EventEmitter<CameraModel>();
  cameraSelected: CameraModel;
  term: string = '';
  search = "";
  cloneData: any;
  constructor(private _treeService: TreeviewService,
    private _cameraService: CameraService,
    private _authenticationService: AuthenticationService,
    public cookie: CookieService) {
    super(_treeService, _authenticationService, cookie);
  }
  ngOnInit(): void {
    $('.sidebar-toggle').on('click', e => {
      e.preventDefault();
      $('.sidebar-wrapper').toggleClass('collapsed');
    });
    if ($('.icon-toggle-cam').length) {
      $('.icon-toggle-cam').on('click', (function () {
        $('.camera-group-toggle').removeClass('active');
        $(this).parent('.camera-group-toggle').toggleClass('active');
      }));
    }
    this.getCameraTreeview();
  }
  cameraTrackById(index, camera: CameraModel) {
    return camera.id;
  }
  getCameraTreeview() {
    this._treeService.getCameraTree()
      .subscribe(res => {
        this.bunchList = res.listBunch;
        this.loopMultiple(this.bunchList);
        if (res.listCamera.length > 0) {
          var bunch = new ObjectNodeBunch();
          let bunchVariable = new BunchModel();
          bunchVariable.name = "Chưa Phân nhóm"
          bunchVariable.id = -1;
          bunch.bunch = bunchVariable;
          bunch.listChild = [];
          bunch.isActive = true;
          bunch.listCamera = res.listCamera.map(
            element => element
          );
          
          this.cloneData.push(bunch);
        }
      },
        err => {
          if (err === 'Unauthorized') {
            this.unAuthHandle(() => this.getCameraTreeview());
          }
          else {
            console.log("getCameraTreeView " + err);
          }
        })
  }
  getCameraInBunch(_bunch: BunchModel) {
    this._treeService.getCameraInBunch(_bunch.id).subscribe(res => {
      _bunch.listCamera = res.listCamera;
    },
      err => {

        if (err === 'Unauthorized') {
          this.unAuthHandle(() => this.getCameraInBunch(_bunch));
        }
        else {
          console.log("getCameraInBunch " + err);
        }
      });
  }

  playbackView(cam: any) {

    this.cameraSelected = new CameraModel();
    this.cameraSelected.id = cam.id;
    this.onPlay.emit(cam);
  }


 
  getCameraInBunchMaptoObjectModel(_bunch: any) {
    this._treeService.getCameraInBunch(_bunch.bunch.id).subscribe(res => {
      _bunch.listCamera = res.listCamera;
    },
      err => {

        if (err === 'Unauthorized') {
          this.unAuthHandle(() => this.getCameraInBunch(_bunch.bunch.id));
        }
        else {
          console.log("getCameraInBunch " + err);
        }
      });
  }

  getCameraPlaybackList($event){    
    this.playbackView($event);
  }
  loopMultiple(listParent: any[]){
    // let i = 0;
    // let listLength = listParent.length;
    var parent  = [];
    // while(i < listLength){
    //   if(listParent[i].parentId == null){
    //     var obj = new ObjectNodeBunch();
    //     obj.level = 0;
    //     obj.bunch = listParent[i];
    //     obj.isActive = true;
    //     this.getCameraInBunchMaptoObjectModel(obj);
    //     parent.push(obj);
    //   }
    //   i++;
    // }
    //9-9-24 Xử lý bất đồng bộ
    const promises = listParent.map((element) => {
      if(element.parentId == null){
        var obj = new ObjectNodeBunch();
        obj.level = 0;
        obj.bunch = element;
        obj.isActive = true;
        this.getCameraInBunchMaptoObjectModel(obj);
        parent.push(obj);
      }
    });
    Promise.all(promises)
      .then((results) => {
        // Xử lý khi tất cả các promise đã hoàn thành
        console.log("All API calls are complete");
      })
      .catch((error) => {
        // Xử lý lỗi nếu có bất kỳ API nào gặp lỗi
        console.error("Error in API calls:", error);
      });
    parent.forEach(element => {
      listParent.splice(listParent.indexOf(element.bunch),1);
    });
    this.nodeStructer(parent);
    
  }
  nodeStructer(list:any[]){
    var parent = [];
    var data = [];
    list.forEach(element => {
      
      parent.push(element);
      data = this.sortingChildren(element);
      parent = parent.concat(data);
    });
    this.cloneData = parent;
  }
  sortingChildren(item){
    var resoult = [];
    var data = [];
    // this.bunchList.forEach(
    //   element => {
    //     if(element.parentId == item.bunch.id){
    //       var bunch = new ObjectNodeBunch();
    //       bunch.level = item.level + 1;
    //       bunch.bunch = element;
    //       this.getCameraInBunchMaptoObjectModel(bunch);
    //       resoult.push(bunch);
    //       data = this.sortingChildren(bunch);
    //       resoult = resoult.concat(data);
          
    //     }
    //   }
    // )
    //9-9-24 Xử lý bất đồng bộ
    const promises = this.bunchList.map((element) => {
      if(element.parentId == item.bunch.id){
        var bunch = new ObjectNodeBunch();
        bunch.level = item.level + 1;
        bunch.bunch = element;
        this.getCameraInBunchMaptoObjectModel(bunch);
        resoult.push(bunch);
        data = this.sortingChildren(bunch);
        resoult = resoult.concat(data);
        
      }
    });
    Promise.all(promises)
      .then((results) => {
        // Xử lý khi tất cả các promise đã hoàn thành
        console.log("All API calls are complete");
      })
      .catch((error) => {
        // Xử lý lỗi nếu có bất kỳ API nào gặp lỗi
        console.error("Error in API calls:", error);
      });    
    return resoult;
  }

  searching($event:any){
    if($event.target.value === ""){
      this.ngOnInit();
    }
      this.search = $event.target.value;
  }
}
