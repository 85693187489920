import { AfterViewInit, Component, Inject, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { AgmInfoWindow, MouseEvent } from '@agm/core';
import { CameraModel } from './../../_models/camera.model';
import { TreeviewService } from './../../_services/treeview.service';
import { DOCUMENT } from '@angular/common';

declare var $: any;
declare var videojs: any;
declare var vtmapgl: any;

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.css']
})
export class MapComponent implements OnInit, AfterViewInit {
  @ViewChildren('MapInfoWindow') MapInfoWindows: QueryList<AgmInfoWindow>;
  location: Location;
  button: any;
  constructor(@Inject(DOCUMENT) private document: any, private _treeService: TreeviewService) { }
  ngOnInit(): void {
    this.location = {
      latitude: 20.877814637829534,
      longitude: 105.91429796068502,
      mapType: "satelite",
      zoom: 8,
      markers: [{
        lat: 20.877814637829534,
        lng: 105.91429796068502,
        label: 'A'
      },
      {
        lat: 20.875248367489622,
        lng: 106.3208779319985,
        label: 'B'
      }
      ]
    }
    this.button = this.document.getElementById("btnCam1");
    this.button.addEventListener("click", function () {
      alert("You clicked the button!");
    });
  }

  ngAfterViewInit(): void {
    vtmapgl.accessToken = '6f249a4eaba2be6ffd95fb2ee3bb365e';
    // Khởi tạo bản đồ
    const map = new vtmapgl.Map({
      container: 'map',
      style: vtmapgl.STYLES.VTRANS,
      center: [108.2022, 16.0544], // tọa độ trung tâm [lng, lat]
      zoom: 5 // mức zoom
    });
    var h1 = document.createElement('dlllll');
    h1.innerHTML = `<div style="border-radius: 6px; background-color: white">
<h3 style="font-size: 13px; font-weight: bold; text-align: left; margin-top: 0">Camera 1</h3>
<p style="position: relative; padding-left: 15px; margin-bottom: 10px">
  <img src="https://i.imgur.com/PG2UFj9.png" style="position: absolute;left: 0;top: 0;" alt="">
  <span
    style="margin-left: 5px; display: inline-block">21.0291562</span><span>105.8501162</span>
</p>
<p style="margin-bottom: 10px">45 Nguyễn Trãi, Ba Đình, Hà Nội</p>
<div style="text-align: right; display: block;">
  <button id="btnCam1" onclick="$('#cam1').modal('show');"
    style="display: inline-block; position: relative; padding: 10px 10px 10px 35px; border-radius: 4px; background-color: #1865fd; border: 0; box-shadow: none; color: white">
    <img src="https://i.imgur.com/xpzjn0k.png" style="position: absolute;left: 5px;top: 5px;"
      alt="">Xem trực tiếp</button>
      <p><a href="https://vi.wikipedia.org/wiki/%C4%90%C3%A0_N%E1%BA%B5ng" target="_blank">Learn more</a></p>
</div>
</div>`;
    const popup = new vtmapgl.Popup().setDOMContent(h1);
    // Khởi tạo marker
    const marker = new vtmapgl.Marker()
      .setLngLat([105.64541502352529, 21.02316323590901])
      .setPopup(popup)
      .addTo(map);
  }
  clickedMarker(label: string, index: number) {
    this.MapInfoWindows.forEach(window => {
      window.close();
    });
    // console.log(`clicked the marker: ${label || index}`)
  }

  mapClicked($event: MouseEvent) {
    this.location.markers.push({
      lat: $event.coords.lat,
      lng: $event.coords.lng,
      draggable: true
    });
  }

  markerDragEnd(m: Marker, $event: MouseEvent) {
    // console.log('dragEnd', m, $event);
    // const infowindow = new google.maps.InfoWindow({
    //   content: "nội dung",
    // });

  }
  showAlert() {
    alert("Hello mr nghia");
    console.log("11111");
  }
  modalShow(id) {
    this.initPlayer();
    this.player.play();
    // console.log(id);
    $(id).modal('show');
  }
  player: any;
  initPlayer() {
    this.player = videojs('idVideojs',
      {
        autoplay: true,
        controls: true,
        isAudio: true,
        controlBar: {
          timeDivider: false,
          durationDisplay: false,
          progressControl: false,
          currentTimeDisplay: false,
          remainingTimeDisplay: false,
          pictureInPictureToggle: false
        },
        preload: 'auto',
        fluid: true,
        sources: [{ src: 'https://media10.cloudcam.vn/cloud-camera/idc_admin1573096393351.stream/playlist.m3u8', type: "application/x-mpegURL" }]
      });
  }
}
// just an interface for type safety.
interface Marker {
  lat: number;
  lng: number;
  label?: string;
  draggable?: boolean;
}
interface Location {
  latitude: number;
  longitude: number;
  mapType?: string;
  zoom?: number;
  markers: Marker[];
}
