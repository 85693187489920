import { Component, Input,EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-page',
  templateUrl: './page.component.html',
  styleUrls: ['./page.component.css']
})
export class PageComponent implements OnInit {

  @Input() active = false;
  @Input() number = 0;
  @Output() clickItem = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {

  }

  click = () => {
    this.clickItem.emit(this.number);
  }
}
