<div class="table-responsive">
  <table class="table" style="margin-bottom:unset">
    <thead>
      <tr class="header-table">
        <th scope="col" style="width: 1%;"></th>
        <th scope="col" style="width: 2%;">STT</th>
        <th scope="col" class="text-center">Tên camera</th>
        <th scope="col" class="text-center">Cloud ID</th>
        <th scope="col" class="text-center">Trạng thái</th>
        <th scope="col" class="text-center">Trạng thái AI</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let cam of cameras; let i = index">
        <td class="text-left" style="display: flex;">
          <input id="cam_{{cam.cameraId}}" type="checkbox" name="cb_RegisAI" [(ngModel)]="cam._isActive"
            style="width: 18px; height: 18px;">
        </td>
        <td class="text-center">{{i+1}} {{cam.checked}}</td>
        <td class="text-center">{{cam.cameraName}}</td>
        <td class="text-center">{{cam.cloudId}}</td>
        <td class="text-center">
          <div *ngIf="cam.connectionStatus=='CONNECTED'">
            <span class="camera-status active"></span> Hoạt động
          </div>
          <div *ngIf="cam.connectionStatus=='DISCONNECTED'">
            <span class="camera-status inactive"></span> Không hoạt động
          </div>
        </td>
        <td class="text-center">
          <div *ngIf="cam.connectionStatus=='CONNECTED'">
            <span class="camera-status active"></span> Hoạt động
          </div>
          <div *ngIf="cam.connectionStatus=='DISCONNECTED'">
            <span class="camera-status inactive"></span> Không hoạt động
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</div>
