import { Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { BehaviorSubject } from 'rxjs';
import { FirebaseService } from './firebase.service';

@Injectable({
  providedIn: 'root',
})
export class MessagingService {
  currentMessage = new BehaviorSubject(null);

  constructor(private afMessaging: AngularFireMessaging, private readonly firebaseService: FirebaseService) { }

  requestPermission(): void {
    this.afMessaging.requestToken.subscribe(
      (token) => {
        console.log('[FCM] Permission granted!');
      },
      (error) => {
        console.error('Unable to get permission to notify.', error);
      }
    );
  }

  receiveMessages(): void {
    this.afMessaging.messages.subscribe((message) => {
      this.currentMessage.next(message);
    });
  }

  subscribeTopic(): void {
    this.afMessaging.getToken.subscribe((token) => {
      this.firebaseService.subscribeToTopic(token).subscribe(
        (res) => {
          console.log("[FCM] Subscribed to topic successfully.");
        },
        (err) => {
          console.error('[FCM] Unable to subscribe to topic', err);
        }
      );
    }, (err) => {
      console.error('[FCM] Unable to get token', err);
    });
  }

  unsubscribeTopic(): void {
    this.afMessaging.getToken.subscribe((token) => {
      this.firebaseService.unsubscribeFromTopic(token).subscribe(
        (res) => {
          console.log("[FCM] Unsubscribed from topic successfully.");
        },
        (err) => {
          console.error('[FCM] Unable to unsubscribe from topic', err);
        }
      );
    }, (err) => {
      console.error('[FCM] Unable to get token', err);
    });
  };
}
