<a class="row custom-row"  *ngIf="bunch.isActive">
  <a class="row col-sm" (click)="onClick()">
    <div class="col-sm-9">
        <div class="icons-dropdown" *ngIf="bunch.listChild.length > 0" [style]="style"></div>
        <div class="col-sm-6" [style]="displayLevel()">{{bunch.bunch.name}}</div>
    </div>
    <div class="col-sm">
      <div class="nav-icon icon-camera" *ngIf="bunch.listCamera.length > 0"></div>
    </div>
  </a>
</a>
<div class="row custom-row header-table" *ngIf="bunch.listCamera.length > 0 && bunch.isClick == true" [style]="displayLevelHeader()" style="margin-top: 20px;">
    <div class="col-sm">{{'ad_stt'|translate}}</div>
    <div class="col-sm">{{'ad_camera_name'|translate}}</div>
    <div class="col-sm">CloudID</div>
    <!-- <div class="col-sm">{{'ad_resolution'|translate}}</div> -->
    <div class="col-sm">{{'ad_status'|translate}}</div>
    <!-- <div class="col-sm">{{'ad_numshare'|translate}}</div> -->
    <div class="col-sm">{{'ad_action'|translate}}</div>
</div>
<div class="camera-groups" *ngIf=" bunch.isClick == true">
    <a *ngFor="let item of bunch.listCamera; let index = index">

      <a  class="row custom-row" *ngIf="item.display">
        <div *ngIf="item.display" class="col-sm">
            {{index + 1}}</div>
        <div class="col-sm" style="white-space: nowrap;overflow: hidden;
                text-overflow: ellipsis;">{{item.name}}</div>
        <div class="col-sm">{{item.cloudId}}</div>
        <!-- <div class="col-sm">{{item.resolution}}</div> -->
        <div class="col-sm" style="text-align: center;">
            <div *ngIf="item.connectionStatus=='CONNECTED'">
            <span class="camera-status active"></span>{{'ad_connect'|translate}}
          </div>
          <div *ngIf="item.connectionStatus=='DISCONNECTED'">
            <span class="camera-status inactive"></span>{{'ad_no_connect'|translate}}
          </div>
        </div>
        <!-- <div class="col-sm">{{item.numShare}}</div> -->
        <div class="col-sm">
            <nav class="action-list">
                <a  title="Sửa camera" (click)="EditModal(item)">
                    <i class="icon icon-edit"></i>
                  </a>
                  <a  title="Giao thức stream" (click)="ShowModal(item)">
                    <i class="icon icon-link"></i>
                  </a>
                  <a  (click)="RemoveCamera(item.id)" title="Xóa camera">
                    <i class="icon icon-delete"></i>
                  </a>
                  <a  title="Reload camera" (click) = "ReloadCamera(item)">
                    <i class="icon icon-reload-resolution"></i>
                  </a>
            </nav>

        </div>
      </a>
    </a>
</div>
