import { AuthHepler } from "../../_helpers/auth.helper";
import { AccountModel, Transaction } from "./../../_models/account.model";
import { AccountService } from "./../../_services/account.service";
import { AuthenticationService } from "./../../_services/authentication.service";
import { FormValidateService } from "../../_services/form-validate.service";
import { EncryptPasswordService } from "../../_services/encrypt-password.service";
import { UserService } from "../../_services/user.service";
import { BaseService } from "../../_services/base.service";
import { Component, OnInit, Input, SecurityContext } from "@angular/core";
import {
  FormGroup,
  Validators,
  FormBuilder,
  FormControl,
} from "@angular/forms";
import { CookieService } from "ngx-cookie-service";
import Swal from "sweetalert2";
import { DomSanitizer } from "@angular/platform-browser";
import { AppComponent } from "src/app/app.component";
import { LanguageModel } from "src/app/_models/language.model";
declare var $: any;

@Component({
  selector: "app-info-account",
  templateUrl: "./info-account.component.html",
  styleUrls: ["./info-account.component.css"],
})
export class InfoAccountComponent extends AuthHepler implements OnInit {
  @Input() password: string;
  @Input() oldPassword: string;
  changePasswordForm: FormGroup;
  twoFactorForm: FormGroup;
  accForm: FormGroup;
  currAccount: AccountModel = new AccountModel();
  totalHistory: number = 0;
  listHistory: Transaction[];
  pageSize: number = 5;
  currentPage: number = 1;
  isConfirmCaptcha: boolean = false;
  isTwoFactor: boolean = true;
  langModel = new LanguageModel();
  secretCode = "";
  qrCode = "";
  otpCode = "";
  constructor(
    public _authenticationService: AuthenticationService,
    private _accountService: AccountService,
    public _formValidateService: FormValidateService,
    private _fb: FormBuilder,
    private _encryptService: EncryptPasswordService,
    private _userService: UserService,
    public baseService: BaseService,
    public authenticationSevice: AuthenticationService,
    private dom: DomSanitizer,
    private _appComponent: AppComponent,
    public cookie: CookieService
  ) {
    super(baseService, authenticationSevice, cookie);
  }

  ngOnInit(): void {
    this.getCurrAccount();
    this.createForm();
    this.GetTransHistory();
    //chuyển ngữ
    this._appComponent.translate
      .get("li_update_pass")
      .subscribe((res: string) => {
        this.langModel.title = res;
      });
    this._appComponent.translate
      .get("li_change_pass_success")
      .subscribe((res: string) => {
        this.langModel.content = res;
      });
    this._appComponent.translate
      .get("li_err_update")
      .subscribe((res: string) => {
        this.langModel.title1 = res;
      });
    this._appComponent.translate
      .get("li_err_check")
      .subscribe((res: string) => {
        this.langModel.content1 = res;
      });
    this._appComponent.translate
      .get("li_change_info")
      .subscribe((res: string) => {
        this.langModel.title2 = res;
      });
    this._appComponent.translate
      .get("li_change_info_succ")
      .subscribe((res: string) => {
        this.langModel.content2 = res;
      });
    this._appComponent.translate
      .get("li_ques_off_2factor")
      .subscribe((res: string) => {
        this.langModel.title3 = res;
      });
    this._appComponent.translate
      .get("li_off_2factor_succ")
      .subscribe((res: string) => {
        this.langModel.content3 = res;
      });
    this._appComponent.translate
      .get("li_notiffi")
      .subscribe((res: string) => {
        this.langModel.title4 = res;
      });
    this._appComponent.translate
      .get("li_change_pass_success")
      .subscribe((res: string) => {
        this.langModel.content4 = res;
      });

    this._appComponent.translate
      .get("li_register_succ")
      .subscribe((res: string) => {
        this.langModel.title5 = res;
      });
    this._appComponent.translate
      .get("li_register_2factor_succ")
      .subscribe((res: string) => {
        this.langModel.content5 = res;
      });
    this._appComponent.translate
      .get("li_register_failed")
      .subscribe((res: string) => {
        this.langModel.title6 = res;
      });
    this._appComponent.translate
      .get("li_2factor_failed")
      .subscribe((res: string) => {
        this.langModel.content6 = res;
      });

    this._appComponent.translate
      .get("li_cancel")
      .subscribe((res: string) => {
        this.langModel.title7 = res;
      });
    this._appComponent.translate.get("li_ok").subscribe((res: string) => {
      this.langModel.title8 = res;
    });
    this._appComponent.translate
      .get("li_confirm")
      .subscribe((res: string) => {
        this.langModel.title9 = res;
      });

      
    this._appComponent.translate
    .get("otp_title")
    .subscribe((res: string) => {
      this.langModel.content7 = res;
    });
    this._appComponent.translate
    .get("otpcontent")
    .subscribe((res: string) => {
      this.langModel.content8 = res;
    });
  }
  getCurrAccount() {
    this._accountService.getDetail().subscribe(
      (res) => {
        this.currAccount = res["account"] as AccountModel;
        let sTwoFactor = this.currAccount.twoFactor;
        if (sTwoFactor == "ACTIVE") this.isTwoFactor = true;
        else this.isTwoFactor = false;
        if (this.currAccount != null && this.currAccount.qrcode != null)
          this.qrCode = this.currAccount.qrcode.replace(/\./gi, "+");
      },
      (err) => {
        if (err === "Unauthorized") {
          this.unAuthHandle(() => this.getCurrAccount());
        } else {
          console.log("Lỗi lấy acc hiện tại" + err);
        }
      }
    );
  }

  createForm() {
    this.twoFactorForm = this._fb.group({
      secretCode: [""],
      otpCode: [
        "",
        this._formValidateService.twoFactorValidate.otpCodeValidate.validator,
      ],
    });
    this.changePasswordForm = this._fb.group({
      oldPassword: [
        "",
        this._formValidateService.customerFormValidate.oldPasswordValidate
          .validator,
      ],
      matchingPassword: this._fb.group(
        {
          password: [
            "",
            this._formValidateService.customerFormValidate.passwordValidate
              .validator,
          ],
          passwordConfirm: [""],
        },
        {
          validator:
            this._formValidateService.customerFormValidate.matchingPassword
              .validator,
        }
      ),
    });

    this.accForm = this._fb.group({
      username: [""],
      fullName: [
        "",
        this._formValidateService.customerFormValidate.fullNameValidate
          .validator,
      ],
      email: [""],
      phoneNumber: [""],
      personalId: [""],
      address: [""],
    });
  }
  updatePassword() {
    const username = JSON.parse(this.cookie.get("currentUser")).username;
    const oldPassword = this._encryptService.encryptSHA512(
      username,
      this.oldPassword
    );
    const newPassword = this._encryptService.encryptSHA512(
      username,
      this.password
    );
    this._userService.changePassword(oldPassword, newPassword).subscribe(
      (res) => {
        $("#changePassword").modal("hide");
        Swal.fire(this.langModel.title, this.langModel.content, "success");
      },
      (err) => {
        if (err === "Unauthorized") {
          this.unAuthHandle(() => this.updatePassword());
        } else if (err === "Invalid data") {
          Swal.fire(this.langModel.title1, this.langModel.content1, "error");
        } else {
          console.log(err);
        }
      }
    );
  }
  GetTransHistory() {
    this._accountService.TransHistory().subscribe(
      (res) => {
        this.listHistory = res.listTransaction;
        this.totalHistory = res.totalTransaction;
      },
      (err) => {
        if (err === "Unauthorized") {
          this.unAuthHandle(() => this.getCurrAccount());
        } else {
          console.log(err);
        }
      }
    );
  }
  resolved(captchaResponse: string) {
    if (captchaResponse.length == 0) {
      this.isConfirmCaptcha = false;
    } else {
      this.isConfirmCaptcha = true;
    }
  }
  UpdateAccount() {
    this._accountService.updateAccount(this.currAccount).subscribe(
      (res) => {
        Swal.fire(this.langModel.title2, this.langModel.content2, "success");
      },
      (err) => {
        if (err === "Unauthorized") {
          this.unAuthHandle(() => this.UpdateAccount());
        } else {
          console.log("lỗi sửa người dùng " + err);
        }
      }
    );
  }
  ShowConfirmTwoFactor() {
    const username = JSON.parse(this.cookie.get("currentUser")).username;
    if (this.isTwoFactor === true) {
      //Show modal xác thực hai lớp---tạm bỏ việc tạo QR mới
      if (
        this.currAccount.secretKey == null ||
        this.currAccount.secretKey == "" ||
        this.currAccount.qrcode == null ||
        this.currAccount.qrcode == ""
      ) {
        this._authenticationService.genKey().subscribe((res) => {
          this.secretCode = res.secretkey;
          this._authenticationService
            .getqrCode(username, res.secretkey)
            .subscribe((res1) => {
              this.qrCode = "data:image/png;base64," + res1.base64Encoded;
            });
        });
      } else {
        this.secretCode = this.currAccount.secretKey;
        this.qrCode = this.currAccount.qrcode.replace(/\./gi, "+");
      }
      this.otpCode = "";
      $("#twoFactorModal").modal("show");
    } else {
      Swal.fire({
        title: this.langModel.title9,
        text: this.langModel.title3,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: this.langModel.title8,
        cancelButtonText: this.langModel.title7,
      }).then((result) => {
        if (result.value) {
          this.authenticationSevice
            .DoTwoFactor(username, "UNACTIVE", this.otpCode)
            .subscribe(
              (res2) => {
                Swal.fire(
                  this.langModel.title4,
                  this.langModel.content3,
                  "success"
                );
              },
              (err2) => {
                console.log("Lỗi tắt tính năng xác thực hai lớp");
              }
            );
          this.isTwoFactor = false;
        } else if (result.dismiss) {
          this.isTwoFactor = true;
        }
      });
    }
  }
  closeModal() {
    this.isTwoFactor = false;
    $("#twoFactorModal").modal("hide");
  }
  registerFactor() {
    const username = JSON.parse(this.cookie.get("currentUser")).username;
    if (
      this.currAccount.secretKey == null ||
      this.currAccount.secretKey == ""
    ) {
      this.authenticationSevice
        .WriteSecretKey(username, this.secretCode)
        .subscribe(
          (res2) => {
            console.log("ghi secretKey vào database");
            if (
              this.currAccount.qrcode == null ||
              this.currAccount.qrcode == ""
            ) {
              let qrCodeSave = this.qrCode.replace(/\+/gi, ".");
              console.log(qrCodeSave);
              this.authenticationSevice
                .WriteQrcode(username, qrCodeSave)
                .subscribe(
                  (res3) => {
                    this.exeRegister(username);
                  },
                  (err3) => {
                    Swal.fire(
                      this.langModel.content7,
                      this.langModel.content8,
                      "error"
                    );
                    console.log("Lỗi ghi qrcode vào database");
                  }
                );
            }
          },
          (err2) => {
            Swal.fire(
              this.langModel.content7,
              this.langModel.content8,
              "error"
            );
            console.log("Lỗi ghi secretKey vào database");
          }
        );
    } else {
      this._accountService.getDetail().subscribe(
        (res) => {
          this.currAccount = res["account"] as AccountModel;
          if (this.currAccount.twoFactor == "UNACTIVE" ||  this.currAccount.twoFactor == null) {
            this.exeRegister(username);
          } else {
            Swal.fire(
              this.langModel.title5,
              this.langModel.content5,
              "success"
            );
            $("#twoFactorModal").modal("hide");
          }
        },
        (err) => {}
      );
    }
  }
  exeRegister(username) {
    this.authenticationSevice
      .DoTwoFactor(username, "ACTIVE", this.otpCode)
      .subscribe(
        (res1) => {
          Swal.fire(this.langModel.title5, this.langModel.content5, "success");
          $("#twoFactorModal").modal("hide");
        },
        (error) => {
          Swal.fire(
            this.langModel.content7,
            this.langModel.content8,
            "error"
          );
          console.log("Lỗi kích hoạt xác thực hai lớp");
        }
      );
  }
}
