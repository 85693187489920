<div class="sidebar">
  <a href="#" class="sidebar-toggle"></a>
  <h3 class="sidebar-title">{{'ad_BUNCH'|translate}}</h3>
  <div class="sidebar-content">
    <div class="list-item">
      <i class="icon icon-search"></i>
      <input class="search-input" placeholder="{{'ad_search_bunch'|translate}}" (keyup)="searching($event)" />
    </div>
    <div id="camera-accordion" style="width: 100%;overflow-x: scroll;">
        <app-tree-play-back-group [listBunch]="cloneData" (camera)="getCameraPlaybackList($event)" [search]="search"></app-tree-play-back-group>
    </div>
  </div>
</div>