import { AuthHepler } from '../../_helpers/auth.helper';
import { UserService } from './../../_services/user.service';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { FormGroup, Validators, FormBuilder, FormControl } from '@angular/forms';
import { FormValidateService } from '../../_services/form-validate.service';
import { EncryptPasswordService } from '../../_services/encrypt-password.service';
import { AuthenticationService } from './../../_services/authentication.service';
import { CookieService } from 'ngx-cookie-service';
import Swal from 'sweetalert2';
import { AppComponent } from 'src/app/app.component';
import { LanguageModel } from 'src/app/_models/language.model';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent extends AuthHepler implements OnInit {

  showload = false;
  resetForm: FormGroup;
  currToken:string ='';
  password:string='';
  username: string ='';
  langModel = new LanguageModel();
  constructor(public _authenticationService: AuthenticationService,
    private _fb: FormBuilder,
    private _encryptService: EncryptPasswordService,
    public _formValidateService: FormValidateService,
    private activatedRoute: ActivatedRoute,
    private _appComponent: AppComponent,
    private _userService: UserService
    ,public cookie: CookieService) {
      super(_userService, _authenticationService, cookie) }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe((params: Params) => {
      this.currToken = params["token"];
      this._userService.verifyResetPassword(this.currToken).subscribe(
        res => {
          this.showload = true;
          this.username= res.username;
        },
        err => {
          this.showload = false;
          // console.log(err);
        }
      );
    });
    this.createForm();
    //chuyển ngữ
    this._appComponent.translate.get('li_update_pass').subscribe((res: string) => {
      this.langModel.title = res;
    });
    this._appComponent.translate.get('li_reset_pass_succ').subscribe((res: string) => {
      this.langModel.content = res;
    });
    this._appComponent.translate.get('li_err_update').subscribe((res: string) => {
      this.langModel.title1 = res;
    });
    this._appComponent.translate.get('li_err_check').subscribe((res: string) => {
      this.langModel.content1 = res;
    });
  }
  createForm() {
    this.resetForm = this._fb.group({
      matchingPassword: this._fb.group({
        password: ['', this._formValidateService.customerFormValidate.passwordValidate.validator],
        passwordConfirm: [''],
      }, { validator: this._formValidateService.customerFormValidate.matchingPassword.validator })
    });
  }
  ResetPass(){
    const newPassword = this._encryptService.encryptSHA512(this.username, this.password);

    this._userService.resetPassword(this.currToken,newPassword).subscribe(
      res => {
        Swal.fire(this.langModel.title,this.langModel.content,'success');
        // console.log(res);
      },
      err => {
        // console.log(err);
        if (err === 'Unauthorized') {
          this.unAuthHandle(() => this.ResetPass());
        }
        else if(err==='Invalid data'){
          Swal.fire(this.langModel.title1,this.langModel.content1,'error');
        }
        else{
          console.log('Loi reset password '+ err);
        }
      }
    );
  }
}
