import { Injectable } from '@angular/core';
import { Http, Response, URLSearchParams } from '@angular/http';
import { Router } from '@angular/router';
import { ApiUrlService } from '../_services/api-url.service';
import { BaseService } from '../_services/base.service';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class TreeviewService extends BaseService {

  constructor(private _apiUrl: ApiUrlService, public _http: Http,
        public cookie: CookieService) {
    super(_http, _apiUrl, cookie);
  }
  getCameraTree() {
    return this._http.get(this._apiUrl.MANAGEMENT_BUNCH_CAMERA_TREEVIEW, this.authorizationOption())
      .map(this.extractData)
      .catch(this.handleError);
  }
  getCameraInBunch(_bunchId: number) {
    let reqParams = {};
    const params: URLSearchParams = new URLSearchParams();
    params.set('bunch-id', `${_bunchId}`);
    return this._http.get(this._apiUrl.MANAGEMENT_LIST_CAMERA_BY_BUNCH_ID, this.authorizationOption().merge({ search: params }))
      .map(this.extractData)
      .catch(this.handleError);

  }
}
