import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from 'src/app/_guards';
import { AicameraComponent } from './aicamera.component';
import { RegisterFaceIDComponent } from './register-face-id/register-face-id.component';
import { CheckInEventComponent } from './manage-event/check-in-event/check-in-event.component';
import { RegisterCameraComponent } from './register-camera/register-camera.component';
import { RegisterAreasComponent } from './register-areas/register-areas.component';
import { ManageLogComponent } from './manage-log/manage-log/manage-log.component';
import { RegisterForScenarioComponent } from './register-camera/register-for-scenario/register-for-scenario.component';
import { IntrusionEventComponent } from './manage-event/intrusion-event/intrusion-event.component';
import { RegisterAreaComponent } from './register-areas/register-area/register-area.component';
import { EliteGuestEventComponent } from './manage-event/elite-guest-event/elite-guest-event.component';
import { SmartAlertComponent } from './smart-alert/smart-alert-event/smart-alert.component';
import { ConfigAlertComponent } from './smart-alert/config-alert/config-alert.component';

const routes: Routes = [
  {
    path: 'aicamera',
    component: AicameraComponent,
    children: [
      { path: 'register-scenarios', component: RegisterCameraComponent, canActivate: [AuthGuard] },
      { path: 'register-scenarios/:id', component: RegisterForScenarioComponent, canActivate: [AuthGuard] },
      { path: 'register-face-ids', component: RegisterFaceIDComponent, canActivate: [AuthGuard] },
      { path: 'register-areas', component: RegisterAreasComponent, canActivate: [AuthGuard] },
      { path: 'register-areas/:id', component: RegisterAreaComponent, canActivate: [AuthGuard] },
      { path: 'logs', component: ManageLogComponent, canActivate: [AuthGuard] },
      { path: 'check-in-events', component: CheckInEventComponent, canActivate: [AuthGuard] },
      { path: 'elite-guest-events', component: EliteGuestEventComponent, canActivate: [AuthGuard] },
      { path: 'intrusion-events', component: IntrusionEventComponent, canActivate: [AuthGuard] },
      { path : 'smart-alerts', component: SmartAlertComponent, canActivate: [AuthGuard] },
      { path : 'config-alerts', component: ConfigAlertComponent, canActivate: [AuthGuard] }
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AicameraRoutingModule { }
