import { Injectable } from '@angular/core';
import { Response, Headers, RequestOptions, Http } from '@angular/http';
import { ApiUrlService } from './api-url.service';
import { Observable } from 'rxjs/Observable';
import { CookieService } from 'ngx-cookie-service';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BaseService {
  accessToken: string;
  constructor(
    public _http: Http,
    public _apiUrlService: ApiUrlService,
    public cookie: CookieService
  ) { }
  protected extractData(res: Response) {
    const body = res.json();
    return body || {};
  }
  
  protected handleError(error: Response | any) {
    let errorMessage: string;

    if (error.status === 401) {
      return throwError('Unauthorized');
    }
    if (error.status === 402) {
      return throwError('Payment Required');
    }
    if (error.status === 403) {
      return throwError('Inactive');
    }
    if (error.status === 409) {
      return throwError('Conflict');
    }
    if (error.status === 422) {
      return throwError('Invalid data');
    }
    if (error.status === 500) {
      return throwError('Internal server error');
    }
    if (error instanceof Response) {
      const body = error.json() || '';
      const err = body.error || JSON.stringify(body);
      errorMessage = `${error.status} - ${error.statusText || ''} ${err}`;
    } else {
      errorMessage = error.message ? error.message : error.toString();
    }
    return throwError(error);
  }

  public authorizationOption(): RequestOptions {
    if(this.cookie.get('currentUser')!= ''){
    const currentUser = JSON.parse(this.cookie.get('currentUser'));
    if (currentUser && currentUser.accessToken) {
      this.accessToken = currentUser.accessToken;
    }
  }
    const headers = new Headers({ Authorization: `Bearer ${this.accessToken}` });
    headers.set('Content-Type', 'application/json');
    // headers.set('Access-Control-Allow-Origin','*');
    return new RequestOptions({ headers: headers });
  }
  public aiAuthorizationOption(): RequestOptions {
    if (this.cookie.get("ai_camera_token") != "") {
      this.accessToken = this.cookie.get("ai_camera_token");
    }
    const headers = new Headers({
      Authorization: `${this.accessToken}`,
    });
    headers.set("Content-Type", "application/json");
    headers.set("Access-Control-Allow-Origin", "*");
    headers.set("Grpc-Metadata-admin", "admin");
    return new RequestOptions({ headers: headers });
  }
  refreshAuthentication() {
    this.accessToken = null;
    const currentUser = JSON.parse(this.cookie.get('currentUser'));
    const username = currentUser.username;
    const deviceId = currentUser.deviceId;  
    const refreshToken = currentUser.refreshToken;
    return this._http.post(this._apiUrlService.ACCOUNT_REFRESH_TOKEN,
      {
        username: username,
        refreshToken: refreshToken,
        deviceId: deviceId
      },this.authorizationOption())
      .map(this.extractData)
      .catch(
      error => {
        // console.log('phiên làm việc không còn hiệu lực, vui lòng đăng nhập lại để tiếp tục');
        return throwError(error);
      });
  }

  public exportFileOption(): RequestOptions {
    if(this.cookie.get('currentUser')!= ''){
    const currentUser = JSON.parse(this.cookie.get('currentUser'));
    if (currentUser && currentUser.accessToken) {
      this.accessToken = currentUser.accessToken;
    }
  }
    const headers = new Headers({ Authorization: `Bearer ${this.accessToken}` });
    headers.set('Content-Type', 'application/json');
    return new RequestOptions({ headers: headers , responseType: 3 as 1});
  }

}
