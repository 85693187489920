<header>
  <div class="header">
    <div><a href="/home">
        <img class="logo" src="assets/logo camera.svg" alt="Cloud Camera" />

      </a></div>
  </div>
</header>

<body style="background: #F5F5F5;">
  <div class="container">
    <div class="row">
      <div class="col-md-2"></div>
      <div class="col-md-6">
        <div class="border-form">

          <div class="login-round">
            <div class="title-login" translate>{{'ldp_login'}}</div>
            <form [formGroup]="loginForm" (ngSubmit)="loginForm.valid && login()" class="form">
              <div class="input-form">
                <input id="form-username" class="input-text" placeholder="{{'li_username'|translate}}" type="text"
                  [(ngModel)]="usernameLogin" formControlName="username">
                <div *ngIf="loginForm.controls.username.invalid && loginForm.controls.username.touched"
                  class="alert alert-danger">
                  {{_formValidateService.LoginValidate.usernameValidate.message(loginForm.controls.username)}}.</div>

                <div class="form-group form-group-password position-relative" style="width: 100%;">
                  <input id="passLogin" class="input-text" placeholder="{{'li_password'|translate}}"
                    type="password" [(ngModel)]="passwordLogin" formControlName="password">
                  <i class="icon icon-eye open" data-for="password" (click)="hideShowPass()"></i>
                  <div *ngIf="loginForm.controls.password.invalid &&loginForm.controls.password.touched"
                    class="alert alert-danger" style="margin-top: 10px;">
                    {{_formValidateService.LoginValidate.passwordValidate.message(loginForm.controls.password)}}.</div>
                </div>
              </div>

              <div *ngIf="numFailed>4" translate>
                <re-captcha (resolved)="resolved($event)"
                  siteKey="6LdKA6kZAAAAANmMBOx0njeanv_bOj-RmB8uvK1a"></re-captcha>
                <button class="btn btn-login" type="submit"
                  [disabled]="loginForm.invalid || !isConfirmCaptcha">{{'li_LOGIN'}}</button>
              </div>
              <div *ngIf="numFailed<5">
                <button class="btn btn-login" type="submit" [disabled]="loginForm.invalid" translate><img
                    *ngIf="loading"
                    src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                  {{'li_LOGIN'}}</button>
              </div>
            </form>
            <div class="not-account">
              <div class="register">
                <p translate>{{'li_have_acc'}}</p>
                <a routerLink="/register" style="color:#EE0033; margin-top: 1em;"
                  translate>&nbsp;{{'ldp_register'}}</a>
              </div>
              <a routerLink="/forgot-password" class="forgot-password" translate>{{'li_forgot_pass'}}</a>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-2"></div>
    </div>
  </div>
</body>
<footer>
  <div class="footer">
    <p translate>{{'li_copyright'}}<span class="text-red" translate>{{'li_phone'}}</span>
      {{'li_copyright1'}}
      <span class="text-red" translate>{{'li_email'}}</span>
    </p>
  </div>
</footer>

<!--Modal register twofactor-->
<div class="modal fade bd-example-modal-lg" id="twoFactorModal" tabindex="-1" role="dialog"
  aria-labelledby="twoFactorModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header bg-primary">
        <h5 class="modal-title" id="twoFactorTitle">{{'li_CONFIRM_ACCOUNT'|translate}}</h5>
        <button type="button" class="close" aria-label="Close" (click)="closeModal('twoFactorModal')">
          <i class="icon icon-close"></i>
        </button>
      </div>
      <div class="modal-body">
        <form [formGroup]="twoFactorForm" (ngSubmit)="twoFactorForm.valid  && registerFactor()">
          <div class="form-group">
            <div class="form-group">
              <label class="control-label" for="current-password">{{'li_step_1'|translate}} </label>
              <div style="display: flex; align-items: center; justify-content: center;">
                <img [src]="qrCode" width="400" height="400">
              </div>
            </div>
            <div class="form-group">
              <label class="control-label" for="current-password">{{'li_or_handmade'|translate}}&ensp;</label>
              <label class="control-label">{{secretCode}}</label>
            </div>
            <div class="form-group">
              <label class="control-label" for="current-password">{{'li_step_2'|translate}}&emsp;</label>
              <input class="form-control" placeholder="{{'otp_placeholder'|translate}}"
                formControlName="otpCode" [(ngModel)]="otpCode" />
              <div *ngIf="twoFactorForm.controls.otpCode.invalid && twoFactorForm.controls.otpCode.touched"
                class="alert alert-danger">
                {{_formValidateService.twoFactorValidate.otpCodeValidate.message(twoFactorForm.controls.otpCode)}}.
              </div>
            </div>
          </div>
          <div class="form-action" style="text-align: center;">
            <button class="btn btn-outline-secondary mr-3" type="button"
              (click)="closeModal('twoFactorModal')">{{'li_cancel'|translate}}</button>
            <button class="btn btn-primary" [disabled]="twoFactorForm.invalid"
              type="submit">{{'li_confirm'|translate}}</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
<!--Modal break confirm twofactor-->
<div class="modal fade bd-example-modal-lg" id="confirmFactorModal" tabindex="-1" role="dialog"
  aria-labelledby="confirmFactorModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header bg-primary">
        <h5 class="modal-title" translate>{{'li_title_remind_twofactor'}}</h5>
        <button type="button" class="close" aria-label="Close" (click)="closeModal('confirmFactorModal')">
          <i class="icon icon-close"></i>
        </button>
      </div>
      <div class="modal-body" style="display: flex; flex-direction: column; align-items: flex-start; gap: 10px;">
        <div
          style="line-height: 1.5; color: var(--home-black-90, #262626); text-align: justify;font-family: Sarabun; font-size: 16px;font-weight: 550;">
          <div translate>{{'li_content_remind_twofactor1'}}</div>
          <div translate>{{'li_content_remind_twofactor2'}}</div>
          <div translate>{{'li_content_remind_twofactor3'}}</div>
          <ul>
            <li translate>{{'li_content_remind_twofactor4'}}</li>
            <li translate>{{'li_content_remind_twofactor5'}}</li>
          </ul>
        </div>
        <div style="display: flex; gap:15px; justify-content: end; width: 100%;">
          <div
            style="display: flex; height: 44px; padding: 10px 32px; flex-direction: column; justify-content: center; align-items: center; gap: 10px;border-radius: 2px; border: 1px solid #000;box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.04);cursor: pointer;"
            (click)="hideTwoFactor()" translate>{{'li_break'}}</div>
          <div
            style="display: flex; height: 44px; padding: 10px 32px; justify-content: center; align-items: center; gap: 10px;border-radius: 2px; background: var(--Viettel, #E03); box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.04);">
            <span
              style="color: var(--character-primary-inverse, #FFF); text-align: center; font-family: Sarabun; font-size: 16px; font-style: normal; font-weight: 600; line-height: 22px; cursor: pointer;"
              (click)="EnableTwoFactor('confirmFactorModal')" translate>{{'li_on_twofactor'}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Modal Change Password -->
<div class="modal fade" id="changePasswordModal" tabindex="-1" role="dialog" aria-labelledby="changePasswordTitle"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered account-modal" role="document">
    <div class="modal-content">
      <div class="modal-header bg-primary">
        <h5 class="modal-title" id="changePasswordTitle">THAY ĐỔI MẬT KHẨU</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <i class="icon icon-close"></i>
        </button>
      </div>
      <div style="font-style: italic;
            padding: 1.5rem 1.5rem 0 1.5rem;
            font-size: smaller;">Tài khoản của bạn vừa reset mật khẩu. Vui lòng thay đổi mật khẩu để tiếp tục sử dụng
        dịch vụ Viettel Cloud Camera</div>
      <div class="modal-body">
        <form [formGroup]="changePasswordForm" (ngSubmit)="changePasswordForm.valid && updatePassword()">
          <div class="form-group">
            <label class="control-label" for="current-password">{{'li_curr_pass'|translate}} (<span
                class="text-danger">*</span>)</label>
            <input autocomplete="off" [(ngModel)]="oldPassword" class="form-control" type="password"
              id="current-password" placeholder="{{'li_enter_info'|translate}}" formControlName="oldPassword"
              required>
            <div
              *ngIf="changePasswordForm.controls.oldPassword.invalid &&changePasswordForm.controls.oldPassword.touched"
              class="alert alert-danger">
              {{_formValidateService.customerFormValidate.oldPasswordValidate.message(changePasswordForm.controls.oldPassword)}}.
            </div>
          </div>
          <div formGroupName="matchingPassword">
            <div class="form-group">
              <label class="control-label" for="new-password">{{'li_new_pass'|translate}} (<span
                  class="text-danger">*</span>)</label>
              <input [(ngModel)]="password" class="form-control" type="password" autocomplete="off"
                formControlName="password" id="new-password" placeholder="{{'li_enter_info'|translate}}" required>
              <div
                *ngIf="changePasswordForm.controls.matchingPassword.get('password').invalid &&changePasswordForm.controls.matchingPassword.get('password').touched"
                class="alert alert-danger">
                {{_formValidateService.customerFormValidate.passwordValidate.message(changePasswordForm.controls.matchingPassword.get('password'))}}.
              </div>
            </div>
            <div class="form-group">
              <label class="control-label" for="confirm-password">{{'li_re_pass'|translate}} (<span
                  class="text-danger">*</span>)</label>
              <input class="form-control" type="password" formControlName="passwordConfirm" autocomplete="off"
                id="confirm-password" placeholder="{{'li_enter_info'|translate}}" required />
              <div
                *ngIf="changePasswordForm.controls.matchingPassword.invalid && changePasswordForm.controls.matchingPassword.get('passwordConfirm').touched"
                class="alert alert-danger">
                {{_formValidateService.customerFormValidate.matchingPassword.message(changePasswordForm.controls.matchingPassword)}}.
              </div>
            </div>
          </div>
          <!-- <re-captcha (resolved)="resolved($event)" siteKey="6LdKA6kZAAAAANmMBOx0njeanv_bOj-RmB8uvK1a"></re-captcha> -->
          <div class="form-action">
            <button class="btn btn-outline-secondary mr-3" type="button"
              data-dismiss="modal">{{'li_cancel'|translate}}</button>
            <button class="btn btn-primary" [disabled]="changePasswordForm.invalid "
              type="submit">{{'li_confirm'|translate}}</button>
            <!-- <button class="btn btn-primary" [disabled]="changePasswordForm.invalid || !isConfirmCaptcha" type="submit">Xác nhận</button> -->
          </div>
        </form>
      </div>
    </div>
  </div>
</div>