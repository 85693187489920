import { Component, ElementRef, OnInit, Renderer2 } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { CookieService } from "ngx-cookie-service";
import { AuthHepler } from "src/app/_helpers/auth.helper";
import {
  AccountModel,
  BunchModel,
  CameraModel,
  UsernameCameraIdsModel,
} from "src/app/_models";
import {
  AccountService,
  AuthenticationService,
  CopyPermissionService,
} from "src/app/_services";
import { TreeviewService } from "src/app/_services/treeview.service";
import { AppComponent } from "src/app/app.component";
import Swal from "sweetalert2";
declare var $: any;
@Component({
  selector: "app-share-permission",
  templateUrl: "./share-permission.component.html",
  styleUrls: ["./share-permission.component.css"],
})
export class SharePermissionComponent extends AuthHepler implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private _treeService: TreeviewService,
    private _accService: AccountService,
    private _copyPerService: CopyPermissionService,
    private _router: Router,
    public _authenticationService: AuthenticationService,
    public cookie: CookieService,
    private renderer: Renderer2,
    private el: ElementRef,
    private _appComponent: AppComponent
  ) {
    super(_authenticationService, _authenticationService, cookie);
  }
  // selectedBunch: BunchModel = null;
  isView: boolean = false;
  isPermiss: boolean = true;
  isChooseUser: boolean = true;
  lstUser: AccountModel[] = [];
  lstSubAccount: AccountModel[] = [];
  bunchList: BunchModel[];
  bunchCList: BunchModel[];
  cameraAllows: CameraModel[];
  currIdCamAlloweds: number[] = [];
  idCamCopyAlloweds: number[] = [];
  currUsername: string = "";
  keyBunch: string = "";
  keyCamera: string = "";
  keySubAccount: string = "";
  usernameCopy: string = "";
  keyCBunch: string = "";
  keyCCamera: string = "";
  roleId: string = "";
  accSelected: AccountModel = new AccountModel();
  translations: { [key: string]: string } = {};
  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.currUsername = params["username"];
      this.roleId = params["roleId"];
    });
    this.getCameraAllows();
    this.getListSubAccount();
    if (this.roleId == "2") {
      this.isView = true;
    } else this.isView = false;
    this.updateActiveClass();
  }
  updateActiveClass() {
    const div = this.el.nativeElement.querySelector("#iAccount");
    this.renderer.addClass(div, "active");
  }
  //#region Phân quyền camera
  getListBunch() {
    this._treeService.getCameraTree().subscribe(
      (res) => {
        this.bunchList = res.listBunch;
        this.bunchCList = res.listBunch;
        //9-9-24 Xử lý bất đồng bộ
        const promises = res.listBunch.map((bunch) => {
          this.getCamerasInBunch(bunch);
        });
        Promise.all(promises)
          .then((results) => {
            // Xử lý khi tất cả các promise đã hoàn thành
            console.log("All API calls are complete");
          })
          .catch((error) => {
            // Xử lý lỗi nếu có bất kỳ API nào gặp lỗi
            console.error("Error in API calls:", error);
          });
        if (res.listCamera.length > 0) {
          let lstCamNoBunch: CameraModel[] = [];
          for (let cam of res.listCamera) {
            let camAllow = this.cameraAllows.find(
              (camAllow) => camAllow.id === cam.id
            );
            if (camAllow) {
              lstCamNoBunch.push(camAllow);
            }
          }
          if (lstCamNoBunch.length > 0) {
            var bunch = new BunchModel();
            bunch.name = this.t("li_not_bunch");
            bunch.id = 0;
            bunch.listCamera = lstCamNoBunch;
            this.bunchList.push(bunch);
          }
        }
      },
      (err) => {
        let errorBody = JSON.parse(err._body || "{}");
        if (errorBody.code === 401) {
          this.unAuthHandle(() => this.getListBunch());
        } else {
          console.log("Lỗi function: getListBunch " + err);
        }
      }
    );
  }
  getCameraAllows() {
    this._accService.listCameraAllowAsign().subscribe(
      (res) => {
        this.cameraAllows = res.listCamera;
        this.getCamerasAssigned();
        this.getListBunch();
      },
      (err) => {
        let errorBody = JSON.parse(err._body || "{}");
        if (errorBody.code === 401) {
          this.unAuthHandle(() => this.getCameraAllows());
        } else {
          console.log("Lỗi function: getCameraAllows " + err);
        }
      }
    );
  }
  getCamerasInBunch(_bunch: BunchModel) {
    // this.selectedBunch = _bunch;
    if (_bunch.id !== 0) {
      this._treeService.getCameraInBunch(_bunch.id).subscribe(
        (res) => {
          _bunch.listCamera = [];
          //check list cam đc quyền gán có trong nhóm này thì push vào
          for (let cam of res.listCamera) {
            const camAllow = this.cameraAllows.find(
              (camAllow) => camAllow.id === cam.id
            );
            if (camAllow) {
              _bunch.listCamera.push(camAllow);
            }
          }
        },
        (err) => {
          let errorBody = JSON.parse(err._body || "{}");
          if (errorBody.code === 401) {
            this.unAuthHandle(() => this.getCamerasInBunch(_bunch));
          } else {
            console.log("Lỗi function: getCameraInBunch " + err);
          }
        }
      );
    }
  }
  getCamerasAssigned() {
    //reset checked
    if (this.bunchCList != null) {
      this.isCheckedAll = false;
      this.bunchCList.forEach((b) => {
        b.listCamera.forEach((c) => {
          c.checked = false;
        });
      });
    }
    let username: string = "";
    if (this.isPermiss) {
      username = this.currUsername;
      if (this.roleId == "2") this.isView = true;
      else this.isView = false;
    } else {
      username = this.accSelected.username;
      if (this.accSelected?.role?.id == 2) this.isView = true;
      else this.isView = false;
    }
    this._accService.listCameraAssign(username).subscribe(
      (res) => {
        if (this.isPermiss) {
          this.currIdCamAlloweds = [];
          res.listCamera.forEach((camAssin) => {
            let camAllowed = this.cameraAllows.find(
              (camAllowed) => camAllowed.id === camAssin.id
            );
            if (camAllowed) {
              this.currIdCamAlloweds.push(camAllowed.id);
              camAllowed.checked = true;
            }
          });
          //Màn phân quyền khi camera đc phân hết thì checked checkAll
          if (this.currIdCamAlloweds.length == this.cameraAllows.length)
            this.isCheckedAll = true;
        } else {
          this.idCamCopyAlloweds = [];
          res.listCamera.forEach((camAssin) => {
            let camAllowed = this.cameraAllows.find(
              (camAllowed) => camAllowed.id === camAssin.id
            );
            if (camAllowed) {
              this.idCamCopyAlloweds.push(camAllowed.id);
              camAllowed.checked = true;
            }
          });
          //Màn sao chép quyền khi camera đc phân hết thì checked checkAll
          if (this.idCamCopyAlloweds.length == this.cameraAllows.length)
            this.isCheckedAll = true;
        }
      },
      (err) => {
        let errorBody = JSON.parse(err._body || "{}");
        if (errorBody.code === 401) {
          this.unAuthHandle(() => this.getCamerasAssigned());
        } else {
          console.log("Lỗi function: getCamerasAssigned " + err);
        }
      }
    );
  }
  //#endregion
  //#region Checkbox camera
  isCheckedAll: boolean = false;
  onSelectAllChange(event: any) {
    const isChecked = event.target.checked;
    for (let b of this.bunchList) {
      for (let c of b.listCamera) {
        c.checked = isChecked;
      }
    }
  }
  onCheckboxChange(event: any) {
    this.isCheckedAll = this.areAllChecked();
  }
  areAllChecked(): boolean {
    for (let b of this.bunchList) {
      for (let c of b.listCamera) {
        if (c.checked == undefined || c.checked == false) return false;
      }
    }
    return true;
  }
  cameraCount: number = 0;
  totalCamera: number = 0;
  strListCamera: string = "";
  savePermission() {
    let idRole = "2";
    if (this.isView == true) idRole = "2";
    else idRole = "3";
    let lstCameraChecked = this.cameraAllows
      .filter((c) => c.checked === true)
      .map((ac) => ac.id);
    this.totalCamera = lstCameraChecked.length;
    if (this.isPermiss == true) {
      this._accService
        .UpdateConfig(this.currUsername, idRole, lstCameraChecked)
        .subscribe(
          (res) => {
            Swal.fire(
              this.t("role_role"),
              this.t("role_content_success"),
              "success"
            ).then(() => this._router.navigate(["/account"]));
          },
          (err) => {
            let errorBody = JSON.parse(err._body || "{}");
            if (errorBody.code === 401) {
              this.unAuthHandle(() => this.savePermission());
            } else if (errorBody.code == 402) {
              this.showModalLimitCamera(errorBody.message);
            } else {
              Swal.fire(
                this.t("role_role"),
                this.t("role_error") + err,
                "error"
              );
            }
          }
        );
    } else {
      //Lưu sao chép quyền
      if (this.accSelected.id == null) {
        Swal.fire(
          this.t("role_copy_user_permission"),
          this.t("role_chooseuser"),
          "warning"
        );
      } else {
        let strRole = this.t("role_view");
        if (this.isView == false) strRole = this.t("role_manage");
        strRole += lstCameraChecked.length.toString();
        Swal.fire({
          title: this.t("role_copy_user_permission"),
          html:
            '<div style="text-align: left; font-size: 16px;"><b>' +
            this.t("role_confirm_copy") +
            strRole +
            this.t("role_confirm1") +
            '<span style="color:#e03">' +
            this.accSelected.username +
            "</span>" +
            this.t("role_confirm2") +
            '<span style="color:#e03">' +
            this.currUsername +
            "</span></b>" +
            '<label style="margin-top: 15px; font-weight:bold;"><input type="checkbox" style="cursor: pointer;margin: 5px; transform: scale(1.4);" id="move-checkbox"/>' +
            this.t("role_confirm_del") +
            '<span style="color:#e03">' +
            this.accSelected.username +
            "</span>" +
            this.t("role_confirm_del1") +
            "</label></div>",
          showCancelButton: true,
          confirmButtonColor: "#E03",
          confirmButtonText: "OK",
          cancelButtonText: this.t("li_cancel"),
        }).then((result) => {
          if (result.value) {
            const chkMove = document.getElementById(
              "move-checkbox"
            ) as HTMLInputElement;
            let model = new UsernameCameraIdsModel();
            model.username = this.accSelected.username;
            //chỉ thực hiện remove những camera nào check
            model.listCameraId = lstCameraChecked;
            //Extend list camera của subacc hiện tại + list cam của subacc mới
            let listIdExtend = Array.from(
              new Set(this.currIdCamAlloweds.concat(lstCameraChecked))
            );
            if (chkMove.checked == true) {
              //Thực hiện xóa ở tài khoản cũ, chuyển sang tài khoản mới
              this._copyPerService.removeListByUsername(model).subscribe(
                (res) => {
                  this._accService
                    .UpdateConfig(this.currUsername, idRole, listIdExtend)
                    .subscribe(
                      (res1) => {
                        Swal.fire({
                          title: this.t("role_trans"),
                          icon: "success",
                          html:
                            '<div style="text-align: left; font-size: 16px;"><b>' +
                            this.t("role_trans1") +
                            '<span style="color:#e03">' +
                            this.accSelected.username +
                            "</span>" +
                            this.t("role_trans2") +
                            '<span style="color:#e03">' +
                            this.currUsername +
                            "</span>" +
                            this.t("role_trans3") +
                            "</span></b></div>",
                          confirmButtonText: "OK",
                        });
                        this._router.navigate(["/account"]);
                      },
                      (err1) => {
                        let errorBody = JSON.parse(err1._body || "{}");
                        if (errorBody.code === 401) {
                          this.unAuthHandle(() => this.savePermission());
                        } else if (errorBody.code == 402) {
                          this.showModalLimitCamera(errorBody.message);
                        } else {
                          Swal.fire(
                            this.t("role_trans"),
                            this.t("role_trans_err") + errorBody.message,
                            "error"
                          );
                        }
                      }
                    );
                },
                (err) => {
                  let errorBody = JSON.parse(err._body || "{}");
                  if (errorBody.code === 401) {
                    this.unAuthHandle(() => this.savePermission());
                  } else {
                    console.log("Lỗi xóa khi chuyển quyền: " + err);
                  }
                }
              );
            } else {
              //Sao chép sang tài khoản mới
              this._accService
                .UpdateConfig(this.currUsername, idRole, listIdExtend)
                .subscribe(
                  (res1) => {
                    Swal.fire({
                      title: this.t("role_copy_user_permission"),
                      icon: "success",
                      html:
                        '<div style="text-align: left; font-size: 16px;"><b>' +
                        this.t("role_copy1") +
                        strRole +
                        this.t("role_copy2") +
                        '<span style="color:#e03">' +
                        this.accSelected.username +
                        "</span>" +
                        this.t("role_trans2") +
                        '<span style="color:#e03">' +
                        this.currUsername +
                        "</span>" +
                        this.t("role_trans3") +
                        "</span></b></div>",
                      confirmButtonText: "OK",
                    });
                    this._router.navigate(["/account"]);
                  },
                  (err1) => {
                    let errorBody = JSON.parse(err1._body || "{}");
                    if (errorBody.code === 401) {
                      this.unAuthHandle(() => this.savePermission());
                    } else if (errorBody.code === 402) {
                      this.showModalLimitCamera(errorBody.message);
                    } else {
                      console.log(
                        "Lỗi khi thực hiện sao chép quyền cho người mới: " +
                          err1
                      );
                    }
                  }
                );
            }
          } else if (result.dismiss) {
            return;
          }
        });
      }
    }
  }
  //#endregion
  //#region Sao chép quyền
  getListSubAccount() {
    this._accService.findAll().subscribe(
      (res) => {
        this.lstSubAccount = res.listAccount.filter(
          (c) => c.username !== this.currUsername && c.status !== "PENDING"
        );
      },
      (error) => {
        let errorBody = JSON.parse(error._body || "{}");
        if (errorBody.code === 401) {
          this.unAuthHandle(() => this.getListSubAccount());
        } else {
          console.log(
            "Lỗi lấy danh sách người dùng: getListSubAccount: " + error
          );
        }
      }
    );
  }
  countCameras(str: string): number {
    // Tách chuỗi dựa trên dấu phẩy và loại bỏ khoảng trắng
    const cameras = str
      .split(",")
      .map((item) => item.trim()) // Loại bỏ khoảng trắng ở đầu và cuối mỗi phần tử
      .filter((item) => item.length > 0); // Loại bỏ các phần tử rỗng nếu có

    // Đếm số lượng phần tử
    return cameras.length;
  }
  exportToFile() {
    // Tạo đối tượng Blob với nội dung chuỗi
    const blob = new Blob([this.strListCamera], {
      type: "text/plain;charset=utf-8",
    });

    // Tạo URL cho đối tượng Blob
    const url = URL.createObjectURL(blob);

    // Tạo một thẻ <a> và kích hoạt sự kiện click để tải tệp
    const a = document.createElement("a");
    a.href = url;
    a.download = "list-camera-limit.txt"; // Tên tệp tải về
    document.body.appendChild(a);
    a.click();

    // Giải phóng URL
    URL.revokeObjectURL(url);

    // Xóa thẻ <a> khỏi DOM
    document.body.removeChild(a);
  }
  showModalLimitCamera(_mess: string) {
    if (_mess != "") {
      this.strListCamera =
        this.t("role_list_limit") + _mess.trim().replace(/,\s*$/, "");
      this.cameraCount = this.countCameras(_mess);
    }
    $("#shareCameraLimit")
      .modal({ backdrop: "static", keyboard: false })
      .modal("show");
  }
  t(_key): string {
    return this._appComponent.translate.instant(_key);
  }
  //#endregion
}
