<ul class="pagination" style="margin-top: 20px;">
    <li class="page-item">
      <a class="page-link" (click)="previousPage()" [style] = "stylePreviousPage">
        <i class="icon icon-arrow-left"></i>
      </a>
    </li>
    <app-page *ngFor="let page of pages" [number]="page.number" [active]="page.active" (clickItem)="getClickItem($event)"></app-page>
    <li class="page-item">
      <a class="page-link" (click)="nextPage()" [style] = "styleNextPage">
        <i class="icon icon-arrow-right"></i>
      </a>
    </li>
  </ul>
