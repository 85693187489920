import { Component, OnInit } from '@angular/core';
declare var $: any;

@Component({
  selector: 'app-slider-solution',
  templateUrl: './slider-solution.component.html',
  styleUrls: ['./slider-solution.component.css']
})
export class SliderSolutionComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {

    if ($(".block-solutions").length) {
      $(".block-solutions").slick({
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
          {
            breakpoint: 1025,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
              dots: true,
              arrows: false,
            }
          },
          {
            breakpoint: 769,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              dots: true,
              arrows: false,
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              dots: true,
              arrows: false,
            }
          }
        ]
      });
    }

  }

}
