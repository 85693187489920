import {Component, DoCheck, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-goi-cuoc-bought',
  templateUrl: './goi-cuoc-bought.component.html',
  styleUrls: ['./goi-cuoc-bought.component.css']
})
export class GoiCuocBoughtComponent implements OnInit, DoCheck {
  @Input() bunch: any;
  @Output() sendIdBunch  = new EventEmitter<any>();
  @Output() sendIsClick = new EventEmitter<any>();
  @Output() showBuyCam = new EventEmitter<any>();
  @Output() showBuyCamSwitchPackage = new EventEmitter<any>();
  @Output() cancelPackage = new EventEmitter<any>();

  isClick = false;
  marginLeft: number;

  constructor() { }
  ngDoCheck(): void {
  }
  ngOnInit(): void {
  }


  onClick(){
    this.bunch.isClick = !this.bunch.isClick;
    this.sendIdBunch.emit(this.bunch);
    this.sendIsClick.emit(this.bunch.isClick);
    console.log(this.bunch);
  }

  displayLevel(){
    this.marginLeft = (this.bunch.level * 20) + 15;
    let paddingString = 'margin-left: ' + this.marginLeft + 'px;';
    return paddingString;
  }


  ShowBuyCam($event: any) {
    this.showBuyCam.emit($event);
  }

  ShowBuyCamSwitchPackage($event: any) {
    this.showBuyCamSwitchPackage.emit($event);
  }

  CancelPackage($event: any) {
    this.cancelPackage.emit($event);
    console.log("cancel package");
    console.log($event);
  }

  getItemStyle(item) {
    var style = '';
    if (item.parentId > 0) {
      style += ' background-color: #f1f1f5; ';
    }
    return style;
  }
}


