
import { AuthHepler } from '../../_helpers/auth.helper';
import { AccountModel } from '../../_models/account.model';
import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, Validators, FormBuilder, FormControl } from '@angular/forms';
import { FormValidateService } from '../../_services/form-validate.service';
import { AuthenticationService } from '../../_services/authentication.service';
import { EncryptPasswordService } from '../../_services/encrypt-password.service';
import { Location } from '@angular/common';
import Swal from 'sweetalert2';
import { CookieService } from 'ngx-cookie-service';
import { AppComponent } from 'src/app/app.component';
import { LanguageModel } from 'src/app/_models/language.model';
declare var $: any;

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent extends AuthHepler implements OnInit {
  registerForm: FormGroup;
  @Input() registerAccount: AccountModel;
  @Input() password: string;
  isRegister: boolean = false;
  isConfirmCaptcha: boolean = false;
  langModel = new LanguageModel();

  constructor(
    private _location: Location,
    private _fb: FormBuilder,
    private _router: Router,
    public _registerService: FormValidateService,
    private _encryptService: EncryptPasswordService,
    private _appComponent: AppComponent,
    private _authentication: AuthenticationService,public cookie: CookieService) {
    super(_authentication, _authentication, cookie)
    this.registerAccount = new AccountModel();
  }
  createForm() {
    this.registerForm = this._fb.group({
      username: ['', this._registerService.RegisterValidate.usernameValidate.validator],
      fullname: ['', this._registerService.RegisterValidate.fullNameValidate.validator],
      email: ['', this._registerService.RegisterValidate.emailValidate.validator],
      phone: [''],
      address: [''],
      matchingPassword: this._fb.group({
        password: ['', this._registerService.RegisterValidate.passwordValidate.validator],
        confirmpass: ['']
      },
        { validator: this._registerService.RegisterValidate.matchingPassword.validator })
    });
  }
  ngOnInit() {
    this.createForm();
    if ($('.form-group-password').length) {
      $('.icon-eye').on('click', (function () {
        $(this).toggleClass('closed');
        var input = $('#' + $(this).attr('data-for'));
        if ($(this).hasClass('closed')) {
          input.attr('type', 'text');
        } else {
          input.attr('type', 'password');
        }
      }));
    }
    //chuyển ngữ
    this._appComponent.translate.get('li_register_succ').subscribe((res: string) => {
      this.langModel.title = res;
    });
    this._appComponent.translate.get('li_register_acc_succ').subscribe((res: string) => {
      this.langModel.content = res;
    });
    this._appComponent.translate.get('li_register_failed').subscribe((res: string) => {
      this.langModel.title1 = res;
    });
    this._appComponent.translate.get('li_check_acc').subscribe((res: string) => {
      this.langModel.content1 = res;
    });
    this._appComponent.translate.get('li_lock_account').subscribe((res: string) => {
      this.langModel.content2 = res;
    });
    this._appComponent.translate.get('li_check_acc2').subscribe((res: string) => {
      this.langModel.content3 = res;
    });
  }
  register() {
    this.isRegister = false;
    this.registerAccount.password = this._encryptService.encryptSHA512(this.registerAccount.username, this.password);
    this._authentication.register(this.registerAccount)
      .subscribe(res => {
        this.isRegister = true;
        Swal.fire(
          this.langModel.title,
          this.langModel.content,
          'success'
        );
        this._router.navigate(['/login']);
      },
        error => {
          this.isRegister = false;
          if (error === 'Unauthorized') {
            Swal.fire(
              this.langModel.title1,
              this.langModel.content1,
              'error'
            );
          } else if (error === 'Conflict') {
            Swal.fire(
              this.langModel.title1,
              this.langModel.content3,
              'error'
            );
          } else if (error === 'Inactive') {
            Swal.fire(
              this.langModel.title1,
             this.langModel.content2,
              'error'
            );
          }

        });
  }
  backClicked() {
    this._location.back();
  }
  resolved(captchaResponse: string) {
    if (captchaResponse.length == 0) {
      this.isConfirmCaptcha = false;
    }
    else {
      this.isConfirmCaptcha = true;
    }
  }
}
