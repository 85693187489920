import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from "@angular/core";

declare var $: any;

@Component({
  selector: "multiple-select",
  templateUrl: "./multiple-select.component.html",
  styleUrls: ["./multiple-select.component.css"],
})
export class MultipleSelectComponent implements AfterViewInit {
  @ViewChild('selectElement') selectElement: ElementRef;

  @Input() selectedValues: any[];
  @Output() selectedChange = new EventEmitter<any[]>();

  constructor(private cdr: ChangeDetectorRef) { }

  ngAfterViewInit() {
    const selectElement = $(this.selectElement.nativeElement);

    if (selectElement.length) {
      selectElement.select2();
      selectElement.on("change", () => {
        const selectedValues = selectElement.val();
        this.selectedChange.emit(selectedValues);
        this.cdr.detectChanges();
      });

      selectElement.val(this.selectedValues).trigger('change');
    }
  }
}
