<div class="detail-body" *ngIf="bunch.isActive">
  <div class="detail-item-wrapper">
    <div class="detail-item">
      <div class="row" [style]="getItemStyle(bunch.bunch)">
        <div class="col-10 col-sm-8">
          <p class="item-text row" data-toggle="collapse" [attr.data-target]="'#gr' + bunch.bunch.id"
             aria-expanded="false" aria-controls="collapse-1" [style]="displayLevel()" (click)="onClick()">
                   <span class="col-7 col-sm-6">
                <i class="icon icon-toggle"></i> <span style="white-space: nowrap;overflow: hidden;margin-right: 10px;
                text-overflow: ellipsis;">{{bunch.bunch.name}}</span>
              </span>
            <span class="col-5 col-sm-4 text-red">({{bunch.listCamera?.length}} cam)</span>
          </p>
        </div>
        <div class="col-2 col-sm-2">

        </div>
        <div class="col-12 col-sm-2">
          <button class="btn btn-primary buy-package" (click)="ShowBuyBunch(bunch)">{{'ad_buy_bunch'|translate}}</button>
        </div>
      </div>
    </div>
    <div id="gr{{bunch.bunch.id}}" class="collapse">
      <div *ngFor="let iCam of bunch.listCamera;">
        <div class="detail-sub-item">
          <div class="row">
            <div class="col-7 col-sm-6">
              <p class="item-text" style="white-space: nowrap;overflow: hidden;
                text-overflow: ellipsis;">
                {{iCam.name}}
              </p>
            </div>
            <div class="col-5 col-sm-4">

            </div>
            <div class="col-12 col-sm-2">
              <button class="btn btn-outline-primary1 buy-cam" (click)="ShowBuyCam(iCam)">{{'ad_buy_cam'|translate}}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
