<div class="block-solutions">
  <div class="block-solutions__solution" style="cursor:pointer;" routerLink="/landing-new">
    <div class="block-solutions__solution-inner">
      <a>
        <img src="assets/landing/solution3.jpg" alt="" />
      </a>
      <div class="card-content">
        <h3 translate>{{'ldp_manage_school'}}</h3>
        <span translate>{{'ldp_content_school'}}</span>
      </div>
    </div>
  </div>
  <div class="block-solutions__solution" style="cursor:pointer;" routerLink="/solution-store">
    <div class="block-solutions__solution-inner">
      <a>
        <img src="assets/landing/solution1.png" alt="" />
      </a>
      <div class="card-content">
        <h3 translate>{{'ldp_manage_store'}}</h3>
        <span translate>{{'ldp_sln_optimal'}}</span>
      </div>
    </div>
  </div>
  <div class="block-solutions__solution" style="cursor:pointer;" routerLink="/solution-general">
    <div class="block-solutions__solution-inner">
      <a>
        <img src="assets/landing/solution2.png" alt="" />
      </a>
      <div class="card-content">
        <h3 translate>{{'ldp_app_of_vCC'}}</h3>
        <span translate>{{'ldp_content_plug_play'}}</span>
      </div>
    </div>
  </div>


  <div class="block-solutions__solution" style="cursor:pointer;" routerLink="/landing-new">
    <div class="block-solutions__solution-inner">
      <a>
        <img src="assets/landing/solution3.jpg" alt="" />
      </a>

      <div class="card-content">
        <h3 translate>{{'ldp_manage_school'}}</h3>
        <span translate>{{'ldp_content_school'}}</span>
      </div>
    </div>
  </div>
  <div class="block-solutions__solution" style="cursor:pointer;" routerLink="/solution-store">
    <div class="block-solutions__solution-inner">
      <a>
        <img src="assets/landing/solution1.png" alt="" />
      </a>

      <div class="card-content">
        <h3 translate>{{'ldp_manage_store'}}</h3>
        <span translate>{{'ldp_sln_optimal'}}</span>
      </div>
    </div>
  </div>
  <div class="block-solutions__solution" style="cursor:pointer;" routerLink="/solution-general">
    <div class="block-solutions__solution-inner">
      <a>
        <img src="assets/landing/solution2.png" alt="" />
      </a>

      <div class="card-content">
        <h3 translate>{{'ldp_app_of_vCC'}}</h3>
        <span translate>{{'ldp_content_plug_play'}}</span>
      </div>
    </div>
  </div>
</div>