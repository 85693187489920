import { Injectable } from '@angular/core';
import { Http, Response, URLSearchParams } from '@angular/http';
import { Router } from '@angular/router';
import { AccountModel } from '../_models/account.model';
import { ApiUrlService } from '../_services/api-url.service';
import { BaseService } from '../_services/base.service';
import { AuthenticationService } from '../_services/authentication.service';
import { CookieService } from 'ngx-cookie-service';
import { EncryptPasswordService } from './encrypt-password.service';
@Injectable({
  providedIn: 'root'
})
export class AccountService extends BaseService {

  constructor(public _apiUrl: ApiUrlService, private _encryptPasswordService: EncryptPasswordService, public _http: Http,public cookie: CookieService) {
    super(_http, _apiUrl, cookie);
  }
  findAll() {
    return this._http.get(this._apiUrl.ACCOUNT_FIND_ALL, this.authorizationOption())
      .map(this.extractData)
      .catch(this.handleError);
  }
  createNew(_account: AccountModel, _password: string) {
    _account.password = this._encryptPasswordService.encryptSHA512(_account.username, _password);
    return this._http.post(this._apiUrl.ACCOUNT_REGISTER_SUB, _account, this.authorizationOption())
      .map(this.extractData)
      .catch(this.handleError);
  }
  update(_account: AccountModel) {
    return this._http.post(this._apiUrl.ACCOUNT_UPDATE_SUB, {
      username: _account.username, fullName: _account.fullName, email: _account.email,
      phoneNumber: _account.phoneNumber, personalId: _account.personalId, address: _account.address, status: _account.status
    }, this.authorizationOption())
      .map(this.extractData)
      .catch(this.handleError);
  }
  updateAccount(_account: AccountModel) {
    return this._http.post(this._apiUrl.Account_update, {
      fullName: _account.fullName, email: _account.email,
      phoneNumber: _account.phoneNumber, personalId: _account.personalId, address: _account.address
    }, this.authorizationOption())
      .map(this.extractData)
      .catch(this.handleError);
  }
  changePassword(oldPassword: string, newPassword: string) {
    return this._http.post(this._apiUrl.ACCOUNT_CHANGE_PASSWORD, { oldPassword: oldPassword, newPassword: newPassword })
      .map(this.extractData)
      .catch(this.handleError);
  }

  remove(username: string) {
    const params: URLSearchParams = new URLSearchParams();
    params.set('username', `${username}`);
    return this._http.get(this._apiUrl.ACCOUNT_REMOVE, this.authorizationOption().merge({ search: params }))
      .map(this.extractData)
      .catch(this.handleError);
  }
  getDetail() {
    return this._http.get(this._apiUrl.ACCOUNT_DETAIL, this.authorizationOption())
      .map(this.extractData)
      .catch(this.handleError);
  }
  listCameraAssign(username: string) {
    const params: URLSearchParams = new URLSearchParams();
    params.set('username', `${username}`);
    return this._http.get(this._apiUrl.Account_list_camera_assigned, this.authorizationOption().merge({ search: params }))
      .map(this.extractData)
      .catch(this.handleError);
  }
  listCameraAllowAsign(){
    return this._http.get(this._apiUrl.Account_list_camera_allow_assign, this.authorizationOption())
    .map(this.extractData)
    .catch(this.handleError);
  }
  //Dùng api này thay Api lấy ds cam cho phép ghán thi thoảng có lỗi
  findAllCam() {
    return this._http.get(this._apiUrl.CAMERA_FIND_ALL, this.authorizationOption())
      .map(this.extractData)
      .catch(this.handleError);
  }
  UpdateConfig(_username:string, _roleId: string, _lstCamId: any[]){
    return this._http.post(this._apiUrl.Account_management_assign,{username:_username,roleId:_roleId,listCameraId:_lstCamId},this.authorizationOption())
    .map(this.extractData);
  }
  TransHistory(){
    const params: URLSearchParams = new URLSearchParams();
    params.set('first-result', '0');
    params.set('max-result', '0');
    return this._http.get(this._apiUrl.Account_transaction_history, this.authorizationOption().merge({ search: params }))
      .map(this.extractData)
      .catch(this.handleError);
  }
  ActiveAccount(_token:string){
    const params: URLSearchParams = new URLSearchParams();
    params.set('token', _token);
    return this._http.get(this._apiUrl.Account_active, this.authorizationOption().merge({ search: params }))
      .map(this.extractData)
      .catch(this.handleError);
  }
  public getListUserCamera(_camId: number){
    let reqParams = {};
    const params: URLSearchParams = new URLSearchParams();
    params.set('bunch-id', `${_camId}`);
    return this._http.get(this._apiUrl.getListUserCamera, this.authorizationOption().merge({ search: params }))
      .map(this.extractData)
      .catch(this.handleError);
  }
}
