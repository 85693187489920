import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from '@angular/router';
@Component({
  selector: "app-aicamera",
  templateUrl: "./aicamera.component.html",
  styleUrls: ["./aicamera.component.css"],
})
export class AicameraComponent implements OnInit {

  constructor(private router: Router, private route: ActivatedRoute) { }

  ngOnInit(): void {
    if (this.router.url === '/aicamera') {
      this.router.navigate(['register-scenarios'], { relativeTo: this.route });
    }
  }
}
