import { PlaybackGuard } from './_guards/playback.guard';
import { SolutionStoreComponent } from './home/solution-store/solution-store.component';
import { SolutionGeneralComponent } from './home/solution-general/solution-general.component';

import { MapComponent } from './manage/map/map.component';
import { GoicuocComponent } from './package/goicuoc/goicuoc.component';
import { PaymentComponent } from './package/payment/payment.component';
import { CameraComponent } from './manage/camera/camera.component';
import { BunchComponent } from './manage/bunch/bunch.component';
import { ForgotPasswordComponent } from './account/forgot-password/forgot-password.component';
import { LandingpageNewComponent } from './home/landingpage-new/landingpage-new.component';
import { LandingContactComponent } from './home/landing-contact/landing-contact.component';
import { InfoAccountComponent } from './account/info-account/info-account.component';
import { NgModule, Component } from '@angular/core';
import { Routes, RouterModule, ExtraOptions } from '@angular/router';
import { RegisterComponent } from './account/register/register.component';
import { LoginComponent } from './login/login.component';
import { HomeComponent } from './home/home.component';
import { ActiveAccountComponent } from './account/active-account/active-account.component';
import {ResetPasswordComponent} from './account/reset-password/reset-password.component';
import { LiveviewComponent } from './liveview/liveview.component';
import { PlaybackComponent } from './playback/playback.component';
import { AuthGuard, LiveviewGuard } from './_guards/index';
import { from } from 'rxjs';
import { AccountComponent } from './manage/account/account.component';
import { ConfirmTwofactorComponent } from './login/two-factor/confirm-twofactor/confirm-twofactor.component';
import { DashboardComponent } from './manage/dashboard/dashboard.component';
import { StatisticCameraComponent } from './manage/dashboard/statistic-camera/statistic-camera.component';
import { CameraOffComponent } from './manage/camera/camera-off/camera-off.component';
import { AicameraComponent } from './manage/aicamera/aicamera.component';
import { SharePermissionComponent } from './manage/account/share-permission/share-permission.component';


const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  {path:'home', component:HomeComponent},
  {path:'landing-contact', component:LandingContactComponent},
  {path:'landing-new', component:LandingpageNewComponent},
  {path:"solution-general", component:SolutionGeneralComponent},
  {path:"solution-store", component:SolutionStoreComponent},
  {path:"forgot-password", component:ForgotPasswordComponent},
  { path: 'login', component: LoginComponent },
  { path: 'register', component: RegisterComponent },
  { path:'active-account', component:ActiveAccountComponent},
  {path:'resetpassword',component: ResetPasswordComponent},
  {path:'twofactor', component: ConfirmTwofactorComponent},
  { path: 'liveview', component: LiveviewComponent, canActivate: [AuthGuard], canDeactivate: [LiveviewGuard]},
  { path: 'playback', component: PlaybackComponent, canActivate: [AuthGuard], canDeactivate: [PlaybackGuard] },
  { path: 'infoaccount', component: InfoAccountComponent, canActivate: [AuthGuard] },
  { path: 'bunch', component: BunchComponent, canActivate: [AuthGuard] },
  {path:'account', component: AccountComponent,canActivate:[AuthGuard]},
  {path:'share-permission', component: SharePermissionComponent,canActivate:[AuthGuard]},


  {path:'camera', component: CameraComponent,canActivate:[AuthGuard]},
  {path:'payment', component: PaymentComponent,canActivate:[AuthGuard]},
  {path:'package', component: GoicuocComponent,canActivate:[AuthGuard]},
  {path:'map', component: MapComponent,canActivate:[AuthGuard]},
  {path:'dashboard', component:DashboardComponent, canActivate:[AuthGuard]},
  {path:'aicamera', component: AicameraComponent, canActivate:[AuthGuard]},
  {path:'statis-camdisconnect', component:StatisticCameraComponent, canActivate:[AuthGuard]},
  {path:'camera-off', component:CameraOffComponent, canActivate:[AuthGuard]},
];
const routerOptions: ExtraOptions = {
  useHash: false,
  anchorScrolling: 'enabled',
  onSameUrlNavigation: 'reload'
  // ...any other options you'd like to use
};

@NgModule({
  // imports: [RouterModule.forRoot(routes, routerOptions)],
  imports: [RouterModule.forRoot(routes,
    {
      anchorScrolling: 'enabled',
      onSameUrlNavigation: 'reload',
      scrollPositionRestoration: 'enabled'
    })],
  declarations: [
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
