import { Component, DoCheck, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CameraModel } from 'src/app/_models';
import { SendData } from 'src/app/_models/sen_data';
import { CameraService } from 'src/app/_services';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-bunch-tables',
  templateUrl: './bunch-tables.component.html',
  styleUrls: ['../camera.component.css']
})
export class BunchTablesComponent  implements OnInit,DoCheck {

  @Input() bunch:any;
  @Input() disableClickBunch:boolean = false;
  @Output() editModal= new EventEmitter<any>();
  @Output() showModal= new EventEmitter<any>();
  @Output() removeModal= new EventEmitter<any>();
  @Output() sendIdBunch = new EventEmitter<any>();
  @Output() sendIsClick = new EventEmitter<any>();
  send = new SendData();
  isClick = false;
  style = "transform: rotate(-90deg);";
  constructor(private _camService: CameraService) {
     }
ngDoCheck(): void {
  if(this.bunch.isClick){
    this.style = "transform: rotate(0deg);"
  }else{
    this.style = "transform: rotate(-90deg);"
  }
}
  ngOnInit(): void {
  }
  displayCountCam($event:any){
    var count = 0;
    count += $event.listCamera.length;
    if($event.listChild.length > 0){
      $event.listChild.forEach(element => {
        count += this.displayCountCam(element);
      });
    }
    return count;
  }
  displayCountChild($event:any){
    var count = 0;
    count += $event.listChild.length;
    if($event.listChild.length > 0){
      $event.listChild.forEach(element => {
        count += this.displayCountCam(element);
      });
    }
    return count;
  }



  onClick(){
    if (!this.disableClickBunch) {
      this.bunch.isClick = !this.bunch.isClick;
      this.sendIdBunch.emit(this.bunch);
      this.sendIsClick.emit(this.bunch.isClick);
    }
      console.log(this.bunch);
  }
  EditModal($event:any){
    this.editModal.emit($event);
  }
  ShowModal(item:any){
    this.send.option = 'streamingProtocol';
    this.send.cam = item;
    this.showModal.emit(this.send);
  }
  RemoveCamera(item:any){
    this.removeModal.emit(item);
  }
  displayLevel(){
    var paddingString = "margin-left: " + this.bunch.level * 50 + "px; overflow:hidden; " +
      '        white-space:nowrap; ' +
      '        text-overflow: ellipsis;';
    return paddingString;
  }

  displayLevelHeader(){
    var paddingString = "margin-right: 0px; margin-left: 0px";
    return paddingString;
  }
  



  ReloadCamera(camera:any){
    this._camService.detailCamera(camera.id).subscribe(
      res => {
        camera.resolution = res.camera.resolution;
      },
      err => {
        console.log("Lỗi reload lấy thông tin độ phân giải cam");
      }
    );
  }



}
