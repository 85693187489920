import { MultivideojsComponent } from "./../_shared/multivideojs/multivideojs/multivideojs.component";
import { Component, OnInit, ViewChildren, QueryList } from "@angular/core";
import { CameraModel, RecordModel, SelectDate } from "../_models/index";
import { CameraService } from "../_services/camera.service";
import { RecordService } from "../_services/record.service";
import { BaseService } from "../_services/base.service";
import { AuthenticationService } from "../_services/authentication.service";
import { AuthHepler } from "../_helpers/auth.helper";
import { VideojsComponent } from "../_shared/videojs/videojs.component";
import { Router } from "@angular/router";
import { CookieService } from "ngx-cookie-service";
import * as _ from "lodash";
import { HttpClient, HttpEventType } from "@angular/common/http";

declare var moment: any;
declare var $: any;
import Swal from "sweetalert2";
import { LanguageModel } from "../_models/language.model";
import { AppComponent } from "../app.component";
@Component({
  selector: "app-playback",
  templateUrl: "./playback.component.html",
  styleUrls: ["./playback.component.css"],
})
export class PlaybackComponent extends AuthHepler implements OnInit {
  lstTypeView = ["One View", "Multi View"];
  selectedTypeView = this.lstTypeView[1];
  @ViewChildren("multivideojs") multilVideojs: QueryList<MultivideojsComponent>;
  @ViewChildren("videojs") videojs: QueryList<VideojsComponent>;
  cameraSelected: CameraModel;
  listRecord: RecordModel[] = [];
  langModel = new LanguageModel();
  startDate: SelectDate = new SelectDate();
  endDate: SelectDate = new SelectDate();
  startTime: Date = new Date(moment(new Date()).startOf("day"));
  endTime: Date = new Date();
  loading: boolean = false;
  isPause: boolean = false;
  strDate: string = "01/01/2020";
  hours = [
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12",
  ];
  minutes = [
    "00",
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "19",
    "20",
    "21",
    "22",
    "23",
    "24",
    "25",
    "26",
    "27",
    "28",
    "29",
    "30",
    "31",
    "32",
    "33",
    "34",
    "35",
    "36",
    "37",
    "38",
    "39",
    "40",
    "41",
    "42",
    "43",
    "44",
    "45",
    "46",
    "47",
    "48",
    "49",
    "50",
    "51",
    "52",
    "53",
    "54",
    "55",
    "56",
    "57",
    "58",
    "59",
  ];
  constructor(
    private _cameraService: CameraService,
    private _recordService: RecordService,
    public baseService: BaseService,
    public authenticationSevice: AuthenticationService,
    public cookie: CookieService,
    private _appComponent: AppComponent,
    private _router: Router,
    private http: HttpClient
  ) {
    super(baseService, authenticationSevice, cookie);
  }

  ngOnInit(): void {
    if ($(".select-time").length) {
      $(".select-time").select2({
        minimumResultsForSearch: -1,
      });
    }
    if ($("#date-range-picker").length) {
      $("#date-range-picker")
        .dateRangePicker({
          inline: true,
          container: "#date-range-picker-container",
          alwaysOpen: true,
        })
        .bind("datepicker-first-date-selected", function (event, obj) {
          if (obj.date1) {
            $("#select-date-first").val(moment(obj.date1).format("DD/MM/YYYY"));
          } else {
            $("#select-date-first").val(moment());
          }
        })
        .bind("datepicker-change", function (event, obj) {
          if (obj.date2) {
            $("#select-date-second").val(
              moment(obj.date2).format("DD/MM/YYYY")
            );
          } else {
            $("#select-date-second").val(
              moment(new Date()).startOf("day").format("DD/MM/YYYY")
            );
          }
        });
    }

    //chuyển ngữ
    this._appComponent.translate.get("li_notiffi").subscribe((res: string) => {
      this.langModel.title = res;
    });
    this._appComponent.translate
      .get("ad_compare_time")
      .subscribe((res: string) => {
        this.langModel.content = res;
      });
    this._appComponent.translate
      .get("ad_choose_Cam")
      .subscribe((res: string) => {
        this.langModel.title1 = res;
      });
  }
  recordTrackById(index, record: RecordModel) {
    return record.id;
  }
  findRecord(camera: CameraModel) {
    //reset data
    this.progressVisible = [];
    this.totalTime = 0;
    this.time = 0;
    this.indexVideo = 0;
    //giải phóng và set trạng thái cho camera
    this.deactiveRouter();
    this.selectDate();
    const from = this.startTime.getTime();
    const end = this.endTime.getTime();
    if (end < from) {
      Swal.fire(this.langModel.title, this.langModel.content, "warning");
    } else {
      this.loading = true;
      this.cameraSelected = camera;
      $("#selectDate").modal("hide");
      this._cameraService.getListRecord(camera.id, from, end).subscribe(
        (res) => {
          let lstRecord = res.listRecord;
          this.listRecord = _.sortBy(lstRecord, "endTime");
          if (this.listRecord.length > 0) {
            this.totalTime =
              this.listRecord[this.listRecord.length - 1].endTime -
              this.listRecord[0].startTime;
          }
          this.loading = false;
          if (res.listRecord.length > 0) camera.state = 1;
          this.listRecord.forEach(() => {
            this.progressVisible.push(false);
            this.progressArr.push(0);

          });
        },
        (err) => {
          this.loading = false;
          if (err === "Unauthorized") {
            this.unAuthHandle(() => this.findRecord(camera));
          } else {
            console.log("findRecord " + err);
          }
        }
      );
    }
  }

  time: number = 0;
  totalTime: number = 0;
  indexVideo: number = 0;
  valueChanged(e, multiVideojs: MultivideojsComponent) {
    this.time = Number(e.target.value);
    let totalTimePlayed1 = 0;
    //xác định index video
    for (let i = 0; i < this.listRecord.length; i++) {
      totalTimePlayed1 += this.listRecord[i].totalTime;
      if (totalTimePlayed1 > this.time) {
        //dừng cộng time
        this.indexVideo = i;
        i = this.listRecord.length;
      }
    }
    //set vị trí video
    multiVideojs.player.playlist.currentItem(this.indexVideo);
    //xác định time của video đang chạy
    let currTime = this.time;
    let totalTimePlayed = 0;
    if (this.indexVideo > 0) {
      totalTimePlayed =
        totalTimePlayed1 - this.listRecord[this.indexVideo].totalTime;
      currTime = this.time - totalTimePlayed;
    }
    multiVideojs.player.currentTime(currTime / 1000);
    multiVideojs.player.on("play", () => {
      multiVideojs.player.currentTime(currTime / 1000);
    });
  }
  playAllRecord(multiVideojs: MultivideojsComponent) {
    if (this.cameraSelected != undefined) {
      this.getListURLRecord(multiVideojs);
    } else {
      Swal.fire(this.langModel.title, this.langModel.title1, "warning");
    }
  }
  play(multiVideojs: MultivideojsComponent) {
    multiVideojs.play();
    this.isPause = multiVideojs.player.paused();
  }
  pause(multiVideojs: MultivideojsComponent) {
    multiVideojs.pause();
    this.isPause = multiVideojs.player.paused();
  }
  currIndex: number = 0;
  getListURLRecord(multiVideojs: MultivideojsComponent) {
    let count = 0;
    for (let i = 0; i < this.listRecord.length; i++) {
      this._recordService.getStream(multiVideojs.lstRecord[i].id).subscribe(
        (res) => {
          if (res.url != "") {
            multiVideojs.lstRecord[i].url = res.url;
            multiVideojs.lstRecord[i].totalTime =
              multiVideojs.lstRecord[i].endTime -
              multiVideojs.lstRecord[i].startTime;
            multiVideojs.lstRecord[i].index = i;
            multiVideojs.lstRecord[i].position =
              multiVideojs.lstRecord[i].totalTime / this.totalTime;
            count++;
            if (count === this.listRecord.length - 1) {
              multiVideojs.initPlayer();
              //Set vị trí slider theo time => sự kiện update time của video
              multiVideojs.player.on("timeupdate", () => {
                let totalTimed = 0;
                let index = multiVideojs.player.playlist.currentIndex();
                for (let j = 1; j <= index; j++) {
                  totalTimed += multiVideojs.lstRecord[j].totalTime;
                }
                this.time =
                  totalTimed + multiVideojs.player.currentTime() * 1000;
              });
            }
          } else {
            console.log(res);
          }
        },
        (err) => {
          if (err === "Unauthorized") {
            this.unAuthHandle(() => this.playAllRecord(multiVideojs));
          } else {
          }
        }
      );
    }
  }
  playRecord(videojs: VideojsComponent) {
    this._recordService.getStream(videojs.record.id).subscribe(
      (res) => {
        console.log(res);
        videojs.record.url = res.url;
        videojs.initPlayer();
      },
      (err) => {
        if (err === "Unauthorized") {
          this.unAuthHandle(() => this.playRecord(videojs));
        } else {
          console.log("playRecord " + err);
        }
      }
    );
  }
  progressArr: number[] = []; // Tiến trình
  progressVisible: boolean[] = []; // Hiển thị ProgressBar

  downloadRecord(id: number, index: number) {
    this._recordService.getUrlDownload(id).subscribe((res) => {
      this.progressArr[index]=0
      this.progressVisible[index] = true; // Hiển thị ProgressBar
      let url = res.url;
      this.http
        .get(url, {
          responseType: "blob",
          observe: "events",
          reportProgress: true,
        })
        .subscribe(
          (event) => {
            if (event.type === HttpEventType.DownloadProgress && event.total) {
              // Tính toán phần trăm tiến trình
              this.progressArr[index]= Math.round((100 * event.loaded) / event.total);
            } else if (event.type === HttpEventType.Response) {
              // Khi file tải xong, tạo blob và tải file
              const blob = new Blob([event.body!], { type: event.body?.type });
              const url = window.URL.createObjectURL(blob);
              const a = document.createElement("a");
              a.href = url;
              a.download = `record_${this.getCurrentTimestamp()}.mp4`; // Tên file khi tải xuống
              document.body.appendChild(a);
              a.click();
              document.body.removeChild(a);
              window.URL.revokeObjectURL(url);
              this.progressVisible[index] = false; // Ẩn ProgressBar khi tải xong
            }
          },
          (error) => {
            if(error.status ===0 && error.statusText=== "Unknown Error"){
              this.progressArr[index] =0;
              this.progressVisible[index] = true; // Hiển thị ProgressBar
              url = url.replace("https://", "http://");
              this.http.get(url, {
                responseType: "blob",
                observe: "events",
                reportProgress: true,
              })
              .subscribe(
                (event) => {
                  if (event.type === HttpEventType.DownloadProgress && event.total) {
                    // Tính toán phần trăm tiến trình
                    this.progressArr[index] = Math.round((100 * event.loaded) / event.total);
                  } else if (event.type === HttpEventType.Response) {
                    // Khi file tải xong, tạo blob và tải file
                    const blob = new Blob([event.body!], { type: event.body?.type });
                    const url = window.URL.createObjectURL(blob);
                    const a = document.createElement("a");
                    a.href = url;
                    a.download = `record_${this.getCurrentTimestamp()}.mp4`; // Tên file khi tải xuống
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);
                    window.URL.revokeObjectURL(url);
                    this.progressVisible[index] = false; // Ẩn ProgressBar khi tải xong
                  }
                },
                (error1) => {
                  console.log("Error downloading the file error1: ", error1);
                  this.progressVisible[index] = false; // Ẩn ProgressBar khi có lỗi
                })
            }
            else if (error.status === 503) {
              console.log("Service unavailable (503), please try again later.");
              this.progressVisible[index] = false; // Ẩn ProgressBar khi có lỗi
            } else {
            console.log("Error downloading the file error: ", error);
            this.progressVisible[index] = false; // Ẩn ProgressBar khi có lỗi
            }
          }
        );
    });
  }

  getCurrentTimestamp = (): string => {
    const now = new Date();
    
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0'); // Tháng bắt đầu từ 0
    const day = String(now.getDate()).padStart(2, '0');
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const seconds = String(now.getSeconds()).padStart(2, '0');
  
    return `${year}-${month}-${day}_${hours}-${minutes}-${seconds}`;
  };

  convertAMPM(_date: SelectDate) {
    if (_date.ampm == "PM") {
      if (_date.hours < 12) _date.hours = Number(_date.hours) + 12;
      if (_date.hours == 12) _date.hours = Number(_date.hours) - 12;
    }
  }
  selectDate() {
    this.startDate.fulldate = $("#select-date-first").val();
    this.startDate.hours = $("#select-hours-first").val();
    this.startDate.minutes = $("#select-minutes-first").val();
    this.startDate.ampm = $("#select-ampm-first").val();
    this.convertAMPM(this.startDate);
    let fStartTime =
      this.startDate.fulldate +
      " " +
      this.startDate.hours +
      ":" +
      this.startDate.minutes +
      ":00";
    if (this.startDate.fulldate != "")
      this.startTime = new Date(moment(fStartTime, "DD-MM-YYYY HH:mm:ss"));
    this.endDate.fulldate = $("#select-date-second").val();
    this.endDate.hours = $("#select-hours-second").val();
    this.endDate.minutes = $("#select-minutes-second").val();
    this.endDate.ampm = $("#select-ampm-second").val();
    this.convertAMPM(this.endDate);
    let fEndTime =
      this.endDate.fulldate +
      " " +
      this.endDate.hours +
      ":" +
      this.endDate.minutes +
      ":00";
    if (this.endDate.fulldate != "")
      this.endTime = new Date(moment(fEndTime, "DD-MM-YYYY HH:mm:ss"));
  }
  Snapshot(videojs: VideojsComponent) {
    videojs.takeSnapshot();
  }
  setZoomIn(videojs: VideojsComponent) {
    videojs.zoomInVideo();
  }
  setZoomOut(videojs: VideojsComponent) {
    videojs.zoomOutVideo();
  }

  forward(videojs: VideojsComponent) {
    videojs.forward();
  }
  backward(videojs: VideojsComponent) {
    videojs.backward();
  }
  ChangeTypeView() {
    this.videojs.forEach((video) => {
      video.camera.state = 0;
      if (video.isInit == true) {
        video.close();
      }
    });
    this.multilVideojs.forEach((video) => {
      video.camera.state = 0;
      if (video.isInit == true) {
        video.close();
      }
    });
  }
  deactiveRouter() {
    if (this.listRecord.length > 0) {
      this.videojs.forEach((video) => {
        video.camera.state = 0;
        if (video.isInit == true) {
          video.close();
        }
      });
      this.multilVideojs.forEach((video) => {
        video.camera.state = 0;
        if (video.isInit == true) {
          video.close();
        }
      });
      this.listRecord = [] as RecordModel[];
    }
  }
}
interface TypeViewModel {
  id: number;
  value: string;
}
