import { Injectable } from '@angular/core';
import { Http, URLSearchParams } from '@angular/http';
import { ApiUrlService } from './api-url.service';
import { BaseService } from './base.service';
import{CamDisconnectByMinModel} from './../_models/dashboard.model';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class DashboardService extends BaseService {
  constructor(
    _http: Http,
    _apiUrlService: ApiUrlService,public cookie: CookieService) {
    super(_http, _apiUrlService, cookie);
    }
    getChartDisconnect(_timeStart: number, _timeEnd: number) {
      const params: URLSearchParams = new URLSearchParams();
      params.set('timeStart', `${_timeStart}`);
      params.set('timeEnd', `${_timeEnd}`);
      return this._http.get(this._apiUrlService.chart_disconnect, this.authorizationOption().merge({ search: params }))
        .map(this.extractData)
        .catch(this.handleError);
    }
    getInfoChart(){
      return this._http.get(this._apiUrlService.chart_infor, this.authorizationOption()).map(this.extractData).catch(this.handleError)
    }
    getTopDisconnect(_timeStart: number, _timeEnd: number){
      const params: URLSearchParams = new URLSearchParams();
      params.set('timeStart', `${_timeStart}`);
      params.set('timeEnd', `${_timeEnd}`);
      return this._http.get(this._apiUrlService.top10_disconnect, this.authorizationOption().merge({ search: params }))
        .map(this.extractData)
        .catch(this.handleError);
    }
    getDetailCameraDisconnect(){
      return this._http.get(this._apiUrlService.detail_camera_disconnect,this.authorizationOption()).map(this.extractData).catch(this.handleError);
    }
}
