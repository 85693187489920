import { Injectable } from '@angular/core';
import { Http, URLSearchParams } from '@angular/http';
import { ApiUrlService } from './api-url.service';
import { BaseService } from './base.service';
import { AuthenticationService } from './authentication.service';
import { CookieService } from 'ngx-cookie-service';

@Injectable()
export class UserService extends BaseService {

  constructor(
    _http: Http,
    _apiUrlService: ApiUrlService,public cookie: CookieService
  ) { super(_http, _apiUrlService, cookie); }

  changePassword(oldPassword: string, newPassword: string) {
    return this._http.post(this._apiUrlService.ACCOUNT_CHANGE_PASSWORD, { oldPassword: oldPassword, newPassword: newPassword }, this.authorizationOption())
      .map(this.extractData)
      .catch(this.handleError);
  }

  forgotPassword(email: string) {
    const params: URLSearchParams = new URLSearchParams();
    params.set('email', email);
    return this._http.get(this._apiUrlService.ACCOUNT_FORGOT_PASSWORD, { search: params })
      .map(this.extractData)
      .catch(this.handleError);
  }

  verifyResetPassword(token: string) {
    const params: URLSearchParams = new URLSearchParams();
    params.set('token', token);
    return this._http.get(this._apiUrlService.ACCOUNT_VERIFY_RESET_PASSWORD, { search: params })
      .map(this.extractData)
      .catch(this.handleError);
  }

  resetPassword(token: string, password: string) {
    return this._http.post(this._apiUrlService.ACCOUNT_RESET_PASSWORD, { token: token, password: password })
      .map(this.extractData)
      .catch(this.handleError);
  }
}
