<div class="camera-group" *ngIf="bunch.isActive" >
    <a  class="custom-camera-group-toggle"
    [ngClass]="bunch.isClick ?  '' : 'collapsed'"
        [id]="bunch.bunch.id"
        (click)="clickHandle($event)"
        >
        <div class="wrapper-group-cam" style="overflow: hidden;width: 70%;
        white-space: nowrap;text-overflow: ellipsis;" [style]=" displayLevel()">{{bunch.bunch.name}}</div>

    </a>
        <div *ngFor="let cam of bunch.listCamera|search:{label:['name'],keyword:term||''};" [style]=" displayLevelCam()" >
        <a class="camera-item" *ngIf="bunch.isClick" (click)="clickCamera(cam)">
          <div class="camera-name">
            <i *ngIf="cam.connectionStatus == 'CONNECTED'" class="icon icon-camera"
              [ngClass]="{'green': cam.state==1}"></i>
            <i *ngIf="cam.connectionStatus == 'DISCONNECTED'" class="icon icon-camera inactive"></i>
            <span style="cursor:pointer;">{{cam.name}}</span>
         </div>
        </a>
      </div>

  </div>
