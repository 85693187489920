<!--bought -->
<div class="detail-body" *ngIf="bunch.isActive">
  <div class="detail-item-wrapper">
    <div class="detail-item">
      <div class="row" [style]="getItemStyle(bunch.bunch)">
        <div class="col-7 col-sm-5">
          <p class="row item-text" data-toggle="collapse" [attr.data-target]="'#group' + bunch.bunch.id"
             aria-expanded="false" aria-controls="collapse-2" [style]="displayLevel()" (click)="onClick()">
              <span class="col-7">
                <i class="icon icon-toggle"></i> <span style="white-space: nowrap;overflow: hidden;margin-right: 10px;
                text-overflow: ellipsis;">{{bunch.bunch.name}}</span>
              </span>
            <span class="col-5 text-red">({{bunch.listCamera?.length}} cam)</span>
          </p>
        </div>
        <div class="col-3 col-sm-2">

        </div>
        <div class="col-2 col-sm-1">
          <ul class="action-list">
          </ul>
        </div>
      </div>
    </div>
    <div id="group{{bunch.bunch.id}}" class="collapse">
      <div *ngFor="let iCam of bunch.listCamera;">
        <div class="detail-sub-item">
          <div class="row">
            <div class="col-7 col-sm-6">
              <p class="item-text" style="white-space: nowrap;overflow: hidden;
                text-overflow: ellipsis;">
                {{iCam.name}}
              </p>
            </div>
            <div class="col-5 col-sm-4">
              <p class="item-text">
                {{iCam.dateExpiration|datex:'DD/MM/YYYY'}}
              </p>
            </div>
            <div class="col-12 col-sm-2">
              <ul class="action-list">
                <li class="extend-package">
                  <a href="javascript:;" title="{{'ad_extend'|translate}}" (click)="ShowBuyCam(iCam)">
                    <i class="icon icon-plus-circle"></i>
                  </a>
                </li>
                <li class="change-package" title="{{'ad_transfer'|translate}}" (click)="ShowBuyCamSwitchPackage(iCam)">
                  <a href="javascript:;">
                    <i class="icon icon-check-out"></i>
                  </a>
                </li>
                <li class="remove-package" title="{{'ad_cancel'|translate}}" (click)="CancelPackage(iCam)">
                  <a href="javascript:;">
                    <i class="icon icon-delete"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


