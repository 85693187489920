// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyCZVcX_mUsEZaDji2MCSmmwo0hQy7BNXwo",
    authDomain: "cloud-camera-2fa34.firebaseapp.com",
    projectId: "cloud-camera-2fa34",
    messagingSenderId: "28443015495",
    appId: "1:28443015495:web:fdfb85dd005af4d2d5f462",
    storageBucket: "cloud-camera-2fa34.appspot.com",
    measurementId: "G-REERPPPBGK"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
