<div class="row custom-row" style="height: auto;" [style]="displayLevel()" *ngIf="bunch.isActive" >
    <div class="row col-sm" (click)="onClick()">
        <div class="col-sm" style="margin-top: auto; margin-bottom: auto;">
            <div class="icons-dropdown" *ngIf="bunch.listChild.length > 0 " [style]="style"></div>
            {{bunch.displayIndex}}
        </div>
        <div class="col-sm " style="margin-top: auto; margin-bottom: auto;">{{bunch.bunch.name}}</div>
        <div class="col-sm" style="text-align: left; line-height: 30px; margin-top: auto; margin-bottom: auto;">{{conCatCameraName()}}</div>
    </div>
    <!-- <div class="col-sm">{{displayCountCam(bunch)}}</div> -->
    <div class="col-sm-2" style="margin-top: auto; margin-bottom: auto;">
        <nav class="action-list">
            <a (click)="editBunch(bunch.bunch)" title="Sửa nhóm">
                <i class="icon icon-edit"></i>
              </a>
            <a (click)="remove(bunch)" title="Xóa nhóm">
                <i class="icon icon-delete"></i>
              </a>
        </nav>
      
    </div>
</div>
<!-- <div class="row custom-row" *ngIf="bunch.listCamera.length > 0 && isClick == true" style="font-weight: 700;margin-top: 20px; background-color: #e2e2ea;">
    <div class="col-sm">STT</div>
    <div class="col-sm">Tên Camera</div>
    <!-- <div class="col-sm">CloudID</div>
    <div class="col-sm">Trạng thái1</div> -->
<!-- </div> -->
<!-- <div class="camera-groups" *ngIf="bunch.listCamera.length > 0 && isClick == true">
    <a class="row custom-row" *ngFor="let item of bunch.listCamera; let index = index">
   
        <div class="col-sm">
            {{index + 1}}</div>
        <div class="col-sm">{{item.name}}</div>
         <div class="col-sm">{{item.cloudId}}</div>
        <div class="col-sm">{{item.connectionStatus}}</div> -->
<!--      
    </a>
</div> --> 