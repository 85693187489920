import { Injectable } from "@angular/core";
import { BaseService } from "./base.service";
import { ApiUrlService } from "./api-url.service";
import { Http, RequestOptions, URLSearchParams } from "@angular/http";
import { CookieService } from "ngx-cookie-service";

@Injectable({
  providedIn: "root",
})
export class AiCameraEventService extends BaseService {
  constructor(
    public _http: Http,
    public _apiUrl: ApiUrlService,
    public cookie: CookieService
  ) {
    super(_http, _apiUrl, cookie);
  }
  login() {
    return this._http
      .post(this._apiUrl.AI_CAMERA_LOGIN, {
      })
      .map(this.extractData)
      .catch(this.handleError);
  }

  findAll(time?: string) {
    return this._http
      .post(
        this._apiUrl.AI_CAMERA_EVENT_GET_EVENTS,
        { limit: "1000", page: "0", startDateTime: time ? time : "0" },
        this.aiAuthorizationOption()
      )
      .map(this.extractData)
      .catch(this.handleError);
  }

  findWithStartTime(startDateTime: string) {
    return this._http
      .post(
        this._apiUrl.AI_CAMERA_EVENT_GET_EVENTS,
        { limit: "0", startDateTime },
        this.aiAuthorizationOption()
      )
      .map(this.extractData)
      .catch(this.handleError);
  }

  findUserWithUserIds(userIds: string[]) {
    return this._http
      .post(
        this._apiUrl.AI_CAMERA_EVENT_GET_USERS,
        { userIds },
        this.aiAuthorizationOption()
      )
      .map(this.extractData)
      .catch(this.handleError);
  }
  //function AI Camera mới
  statisticCameraAI() {
    return this._http.get(this._apiUrl.AI_STATISTIC_CAMERA, this.authorizationOption())
      .map(this.extractData)
      .catch(this.handleError);
  }
  getAllCameraAI(_scenarioId) {
    const params: URLSearchParams = new URLSearchParams();
    params.set('scenarioId', `${_scenarioId}`);
    return this._http.get(this._apiUrlService.AI_GET_ALL_CAMERA, this.authorizationOption().merge({ search: params }))
      .map(this.extractData)
      .catch(this.handleError);
  }

  getAllEventFaceID(_scenarioId, _page, _limit) {
    const params: URLSearchParams = new URLSearchParams();
    params.set('scenarioId', `${_scenarioId}`);
    params.set('page', `${_page}`);
    params.set('limit', `${_limit}`);
    return this._http.get(this._apiUrlService.EVENT_FACEID, this.authorizationOption().merge({ search: params }))
      .map(this.extractData)
      .catch(this.handleError);
  }
  getAllEventFaceID2(_scenarioId, _page, _limit, _timeStart, _timeEnd) {
    const params: URLSearchParams = new URLSearchParams();
    params.set('scenarioId', `${_scenarioId}`);
    params.set('page', `${_page}`);
    params.set('limit', `${_limit}`);
    params.set('timeStart', `${_timeStart}`);
    params.set('timeEnd', `${_timeEnd}`);
    return this._http.get(this._apiUrlService.EVENT_FACEID, this.authorizationOption().merge({ search: params }))
      .map(this.extractData)
      .catch(this.handleError);
  }
  getEventCheckInOUt() {

  }
  getAllScenario() {
    return this._http.get(this._apiUrlService.GET_ALL_SCENARIO, this.authorizationOption())
      .map(this.extractData)
      .catch(this.handleError);
  }

  getTreeViewCameras(_code: string, _keyword: string) {
    const params: URLSearchParams = new URLSearchParams();
    params.set('code', _code);
    params.set('keyword', _keyword);
    return this._http.get(this._apiUrlService.GET_TREE_VIEW_CAMERAS, this.authorizationOption().merge({ search: params }))
      .map(this.extractData)
      .catch(this.handleError);
  }

  activateAlertCamera(code: string, _cameraIds: number[]) {
    return this._http.post(this._apiUrlService.ACTIVATE_ALERT_CAMERA, { code, cameraIds: _cameraIds }, this.authorizationOption())
      .map(this.extractData)
      .catch(this.handleError);
  }

  deactivateAlertCamera(code: string, _cameraIds: number[]) {
    return this._http.post(this._apiUrlService.DEACTIVATE_ALERT_CAMERA, { code, cameraIds: _cameraIds }, this.authorizationOption())
      .map(this.extractData)
      .catch(this.handleError);
  }

  getScenarioById(_scenarioId: number) {
    const params: URLSearchParams = new URLSearchParams();
    params.set('scenarioId', `${_scenarioId}`);
    return this._http.get(this._apiUrlService.GET_SCENARIO_BY_ID, this.authorizationOption().merge({ search: params }))
      .map(this.extractData)
      .catch(this.handleError);
  }

  statisticAICamera() {
    return this._http.get(this._apiUrlService.STATISTIC_CAMERA_AI, this.authorizationOption())
      .map(this.extractData)
      .catch(this.handleError);
  }
  getAllCamAI(_scenarioId) {
    const params: URLSearchParams = new URLSearchParams();
    params.set('scenarioId', `${_scenarioId}`);
    return this._http.get(this._apiUrlService.GET_ALL_CAM_AI, this.authorizationOption().merge({ search: params }))
      .map(this.extractData)
      .catch(this.handleError);
  }
  getAllBunchAI() {
    return this._http.get(this._apiUrlService.GET_ALL_BUNCH_AI, this.authorizationOption())
      .map(this.extractData)
      .catch(this.handleError);
  }
  getAllCamNoBunch() {
    return this._http.get(this._apiUrlService.GET_CAM_AI_NO_BUNCH, this.authorizationOption())
      .map(this.extractData)
      .catch(this.handleError);
  }
  getCamByBunch(_bunchId) {
    const params: URLSearchParams = new URLSearchParams();
    params.set('bunchId', `${_bunchId}`);
    return this._http.get(this._apiUrlService.GET_CAM_AI_BY_BUNCH, this.authorizationOption().merge({ search: params }))
      .map(this.extractData)
      .catch(this.handleError);
  }
  registerCameraAI(_scenarioId: number, _cameraIds: number[]) {
    if (_scenarioId === 21) {
      return this._http.post(this._apiUrlService.ACTIVE_INTRUSION_AI, { scenarioId: _scenarioId, cameraIds: _cameraIds }, this.authorizationOption())
        .map(this.extractData)
        .catch(this.handleError);
    }

    return this._http.post(this._apiUrl.ACTIVE_CAM_AI, { scenarioId: _scenarioId, cameraIds: _cameraIds }, this.authorizationOption())
      .map(this.extractData)
      .catch(this.handleError);
  }

  unRegisterCameraAI(_scenarioId, _cameraIds: number[]) {
    if (_scenarioId === 21) {
      return this._http.post(this._apiUrlService.INACTIVE_INTRUSION_AI, { scenarioId: _scenarioId, cameraIds: _cameraIds }, this.authorizationOption())
        .map(this.extractData)
        .catch(this.handleError);
    }

    return this._http.post(this._apiUrl.INACTIVE_CAM_AI, { scenarioId: _scenarioId, cameraIds: _cameraIds }, this.authorizationOption())
      .map(this.extractData)
      .catch(this.handleError);
  }

  statisticTime(_scenarioId) {
    const params: URLSearchParams = new URLSearchParams();
    params.set('scenarioId', `${_scenarioId}`);
    return this._http.get(this._apiUrl.STATISTIC_TIME, this.authorizationOption().merge({ search: params }))
      .map(this.extractData)
      .catch(this.handleError);
  }
  checkInCheckOut(_scenarioId, _date) {
    const params: URLSearchParams = new URLSearchParams();
    params.set('scenarioId', `${_scenarioId}`);
    params.set('date', `${_date}`);
    return this._http.get(this._apiUrl.EVENT_CHECKINOUT, this.authorizationOption().merge({ search: params }))
      .map(this.extractData)
      .catch(this.handleError);
  }
  registerTimeKeeping(_timeconFig) {
    return this._http.post(this._apiUrl.REGISTER_TIMEKEEPING, _timeconFig, this.authorizationOption())
      .map(this.extractData)
      .catch(this.handleError);
  }
  deleteTimeKeeping(_timeKeepingIds) {
    const params: URLSearchParams = new URLSearchParams();
    params.set('timeKeepingIds', `${_timeKeepingIds}`);
    return this._http.get(this._apiUrl.DELETE_TIMEKEEPING, this.authorizationOption().merge({ search: params }))
      .map(this.extractData)
      .catch(this.handleError);
  }
  findTimeKeeping() {
    return this._http.get(this._apiUrl.FIND_TIMEKEEPING, this.authorizationOption())
      .map(this.extractData)
      .catch(this.handleError);
  }
  findAllLog(_keyword, _type, _timeStart, _timeEnd, _limit, _page) {
    const params: URLSearchParams = new URLSearchParams();
    params.set('keyword', `${_keyword}`);
    params.set('type', `${_type}`);
    params.set('timeStart', `${_timeStart}`);
    params.set('timeEnd', `${_timeEnd}`);
    params.set('limit', `${_limit}`);
    params.set('page', `${_page}`);
    return this._http.get(this._apiUrl.GET_ALL_LOG, this.authorizationOption().merge({ search: params }))
      .map(this.extractData)
      .catch(this.handleError);
  }

  getEliteGuestEvents(_page: number, _limit: number, _keyword: string = '', _timeStart: number, _timeEnd: number) {
    const params: URLSearchParams = new URLSearchParams();
    params.set('page', `${_page}`);
    params.set('limit', `${_limit}`);
    params.set('keyword', `${_keyword}`);
    params.set('timeStart', `${_timeStart}`);
    params.set('timeEnd', `${_timeEnd}`);

    return this._http.get(this._apiUrlService.GET_ELITE_GUEST_EVENTS, this.authorizationOption().merge({ search: params }))
      .map(this.extractData)
      .catch(this.handleError);
  }

  getIntrusionEvents(_scenarioId: number, _page: number, _limit: number, _keyword: string = '', _timeStart: number, _timeEnd: number) {
    const params: URLSearchParams = new URLSearchParams();
    params.set('scenarioId', `${_scenarioId}`);
    params.set('page', `${_page}`);
    params.set('limit', `${_limit}`);
    params.set('keyword', `${_keyword}`);
    params.set('timeStart', `${_timeStart}`);
    params.set('timeEnd', `${_timeEnd}`);

    return this._http.get(this._apiUrlService.GET_INTRUSION_EVENTS, this.authorizationOption().merge({ search: params }))
      .map(this.extractData)
      .catch(this.handleError);
  }

  getSmartAlerts(_page: number, _limit: number, _keyword: string = '', _timeStart: number, _timeEnd: number, type?: string) {
    const params: URLSearchParams = new URLSearchParams();
    params.set('page', `${_page}`);
    params.set('limit', `${_limit}`);
    params.set('timeStart', `${_timeStart}`);
    params.set('timeEnd', `${_timeEnd}`);

    if (_keyword) {
      params.set('keyword', _keyword);
    }

    if (type) {
      params.set('type', type);
    }

    return this._http.get(this._apiUrlService.GET_SMART_ALERTS, this.authorizationOption().merge({ search: params }))
      .map(this.extractData)
      .catch(this.handleError);
  }

  markAlertAsRead(_ids: number[]) {
    return this._http.post(this._apiUrlService.MARK_ALERT_AS_READ, { ids: _ids }, this.authorizationOption())
      .map(this.extractData)
      .catch(this.handleError);
  }
}
