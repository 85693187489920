import {Component, DoCheck, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {SendData} from 'src/app/_models/sen_data';

@Component({
  selector: 'app-bunch-tables-action',
  templateUrl: './bunch-tables-action.component.html',
  styleUrls: ['../bunch.component.css']
})
export class BunchTablesActionComponent implements OnInit, DoCheck {

  @Input() bunch:any;
  @Input() disableClickBunch:boolean = false;
  @Input() listBunch:any;
  @Output() editModal= new EventEmitter<any>();
  @Output() removeModal= new EventEmitter<any>();
  @Output() sendIdBunch = new EventEmitter<any>();
  @Output() sendIsClick = new EventEmitter<any>();
  send = new SendData();
  isClick = false;
  style = "transform: rotate(-90deg);";
  constructor() { }
ngDoCheck(): void {
}
  ngOnInit(): void {
  }
  displayCountCam($event:any){
    var count = 0;
    count += $event.listCamera.length;
    if($event.listChild.length > 0){
      $event.listChild.forEach(element => {
        count += this.displayCountCam(element);
      });
    }
    return count;
  }
  displayCountChild($event:any){
    var count = 0;
    count += $event.listChild.length;
    if($event.listChild.length > 0){
      $event.listChild.forEach(element => {
        count += this.displayCountCam(element);
      });
    }
    return count;
  }

  onClick() {
    if (!this.disableClickBunch) {
      this.bunch.isClick = !this.bunch.isClick;
      if (this.bunch.isClick) {
        this.style = "transform: rotate(0deg);"
      } else {
        this.style = "transform: rotate(-90deg);"
      }
      this.sendIdBunch.emit(this.bunch);
      this.sendIsClick.emit(this.bunch.isClick);
    }
  }

  editBunch($event:any){
    this.editModal.emit($event);
  }

  remove(item:any){
    this.removeModal.emit(item);
  }

  displayLevel(){
    var paddingString = "margin-left: " + this.bunch.level * 30 + "px;";
    return paddingString;
  }
  conCatCameraName(){
    var listCameraName = "";
    if(this.bunch.listCamera.length > 1) {
      this.bunch.listCamera.forEach((element, idx) => {
        if (idx === this.bunch.listCamera.length - 1){ 
          listCameraName += element.name;
        } else {
          listCameraName += element.name + ", ";
        }
      });
    } else if (this.bunch.listCamera.length == 1){
      this.bunch.listCamera.forEach(element => {
        listCameraName += element.name;
      });
    }

    return listCameraName;
  }
}
