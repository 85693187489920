import { PackageModel } from "./package.model";
export class CameraModel {
  id: number;
  name: string;
  bunchId: number;
  streamUrl: string;
  connectionStatus: string;
  cloudId: string;
  provider: string;
  host: string;
  port: number;
  secureCode: string;
  selected: boolean = true;
  typeCam: number;
  username: string;
  password: string;
  dateExpiration: string;
  StreamFileWowza: string;
  package: PackageModel;
  state: number = 0;
  resolution: string = "";
  liveProtocol: string = "";
  checked: boolean = false;
  numShare:string ="";
}
export class CameraType {
  id: number;
  name: string;
}
export class CameraOffModel {
  id: number;
  name: string;
  cloudId: string;
  bunchName: string;
  status: string;
}
export class AccountCameraModel {
  id: number;
  username: string;
  email:string;
  fullName: string;
  status: string;
  roleId: number;
  usernameParent: string;
  fullNameParent:string;
  emailParent:string;
  shared:number;
  share_limit:number;
  checked: boolean = true;
}
