<div class="landing position-relative">
  <app-home-header></app-home-header>
  <div class="page-container ">
    <section class="section-banner " id="sectionBanner ">
      <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel">
        <button class="btn-primary btn-consult"  onclick="window.open('https://viettelidc.com.vn/Home/Contact','_blank')" translate>{{'ldp_presale'}}</button>
        <ol class="carousel-indicators">
          <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active"></li>
          <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
          <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
        </ol>
        <div class="carousel-inner">
          <div class="carousel-item active">
            <img class="d-block w-100" src="assets/landing/banner.png" alt="First slide">
            <div class="container ">
              <div class="carousel-caption ">
                <h3 class="title-banner" translate>{{'ldp_sln_online_optimal'}}</h3>
                <p translate>{{'ldp_sld1'}}</p>
              </div>
            </div>
          </div>
          <div class="carousel-item">
            <img class="d-block w-100" src="assets/landing/banner2.png" alt="Second slide">
            <div class="carousel-caption ">
              <h3 class="title-banner" translate>{{'ldp_sln_online_optimal1'}}</h3>
              <p translate>{{'ldp_sld2'}}</p>
            </div>
          </div>
          <div class="carousel-item">
            <img class="d-block w-100" src="assets/landing/banner3.png" alt="Third slide">

            <div class="carousel-caption ">
              <h3 class="title-banner" translate>{{'ldp_sln_online_optimal2'}}</h3>
              <p translate>{{'ldp_sld3'}}</p>
            </div>
          </div>
        </div>
        <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="sr-only">Previous</span>
        </a>
        <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="sr-only">Next</span>
        </a>

      </div>
    </section>
    <section class="section-services">
      <div class="container">
        <div class="row no-gutters">
          <div class="col-sm-12">
            <div class="service-group slick-initialized slick-slider">
              <div class="slick-list draggable">
                <div class="slick-track" style="opacity: 1;  transform: translate3d(0px, 0px, 0px);">
                  <div class="service-icon slick-slide slick-current slick-active" data-slick-index="0"
                    aria-hidden="false" style="width: 370px;" tabindex="0">
                    <div class="default-status">
                      <h3 translate>{{'ldp_manage_monitor'}}</h3>
                      <p translate>{{'ldp_slogan4'}}
                      </p>
                    </div>
                  </div>
                  <div class="service-icon slick-slide slick-active" data-slick-index="1" aria-hidden="false"
                    style="width: 370px;" tabindex="0">
                    <div class="default-status">
                      <h3 translate>{{'ldp_optimal_money'}}</h3>
                      <p translate>{{'ldp_slogan2'}}
                      </p>
                    </div>
                  </div>
                  <div class="service-icon slick-slide slick-active" data-slick-index="2" aria-hidden="false"
                    style="width: 370px;" tabindex="0">
                    <div class="default-status">
                      <h3 translate>{{'ldp_easy_access'}}</h3>
                      <p translate>{{'ldp_slogan3'}}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>


    <!-- Tính năng -->
    <section id="sectionFeatures">
      <h3 class="title" translate>{{'ldp_bsft'}}</h3>
      <div style="text-align: center; margin-bottom: 48px;" translate>{{'ldp_bsft_content'}}</div>
      <div class="container p-md-0 ">
        <div class="row no-gutters mb-5 mb-md-0 ">
          <div class="col-md-6 ">
            <div class="block-feature-img ">
              <img src="assets/landing/tinhnang.png " alt="Tính năng" style="width: 90%;"/>
            </div>
          </div>
          <div class="col-md-6 ">
            <div class="block-feature" style="margin-top: 48px;">
              <div class="block-feature__inner ">
                <ul class="block-feature__list ">
                  <li>
                    <img src="assets/landing/icons/check-cube.svg" alt="" />
                    <span translate>{{'ldp_manage_focus'}}</span>
                  </li>
                  <li>
                    <img src="assets/landing/icons/check-cube.svg " alt=" " />
                    <span translate>{{'ldp_role_manage_camera'}}</span>
                  </li>
                  <li>
                    <img src="assets/landing/icons/check-cube.svg " alt=" " />
                    <span translate>{{'ldp_support_codec'}}</span>
                  </li>
                  <li>
                    <img src="assets/landing/icons/check-cube.svg " alt=" " />
                    <span translate>{{'ldp_compatible'}}</span>
                  </li>
                  <li>
                    <img src="assets/landing/icons/check-cube.svg " alt=" " />
                    <span translate>{{'ldp_easy_data'}}</span>
                  </li>
                  <li>
                    <img src="assets/landing/icons/check-cube.svg " alt=" " />
                    <span translate>{{'ldp_extend_customer'}}</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>


      <h3 class="title" translate>{{'ldp_adft'}}</h3>
      <div class="container p-md-0 ">
        <div class="row no-gutters mb-5 mb-md-0" style="margin-top: 48px;margin-bottom: 48px !important;">
          <div class="col-md-6 ">
            <div class="block-feature">
              <div class="block-feature__inner ">
                <div style="font-weight: 600; margin-bottom: 15px;" translate>{{'ldp_sync'}}</div>
                <ul class="block-feature__list ">
                  <li>
                    <img src="assets/landing/icons/check-cube.svg" alt="" />
                    <span translate>{{'ldp_sync1'}}</span>
                  </li>
                  <li>
                    <img src="assets/landing/icons/check-cube.svg " alt=" " />
                    <span translate>{{'ldp_sync2'}}</span>
                  </li>
                  <li>
                    <img src="assets/landing/icons/check-cube.svg " alt=" " />
                    <span translate>{{'ldp_sync3'}}</span>
                  </li>
                  <li>
                    <img src="assets/landing/icons/check-cube.svg " alt=" " />
                    <span translate>{{'ldp_sync4'}}</span>
                  </li>
                  <li>
                    <img src="assets/landing/icons/check-cube.svg " alt=" " />
                    <span translate>{{'ldp_sync5'}}</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-md-6 ">
            <div class="block-feature-img">
              <img src="assets/landing/dongbodulieu.svg " alt="Đồng bộ dữ liệu" />
            </div>
          </div>
        </div>
      </div>
      <!-- <h3 class="title" translate>Tính năng nâng cao</h3>
      <div class="container p-md-0 ">
        <div class="row no-gutters mb-5 mb-md-0" style="margin-top: 48px;margin-bottom: 48px !important;">
          <div class="col-md-6 ">
            <div  style="width: 100%;">
              <div class="block-feature__inner ">
                <div *ngFor="let item of data;let i = index;">
                  <button class="accordion" (click)="toggleAccordian($event, i)"> {{item.parentName}} </button>
                  <div class="panel" hide="!item.isActive">
                    <ul class="block-feature__list">
                      <li style="margin-top: 20px;">
                        <img src="assets/landing/icons/check-cube.svg" alt="" />
                        <span translate>  Định kỳ kiểm tra tình trạng mất kết nối từ camera đến hệ thống Viettel Cloud Camera và lưu trữ dữ liệu xem lại cục bộ.</span>
                      </li>
                      <li>
                        <img src="assets/landing/icons/check-cube.svg " alt=" " />
                        <span translate>  Đẩy dữ liệu lên hệ thống Viettel Cloud Camera ngay khi có kết nối lại, đảm bảo tính liên tục cho dữ liệu của bạn.</span>
                      </li>
                      <li>
                        <img src="assets/landing/icons/check-cube.svg " alt=" " />
                        <span translate>  Dễ dàng quản lý các camera sử dụng tính năng.</span>
                      </li>
                      <li>
                        <img src="assets/landing/icons/check-cube.svg " alt=" " />
                        <span translate>  Cung cấp tính năng bổ sung: Restream luồng rtsp mà không cần nat port cho camera.</span>
                      </li>
                      <li>
                        <img src="assets/landing/icons/check-cube.svg " alt=" " />
                        <span translate>  Tính năng quản lý, giám sát thiết bị</span>
                      </li>
                    </ul>
                  </div>
                </div>
                <div style="font-weight: 600; margin-bottom: 15px;" class="accordion">Đồng bộ dữ liệu</div>
                <div class="panel">
                  <ul class="block-feature__list">
                    <li>
                      <img src="assets/landing/icons/check-cube.svg" alt="" />
                      <span translate>Định kỳ kiểm tra tình trạng mất kết nối từ camera đến hệ thống Viettel Cloud Camera và lưu trữ dữ liệu xem lại cục bộ.</span>
                    </li>
                    <li>
                      <img src="assets/landing/icons/check-cube.svg " alt=" " />
                      <span translate>Đẩy dữ liệu lên hệ thống Viettel Cloud Camera ngay khi có kết nối lại, đảm bảo tính liên tục cho dữ liệu của bạn.</span>
                    </li>
                    <li>
                      <img src="assets/landing/icons/check-cube.svg " alt=" " />
                      <span translate>Dễ dàng quản lý các camera sử dụng tính năng.</span>
                    </li>
                    <li>
                      <img src="assets/landing/icons/check-cube.svg " alt=" " />
                      <span translate>Cung cấp tính năng bổ sung: Restream luồng rtsp mà không cần nat port cho camera.</span>
                    </li>
                    <li>
                      <img src="assets/landing/icons/check-cube.svg " alt=" " />
                      <span translate>Tính năng quản lý, giám sát thiết bị</span>
                    </li>
                  </ul></div>
              </div>
            </div>
          </div>
          <div class="col-md-6 ">
            <div class="block-feature-img">
              <img src="assets/landing/dongbodulieu.svg " alt="Đồng bộ dữ liệu" />
            </div>
          </div>
        </div>
      </div> -->
    </section>
    <!-- Giải phap -->
    <section class="section-solutions" id="sectionSolutions">
      <h3 class="section-title gray title" translate>{{'ldp_my_solution'}}</h3>
      <div class="container">
        <app-slider-solution></app-slider-solution>
      </div>
    </section>
    <!-- Khách hàng của chúng tôi -->
    <section class="section-partner" id="sectionPartners">
      <h3 class="section-title gray title" translate>{{'ldp_my_customer'}}</h3>
      <div class="container ">

        <div class="block-partners ">
          <div class="block-partner  ">
            <img src="assets/landing/viettelpost.jpg " alt=" " />
          </div>
          <div class="block-partner ">
            <img src="assets/landing/shopee.png " alt=" " />
          </div>
          <div class="block-partner ">
            <img src="assets/landing/pvoil.png " alt=" " />
          </div>
          <div class="block-partner ">
            <img src="assets/landing/nhcsxh.png " alt=" " />
          </div>
          <div class="block-partner ">
            <img src="assets/landing/f88.png " alt=" " />
          </div>
          <div class="block-partner ">
            <img src="assets/landing/metfone.png " alt=" " />
          </div>
          <div class="block-partner ">
            <img src="assets/landing/viettelcommerce.png " alt=" " />
          </div>

        </div>
      </div>
    </section>
    <!-- Chứng chỉ & Giải thưởng -->
    <section class="section-certificate " id="sectionCertificates">
      <h3 class="section-title blue title" translate>{{'ldp_certificate_awards'}}</h3>
      <div class="container ">
        <div class="container-certificate">

          <div class="block-partners  ">
            <div class="block-partner ">
              <img src="assets/landing/certificate/iso9001.png " alt=" " />
              <div class="text-title">
                <p translate>{{'ldp_certificate'}} </p>
                <p translate>ISO 9001:2015</p>
              </div>

            </div>
            <div class="block-partner ">
              <img src="assets/landing/certificate/iso27001.png " alt=" " />
              <div class="text-title">
                <p translate>{{'ldp_certificate'}} </p>
                <p translate>ISO 27001:2013</p>
              </div>
            </div>
            <div class="block-partner ">
              <img src="assets/landing/certificate/4-iso27017-2015-06.png " alt=" " />
              <div class="text-title">
                <p translate>{{'ldp_certificate'}} </p>
                <p translate>ISO 27017:2015</p>
              </div>
            </div>
            <div class="block-partner ">
              <img src="assets/landing/certificate/saokhue2017.png " alt=" " />
              <div class="text-title">
                <p translate>{{'ldp_awards'}} </p>
                <p translate>{{'ldp_saokhue'}}</p>
              </div>
            </div>
            <div class="block-partner ">
              <img src="assets/landing/certificate/DIGITAL-1.png " alt=" " />
              <div class="text-title">
                <p translate>{{'ldp_awards'}} </p>
                <p translate>{{'ldp_chuyendoiso'}}</p>
              </div>
            </div>
            <div class="block-partner ">
              <img src="assets/landing/certificate/iso22301.png" alt=" " />
              <div class="text-title">
                <p translate>{{'ldp_certificate'}} </p>
                <p translate>ISO 22301:2019</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- Bảng giá -->
    <section id="sectionPrices">
      <h3 class="section-title gray title" translate>{{'ldp_price'}}</h3>
      <div class="container text-center">
        <span style="padding-top:18px" translate>{{'ldp_vat'}}</span>
        <div class="block-prices">
          <div class="block-prices__table">
            <div class="block-prices__table-inner" data-mh="group-price-table">
              <div class="card-image">
                <img src="assets/landing/card-title.png" alt="" />
                <h4 class="package-name" translate>{{'ldp_save3d'}}</h4>
              </div>
              <div class="card-content">
                <h4 class="package-price text-primary">35.000 VND</h4>
                <span class="" style="margin-bottom:32px">/Camera</span>
                <div class="package-time">
                  <p translate>{{'ldp_timesave_3d'}} </p>
                  <p translate>{{'ldp_cycle'}} </p>
                  <p translate>{{'ldp_sd_resolution'}}</p>
                </div>
              </div>
            </div>
            <a routerLink="/package" style="color: var(--primary);" translate>{{'ldp_read_more'}}</a>
          </div>
          <div class="block-prices__table">
            <div class="block-prices__table-inner" data-mh="group-price-table">
              <div class="card-image">
                <img src="assets/landing/card-title.png" alt="" />
                <h4 class="package-name" translate>{{'ldp_save7d'}}</h4>
              </div>
              <div class="card-content">
                <h4 class="package-price text-primary">45.000 đ</h4>
                <span class="" style="margin-bottom:32px">/Camera</span>
                <div class="package-time">
                  <p translate>{{'ldp_timesave_7d'}} </p>
                  <p translate>{{'ldp_cycle'}} </p>
                  <p translate>{{'ldp_sd_resolution'}}</p>
                </div>
              </div>
            </div>
          </div>
          <div class="block-prices__table">
            <div class="block-prices__table-inner" data-mh="group-price-table">
              <div class="card-image">
                <img src="assets/landing/card-title.png" alt="" />
                <h4 class="package-name" translate>{{'ldp_save14d'}}</h4>
              </div>
              <div class="card-content">
                <h4 class="package-price text-primary">65.000 đ</h4>
                <span class="" style="margin-bottom:32px">/Camera</span>
                <div class="package-time">
                  <p translate>{{'ldp_timesave_14d'}} </p>
                  <p translate>{{'ldp_cycle'}} </p>
                  <p translate>{{'ldp_sd_resolution'}}</p>
                </div>
              </div>
            </div>
          </div>
          <div class="block-prices__table">
            <div class="block-prices__table-inner" data-mh="group-price-table">
              <div class="card-image">
                <img src="assets/landing/card-title.png" alt="" />
                <h4 class="package-name" translate>{{'ldp_save30d'}}</h4>
              </div>
              <div class="card-content">
                <h4 class="package-price text-primary">110.000 đ</h4>
                <span class="" style="margin-bottom:32px">/Camera</span>
                <div class="package-time">
                  <p translate>{{'ldp_timesave_30d'}} </p>
                  <p translate>{{'ldp_cycle'}}</p>
                  <p translate>{{'ldp_sd_resolution'}}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- Tải ứng dụng -->
    <div class="section-download" id="sectionDownload">
      <h3 class="title" translate>{{'ldp_down_application'}}<br />{{'ldp_down_application1'}}</h3>
      <div class="row section-dơwnload no-gutters mb-5 mb-md-0 " id="sectionDownload ">
        <div class="container">
          <div class="col-md-8 order-1 order-md-1 ">
            <div class="block-feature ">
              <div class="block-feature__inner download ">
                
                <div class="title-down" translate>{{'ldp_let_down1'}}<span style="color: #e03;" translate>{{'ldp_let_down2'}}</span>{{'ldp_let_down3'}}</div>
                <ul class="check-list ">
                  <li>
                    <span translate>{{'ldp_online'}}</span>
                  </li>
                  <li>
                    <span translate>{{'ldp_playback'}}</span>
                  </li>
                  <li>
                    <span translate>{{'ldp_monitor'}}</span>
                  </li>
                  <li>
                    <span translate>{{'ldp_sync'}}</span>
                  </li>
                  <li>
                    <span translate>{{'ldp_ai'}}</span>
                  </li>
                </ul>
                  <a href="https://itunes.apple.com/fj/app/cloud-camera/id1168865805?mt=84" target="_blank "
                  class="mr-2"><img src="assets/landing/btn-apple.png " alt="App Store "></a>
                <a href="assets/client-pc/Viettel_Desktop_CloudCam.rar" target="_blank " class="mr-2"><img
                    style="border-radius: 12px;" src="assets/landing/btn-windows.png " alt="Windows Store "></a>
                <a href="https://play.google.com/store/apps/details?id=com.viettelidc.cloudcamera&amp;hl=vi"
                  target="_blank "><img src="assets/landing/btn-google.png " alt="Google Play Store "></a>
                  <div class="download-btns" style="margin-top: -18px;"> 
                  <div class="title-down"translate>{{'ldp_let_down1'}}<span style="color: #e03;" translate>{{'ldp_let_down2'}}</span>{{'ldp_down_sync'}}</div>
                    <a href="assets/ViettelEdgeRecordingManager/Viettel Edge Recording Manager.rar" target="_blank " class="mr-2"><img
                        style="border-radius: 12px;" src="assets/landing/ubuntu.svg " alt="Đồng bộ dữ liệu"></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


    <!-- Hướng dẫn sử dụng -->
    <section class="section-document" id="sectionManual">
      <h3 class="section-title gray title" translate>{{'ldp_doc_vCC'}}</h3>
      <div class="container">
        <div class="block-documents" style="display:flex; justify-content:space-between; font-size: 18px !important;">

          <div class="card" style="width: 23%">
            <img src="assets/landing/tailieugioithieu.png" class="card-img-top" alt="...">
            <div class="card-body">
              <h5 class="card-title" translate>{{'ldp_intro_doc'}}</h5>
              <a href="assets/doc/Viettel Cloud Camera_Gioi Thieu San Pham.pdf" target="_blank"
                class="btn btn-primary stretched-link" style="min-height: 33px; min-width: 100px;" translate>{{'ldp_download'}}</a>
            </div>
          </div>

          <div class="card" style="width: 23%">
            <img src="assets/landing/tailieuhuongdansudung.png" class="card-img-top" alt="...">
            <div class="card-body">
              <h5 class="card-title" translate>{{'ldp_guide_doc'}}</h5>
              <a href="assets/doc/Viettel Cloud Camera_Huong Dan Su Dung.pdf" target="_blank"
                class="btn btn-primary stretched-link" style="min-height: 33px; min-width: 100px;" translate>{{'ldp_download'}}</a>
            </div>
          </div>
          <div class="card" style="width: 23%">
            <img src="assets/landing/tailieuhuongdantichhop.png" class="card-img-top" alt="...">
            <div class="card-body">
              <h5 class="card-title" translate>{{'ldp_integrate_doc'}}</h5>
              <a href="assets/doc/Viettel Cloud Camera_Tich Hop Luong Camera RTSP.pdf" target="_blank"
                class="btn btn-primary stretched-link" style="min-height: 33px; min-width: 100px;" translate>{{'ldp_download'}}</a>
            </div>
          </div>
          <div class="card" style="width: 23%">
            <img src="assets/landing/tailieudongbodulieu.jpg" class="card-img-top" alt="...">
            <div class="card-body">
              <h5 class="card-title" translate>{{'doc_sync'}}</h5>
              <a href="assets/doc/Viettel Cloud Camera_Dong Bo Du Lieu.pdf" target="_blank"
                class="btn btn-primary stretched-link" style="min-height: 33px; min-width: 100px;" translate>{{'ldp_download'}}</a>
            </div>
          </div>










          <!-- <div class="block-documents__document" style="width:23%">
            <div class="card mb-6 card_focus">
              <div class="row g-0" style="height: 100%;">
                <div class="col-sm-5">
                  <img src="assets/landing/tailieuhuongdantichhop.png" class="card-img-top h-100" alt="...">
                </div>
                <div class="col-sm-7">
                  <div class="card-body">
                    <h5 class="card-title" translate>{{'ldp_integrate_doc'}}</h5>
                    <a href="assets/doc/Cloudcam_Huong dan lay link rtsp camera tich hop.pdf" target="_blank"
                      style="min-height: 33px; min-width: 100px;" class="btn btn-primary stretched-link" translate>{{'ldp_download'}} </a>
                  </div>
                </div>
              </div>
            </div>
          </div> -->

        </div>
      </div>
    </section>

    <div class="container" id="sectionContact"
      style="background-color: var(--primary); padding-bottom: 30px; margin-top: 48px; margin-bottom: 48px; border-radius: 2px 2px 2px 2px; text-align: center;">

      <div class="row justify-content-sm-center">
        <div class="col-sm-8">
          <div class="">
            <h3 class="title" style="color: var(--white);" translate>{{'ldp_ques_ready'}}</h3>
          </div>

          <div class="why-us">
            <div class="row justify-content-sm-center">
              <div class="col-sm-10">
                <p style="color: var(--white);" translate>{{'ldp_idc_ready'}}</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row" style="padding-top:32px">
        <div class="col-sm-12">
          <div class="why-us">
            <a class="btn btn-secondary1" href="https://viettelidc.com.vn/Home/Contact" style="color: #e03;" target="_blank" translate>{{'ldp_presale'}}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <app-home-footer></app-home-footer>
</div>