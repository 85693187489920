import { Component, OnInit, Input, AfterViewInit, ElementRef, ViewChild } from '@angular/core';
import { CameraModel, RecordModel } from '../../_models/index';
declare var $: any;
declare var videojs: any;
declare var document: any;

@Component({
  selector: 'app-videojs',
  templateUrl: './videojs.component.html',
  styleUrls: ['./videojs.component.css']
})
export class VideojsComponent implements OnInit, AfterViewInit {
  @ViewChild('videoElement') public videoElement: ElementRef;
  @Input() record: RecordModel;
  @Input() camera: CameraModel;
  @Input() type: string;
  loaded: boolean = false;
  isInit: boolean = false;
  isHls: boolean = false;
  player: any;
  constructor() { }

  ngOnInit() {
  }
  ngAfterViewInit() {
    if (this.type === 'LIVE_VIEW') {
      this.initPlayer();
    }
  }

  initPlayer() {
    this.loaded = true;
    this.isInit = true;
    const autoplay = this.type === 'LIVE_VIEW' ? true : false;
    let src = this.type === 'LIVE_VIEW' ? this.camera.streamUrl : this.record.url;
    let l2 = src.length - 13;
    let srcLLHLS = src.substring(0, l2) + '_sfm4s_playlist.m3u8';
    let preSrc = src.substring(0, 4);
    let typeLive = 'application/x-mpegURL';
    let techOrder = this.type === 'LIVE_VIEW' ? 'flash' : 'html5';
    // let type = this.type === 'LIVE_VIEW' ? 'rtmp/mp4' : 'application/x-mpegURL';
    // let techOrder = this.type === 'LIVE_VIEW' ? 'flash' : 'html5';
    if (preSrc == 'http') {
      typeLive = 'application/x-mpegURL';
      techOrder = 'html5';
      this.isHls = true;
    }
    else {
      this.isHls = false;
    }
    const controls = this.type === 'LIVE_VIEW' ? false : true;
    // src='https://s3-north1.viettelidc.com.vn/1days/record/demoidc1711135801154/demoidc1711135801154_1711677045384_1711677946498.mp4?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20240329T031140Z&X-Amz-SignedHeaders=host&X-Amz-Expires=86399&X-Amz-Credential=4d4095afa68656f25b4a%2F20240329%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Signature=0479544f3e5e6cbf2f4a7cb3eb62c55479930bedc03972ddbc0904246eadd839';
    // if(src.includes('.mp4'))
    // typeLive='video/mp4';
    if (this.type === 'LIVE_VIEW') {
      this.player = videojs(`videojs-flash-player-${this.type === 'LIVE_VIEW' ? this.camera.id : this.record.id}`,
        {
          techOrder: [techOrder],
          autoplay: autoplay,
          controls: true,
          isAudio: true,
          controlBar: {
            timeDivider: false,
            durationDisplay: false,
            progressControl: false,
            currentTimeDisplay: false,
            remainingTimeDisplay: false,
            pictureInPictureToggle: false
          },
          preload: 'auto',
          fluid: true,
          sources: [{ src: src, type: typeLive }]
        });
    }
    else {
      this.player = videojs(`videojs-flash-player-${this.type === 'LIVE_VIEW' ? this.camera.id : this.record.id}`,
        {
          techOrder: [techOrder],
          autoplay: autoplay,
          controls: true,
          preload: 'auto',
          fluid: true,
          sources: [{ src: src, type: typeLive }]
        });
    }
    

    this.player.play();
  }
  close() {
    this.isInit = false;
    this.player.dispose();
  }
  takeSnapshot() {
    const canvasElement = <HTMLCanvasElement>document.createElement('CANVAS');
    const objVideo = document.getElementById(this.videoElement.nativeElement.id);
    const context = canvasElement.getContext('2d');
    canvasElement.width = 1280;
    canvasElement.height = 768;
    context.fillRect(0, 0, 1280, 768);
    context.drawImage(objVideo, 0, 0, 1280, 768);
    const link = document.createElement('a');
    link.setAttribute('download', 'Snapshot.png');
    const dataURL = canvasElement.toDataURL();
    link.href = dataURL;
    document.body.appendChild(link);
    link.click();
  }
  z: any = 1;
  zoomInVideo() {
    if (this.z < 3.0) {
      this.z += 0.1;
    }
    this.player.zoomrotate({
      rotate: 0,
      zoom: this.z
    }
    );
  }
  zoomOutVideo() {
    if (this.z > 0.3) {
      this.z -= 0.1;
    }
    this.player.zoomrotate({
      rotate: 0,
      zoom: this.z
    }
    );
  }



  forward = () => {
    this.skip(15);
  }

  backward = () => {
    this.skip(-15);
  }

  skip = (time) => {
    let currTime = this.player.currentTime();
    this.player.currentTime(currTime + time);
  }
}
