import * as _ from "lodash";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { CameraGroup } from "src/app/_models/ai-camera";
import { AiCameraEventService } from "src/app/_services/ai-camera-event.service";
import Swal from "sweetalert2";
import { Subject, forkJoin, of } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
declare var $: any;

@Component({
  selector: "app-config-alert",
  templateUrl: "./config-alert.component.html",
  styleUrls: ["./config-alert.component.css"],
})
export class ConfigAlertComponent implements OnInit {
  selectedScenario: string = "";
  scenarioOptions: { value: string; label: string }[] = [];

  searchTxt: string = "";
  searchTxt$ = new Subject<string>();
  keyword: string = "";

  loading: boolean = false;
  cameraGroups: CameraGroup[] = [];

  constructor(private _aiService: AiCameraEventService, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.searchTxt$.pipe(
      debounceTime(1000),
      distinctUntilChanged()
    ).subscribe((text) => {
      console.log(text);

      this.keyword = this.handleKeyWordBeforeSearch(text);
      this.getTreeViewCameras();
    })

    this.getScenarios();
  }

  onSearchChange(value: string): void {
    this.searchTxt$.next(value);
  }

  getScenarios() {
    this._aiService.getAllScenario().subscribe(
      (res) => {
        this.scenarioOptions = res.reduce((acc, cur) => {
          acc.push({ value: cur.code, label: cur.name });
          return acc;
        }, []);

        if (this.scenarioOptions.length > 0) {
          this.selectedScenario = this.scenarioOptions[this.scenarioOptions.length - 1].value;
          this.getTreeViewCameras();
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }

  getTreeViewCameras() {
    if (!this.selectedScenario) {
      return;
    }

    this._aiService.getTreeViewCameras(this.selectedScenario, this.keyword).subscribe(
      (res) => {
        const camerasInBunch: CameraGroup[] = _.get(res, 'cameraInBunch', []).map((group) => {
          return {
            ...group,
            cameras: _.get(group, 'cameras', []).map((camera) => {
              return {
                ...camera,
                _isActive: camera.isActiveNotification === 'ACTIVE',
              };
            }),
          };
        });

        const cameraNoBunch: CameraGroup = {
          bunchId: 0,
          bunchName: 'Chưa phân nhóm',
          cameras: _.get(res, 'cameraNoBunch', []).map((camera) => {
            return {
              ...camera,
              _isActive: camera.isActiveNotification === 'ACTIVE',
            };
          }),
        };

        this.cameraGroups = [...camerasInBunch, cameraNoBunch];
      },
      (err) => {
        console.log(err);
      }
    );
  }

  bunchClick(groupId: number) {
    let isView = $("#app_" + groupId).css("visibility");
    if (isView == "collapse") {
      $("#app_" + groupId).css("visibility", "visible");
    } else {
      $("#app_" + groupId).css("visibility", "collapse");
    }
  }

  onSelectAllGroupChange(event: any) {
    const isChecked = (<HTMLInputElement>event.target).checked;
    let lstInput = $("input[name='cb_RegisAI']");
    for (let i = 0; i < lstInput.length; i++) {
      $("." + lstInput[i].className).prop("checked", isChecked);
    }

    this.cameraGroups.forEach((group) => {
      group.cameras.forEach((camera) => {
        camera._isActive = isChecked;
      });
    });
  }

  changeScenario() {
    this.getTreeViewCameras();
  }

  onSave() {
    let lstAddId = [];
    let lstRemoveId = [];

    this.cameraGroups.forEach((group) => {
      group.cameras.forEach((camera) => {
        if (camera.isActiveNotification === 'ACTIVE' && !camera._isActive) {
          lstRemoveId.push(camera.cameraId);
        }

        if (camera.isActiveNotification === 'INACTIVE' && camera._isActive) {
          lstAddId.push(camera.cameraId);
        }
      });
    });

    const activateObservable = lstAddId.length > 0 ? this._aiService.activateAlertCamera(this.selectedScenario, lstAddId) : of(null)
    const deactivateObservable = lstRemoveId.length > 0 ? this._aiService.deactivateAlertCamera(this.selectedScenario, lstRemoveId) : of(null)

    if (activateObservable || deactivateObservable) {
      forkJoin([activateObservable, deactivateObservable]).subscribe(
        ([activateRes, deactivateRes]) => {
          if (activateRes || deactivateRes) {
            Swal.fire('Thông báo', 'Cập nhật camera thành công!', 'success');

            this.cameraGroups.forEach((group) => {
              group.cameras.forEach((camera) => {
                camera.isActiveNotification = camera._isActive ? 'ACTIVE' : 'INACTIVE';
              });
            });
          }
        },
        (err) => {
          console.log(err);

          Swal.fire('Lỗi', 'Xử lý camera thất bại!', 'error');
        }
      );
    }
  }


  onSelectGroupChange(event: any, groupId: any) {
    const isChecked = (<HTMLInputElement>event.target).checked;
    this.cameraGroups.forEach((group) => {
      if (group.bunchId === groupId) {
        group.cameras.forEach((camera) => {
          camera._isActive = isChecked;
        });
      }
    });
  }

  handleKeyWordBeforeSearch(str: string) {
    str = str.replace(/[àáạảãâầấậẩẫăằắặẳẵ]/g, "a");
    str = str.replace(/[èéẹẻẽêềếệểễ]/g, "e");
    str = str.replace(/[ìíịỉĩ]/g, "i");
    str = str.replace(/[òóọỏõôồốộổỗơờớợởỡ]/g, "o");
    str = str.replace(/[ùúụủũưừứựửữ]/g, "u");
    str = str.replace(/[ỳýỵỷỹ]/g, "y");
    str = str.replace(/đ/g, "d");
    str = str.replace(/[ÀÁẠẢÃÂẦẤẬẨẪĂẰẮẶẲẴ]/g, "A");
    str = str.replace(/[ÈÉẸẺẼÊỀẾỆỂỄ]/g, "E");
    str = str.replace(/[ÌÍỊỈĨ]/g, "I");
    str = str.replace(/[ÒÓỌỎÕÔỒỐỘỔỖƠỜỚỢỞỠ]/g, "O");
    str = str.replace(/[ÙÚỤỦŨƯỪỨỰỬỮ]/g, "U");
    str = str.replace(/[ỲÝỴỶỸ]/g, "Y");
    str = str.replace(/Đ/g, "D");
    // Some system encode vietnamese combining accent as individual utf-8 characters
    // Một vài bộ encode coi các dấu mũ, dấu chữ như một kí tự riêng biệt nên thêm hai dòng này
    str = str.replace(/[\u0300\u0301\u0303\u0309\u0323]/g, ""); // ̀ ́ ̃ ̉ ̣  huyền, sắc, ngã, hỏi, nặng
    str = str.replace(/[\u0306\u031B]/g, ""); //  ̆ ̛  , Ă, Ơ, Ư
    // no-misleading-character-class
    str = str.replace(/\u02C6/g, ""); // ˆ , Â, Ê
    // Remove extra spaces
    // Bỏ các khoảng trắng liền nhau
    str = str.replace(/ + /g, " ");
    str = str.trim();
    return str.toLowerCase();
  }
}
