import * as _ from 'lodash';
import { Component, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { AuthHepler } from '../../../../_helpers/auth.helper';
import { AuthenticationService } from '../../../../_services';
import { AiCameraEventService } from '../../../../_services/ai-camera-event.service';
import { SelectDate } from '../../../../_models/index';
import { IntrusionEventModel } from 'src/app/_models/ai-camera';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import Swal from 'sweetalert2';

declare var moment: any;
declare var $: any;

@Component({
  selector: 'app-intrusion-event',
  templateUrl: './intrusion-event.component.html',
  styleUrls: ['./intrusion-event.component.css'],
})
export class IntrusionEventComponent extends AuthHepler implements OnInit {
  loading: boolean = false;
  startDate: SelectDate = new SelectDate();
  endDate: SelectDate = new SelectDate();
  startTime: Date = new Date(moment().startOf('month'));
  endTime: Date = new Date();
  currDate: Date = new Date();

  searchTxt: string = '';
  searchTxt$ = new Subject<string>();
  keyword: string = '';

  currentPage: number = 1;
  pageSize: number = 10;
  pageSizes = [5, 10, 20, 50, 100, 1000];
  totalItems: number = 0;

  events: IntrusionEventModel[] = [];
  previewEvent: IntrusionEventModel;

  hours: string[] = Array.from({ length: 12 }, (_, i) =>
    i.toString().padStart(2, '0')
  );
  minutes: string[] = Array.from({ length: 60 }, (_, i) =>
    i.toString().padStart(2, '0')
  );

  constructor(
    private _aiService: AiCameraEventService,
    public _auService: AuthenticationService,
    public cookie: CookieService
  ) {
    super(_aiService, _auService, cookie);
  }

  ngOnInit(): void {
    this.searchTxt$.pipe(
      debounceTime(1000),
      distinctUntilChanged()
    ).subscribe((text) => {
      this.keyword = this.handleKeyWordBeforeSearch(text);
      this.getEvents();
    })

    if ($('.select-time').length) {
      $('.select-time').select2({
        minimumResultsForSearch: -1,
      });
    }

    if ($('#date-time-picker3').length) {
      $('#date-time-picker3')
        .dateRangePicker({
          inline: true,
          container: '#date-time-picker-container3',
          alwaysOpen: true,
        })
        .bind('datepicker-first-date-selected', function (event, obj) {
          if (obj.date1) {
            $('#select-date-first').val(moment(obj.date1).format('DD/MM/YYYY'));
          } else {
            $('#select-date-first').val(moment());
          }
        })
        .bind('datepicker-change', function (event, obj) {
          if (obj.date2) {
            $('#select-date-second').val(
              moment(obj.date2).format('DD/MM/YYYY')
            );
          } else {
            $('#select-date-second').val(
              moment(new Date()).startOf('day').format('DD/MM/YYYY')
            );
          }
        });
    }

    this.getEvents();
  }

  convertAMPM(_date: SelectDate) {
    if (_date.ampm == 'PM') {
      if (_date.hours < 12) _date.hours = Number(_date.hours) + 12;
      if (_date.hours == 12) _date.hours = Number(_date.hours) - 12;
    }
  }

  selectDate() {
    this.startDate.fulldate = $('#select-date-first').val();
    this.startDate.hours = $('#select-hours-first').val();
    this.startDate.minutes = $('#select-minutes-first').val();
    this.startDate.ampm = $('#select-ampm-first').val();
    this.convertAMPM(this.startDate);
    let fStartTime = this.startDate.fulldate + ' ' + this.startDate.hours + ':' + this.startDate.minutes + ':00';
    if (this.startDate.fulldate != '')
      this.startTime = new Date(moment(fStartTime, 'DD-MM-YYYY HH:mm:ss'));
    this.endDate.fulldate = $('#select-date-second').val();
    this.endDate.hours = $('#select-hours-second').val();
    this.endDate.minutes = $('#select-minutes-second').val();
    this.endDate.ampm = $('#select-ampm-second').val();
    this.convertAMPM(this.endDate);
    let fEndTime = this.endDate.fulldate + ' ' + this.endDate.hours + ':' + this.endDate.minutes + ':00';

    if (this.endDate.fulldate != '') {
      this.endTime = new Date(moment(fEndTime, 'DD-MM-YYYY HH:mm:ss'));
    }

    $("#selectDate").modal("hide");
  }

  onSearchChange(value: string): void {
    this.currentPage = 1;
    this.searchTxt$.next(value);
  }

  onPreview(event: IntrusionEventModel) {
    this.previewEvent = event;
  }

  handleKeyWordBeforeSearch(str: string) {
    str = str.replace(/[àáạảãâầấậẩẫăằắặẳẵ]/g, 'a');
    str = str.replace(/[èéẹẻẽêềếệểễ]/g, 'e');
    str = str.replace(/[ìíịỉĩ]/g, 'i');
    str = str.replace(/[òóọỏõôồốộổỗơờớợởỡ]/g, 'o');
    str = str.replace(/[ùúụủũưừứựửữ]/g, 'u');
    str = str.replace(/[ỳýỵỷỹ]/g, 'y');
    str = str.replace(/đ/g, 'd');
    str = str.replace(/[ÀÁẠẢÃÂẦẤẬẨẪĂẰẮẶẲẴ]/g, 'A');
    str = str.replace(/[ÈÉẸẺẼÊỀẾỆỂỄ]/g, 'E');
    str = str.replace(/[ÌÍỊỈĨ]/g, 'I');
    str = str.replace(/[ÒÓỌỎÕÔỒỐỘỔỖƠỜỚỢỞỠ]/g, 'O');
    str = str.replace(/[ÙÚỤỦŨƯỪỨỰỬỮ]/g, 'U');
    str = str.replace(/[ỲÝỴỶỸ]/g, 'Y');
    str = str.replace(/Đ/g, 'D');
    // Some system encode vietnamese combining accent as individual utf-8 characters
    // Một vài bộ encode coi các dấu mũ, dấu chữ như một kí tự riêng biệt nên thêm hai dòng này
    str = str.replace(/[\u0300\u0301\u0303\u0309\u0323]/g, ''); // ̀ ́ ̃ ̉ ̣  huyền, sắc, ngã, hỏi, nặng
    str = str.replace(/[\u0306\u031B]/g, ''); //  ̆ ̛  , Ă, Ơ, Ư
    // no-misleading-character-class
    str = str.replace(/\u02C6/g, ''); // ˆ , Â, Ê
    // Remove extra spaces
    // Bỏ các khoảng trắng liền nhau
    str = str.replace(/ + /g, ' ');
    str = str.trim();
    return str.toLowerCase();
  }

  displayTime(time: number) {
    return moment(Number(time) * 1000).format('DD/MM/YYYY hh:mm A');
  }

  getEvents() {
    this.selectDate();
    this.loading = true;
    this._aiService.getIntrusionEvents(21, this.currentPage - 1, this.pageSize, this.keyword, this.startTime.getTime(), this.endTime.getTime())
      .subscribe(
        (res) => {
          this.events = res.events;
          this.totalItems = res.totalPage * this.pageSize;
        },
        (err) => {
          Swal.fire('Đã có lỗi xảy ra', 'Đã có lỗi xảy ra!', 'error');
        },
        () => {
          this.loading = false;
        }
      );
  }

  onPageChange(event: any) {
    this.currentPage = event;
    this.getEvents();
  }

  pageSizeChange() {
    this.currentPage = 1;
    this.getEvents();
  }
}
