import { RoleModel } from './role.model';
import { NumericDictionary } from 'lodash';
export class AccountModel {
    id: number;
    username: string;
    password: string;
    fullName: string;
    email: string;
    phoneNumber: string;
    personalId:string;
    address: string;
    status: string;
    type:string;
    role:RoleModel;
    isActive: boolean;
    isView:boolean;
    twoFactor:string;
    secretKey:string;
    qrcode:string;
    customerType:string;
    codeContracts: string;
    passTwoFactor:string;
    requireResetPassword:string;
}
export class Transaction{
    amount: number;
    dateCreated: string;
    id:number;
    type:string;
    description:string;
}
export class UsernameCameraIdsModel{
username:string;
listCameraId: number[]=[];
}