<div class="selected-box">
    <div class="boxing" (click) = "activeListItems()" >
      <span class="hordle-item">{{optionSelected}}</span>
      <div class="icons-select"></div>
    </div>
      <div class="items" [style]="styleItems">
      <div  class="item"  >
        <a [id]="" (click)="parentSelected($event)">Bỏ qua</a>
      </div>
      <app-bunch-tree-item  (eventChild)="parentSelected($event)" (eventIsClick)="getIsClick($event)"  (eventSubChild)="getSubIdparent($event)" *ngFor="let bunch of listParentData"  [bunch]="bunch">

      </app-bunch-tree-item>

      <!-- <div class="item"  >
        <a [id]="bunch.id" (click)="parentSelected($event)">{{bunch.name}}</a> 
        <button class="icons-select-item" ></button>
    </div> -->
  </div>