import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { SimpleNotificationsModule } from 'angular2-notifications';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { RegisterComponent } from './account/register/register.component';
import { LoginComponent } from './login/login.component';
import { LiveviewComponent } from './liveview/liveview.component';
import { PlaybackComponent } from './playback/playback.component';
import { HomeComponent } from './home/home.component';
import { AuthGuard, LiveviewGuard } from './_guards/index';
import { AngularFireModule } from '@angular/fire';
import { AngularFireMessagingModule } from '@angular/fire/messaging';

import {
  AuthenticationService,
  BaseService,
  ApiUrlService,
  EncryptPasswordService,
  FormValidateService,
  requestOptionsProvider,
  CameraService,
  RecordService,
  UserService,
  AccountService,
  ExportService,
  MessagingService,
  FirebaseService,
  CopyPermissionService,
} from './_services/index';

import { TreeLiveviewComponent } from './treeview/tree-liveview/tree-liveview.component';
import { TreePlaybackComponent } from './treeview/tree-playback/tree-playback.component';
import { VideojsComponent } from './_shared/videojs/videojs.component';
import { InfoAccountComponent } from './account/info-account/info-account.component';
import { LandingContactComponent } from './home/landing-contact/landing-contact.component';
import { LandingpageNewComponent } from './home/landingpage-new/landingpage-new.component';
import { ForgotPasswordComponent } from './account/forgot-password/forgot-password.component';
import { BunchComponent } from './manage/bunch/bunch.component';
import { CameraComponent } from './manage/camera/camera.component';
import { AccountComponent } from './manage/account/account.component';
import { PaymentComponent } from './package/payment/payment.component';
import { GoicuocComponent } from './package/goicuoc/goicuoc.component';
import { MapComponent } from './manage/map/map.component';

import { environment } from 'src/environments/environment';
import { HomeHeaderComponent } from './home/home-header/home-header.component';
import { HomeFooterComponent } from './home/home-footer/home-footer.component';
import { SliderSolutionComponent } from './home/slider-solution/slider-solution.component';
import { SolutionStoreComponent } from './home/solution-store/solution-store.component';
import { SolutionGeneralComponent } from './home/solution-general/solution-general.component';
import { AllowDecimalNumberDirective } from './_directives/allow-decimal.directive';
import { RecaptchaModule } from 'ng-recaptcha';
import { ActiveAccountComponent } from './account/active-account/active-account.component';
import { ResetPasswordComponent } from './account/reset-password/reset-password.component';
import { BunchTreeItemComponent } from './manage/bunch/bunch-tree-item/bunch-tree-item.component';
import { MultivideojsComponent } from './_shared/multivideojs/multivideojs/multivideojs.component';
import { BunchtreeComponent } from './manage/bunch/bunchtree/bunchtree.component';
import { TreeViewGroupComponent } from './treeview/tree-liveview/tree-view-group/tree-view-group.component';
import { TreeViewItemComponent } from './treeview/tree-liveview/tree-view-item/tree-view-item.component';
import { TreePlayBackItemComponent } from './treeview/tree-playback/tree-play-back-item/tree-play-back-item.component';
import { TreePlayBackGroupComponent } from './treeview/tree-playback/tree-play-back-group/tree-play-back-group.component';
import { BunchTablesActionComponent } from './manage/bunch/bunch-tables-action/bunch-tables-action.component';
import { BunchTablesComponent } from './manage/camera/bunch-tables/bunch-tables.component';
import { BunchTablesActionGroupComponent } from './manage/bunch/bunch-tables-action-group/bunch-tables-action-group.component';
import { GoiCuocBoughtComponent } from './package/goicuoc/goi-cuoc-bought/goi-cuoc-bought.component';
import { GoiCuocNotBuyComponent } from './package/goicuoc/goi-cuoc-not-buy/goi-cuoc-not-buy.component';
import { BunchTablesGroupComponent } from './manage/camera/bunch-tables-group/bunch-tables-group.component';
import { ConfirmTwofactorComponent } from './login/two-factor/confirm-twofactor/confirm-twofactor.component';
import { WebrtcplayerComponent } from './_shared/webrtc/webrtcplayer/webrtcplayer.component';
import { DashboardComponent } from './manage/dashboard/dashboard.component';
import { StatisticCameraComponent } from './manage/dashboard/statistic-camera/statistic-camera.component';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CameraOffComponent } from './manage/camera/camera-off/camera-off.component';
//import {CrudTableModule} from './_shared/crud_table';
import { AicameraModule } from './manage/aicamera/aicamera.module';
import { SharedModule } from './_shared/shared.module';
import { PipeModule } from './_pipes/pipe.module';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgxPageScrollModule } from 'ngx-page-scroll';
import { SharePermissionComponent } from './manage/account/share-permission/share-permission.component';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    RegisterComponent,
    LoginComponent,
    LiveviewComponent,
    PlaybackComponent,
    HomeComponent,
    TreeLiveviewComponent,
    TreePlaybackComponent,
    VideojsComponent,
    InfoAccountComponent,
    LandingContactComponent,
    LandingpageNewComponent,
    ForgotPasswordComponent,
    BunchComponent,
    CameraComponent,
    AccountComponent,
    PaymentComponent,
    GoicuocComponent,
    MapComponent,
    HomeHeaderComponent,
    HomeFooterComponent,
    SliderSolutionComponent,
    SolutionStoreComponent,
    SolutionGeneralComponent,
    AllowDecimalNumberDirective,
    ActiveAccountComponent,
    ResetPasswordComponent,
    MultivideojsComponent,
    BunchtreeComponent,
    BunchTreeItemComponent,
    TreeViewGroupComponent,
    TreeViewItemComponent,
    TreePlayBackItemComponent,
    TreePlayBackGroupComponent,
    BunchTablesActionComponent,
    BunchTablesComponent,
    BunchTablesActionGroupComponent,
    GoiCuocBoughtComponent,
    GoiCuocNotBuyComponent,
    BunchTablesGroupComponent,
    ConfirmTwofactorComponent,
    WebrtcplayerComponent,
    DashboardComponent,
    StatisticCameraComponent,
    CameraOffComponent,
    SharePermissionComponent,
  ],
  imports: [
    BrowserModule,
    ReactiveFormsModule,
    FormsModule,
    NgxPaginationModule,
    NgxPageScrollModule,
    AppRoutingModule,
    HttpModule,
    RecaptchaModule,
    BrowserAnimationsModule,
    SimpleNotificationsModule.forRoot({ position: ['bottom', 'right'], showProgressBar: false, timeOut: 5000, }),
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireMessagingModule,
    SharedModule,
    PipeModule,
    AicameraModule,
  ],
  providers: [
    AccountService,
    ExportService,
    AuthenticationService,
    requestOptionsProvider,
    BaseService,
    CameraService,
    RecordService,
    UserService,
    ApiUrlService,
    EncryptPasswordService,
    FormValidateService,
    MessagingService,
    FirebaseService,
    CopyPermissionService,
    AuthGuard,
    LiveviewGuard,
    AppComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
