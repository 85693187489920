<router-outlet (activate)="onActivate($event)"></router-outlet>

<simple-notifications></simple-notifications>

<ng-template #alertRef let-text="text" let-title="title" let-body="body" let-data="data">
  <div class="alert-container" role="alert" (click)="onNotificationClick(data)">
    <i class="icon icon-alert-bell-warn"></i>
    <div class="alert-content">
      <div class="alert-title">{{title}}</div>
      <div class="alert-body">{{body}}</div>
    </div>
  </div>
</ng-template>
