<div class="page-container page-login">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="card login-block">
            <div>
              <a href="javascript:;" routerLink="/home">
                <img  class="logo-login" src="assets/logo camera.svg" alt="Cloud Camera" />
              </a>
            </div>
            <h2 class="text-login" translate>{{'li_two_factor'}}</h2>
            <form [formGroup]="twoFactorForm" (ngSubmit)="twoFactorForm.valid && confirm()" class="form">
              <div class="form-group mb-0 form-group-password">
                <input id="form-twofactor" class="form-control" placeholder="{{'li_secure_code'|translate}}" appAutoFocus
                  [(ngModel)]="otpCode" formControlName="otpName" />
                <div *ngIf="twoFactorForm.controls.otpName.invalid && twoFactorForm.controls.otpName.touched"
                  class="alert alert-danger">
                  {{_formValidateService.twoFactorValidate.otpCodeValidate.message(twoFactorForm.controls.otpName)}}.</div>
              </div>
              <div>
                <button class="text-center w-100 btn btn-primary btn-login" type="submit" [disabled]="twoFactorForm.invalid">{{'li_CONFIRM'|translate}}</button>
              </div>
            </form>
            <div class="forgot-password-link">
                <svg class="octicon octicon-device-mobile" viewBox="0 0 10 16" version="1.1" width="10" height="16" aria-hidden="true"><path fill-rule="evenodd" d="M9 0H1C.45 0 0 .45 0 1v14c0 .55.45 1 1 1h8c.55 0 1-.45 1-1V1c0-.55-.45-1-1-1zM5 15.3c-.72 0-1.3-.58-1.3-1.3 0-.72.58-1.3 1.3-1.3.72 0 1.3.58 1.3 1.3 0 .72-.58 1.3-1.3 1.3zM9 12H1V2h8v10z"></path></svg>
                {{'li_cf_content'|translate}}<b>{{'li_cf_content1'|translate}}</b>{{'li_cf_content2'|translate}}
            </div>
            <div class="text-center" style="font-style: italic; cursor: pointer; font-size: 14px;" (click)="logWithOther()" translate>{{'login_other'|translate}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Modal Change Password -->
  <div class="modal fade" id="changePasswordModal" tabindex="-1" role="dialog" aria-labelledby="changePasswordTitle"
      aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered account-modal" role="document">
          <div class="modal-content">
              <div class="modal-header bg-primary">
                  <h5 class="modal-title" id="changePasswordTitle">THAY ĐỔI MẬT KHẨU</h5>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                      <i class="icon icon-close"></i>
                  </button>
              </div>
              <div style="font-style: italic;
              padding: 1.5rem 1.5rem 0 1.5rem;
              font-size: smaller;">Tài khoản của bạn vừa reset mật khẩu. Vui lòng thay đổi mật khẩu để tiếp tục sử dụng dịch vụ Viettel Cloud Camera</div>
              <div class="modal-body">
                  <form [formGroup]="changePasswordForm" (ngSubmit)="changePasswordForm.valid && updatePassword()">
                      <div class="form-group">
                          <label class="control-label" for="current-password">{{'li_curr_pass'|translate}} (<span
                                  class="text-danger">*</span>)</label>
                          <input autocomplete="off" [(ngModel)]="oldPassword" class="form-control" type="password"
                              id="current-password" placeholder="{{'li_enter_info'|translate}}" formControlName="oldPassword" required>
                          <div *ngIf="changePasswordForm.controls.oldPassword.invalid &&changePasswordForm.controls.oldPassword.touched"
                              class="alert alert-danger">
                              {{_formValidateService.customerFormValidate.oldPasswordValidate.message(changePasswordForm.controls.oldPassword)}}.
                          </div>
                      </div>
                      <div formGroupName="matchingPassword">
                          <div class="form-group">
                              <label class="control-label" for="new-password">{{'li_new_pass'|translate}} (<span
                                      class="text-danger">*</span>)</label>
                              <input [(ngModel)]="password" class="form-control" type="password" autocomplete="off"
                                  formControlName="password" id="new-password" placeholder="{{'li_enter_info'|translate}}" required>
                              <div *ngIf="changePasswordForm.controls.matchingPassword.get('password').invalid &&changePasswordForm.controls.matchingPassword.get('password').touched"
                                  class="alert alert-danger">
                                  {{_formValidateService.customerFormValidate.passwordValidate.message(changePasswordForm.controls.matchingPassword.get('password'))}}.
                              </div>
                          </div>
                          <div class="form-group">
                              <label class="control-label" for="confirm-password">{{'li_re_pass'|translate}} (<span
                                      class="text-danger">*</span>)</label>
                              <input class="form-control" type="password" formControlName="passwordConfirm"
                                  autocomplete="off" id="confirm-password" placeholder="{{'li_enter_info'|translate}}" required />
                              <div *ngIf="changePasswordForm.controls.matchingPassword.invalid && changePasswordForm.controls.matchingPassword.get('passwordConfirm').touched"
                                  class="alert alert-danger">
                                  {{_formValidateService.customerFormValidate.matchingPassword.message(changePasswordForm.controls.matchingPassword)}}.
                              </div>
                          </div>
                      </div>
                      <!-- <re-captcha (resolved)="resolved($event)" siteKey="6LdKA6kZAAAAANmMBOx0njeanv_bOj-RmB8uvK1a"></re-captcha> -->
                      <div class="form-action">
                          <button class="btn btn-outline-secondary mr-3" type="button" data-dismiss="modal">{{'li_cancel'|translate}}</button>
                          <button class="btn btn-primary" [disabled]="changePasswordForm.invalid " type="submit">{{'li_confirm'|translate}}</button>
                          <!-- <button class="btn btn-primary" [disabled]="changePasswordForm.invalid || !isConfirmCaptcha" type="submit">Xác nhận</button> -->
                      </div>
                  </form>
              </div>
          </div>
      </div>
  </div>