import { Component, Input } from '@angular/core';
import {  TimeRepeatModel } from 'src/app/_models/ai-camera';

@Component({
  selector: 'weekly-time-range',
  templateUrl: './weekly-time-range.component.html',
  styleUrls: ['./weekly-time-range.component.css'],
})
export class WeeklyTimeRangeComponent {
  @Input() title: string;
  @Input() repeats: TimeRepeatModel[] = [];
  @Input() dayOptions: { id: string, name: string }[] = []

  constructor() { }

  addRepeatSelection() {
    this.repeats.push({
      firstTime: "00:00:00",
      lastTime: "23:59:59",
      selected: ["mon", "tue", "wed", "thur", "fri"],
    });
  }

  removeRepeatSelection(index: number) {
    this.repeats.splice(index, 1);
  }

  onSelectionChange(selected: string[], index: number) {
    this.repeats[index].selected = selected;
  }
}
