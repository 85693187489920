
<div class="d-flex flex-column common-gap">
  <div *ngFor="let item of repeats; let i = index" class="d-flex common-gap align-items-center">
    <label class="fw-bold">Bắt đầu:</label>
    <input type="time" step="1" [(ngModel)]="item.firstTime" class="time-input" style="width: 140px;" />
    <label class="fw-bold">Kết thúc:</label>
    <input type="time" step="1" [(ngModel)]="item.lastTime" class="time-input" style="width: 140px;" />
    <label class="fw-bold">Lặp lại:</label>
    <multiple-select [selectedValues]="item.selected" (selectedChange)="onSelectionChange($event, i)">
      <ng-container *ngFor="let day of dayOptions">
        <option [value]="day.id">{{day.text}}</option>
      </ng-container>
    </multiple-select>
    <button type="button" class="btn btn-danger" style="padding: 2.5px 8px; border-radius: 4px;" (click)="removeRepeatSelection(i)">Xoá</button>
  </div>
</div>

<button type="button" class="btn btn-primary mt-3" (click)="addRepeatSelection()">Thêm khoảng thời gian</button>
