<header>
  <div class="header">
    <div><a href="/home">
        <img class="logo" src="assets/logo camera.svg" alt="Cloud Camera" />

      </a></div>
  </div>
</header>

<body style="background: #F5F5F5;">
  <div class="container">
    <div class="row">
      <div class="col-md-2"></div>
      <div class="col-md-6">
        <div class="border-form">

          <div class="register-round">
            <div class="title-login">{{'ldp_register'|translate}}</div>

            <form class="form" [formGroup]="registerForm" (ngSubmit)="registerForm.valid && register()">

              <div class="input-form">
                <input [(ngModel)]="registerAccount.username" formControlName="username" class="input-text"
                  placeholder="{{'li_username'|translate}}" />
                <div *ngIf="registerForm.controls.username.invalid && registerForm.controls.username.touched"
                  class="alert alert-danger">
                  {{_registerService.RegisterValidate.usernameValidate.message(registerForm.controls.username)}}.
                </div>
                </div>
              <div class="input-form">
                <input class="input-text" placeholder="{{'li_full_name'|translate}} (*)" [(ngModel)]="registerAccount.fullName"
                  formControlName="fullname" />
                <div *ngIf="registerForm.controls.fullname.invalid && registerForm.controls.fullname.touched"
                  class="alert alert-danger">
                  {{_registerService.RegisterValidate.fullNameValidate.message(registerForm.controls.fullname)}}.
                </div>
              </div>

              <div class="input-form">
                <input class="input-text" placeholder="Email (*)" [(ngModel)]="registerAccount.email"
                  formControlName="email" />
                <div *ngIf="registerForm.controls.email.invalid && registerForm.controls.email.touched"
                  class="alert alert-danger">
                  {{_registerService.RegisterValidate.emailValidate.message(registerForm.controls.email)}}.
                </div>
              </div>

              <div formGroupName="matchingPassword">
                <div class="form-group form-group-password position-relative">
                  <input class="input-text" autocomplete="off" placeholder="{{'li_password'|translate}}" [(ngModel)]="password" style="margin-bottom: 20px;"
                    id="password" type="password" formControlName="password" />
                  <i class="icon icon-eye open" data-for="password"></i>
                  <div
                    *ngIf="registerForm.controls.matchingPassword.get('password').invalid && registerForm.controls.matchingPassword.get('password').touched"
                    class="alert alert-danger">
                    {{_registerService.RegisterValidate.passwordValidate.message(registerForm.controls.matchingPassword.get('password'))}}.
                  </div>
                </div>
                <div class="form-group form-group-password position-relative">
                  <input class="input-text" autocomplete="off" type="password" placeholder="{{'li_reconfirm_pass'|translate}}" style="margin-bottom: 20px;"
                    id="retype-password" formControlName="confirmpass" />
                  <i class="icon icon-eye open" data-for="retype-password"></i>
                  <div
                    *ngIf="registerForm.controls.matchingPassword.invalid && registerForm.controls.matchingPassword.get('confirmpass').touched"
                    class="alert alert-danger">
                    {{_registerService.RegisterValidate.matchingPassword.message(registerForm.controls.matchingPassword)}}.
                  </div>
                </div>
              </div>
              <div class="input-form">
                <input class="input-text" placeholder="{{'li_phone_register'|translate}}"
                  [(ngModel)]="registerAccount.phoneNumber" formControlName="phone" />
              </div>
              <div class="input-form">
                <input class="input-text" placeholder="{{'li_address_register'|translate}}" [(ngModel)]="registerAccount.address"
                  formControlName="address" />
              </div>
              <div class="form-group" style="margin-left: 50px; margin-bottom: 1rem;">
                <re-captcha (resolved)="resolved($event)"
                  siteKey="6LdKA6kZAAAAANmMBOx0njeanv_bOj-RmB8uvK1a"></re-captcha>
              </div>
              <button class="text-center w-100 btn btn-primary btn-login" type="submit"
                [disabled]="registerForm.invalid || !isConfirmCaptcha">{{'li_REGISTER'|translate}}</button>
            </form>

          </div>
        </div>
      </div>
      <div class="col-md-2"></div>
    </div>
  </div>
</body>

<footer>
  <div class="footer">
    <p translate>{{'li_copyright'}}<span class="text-red" translate>{{'li_phone'}}</span> {{'li_copyright1'}}
      <span class="text-red" translate>{{'li_email'}}</span>
    </p>
  </div>
</footer>