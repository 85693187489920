<div class="container-fluid p-0">
    <app-header></app-header>
    <div class="page-container page-user">
        <div class="container">
            <div class="page-action">
                <div class="row">
                    <div class="col-12 col-sm-6">
                        <div class="row">
                            <div class="col-12 col-sm-6">
                                <div class="search-form user-search">
                                    <label for="search-user" class="sr-only">{{'ad_search'|translate}}</label>
                                    <input type="text" class="form-control border-control" id="search-user"
                                        placeholder="{{'ad_search'|translate}}" [(ngModel)]="term">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-sm-6">
                        <div class="camera-buttons">
                            <button class="btn btn-primary btn-icon-text add-user" (click)="ShowModal()">
                                <i class="icon icon-plus"></i> <span>{{'ad_add_user'|translate}}</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="page-table">
                <div class="row no-gutters">
                    <div class="col-12 col-sm-6">
                        <div class="camera-result">
                            {{'li_have'|translate}} <span class="count">{{listAccount?.length}}</span>
                            {{'ad_user'|translate}}
                        </div>
                    </div>
                    <div class="col-12 col-sm-6 text-right">
                        <select (change)="pageSize = $event.target.value" class="d-inline-block border-control"
                            style="padding: 0.375rem 1rem;">
                            <option value="5">{{'ad_view'|translate}} 5</option>
                            <option value="10" selected>{{'ad_view'|translate}} 10</option>
                            <option value="20">{{'ad_view'|translate}} 20</option>
                            <option value="50">{{'ad_view'|translate}} 50</option>
                            <option value="100">{{'ad_view'|translate}} 100</option>
                            <option value="1000">{{'ad_view'|translate}} 1000</option>
                        </select>
                    </div>
                </div>
                <div class="table-responsive">
                    <table class="table table-striped user-table">
                        <thead>
                            <tr class="header-table" style="padding:15px 0px 15px 0px; text-align: center;">
                                <th scope="col">{{'ad_stt'|translate}}</th>
                                <th scope="col">{{'li_full_name'|translate}}</th>
                                <th scope="col">Email</th>
                                <th scope="col">{{'li_phone_number'|translate}}</th>
                                <th scope="col">{{'ad_role'|translate}}</th>
                                <th scope="col">{{'ad_status'|translate}}</th>
                                <th scope="col">{{'ad_action'|translate}}</th>
                            </tr>
                        </thead>
                        <tbody *ngIf="!loaded">
                            <tr>
                                <td colspan="7" class="text-center">
                                    <i class="fa fa-spinner fa-pulse"></i>
                                    <span>{{'ad_loading'|translate}}</span>
                                </td>
                            </tr>
                        </tbody>
                        <tbody *ngIf="listAccount">
                            <tr
                                *ngFor="let _account of listAccount|search:{label:['fullName','username','email','phoneNumber'],keyword:term||''}|orderBy : order||'fullName'| paginate: { itemsPerPage: pageSize || 10, currentPage: currentPage, totalItems: listAccount.length }; let i=index;">
                                <td>{{ pageSize*(currentPage-1)+i+1 || i+1 }}</td>
                                <!-- <td>{{_account.username}}</td> -->
                                <td>{{_account.fullName}}</td>
                                <td>{{_account.email}}</td>
                                <td>{{_account.phoneNumber}}</td>
                                <td>{{_account?.role?.name}}</td>
                                <td>
                                    <div *ngIf="_account.status == 'LOCK'">
                                        <span class="user-status locked"></span> {{'ad_lock'|translate}}
                                    </div>
                                    <div *ngIf="_account.status == 'ACTIVE'">
                                        <span class="user-status active"></span> {{'ad_active'|translate}}
                                    </div>
                                    <div *ngIf="_account.status == 'PENDING'">
                                        <span class="user-status inactive"></span>
                                        {{'ad_not_active'|translate}}
                                    </div>
                                </td>
                                <td>
                                    <ul class="action-list" *ngIf="_account.status!== 'PENDING'">
                                        <li class="user-update" title="{{'ad_edit'|translate}}">
                                            <a (click)="ShowEditAccount(_account)" href="javascript:;">
                                                <i class="icon icon-edit"></i>
                                            </a>
                                        </li>
                                        <li class="user-config" title="{{'ad_share_permiss'|translate}}">
                                            <!-- <a href="javascript:;" (click)="ShowUserConfig(_account)"> -->
                                            <a href="javascript:;" [routerLink]="['/share-permission']"
                                                [queryParams]="{username: _account.username, roleId: _account?.role?.id}" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
                                                <i class="icon icon-setting-2"></i>
                                            </a>
                                        </li>
                                    </ul>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="text-center">
                    <nav class="nav-pagination">
                        <pagination-template #p="paginationApi" (pageChange)="currentPage = $event;">
                            <ul *ngIf="pageSize<=p.getTotalItems()" class="pagination">
                                <li class="page-item" [class.disabled]="p.isFirstPage()">
                                    <a *ngIf="!p.isFirstPage()" class="page-link" (click)="p.previous()">
                                        <i class="icon icon-arrow-left"></i></a>
                                </li>

                                <li class="page-item" [class.active]="p.getCurrent()===page.value"
                                    *ngFor="let page of p.pages">
                                    <a class="page-link" (click)="p.setCurrent(page.value)">{{page.label}}</a>
                                </li>

                                <li class="page-item" [class.disabled]="p.isLastPage()">
                                    <a *ngIf="!p.isLastPage()" class="page-link" (click)="p.next()">
                                        <i class="icon icon-arrow-right"></i></a>
                                </li>
                            </ul>
                        </pagination-template>
                    </nav>
                </div>
            </div>


        </div>
    </div>
    <footer class="idc-footer">
        <p class="copyright">{{'ldp_copyright'|translate}}</p>
    </footer>
</div>

<!-- Modal -->
<div class="modal fade" id="addUser" tabindex="-1" role="dialog" aria-labelledby="addUserTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered user-modal" role="document">
        <div class="modal-content">
            <div class="modal-header bg-primary">
                <h5 class="modal-title" id="addUserTitle">{{'ad_add_user'|translate}}</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <i class="icon icon-close"></i>
                </button>
            </div>
            <div class="modal-body">
                <form class="form" [formGroup]="userForm" (ngSubmit)="userForm.valid && CreateUser()">
                    <div class="row">
                        <div class="col-12 col-sm-6">
                            <div class="form-group">
                                <label class="control-label" for="username">{{'li_user_name'|translate}}
                                    <span class="required">(<span class="asterisk">*</span>)</span>
                                </label>
                                <input type="text" class="form-control" id="username" placeholder="VD: nguyenvana"
                                    [(ngModel)]="account.username" formControlName="username">
                                <div *ngIf="userForm.controls.username.invalid && userForm.controls.username.touched"
                                    class="alert alert-danger">
                                    {{_accValidateService.customerFormValidate.usernameValidate.message(userForm.controls.username)}}
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-sm-6">
                            <div class="form-group">
                                <label class="control-label" for="phone">{{'li_phone_number'|translate}}</label>
                                <input type="text" class="form-control" id="phone" placeholder="VD: 0988888888"
                                    [(ngModel)]="account.phoneNumber" formControlName="phoneNumber">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 col-sm-6">
                            <div class="form-group">
                                <label class="control-label" for="password">{{'ad_pass'|translate}}
                                    <span class="required">(<span class="asterisk">*</span>)</span>
                                </label>
                                <input type="password" class="form-control" id="password" autocomplete="off"
                                    placeholder="> 8 ký tự, gồm: ký tự số,chữ thường, chữ in hoa" required
                                    [(ngModel)]="password" formControlName="password">
                                <div *ngIf="userForm.controls.password.invalid && userForm.controls.password.touched"
                                    class="alert alert-danger">
                                    {{_accValidateService.customerFormValidate.passwordValidate.message(userForm.controls.password)}}
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-sm-6">
                            <div class="form-group">
                                <label class="control-label"
                                    for="identification-card">{{'ad_cmnd'|translate}}</label>
                                <input type="text" class="form-control" id="identification-card"
                                    placeholder="{{'li_enter_info'|translate}}" [(ngModel)]="account.personalId"
                                    formControlName="personalId">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 col-sm-6">
                            <div class="form-group">
                                <label class="control-label" for="full-name">{{'li_full_name'|translate}}
                                    <span class="required">(<span class="asterisk">*</span>)</span>
                                </label>
                                <input type="text" class="form-control" id="full-name" placeholder="VD: Nguyễn Văn A"
                                    required [(ngModel)]="account.fullName" formControlName="fullName">
                                <div *ngIf="userForm.controls.fullName.invalid && userForm.controls.fullName.touched"
                                    class="alert alert-danger">
                                    {{_accValidateService.customerFormValidate.fullNameValidate.message(userForm.controls.fullName)}}
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-sm-6">
                            <div class="form-group">
                                <label class="control-label" for="address">{{'li_address'|translate}}</label>
                                <input type="text" class="form-control" id="address" placeholder="Điền địa chỉ chi tiết"
                                    [(ngModel)]="account.address" formControlName="address">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 col-sm-6">
                            <div class="form-group mb-0">
                                <label class="control-label" for="email">Email
                                    <span class="required">(<span class="asterisk">*</span>)</span>
                                </label>
                                <input type="text" class="form-control" id="email" [(ngModel)]="account.email"
                                    formControlName="email" placeholder="VD: nguyenvana@gmail.com" required>
                                <div *ngIf="userForm.controls.email.invalid && userForm.controls.email.touched"
                                    class="alert alert-danger">
                                    {{_accValidateService.customerFormValidate.emailValidate.message(userForm.controls.email)}}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-action">
                        <button class="btn btn-outline-secondary mr-3" type="button"
                            data-dismiss="modal">{{'li_cancel'|translate}}</button>
                        <button class="btn btn-primary" type="submit"
                            [disabled]="userForm.invalid">{{'ad_add'|translate}}</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
<!--Modal phân quyền user-->
<div class="modal fade" id="configUser" tabindex="-1" role="dialog" aria-labelledby="configUserTitle"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered user-modal user-role-modal" role="document">
        <div class="modal-content">
            <div class="modal-header bg-primary">
                <h5 class="modal-title" id="configUserTitle">{{'ad_share_acc_permiss'|translate}} <span
                        class="user-name">{{account.fullName}}</span> -
                    <span class="user-email">{{account.email}}</span>
                </h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <i class="icon icon-close"></i>
                </button>
            </div>
            <div class="modal-body">
                <form>
                    <div class="form-group">
                        <label class="control-label">{{'ad_choose_permiss'|translate}}</label>
                        <div class="form-radio-group">
                            <div class="form-radio mr-5">
                                <input class="magic-radio" type="radio" name="role" id="role-1"
                                    [(ngModel)]="account.isView" [value]="true" />
                                <label for="role-1">{{'ad_view_cam'|translate}}</label>
                            </div>
                            <div class="form-radio">
                                <input class="magic-radio" type="radio" name="role" id="role-2"
                                    [(ngModel)]="account.isView" [value]="false" />
                                <label for="role-2">{{'ad_manage_cam'|translate}}</label>
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label class="control-label">{{'ad_cam_for_group'|translate}}</label>

                        <div id="select-camera-group1" class="transfer-camera"></div>
                    </div>
                    <div class="form-action">
                        <button class="btn btn-outline-secondary mr-3" type="button"
                            data-dismiss="modal">{{'li_cancel'|translate}}</button>
                        <button class="btn btn-primary"
                            (click)="SaveUserConFig()">{{'li_confirm'|translate}}</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="updateUser" tabindex="-1" role="dialog" aria-labelledby="updateUserTitle"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered user-modal" role="document">
        <div class="modal-content">
            <div class="modal-header bg-primary">
                <h5 class="modal-title" id="updateUserTitle">{{'ad_change_user'|translate}}</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <i class="icon icon-close"></i>
                </button>
            </div>
            <div class="modal-body">
                <form class="form" [formGroup]="updateForm" (ngSubmit)="updateForm.valid && UpdateUser()">
                    <div class="row">
                        <div class="col-12 col-sm-6">
                            <div class="form-group mr-3">
                                <label class="control-label" for="username">{{'li_user_name'|translate}}
                                    <span class="required">(<span class="asterisk">*</span>)</span>
                                </label>
                                <input type="text" class="form-control" [(ngModel)]="account.username"
                                    formControlName="username" disabled>
                            </div>
                        </div>
                        <div class="col-12 col-sm-6">
                            <div class="form-group">
                                <label class="control-label" for="phone">{{'li_phone_number'|translate}}</label>
                                <input type="text" class="form-control" id="phone" placeholder="VD: 0988888888"
                                    value="0988888888" [(ngModel)]="account.phoneNumber" formControlName="phoneNumber">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 col-sm-6">
                            <div class="form-group mr-3">
                                <label class="control-label" for="full-name">{{'li_full_name'|translate}}
                                    <span class="required">(<span class="asterisk">*</span>)</span>
                                </label>
                                <input type="text" class="form-control" [(ngModel)]="account.fullName"
                                    formControlName="fullName">
                                <div *ngIf="updateForm.controls.fullName.invalid && updateForm.controls.fullName.touched"
                                    class="alert alert-danger">
                                    {{_accValidateService.customerFormValidate.fullNameValidate.message(updateForm.controls.fullName)}}
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-sm-6">
                            <div class="form-group">
                                <label class="control-label"
                                    for="identification-card">{{'ad_cmnd'|translate}}</label>
                                <input type="text" class="form-control" id="identification-card"
                                    [(ngModel)]="account.personalId" placeholder="{{'li_enter_info'|translate}}"
                                    value="14050105010" formControlName="personalId">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 col-sm-6">
                            <div class="form-group mb-0 mr-3">
                                <label class="control-label" for="email">Email
                                    <span class="required">(<span class="asterisk">*</span>)</span>
                                </label>
                                <input type="text" class="form-control" [(ngModel)]="account.email"
                                    formControlName="email" disabled>
                            </div>
                        </div>
                        <div class="col-12 col-sm-6">
                            <div class="form-group">
                                <label class="control-label" for="address">{{'li_address'|translate}}</label>
                                <input type="text" class="form-control" id="address"
                                    placeholder="{{'li_enter_info'|translate}}"
                                    value="231c Đội Cấn, Ba Đình, Hà Nội" [(ngModel)]="account.address"
                                    formControlName="address">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 col-sm-6">
                            <div class="form-group mb-0 mr-3">
                                <label class="control-label" for="email">{{'ad_status'|translate}}</label>
                                <div class="user-switch-status">
                                    <input type="checkbox" id="switch-stt" checked [(ngModel)]="account.isActive"
                                        formControlName="isActive" /><label for="switch-stt"></label><span></span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-action">
                        <button class="btn btn-outline-secondary mr-3" type="button"
                            data-dismiss="modal">{{'li_cancel'|translate}}</button>
                        <button class="btn btn-primary" type="submit"
                            [disabled]="updateForm.invalid">{{'li_confirm'|translate}}</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>