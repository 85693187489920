<div class="container-fluid p-0">
  <app-header></app-header>
  <div class="page-container page-camera">
    <div class="container">
      <div class="page-action">
        <div class="row">
          <div class="col-12 col-sm-5">
            <div class="row">
              <div class="col-12 col-md-5">
                <div class="search-form camera-search">
                  <input type="text" class="form-control border-control" id="search-camera"
                    placeholder="{{'ad_search'|translate}}" (keyup)="getSearching($event)" [(ngModel)]="txtSearch">
                </div>
              </div>
              <div class="col-12 col-md-5">
                <div class="filter-dropdown camera-filter">
                  <div class="btn-group dropdown">
                    <button class="btn dropdown-toggle border-control" type="button" data-toggle="dropdown"
                      aria-expanded="false">
                      <span class="title" style="font-size: unset;">{{titleSelect}}</span>
                    </button>
                    <ul class="dropdown-menu" role="menu">
                      <li *ngFor="let status of listStatus"><a href="javascript:;"
                          (click)="getFilter(status)">{{status.value}}</a></li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-1">
              </div>
            </div>
          </div>
          <div class="col-12 col-sm-7">
            <div class="camera-buttons">
              <button class="btn btn-primary btn-icon-text add-camera" routerLink="/camera-off">
                <span>{{'cam_history_cloudcam'|translate}}</span>
              </button>
              <button class="btn btn-primary btn-icon-text add-camera" (click)="ShowModal('addCamera', null)">
                <i class="icon icon-plus"></i> <span>{{'ad_add_third_cam'|translate}}</span>
              </button>
              <button class="btn btn-primary btn-icon-text add-viettel-camera"
                (click)="ShowModal('addViettelCamera', null)">
                <i class="icon icon-plus"></i> <span>{{'ad_add_viettel_cam'|translate}}</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="page-table">
        <div class="row no-gutters">
          <div class="col-12 col-sm-6">
            <div class="camera-result">
              {{'li_have'|translate}} <span class="count">{{listCamera?.length}}</span>
              {{'ad_cam_inlist'|translate}}
            </div>
          </div>
          <div class="col-12 col-sm-6 text-right">
            <select (change)="getPageSize($event)" [disabled]="disableChoosePageSize" class="border-control"
              [(ngModel)]="pageSize" style="padding: 0.375rem 1rem;">
              <option *ngFor="let size of pageSizes" [ngValue]="size">
                {{'ad_view'|translate}} {{ size }}
              </option>
            </select>
          </div>
        </div>
        <div class="container">
          <app-bunch-tables-group [disableClickBunch]="disableClickBunch" [reload]="reloadTable"
            [listBunch]="listBunchPerPage" (idRemove)="getRemoveId($event)" (idEditBunch)="getEditModal($event)"
            (cam)="getShowModal($event)"></app-bunch-tables-group>
        </div>
        <div class="text-center">
          <nav class="nav-pagination">
            <app-pagination [length]="lengthPagination" [limit]="pageSize"
              (clickItem)="onChangePage($event)"></app-pagination>
          </nav>
        </div>
      </div>
    </div>
  </div>
  <footer class="idc-footer">
    <p class="copyright">{{'ldp_copyright'|translate}}</p>
  </footer>
</div>

<!-- Modal thêm camera ngoài-->
<div class="modal fade" id="addCamera" tabindex="-1" role="dialog" aria-labelledby="addCameraTitle" aria-hidden="true">
  <input id="ipCamera" value="{{camera.id}}" hidden />
  <div class="modal-dialog modal-dialog-centered camera-modal" role="document">
    <div class="modal-content">
      <div class="modal-header bg-primary">
        <h5 class="modal-title" id="addCameraTitle">{{title}}</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <i class="icon icon-close"></i>
        </button>
      </div>
      <div class="modal-body">
        <form class="form" [formGroup]="addCamForm" (ngSubmit)="addCamForm.valid && AddThirdCam()">
          <div class="form-group">
            <label class="control-label" for="camera-name">{{'ad_camera_name'|translate}}</label>
            <input type="text" class="form-control" id="cam-name" placeholder="{{'li_enter_info'|translate}}"
              formControlName="name" [(ngModel)]="camera.name">
            <div *ngIf="addCamForm.controls.name.invalid && addCamForm.controls.name.touched"
              class="alert alert-danger">
              {{_camValidateService.CameraValidate.nameValidate.message(addCamForm.controls.name)}}
            </div>
          </div>
          <div class="form-group">
            <label class="control-label" for="camera-model">Model</label>
            <div class="w-100">
              <select class="custom-select" id="camera-model">
                <option *ngFor="let type of listTypeCam; let i = index" [value]="type.id" [selected]>{{type.name}}
                </option>
              </select>
            </div>
          </div>
          <div class="form-group">
            <label class="control-label" for="url-stream">URL Stream</label>
            <div class="row form-row align-items-center">
              <div class="col-12">
                <input type="text" class="form-control" id="url-stream" placeholder="rtmp://…"
                  formControlName="streamUrl" [(ngModel)]="camera.streamUrl" />
              </div>
              <div *ngIf="addCamForm.controls.streamUrl.invalid && addCamForm.controls.streamUrl.touched"
                class="alert alert-danger col-12">
                {{_camValidateService.CameraValidate.streamValidate.message(addCamForm.controls.streamUrl)}}
              </div>
            </div>
          </div>
          <div class="form-action">
            <button class="btn btn-outline-secondary mr-3" type="button"
              data-dismiss="modal">{{'li_cancel'|translate}}</button>
            <button class="btn btn-primary" type="submit" [disabled]="addCamForm.invalid">{{btnSubmit}}</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
<!--Modal thêm camera viettel-->
<div class="modal fade" id="addViettelCamera" tabindex="-1" role="dialog" aria-labelledby="addViettelCameraTitle"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered camera-modal" role="document">
    <div class="modal-content">
      <div class="modal-header bg-primary">
        <h5 class="modal-title" id="addViettelCameraTitle">{{'ad_add_new_viettel'|translate}}</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <i class="icon icon-close"></i>
        </button>
      </div>
      <div class="modal-body">
        <form class="form" [formGroup]="addViettelCamForm" (ngSubmit)="addViettelCamForm.valid && AddViettelCam()">
          <div class="form-group">
            <label class="control-label" for="cam-name">{{'ad_camera_name'|translate}}</label>
            <input type="text" class="form-control" id="cam-name" placeholder="{{'li_enter_info'|translate}}"
              formControlName="name" [(ngModel)]="camera.name">
            <div *ngIf="addViettelCamForm.controls.name.invalid && addViettelCamForm.controls.name.touched"
              class="alert alert-danger">
              {{_camValidateService.CameraValidate.nameValidate.message(addViettelCamForm.controls.name)}}
            </div>
          </div>
          <div class="form-group">
            <label class="control-label" for="cloud-id">Cloud ID</label>
            <input type="text" class="form-control" id="cloud-id" placeholder="{{'li_enter_info'|translate}}"
              formControlName="cloudId" [(ngModel)]="camera.cloudId">
            <div *ngIf="addViettelCamForm.controls.cloudId.invalid && addViettelCamForm.controls.cloudId.touched"
              class="alert alert-danger">
              {{_camValidateService.CameraValidate.cloudIDValidate.message(addViettelCamForm.controls.cloudId)}}
            </div>
          </div>
          <div class="form-group">
            <label class="control-label" for="secure-code">Secure Code</label>
            <input type="text" class="form-control" id="secure-code" placeholder="{{'li_enter_info'|translate}}"
              formControlName="secureCode" [(ngModel)]="camera.secureCode">
            <div *ngIf="addViettelCamForm.controls.secureCode.invalid && addViettelCamForm.controls.secureCode.touched"
              class="alert alert-danger">
              {{_camValidateService.CameraValidate.codeValidate.message(addViettelCamForm.controls.secureCode)}}
            </div>
          </div>
          <div class="form-action">
            <button class="btn btn-outline-secondary mr-3" type="button"
              data-dismiss="modal">{{'li_cancel'|translate}}</button>
            <button class="btn btn-primary" type="submit"
              [disabled]="addViettelCamForm.invalid">{{'ad_add'|translate}}</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
<!--Modal update camera ngoài-->
<div class="modal fade" id="updateThirdCam" tabindex="-1" role="dialog" aria-labelledby="updateThirdCamTitle"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered user-modal" role="document">
    <div class="modal-content">
      <div class="modal-header bg-primary">
        <h5 class="modal-title" id="updateThirdCamTitle">{{'cam_update_third'|translate}}</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <i class="icon icon-close"></i>
        </button>
      </div>
      <div class="modal-body">
        <form class="form" [formGroup]="updateThirdForm" (ngSubmit)="updateThirdForm.valid && UpdateThirdCam()">
          <div class="row">
            <div class="col-12 col-sm-6">
              <div class="form-group mr-3">
                <label class="control-label" for="name">{{'cam_name'|translate}}<span class="required">(<span
                      class="asterisk">*</span>)</span>
                </label>
                <input type="text" class="form-control" [(ngModel)]="camera.name" formControlName="name">
                <div *ngIf="updateThirdForm.controls.name.invalid && updateThirdForm.controls.name.touched"
                  class="alert alert-danger">
                  {{_camValidateService.CameraValidate.nameValidate.message(updateThirdForm.controls.name)}}
                </div>
              </div>
            </div>
            <div class="col-12 col-sm-6">
              <div class="form-group">
                <label class="control-label" for="model">Model <span class="required">(<span
                      class="asterisk">*</span>)</span></label>
                <div class="w-100">
                  <select class="custom-select" id="idTypeCam">
                    <option *ngFor="let type of listTypeCam; let i = index" [value]="type.id">{{type.name}}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-sm-6">
              <div class="form-group mr-3">
                <label class="control-label" for="urlStream">URL Stream
                  <span class="required">(<span class="asterisk">*</span>)</span>
                </label>
                <input type="text" class="form-control" [(ngModel)]="camera.streamUrl" formControlName="streamUrl"
                  placeholder="rtmp://">
                <div *ngIf="updateThirdForm.controls.streamUrl.invalid && updateThirdForm.controls.streamUrl.touched"
                  class="alert alert-danger col-12">
                  {{_camValidateService.CameraValidate.streamValidate.message(updateThirdForm.controls.streamUrl)}}
                </div>
              </div>
            </div>
            <div class="col-12 col-sm-6" *ngIf="isOwner">
              <div class="form-group">
                <label class="control-label">{{'cam_numshare'|translate}}</label>
                <input type="text" class="form-control" id="identification-card" disabled
                  *ngIf="listAccCamera.length>0; else elseBlock"
                  value="{{listAccCamera[0].shared}}/{{listAccCamera[0].share_limit}}">
                <ng-template #elseBlock>
                  <input type="text" class="form-control" id="identification-card" disabled value="0">
                </ng-template>
              </div>
            </div>
          </div>
          <div class="row" *ngIf="isOwner && listAccCamera.length>0;">
            <div class="per-border" style="width: 100%; font-size: 13px;">
              <label class="control-label" for="listShare">{{'cam_list_share'|translate}}</label>
              <table class="table table-hover">
                <thead>
                  <tr style="font-weight: bold;">
                    <th scope="col"><label class="cb-container">
                        <input id="cbAll" class="cb" type="checkbox" (change)="onSelectAllChange($event)"
                          [checked]="isCheckedAll" />
                        <span class="checkmark"></span></label>
                    </th>
                    <th scope="col">{{'cam_user'|translate}}</th>
                    <th scope="col">Email</th>
                    <th scope="col">{{'cam_user_share'|translate}}</th>
                    <th scope="col">{{'cam_role'|translate}}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let _account of listAccCamera;">
                    <td><label class="cb-container">
                        <input id="{{_account.username}}" class="cb" type="checkbox" (change)="onCheckboxChange($event)"
                          [checked]="_account.checked">
                        <span class="checkmark"></span></label></td>
                    <td>{{_account.fullName}}</td>
                    <td>{{_account.email}}</td>
                    <td>{{_account.fullNameParent}} - {{_account.emailParent}}</td>
                    <td>
                      <span *ngIf="_account.roleId == 1">{{'cam_owner'|translate}}</span>
                      <span *ngIf="_account.roleId == 2">{{'ad_view_cam'|translate}}</span>
                      <span *ngIf="_account.roleId == 3">{{'ad_manage_cam'|translate}}</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="form-action" style="text-align: right;">
            <button class="btn btn-outline-secondary mr-3" type="button"
              data-dismiss="modal">{{'li_cancel'|translate}}</button>
            <button class="btn btn-primary" type="submit" [disabled]="updateThirdForm.invalid">Lưu</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
<!--Modal update camera Viettel-->
<div class="modal fade" id="updateViettelCam" tabindex="-1" role="dialog" aria-labelledby="updateViettelCamTitle"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered user-modal" role="document">
    <div class="modal-content">
      <div class="modal-header bg-primary">
        <h5 class="modal-title" id="updateThirdCamTitle">{{'cam_update_viettel'|translate}}</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <i class="icon icon-close"></i>
        </button>
      </div>
      <div class="modal-body">
        <form class="form" [formGroup]="updateViettelForm" (ngSubmit)="updateViettelForm.valid && UpdateViettelCam()">
          <div class="row">
            <div class="col-12 col-sm-6">
              <div class="form-group mr-3">
                <label class="control-label" for="name">{{'cam_name'|translate}}
                  <span class="required">(<span class="asterisk">*</span>)</span>
                </label>
                <input type="text" class="form-control" [(ngModel)]="camera.name" formControlName="name">
                <div *ngIf="updateViettelForm.controls.name.invalid && updateViettelForm.controls.name.touched"
                  class="alert alert-danger">
                  {{_camValidateService.CameraValidate.nameValidate.message(updateViettelForm.controls.name)}}
                </div>
              </div>
            </div>
            <div class="col-12 col-sm-6">
              <div class="form-group mr-3">
                <label class="control-label" for="cloudId">Cloud ID
                  <span class="required">(<span class="asterisk">*</span>)</span>
                </label>
                <input type="text" class="form-control" id="cloud-id" placeholder="{{'li_enter_info'|translate}}"
                  formControlName="cloudId" [(ngModel)]="camera.cloudId" disabled>
              </div>
            </div>
          </div>
          <div class="row" *ngIf="isOwner">
            <div class="col-12">
              <div class="form-group">
                <label class="control-label" for="numShare-card">{{'cam_numshare'|translate}}</label>
                <input type="text" class="form-control" id="identification-card" disabled
                  *ngIf="listAccCamera.length>0; else elseBlock"
                  value="{{listAccCamera[0].shared}}/{{listAccCamera[0].share_limit}}">
                <ng-template #elseBlock>
                  <input type="text" class="form-control" id="identification-card" disabled value="0">
                </ng-template>
              </div>
            </div>
          </div>

          <div class="row" *ngIf="isOwner && listAccCamera.length>0;">
            <div class="per-border" style="width: 100%; font-size: 13px;">
              <label class="control-label" for="listShare">{{'cam_list_share'|translate}}</label>
              <table class="table table-hover">
                <thead>
                  <tr style="font-weight: bold;">
                    <th scope="col"><label class="cb-container">
                        <input id="cbAll" class="cb" type="checkbox" (change)="onSelectAllChange($event)"
                          [checked]="isCheckedAll" />
                        <span class="checkmark"></span></label>
                    </th>
                    <th scope="col">{{'cam_user'|translate}}</th>
                    <th scope="col">Email</th>
                    <th scope="col">{{'cam_user_share'|translate}}</th>
                    <th scope="col">{{'cam_role'|translate}}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let _account of listAccCamera;">
                    <td><label class="cb-container">
                        <input id="{{_account.username}}" class="cb" type="checkbox" (change)="onCheckboxChange($event)"
                          [checked]="_account.checked">
                        <span class="checkmark"></span></label></td>
                    <td>{{_account.fullName}}</td>
                    <td>{{_account.email}}</td>
                    <td>{{_account.fullNameParent}} - {{_account.emailParent}}</td>
                    <td>
                      <span *ngIf="_account.roleId == 1">{{'cam_owner'|translate}}</span>
                      <span *ngIf="_account.roleId == 2">{{'ad_view_cam'|translate}}</span>
                      <span *ngIf="_account.roleId == 3">{{'ad_manage_cam'|translate}}</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="form-action" style="text-align: right;">
            <button class="btn btn-outline-secondary mr-3" type="button"
              data-dismiss="modal">{{'li_cancel'|translate}}</button>
            <button class="btn btn-primary" type="submit" [disabled]="updateViettelForm.invalid">Lưu</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
<!--Modal cấu hình giao thức stream-->
<div class="modal fade" id="streamingProtocol" tabindex="-1" role="dialog" aria-labelledby="streamingProtocolTitle"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered camera-modal" role="document">
    <div class="modal-content">
      <div class="modal-header bg-primary">
        <h5 class="modal-title" id="streamingProtocolTitle">{{'ad_protocol'|translate}}</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <i class="icon icon-close"></i>
        </button>
      </div>
      <div class="modal-body">
        <form class="form">
          <div class="form-group">
            <label>{{'ad_protocol_for_web'|translate}}</label>
            <div class="form-radio-group">
              <div class="form-radio mr-5">
                <input class="magic-radio" type="radio" name="protocol1" id="protocol-1" [(ngModel)]="webProtocol"
                  value="HLS" />
                <label for="protocol-1">HLS</label>
              </div>
              <div class="form-radio">
                <input class="magic-radio" type="radio" name="protocol2" id="protocol-2" [(ngModel)]="webProtocol"
                  value="RTC" />
                <label for="protocol-2">RTC</label>
              </div>
            </div>
          </div>
          <!-- <hr> -->

          <div class="form-group">
            <label>{{'ad_protocol_for_app'|translate}}</label>
            <div class="form-radio-group">
              <div class="form-radio mr-5">
                <input class="magic-radio" type="radio" name="protocol3" id="protocol-3" [(ngModel)]="protocol"
                  value="https" />
                <label for="protocol-3">HLS</label>
              </div>
              <div class="form-radio">
                <input class="magic-radio" type="radio" name="protocol4" id="protocol-4" [(ngModel)]="protocol"
                  value="rtmp" />
                <label for="protocol-4">RTMP</label>
              </div>
            </div>
          </div>

          <div class="form-action">
            <button class="btn btn-outline-secondary mr-3" type="button"
              data-dismiss="modal">{{'li_cancel'|translate}}</button>
            <button class="btn btn-primary" (click)="ApplyProtocol()">{{'li_confirm'|translate}}</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>