import { Component, OnInit } from '@angular/core';
declare var $: any;

@Component({
  selector: 'app-home-footer',
  templateUrl: './home-footer.component.html',
  styleUrls: ['./home-footer.component.css']
})
export class HomeFooterComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    if ($("#backToTop").length) {
      $(window).scroll((function () {
        if ($(this).scrollTop() >= 50) {        // If page is scrolled more than 50px
          $('#backToTop').fadeIn(200);    // Fade in the arrow
        } else {
          $('#backToTop').fadeOut(200);   // Else fade out the arrow
        }
      }));
      $("#backToTop").click((function (e) {
        e.preventDefault();
        $("html, body").animate({ scrollTop: 0 }, 1000);
      }));
    }

  }

}
