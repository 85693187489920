import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'search'
})
export class SearchPipe implements PipeTransform {

  transform(items: any, config: { label: string[], keyword: string }): any {
    if (config && config.keyword && Array.isArray(items)) {
      return items.filter(item => this.check(item, config.label, config.keyword));
    } else {
      return items;
    }
  }
  check(item: any, props: string[], keyword: string): boolean {
    for (let i = 0; i < props.length; i++) {
      if (item[props[i]] && `${item[props[i]]}`.toLowerCase().indexOf(keyword.toLowerCase()) !== -1) { return true; }
    }
    return false;
  }
}
