import * as _ from "lodash";
import { CookieService } from "ngx-cookie-service";
import { AuthHepler } from "../../../../_helpers/auth.helper";
import { AuthenticationService } from "../../../../_services";
import { AiCameraEventService } from "../../../../_services/ai-camera-event.service";
import { Component, OnInit } from "@angular/core";
import { SelectDate } from "../../../../_models/index";
import { eventFaceIdModel, eventFaceModel, faceIdModel } from "src/app/_models/ai-camera";
import Swal from "sweetalert2";

declare var moment: any;
declare var $: any;
@Component({
  selector: "app-check-in-event",
  templateUrl: "./check-in-event.component.html",
  styleUrls: ["./check-in-event.component.css"],
})
export class CheckInEventComponent extends AuthHepler implements OnInit {
  evtFaceIds: eventFaceIdModel = new eventFaceIdModel();
  evtCheckInCheckOut: faceIdModel[] = [];
  lstEvtFaceModel: eventFaceModel[] = [];
  totalItems: number = 0;
  loading: boolean = true;
  startDate: SelectDate = new SelectDate();
  endDate: SelectDate = new SelectDate();
  startTime: Date = new Date(moment().startOf("day"));
  lstBetweebDate = [];
  endTime: Date = new Date();
  currDate: Date = new Date();
  rawListEvents: any[] = [];
  listEvents: any[] = [];
  pageSize: number = 10;
  currentPage: number = 0;
  currpagination: number = 0;
  pageSizes = [5, 10, 20, 50, 100, 1000];
  txtSearch = "";
  lengthPagination = 1;
  listUsers: any[] = [];
  listEventsWithPagination: any[] = [];
  minFace: number = 0;
  maxFace: number = 0;

  listEventType: { value: string; label: string }[] = [
    { value: "CCNV", label: "Chấm công nhân viên" },
  ];
  titleSelect: string = "Chấm công nhân viên";
  eventType: string = "CCNV";
  strDate: string = "01/01/2020";
  maxsize: number = 5;
  stWork = "05/06";
  stNoKeep = "";
  stLate = "";
  stEarly = "";
  currEventType = "1";
  pg: any;
  hours = [
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12",
  ];
  minutes = [
    "00",
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "19",
    "20",
    "21",
    "22",
    "23",
    "24",
    "25",
    "26",
    "27",
    "28",
    "29",
    "30",
    "31",
    "32",
    "33",
    "34",
    "35",
    "36",
    "37",
    "38",
    "39",
    "40",
    "41",
    "42",
    "43",
    "44",
    "45",
    "46",
    "47",
    "48",
    "49",
    "50",
    "51",
    "52",
    "53",
    "54",
    "55",
    "56",
    "57",
    "58",
    "59",
  ];
  constructor(
    private AiCameraEventService: AiCameraEventService,
    public authenticationService: AuthenticationService,
    public cookie: CookieService
  ) {
    super(AiCameraEventService, authenticationService, cookie);
  }

  ngOnInit(): void {
    if ($(".select-time").length) {
      $(".select-time").select2({
        minimumResultsForSearch: -1,
      });
    }
    if ($("#date-time-picker2").length) {
      $("#date-time-picker2")
        .dateRangePicker({
          inline: true,
          container: "#date-time-picker-container2",
          alwaysOpen: true,
        })
        .bind("datepicker-first-date-selected", function (event, obj) {
          if (obj.date1) {
            $("#select-date-first").val(moment(obj.date1).format("DD/MM/YYYY"));
          } else {
            $("#select-date-first").val(moment());
          }
        })
        .bind("datepicker-change", function (event, obj) {
          if (obj.date2) {
            $("#select-date-second").val(
              moment(obj.date2).format("DD/MM/YYYY")
            );
          } else {
            $("#select-date-second").val(
              moment(new Date()).startOf("day").format("DD/MM/YYYY")
            );
          }
        });
    }
    this.statisticTime();
    this.findAllEventFaceID();
    // this.checkInCheckOut();
    // this.login().add(() => this.findAll(this.startTime.getTime().toString()));
  }
  login() {
    return this.AiCameraEventService.login().subscribe(
      (res) => {
        this.cookie.set("ai_camera_token", res.token);
      },
      (error) => {
        console.log(error);
      }
    );
  }

  findAll(time?: string) {
    this.loading = true;
    const startDate = time ? Number(time) / 1000 : 0;
    return this.AiCameraEventService.findAll(startDate.toString()).subscribe(
      (res) => {
        this.rawListEvents = res.events;
        const userIds = this.getUserIdsInEvents(res.events);
        this.findUserWithUserIds(userIds);
      },
      (error) => {
        console.log(error);
      },
      () => {
        this.loading = false;
      }
    );
  }

  getUserIdsInEvents(events: any[]) {
    let userIds = [];
    events.map((event) => {
      const { faces } = event;
      if (faces && faces.length > 0) {
        _.forEach(faces, (face) => {
          userIds.push(face.userId);
        });
      }
    });
    return new Set(userIds);
  }

  findUserWithUserIds(userIds) {
    return this.AiCameraEventService.findUserWithUserIds([
      ...userIds,
    ]).subscribe(
      (res) => {
        this.listUsers = res.users;
        this.listEvents = this.formatResponseEvent(
          this.rawListEvents,
          this.listUsers
        );
        this.listEventsWithPagination = this.paginateWithSearch(
          this.listEvents,
          this.txtSearch
        );
        this.lengthPagination = this.listEvents.length;
      },
      (error) => {
        console.log(error);
      }
    );
  }

  convertAMPM(_date: SelectDate) {
    if (_date.ampm == "PM") {
      if (_date.hours < 12) _date.hours = Number(_date.hours) + 12;
      if (_date.hours == 12) _date.hours = Number(_date.hours) - 12;
    }
  }

  selectDate() {
    this.startDate.fulldate = $("#select-date-first").val();
    this.startDate.hours = $("#select-hours-first").val();
    this.startDate.minutes = $("#select-minutes-first").val();
    this.startDate.ampm = $("#select-ampm-first").val();
    this.convertAMPM(this.startDate);
    let fStartTime =
      this.startDate.fulldate +
      " " +
      this.startDate.hours +
      ":" +
      this.startDate.minutes +
      ":00";
    if (this.startDate.fulldate != "")
      this.startTime = new Date(moment(fStartTime, "DD-MM-YYYY HH:mm:ss"));
    this.endDate.fulldate = $("#select-date-second").val();
    this.endDate.hours = $("#select-hours-second").val();
    this.endDate.minutes = $("#select-minutes-second").val();
    this.endDate.ampm = $("#select-ampm-second").val();
    this.convertAMPM(this.endDate);
    let fEndTime =
      this.endDate.fulldate +
      " " +
      this.endDate.hours +
      ":" +
      this.endDate.minutes +
      ":00";
    if (this.endDate.fulldate != "")
      this.endTime = new Date(moment(fEndTime, "DD-MM-YYYY HH:mm:ss"));
  }

  getSearching(event) {
    this.currentPage = 0;
    this.listEventsWithPagination = this.paginateWithSearch(
      this.listEvents,
      this.txtSearch
    );
  }

  onChangePageSize($event: any) {
    this.currentPage = 0;
    this.listEventsWithPagination = this.paginateWithSearch(
      this.listEvents,
      this.txtSearch
    );
  }

  onChangePage($event: any) {
    this.currentPage = Number($event);
    this.listEventsWithPagination = this.paginateWithSearch(
      this.listEvents,
      this.txtSearch
    );
  }

  handleKeyWordBeforeSearch(str: string) {
    str = str.replace(/[àáạảãâầấậẩẫăằắặẳẵ]/g, "a");
    str = str.replace(/[èéẹẻẽêềếệểễ]/g, "e");
    str = str.replace(/[ìíịỉĩ]/g, "i");
    str = str.replace(/[òóọỏõôồốộổỗơờớợởỡ]/g, "o");
    str = str.replace(/[ùúụủũưừứựửữ]/g, "u");
    str = str.replace(/[ỳýỵỷỹ]/g, "y");
    str = str.replace(/đ/g, "d");
    str = str.replace(/[ÀÁẠẢÃÂẦẤẬẨẪĂẰẮẶẲẴ]/g, "A");
    str = str.replace(/[ÈÉẸẺẼÊỀẾỆỂỄ]/g, "E");
    str = str.replace(/[ÌÍỊỈĨ]/g, "I");
    str = str.replace(/[ÒÓỌỎÕÔỒỐỘỔỖƠỜỚỢỞỠ]/g, "O");
    str = str.replace(/[ÙÚỤỦŨƯỪỨỰỬỮ]/g, "U");
    str = str.replace(/[ỲÝỴỶỸ]/g, "Y");
    str = str.replace(/Đ/g, "D");
    // Some system encode vietnamese combining accent as individual utf-8 characters
    // Một vài bộ encode coi các dấu mũ, dấu chữ như một kí tự riêng biệt nên thêm hai dòng này
    str = str.replace(/[\u0300\u0301\u0303\u0309\u0323]/g, ""); // ̀ ́ ̃ ̉ ̣  huyền, sắc, ngã, hỏi, nặng
    str = str.replace(/[\u0306\u031B]/g, ""); //  ̆ ̛  , Ă, Ơ, Ư
    // no-misleading-character-class
    str = str.replace(/\u02C6/g, ""); // ˆ , Â, Ê
    // Remove extra spaces
    // Bỏ các khoảng trắng liền nhau
    str = str.replace(/ + /g, " ");
    str = str.trim();
    return str.toLowerCase();
  }

  paginateWithSearch(array, text: string) {
    const keyWordAfterFilter = this.handleKeyWordBeforeSearch(text || "");
    const filteredArray = array.filter(
      (item) =>
        item.userName.toLowerCase().includes(text.toLowerCase()) ||
        item.cameraName.toLowerCase().includes(text.toLowerCase())
    );
    return filteredArray;
  }

  formatResponseEvent(events, users) {
    return events.map((item) => {
      const user = users.find((u) => u.userId === item.faces[0].userId);
      return {
        ...item,
        userName: user ? user.userName : item.faces[0].userId,
        cameraName: "Camera 1",
      };
    });
  }

  displayUsername(userId: string) {
    const user = this.listUsers.find((u) => u.userId === userId);
    return user ? user.userName : userId;
  }

  getImageUrl(imageUrl: string) {
    return "https://" + imageUrl;
  }

  displayTime(time: number) {
    return moment(Number(time) * 1000).format("DD/MM/YYYY hh:mm A");
  }
  //Code by LeMui
  findRecord() {
    this.selectDate();
    switch (this.currEventType) {
      case '1':
        this.findAllEventFaceID();
        break;
      case '2':
        this.checkInCheckOut('2');
        break;
      case '3':
        this.checkInCheckOut('3');
        break;
      default:
    }
    $("#selectDate").modal("hide");
  }

  checkInCheckOut(typeEvent) {
    this.AiCameraEventService.checkInCheckOut(1, this.startTime.getTime()).subscribe(
      res => {
        this.evtCheckInCheckOut = res;
        this.lstEvtFaceModel = [];
        for (let i = 0; i < res.length; i++) {
          if (res[i].checkin != null) {
            let evtFM = new eventFaceModel();
            evtFM.employeeCode = res[i].employeeCode ?? "";
            evtFM.cameraName = res[i].cameraName;
            evtFM.userName = res[i].userName;
            evtFM.imageResult = res[i].checkin.imageResult;
            evtFM.typeCheck = "Check in";
            evtFM.time = res[i].checkin.time;
            if (typeEvent == '2') {
              if (res[i].checkin.late == true) {
                evtFM.lateOrSoon = "Đi muộn " + Math.floor(res[i].checkin.lateTime / 60000) + " p";
              }
              this.lstEvtFaceModel.push(evtFM);
            }
            else if (typeEvent == '3') {
              if (res[i].checkin.late == true) {
                evtFM.lateOrSoon = "Đi muộn " + Math.floor(res[i].checkin.lateTime / 60000) + " p";
                this.lstEvtFaceModel.push(evtFM);
              }
            }
          }
          if (res[i].checkout != null) {
            let evtFM = new eventFaceModel();
            evtFM.employeeCode = res[i].employeeCode ?? "";
            evtFM.cameraName = res[i].cameraName;
            evtFM.userName = res[i].userName;
            evtFM.imageResult = res[i].checkout.imageResult;
            evtFM.typeCheck = "Check out";
            evtFM.time = res[i].checkout.time;


            if (typeEvent == '2') {
              if (res[i].checkout.soon == true) {
                evtFM.lateOrSoon = "Về sớm " + Math.floor(res[i].checkout.soonTime / 60000) + " p";
              }
              this.lstEvtFaceModel.push(evtFM);
            }
            else if (typeEvent == '3') {
              if (res[i].checkout.soon == true) {
                evtFM.lateOrSoon = "Về sớm " + Math.floor(res[i].checkout.soonTime / 60000) + " p";
                this.lstEvtFaceModel.push(evtFM);
              }
            }
          }
        }
        this.totalItems = this.lstEvtFaceModel.length;
      },
      err => {
        console.log(err);
      }
    );

  }
  findAllEventFaceID() {
    this.AiCameraEventService.getAllEventFaceID2(1, this.currentPage, this.pageSize, this.startTime.getTime(), this.endTime.getTime()).subscribe(
      res => {
        this.evtFaceIds = res;
        this.totalItems = res.totalPages * this.pageSize;
        // const faces = this.evtFaceId.data.map((a) => a.timeStart)
        // this.minFace = Math.min(...faces);
        // this.maxFace = Math.max(...faces);
      },
      err => {
        console.log('Lỗi load toàn bộ sự kiện ' + err)
      }
    )
  }
  exportExcel() {
    Swal.fire('Thông báo', 'Tính năng đang phát triển, vui lòng thử lại sau!', 'info');
  }
  //dynamic-pagination by LeMui
  changeEventType(val) {
    this.currEventType = val;
    switch (val) {
      case '1':
        this.findAllEventFaceID();
        break;
      case '2':
        this.checkInCheckOut('2');
        break;
      case '3':
        this.checkInCheckOut('3');
        break;
      default:
      // code block
    }

  }
  onPageChange(event: any) {
    this.currpagination = event;
    this.currentPage = this.currpagination - 1;
    this.findAllEventFaceID();
  }
  pageSizeChange() {
    this.currentPage = 0;
    this.findAllEventFaceID();
  }
  statisticTime() {
    this.AiCameraEventService.statisticTime(1).subscribe(
      res => {
        this.stWork = (res.total - res.notPresent) + '/' + res.total;
        this.stNoKeep = res.notPresent + '/' + res.total;
        this.stLate = res.late + '/' + res.total;
        this.stEarly = res.backSoon + '/' + res.total;
      },
      err => {
        console.log('Lỗi lấy dữ liệu thống kê');
      }
    );
  }
}
