<div class="container-fluid p-0">
  <app-header></app-header>

  <div class="page-container page-rewatch">
    <div class="row no-gutters w-100">
      <!-- <div class="col-12 col-md-2 col-lg-2 sidebar-wrapper">
        <app-tree-liveview #cameraTree></app-tree-liveview>
      </div> -->
      <div class="col-12 col-md-12 col-lg-12 content-wrapper">
        <div class="main-content">
          <div class="container">
            <div class="row py-4 row-control">
              <div class="col-md-5 d-flex align-items-center">
                <h1 class="page-title pl-3 pl-xlg-0">Bản đồ</h1>
              </div>
              <div class="col-md-7">
                <div class="row">
                  <div class="col-md-6">
                    <form action="#" class="search-maps-wrapper">
                      <input type="text" class="search-maps">
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <button (click)="showAlert()">show alerat</button>
            <div class="row">
              <div class="col-12">
                <!-- <agm-map id="map" [latitude]="location.latitude" [longitude]="location.longitude" [zoom]="location.zoom" [disableDefaultUI]="false">
                  <agm-marker *ngFor="let m of location.markers; let i = index" (markerClick)="clickedMarker(m.label, i)" 
                  [latitude]="m.lat" [longitude]="m.lng" [label]="m.label" [markerDraggable]="m.draggable"
                  (dragEnd)="markerDragEnd(m, $event)">
                    <agm-info-window #MapInfoWindow>
                      <div style="min-width: 250px; border-radius: 6px; background-color: white">
                        <h3 style="font-size: 13px; font-weight: bold; text-align: left; margin-top: 0">Camera 1</h3>
                        <p style="position: relative; padding-left: 15px; margin-bottom: 10px">
                          <img src="https://i.imgur.com/PG2UFj9.png" style="position: absolute;left: 0;top: 0;" alt="">
                          <span
                            style="margin-left: 5px; display: inline-block">21.0291562</span><span>105.8501162</span>
                        </p>
                        <p style="margin-bottom: 10px">45 Nguyễn Trãi, Ba Đình, Hà Nội</p>
                        <div style="text-align: right; display: block;">
                          <button id="#cam1" (click)="modalShow('#cam1',m)"
                            style="display: inline-block; position: relative; padding: 10px 10px 10px 35px; border-radius: 4px; background-color: #1865fd; border: 0; box-shadow: none; color: white">
                            <img src="https://i.imgur.com/xpzjn0k.png" style="position: absolute;left: 5px;top: 5px;"
                              alt="">Xem trực tiếp</button>
                        </div>
                      </div></agm-info-window>
                  </agm-marker>
                </agm-map> -->
                <div id="map"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>



<!-- Modal -->
<div class="modal fade" id="cam1" tabindex="-1" role="dialog" aria-labelledby="addUserTitle" aria-hidden="true">
  <div class="modal-dialog user-modal" role="document">
    <div class="modal-content">
      <div class="modal-header bg-primary">
        <h5 class="modal-title" id="addUserTitle">Camera 1</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <i class="icon icon-close"></i>
        </button>
      </div>
      <div class="modal-body">
        <!-- <div *ngFor="let camera of listCameraPlaying;"> -->
        <!-- <app-videojs [camera]="cameramap"  [type]="'LIVE_VIEW'" class="camera-media" #videojs></app-videojs> -->
        <!-- <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/WrHDquZ-tj0"
            allowfullscreen></iframe> -->
        <!-- </div> -->
        <video id="idVideojs" class="video-js vjs-default-skin vjs-big-play-centered vjs-16-9">
          <source type="rtmp/mp4" src="null">
        </video>
      </div>
    </div>
  </div>
</div>