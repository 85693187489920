<div class="container-fluid p-0">
    <app-header></app-header>
    <div class="page-container page-view-online">
        <div class="row no-gutters w-100">
            <div class="col-12 col-md-2 col-lg-2 sidebar-wrapper">
                <app-tree-liveview #cameraTree (onPlay)="liveView($event)" (onClear)="ResetListPlaying()">
                </app-tree-liveview>
            </div>
            <div class="col-12 col-md-10 col-lg-10 content-wrapper">
                <div class="main-content">
                    <!-- <video autoplay src="https://s3-north1.viettelidc.com.vn/1days/record/demoidc1711135801154/demoidc1711135801154_1711677045384_1711677946498.mp4?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20240329T031140Z&X-Amz-SignedHeaders=host&X-Amz-Expires=86399&X-Amz-Credential=4d4095afa68656f25b4a%2F20240329%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Signature=0479544f3e5e6cbf2f4a7cb3eb62c55479930bedc03972ddbc0904246eadd839" type="video/mp4"></video> -->
                    <div class="container container-watch">
                        <div class="row py-4 row-control">
                            <div class="col-md-4">
                                <h1 class="page-title" style="padding-top: 0.5rem !important;">{{'ad_online'|translate}}</h1>
                            </div>
                            <div class="col-md-4 text-center">
                                <ul class="nav nav-tabs tab-views">
                                    <li class="nav-item">
                                        <a class="nav-link view-1x1" id="1x1-tab" data-toggle="tab"
                                            (click)="ChangeTab(1)" href="#tab1x1" role="tab" aria-controls="1x1"
                                            aria-selected="true">1x1</a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link view-2x2 active" id="2x2-tab" data-toggle="tab"
                                            (click)="ChangeTab(2)" href="#tab2x2" role="tab" aria-controls="2x2"
                                            aria-selected="true">2x2</a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link view-3x3" id="3x3-tab" data-toggle="tab"
                                            (click)="ChangeTab(3)" href="#tab3x3" role="tab" aria-controls="3x3"
                                            aria-selected="true">3x3</a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link view-4x4" id="4x4-tab" data-toggle="tab"
                                            (click)="ChangeTab(4)" href="#tab4x4" role="tab" aria-controls="4x4"
                                            aria-selected="true">4x4</a>
                                    </li>
                                </ul>
                            </div>
                            <div class="col-md-4">
                                <a class="view-fullscreen fullscreen-toggle enter" (click)="openFullscreen()">
                                    <i class="icon icon-fullscreen"></i>
                                    <span>{{'ad_fullscreen'|translate}}</span>
                                </a>
                                <a class="view-fullscreen fullscreen-toggle exit" (click)="closeFullscreen()">
                                    <i class="icon icon-exit-fullscreen"></i>
                                    <span>{{'ad_finish'|translate}}</span>
                                </a>
                                <a class="close-all mr-3" *ngIf="listCameraPlaying?.length>0"
                                    (click)="deactiveRouter2()">
                                    <i class="icon icon-close-gray"></i>
                                    <span>{{'ad_closeall'|translate}}</span>
                                </a>
                            </div>
                        </div>
                        <div id="testdemo" class="tab-content">
                            <div class="tab-pane active" id="{{gridId}}" role="tabpanel" aria-labelledby="2x2-tab"
                                style="height: 100%;">
                                <div class="row">
                                    <div class="{{gridClass}}"
                                        *ngFor="let camera of listCameraPlaying; trackBy:cameraTrackById;">
                                        <div *ngIf="camera.liveProtocol=='RTC'" id="{{camera.id}}" class="camera-view">
                                            <div class="camera-name">
                                                <h4>{{camera.name}}</h4>
                                            </div>
                                            <app-webrtcplayer [camera]="camera" #webrtc></app-webrtcplayer>
                                            <div class="action">
                                                <a (click)="SnapshotCamera(webrtc)" title="{{'ad_snapshot'|translate}}"
                                                    href="javascript:;"><i class="icon icon-view-fit"></i></a>
                                                <a href="javascript:;" (click)="ReloadCamera(webrtc)" title="Reload"><i
                                                        class="icon icon-reload"></i></a>
                                                <a href="javascript:;" title="{{'ad_fullscreen'|translate}}"
                                                    (click)="fullScreenCamera(webrtc)"><i
                                                        class="icon icon-view-fullscreen"></i></a>
                                            </div>
                                            <a class="view-closecam" title="{{'ad_close'|translate}}" (click)="removeCamera(webrtc)"><i
                                                    class="icon icon-view-closecam" href="javascript:;"></i></a>
                                        </div>
                                        <div *ngIf="camera.liveProtocol!='RTC'" id="{{camera.id}}" class="camera-view"
                                            style="padding-bottom: unset;height: 98%;">
                                            <div class="camera-name">
                                                <h4>{{camera.name}}</h4>
                                            </div>
                                            <app-webrtcplayer [camera]="camera" class="camera-media"></app-webrtcplayer>
                                            <app-videojs [camera]="camera" [type]="'LIVE_VIEW'" class="camera-media"
                                                #videojs></app-videojs>
                                            <div class="action">
                                                <a (click)="Snapshot(videojs)" title="{{'ad_snapshot'|translate}}" href="javascript:;"><i
                                                        class="icon icon-view-fit"></i></a>
                                                <a href="javascript:;" title="{{'ad_fullscreen'|translate}}"
                                                    (click)="fullScreenCam(videojs)"><i
                                                        class="icon icon-view-fullscreen"></i></a>
                                            </div>
                                            <a class="view-closecam" title="Close" (click)="removeVideo(videojs)"><i
                                                    class="icon icon-view-closecam" href="javascript:;"></i></a>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<!--Modal thông báo bảo trì hệ thống-->
<div id="mNotifiInfo" class="modal fade bd-example-modal-lg" tabindex="-1" role="dialog"
  aria-labelledby="myLargeModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content modal-padding">
      <div class="modal-header">
        <h1 class="modal-title" id="exampleModalLabel" style="color:black; font-weight: bold;" translate>{{'ad_NOTIFI_SYSTEM'|translate}}</h1>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <i class="icon icon-close-gray"></i>
        </button>
      </div>
      <div class="modal-body">
        <div class="col-12">
            {{'ad_notifi_first'|translate}}
          <div class="form-group">
            <label class="control-label" for="current-password">{{'ad_notifi_content'|translate}} </label>
            <textarea [(ngModel)]="currNotifi.content" class="form-control" rows="4" cols="50"></textarea>
          </div>
        </div>
        <div class="col-12" style="display: inline-flex;">
            {{'ad_notifi_second'|translate}}
        </div>
        <div class="col-12" style="padding-top: 20px; text-align: center; font-style: italic; ">
            <input id="cbRemind" type="checkbox" style="cursor: pointer;"><label for="cbRemind" style="margin-left: 5px;cursor: pointer;">{{'ad_notifi_no_view'|translate}}</label>
        </div>
        <div class="modal-footer text-center" style="display: block; float: right;">
          <button type="button" class="btn btn-primary" (click)="CloseNotifi()">{{'ad_close'|translate}}</button>
        </div>
      </div>
    </div>
  </div>
</div>