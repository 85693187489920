import { Component, OnInit } from "@angular/core";
import { SelectDate } from "src/app/_models";
import { LogModel } from "src/app/_models/ai-camera";
import { AiCameraEventService } from "src/app/_services/ai-camera-event.service";
declare var moment: any;
declare var $: any;

@Component({
  selector: "app-manage-log",
  templateUrl: "./manage-log.component.html",
  styleUrls: ["./manage-log.component.css"],
})
export class ManageLogComponent implements OnInit {
  pageSizes = [5, 10, 20, 50, 100, 1000];
  pageSize = 5;
  currPage: number = 0;
  totalPage: number = 0;
  totalElement: number = 0;
  keySearch: string = "";
  _typeLog: string = "";
  lstLog: LogModel[] = [];
  loading: boolean = false;
  startTime: Date = new Date(moment().startOf('month'));
  endTime: Date = new Date();
  startDate: SelectDate = new SelectDate();
  endDate: SelectDate = new SelectDate();
  hours = Array.from({ length: 12 }, (_, i) => (i + 1).toString().padStart(2, "0"));
  minutes = Array.from({ length: 60 }, (_, i) => i.toString().padStart(2, "0"));

  constructor(private _logService: AiCameraEventService) {}

  ngOnInit(): void {
    if ($(".select-time").length) {
      $(".select-time").select2({
        minimumResultsForSearch: -1,
      });
    }

    if ($("#date-time-picker5").length) {

      $("#date-time-picker5")
        .dateRangePicker({
          inline: true,
          container: "#date-time-picker-container5",
          alwaysOpen: true,
        })
        .bind("datepicker-first-date-selected", function (event, obj) {
          if (obj.date1) {
            $("#select-date-first").val(moment(obj.date1).format("DD/MM/YYYY"));
          } else {
            $("#select-date-first").val(moment());
          }
        })
        .bind("datepicker-change", function (event, obj) {
          if (obj.date2) {
            $("#select-date-second").val(
              moment(obj.date2).format("DD/MM/YYYY")
            );
          } else {
            $("#select-date-second").val(
              moment(new Date()).startOf("day").format("DD/MM/YYYY")
            );
          }
        });
    }
    this.findAllLog();
  }

  findAllLog() {
    this._logService
      .findAllLog(
        this.keySearch,
        this._typeLog,
        this.startTime.getTime(),
        this.endTime.getTime(),
        this.pageSize,
        this.currPage
      )
      .subscribe(
        (res) => {
          this.lstLog = [];
          res.data.forEach((elm) => {
            let log = new LogModel();
            log = elm;
            switch (log.type) {
              case "REGISTER_CAMERA_AI":
                log.typeDisplay = "Đăng ký camera";
                break;
              case "CANCEL_REGISTRATION":
                log.typeDisplay = "Hủy đăng ký camera";
                break;
              case "REGISTER_FACEID":
                log.typeDisplay = "Đăng ký FaceID";
                break;
              case "MODIFY_FACEID":
                log.typeDisplay = "Chỉnh sửa FaceID";
                break;
              case "CANCEL_REGISTRATION":
                log.typeDisplay = "Khóa FaceID";
                break;
                case "CANCEL_REGISTRATION":
                  log.typeDisplay = "Hủy đăng ký camera";
                  break;
              default:
                case "MODIFY_FACEID":
                  log.typeDisplay = "Chỉnh sửa FaceID";
                  break;
            }
            this.lstLog.push(log);
          });
          this.pageSize = res.limit;
          this.totalPage = res.totalPage;
          this.currPage = res.page;
          this.totalElement = this.pageSize * (this.currPage + 1);
        },
        (err) => {


          console.log("Lỗi load danh sách log: " + err);
        }
      );
  }

  filterLog(){
    this.selectDate();
    this.findAllLog();
    $("#selectDate").modal("hide");
  }

  onPageChange(event: any) {
    this.currPage = event;
    // this.currPage = this.currPagination -1;
    this.findAllLog();
  }
  pageSizeChange() {
    this.currPage = 0;
    this.findAllLog();
  }

  convertAMPM(_date: SelectDate) {
    if (_date.ampm == "PM") {
      if (_date.hours < 12) _date.hours = Number(_date.hours) + 12;
      if (_date.hours == 12) _date.hours = Number(_date.hours) - 12;
    }
  }
  selectDate() {
    this.startDate.fulldate = $("#select-date-first").val().toString();
    this.startDate.hours = Number($("#select-hours-first").val());
    this.startDate.minutes = $("#select-minutes-first").val().toString();
    this.startDate.ampm = $("#select-ampm-first").val().toString();
    this.convertAMPM(this.startDate);
    let fStartTime =
      this.startDate.fulldate +
      " " +
      this.startDate.hours +
      ":" +
      this.startDate.minutes +
      ":00";
    if (this.startDate.fulldate != "")
      this.startTime = new Date(moment(fStartTime, "DD-MM-YYYY HH:mm:ss"));
    this.endDate.fulldate = $("#select-date-second").val().toString();
    this.endDate.hours = Number($("#select-hours-second").val());
    this.endDate.minutes = $("#select-minutes-second").val().toString();
    this.endDate.ampm = $("#select-ampm-second").val().toString();
    this.convertAMPM(this.endDate);
    let fEndTime =
      this.endDate.fulldate +
      " " +
      this.endDate.hours +
      ":" +
      this.endDate.minutes +
      ":00";
    if (this.endDate.fulldate != "")
      this.endTime = new Date(moment(fEndTime, "DD-MM-YYYY HH:mm:ss"));
  }
}
