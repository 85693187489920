import { AccountService } from './../../_services/account.service';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
@Component({
  selector: 'app-active-account',
  templateUrl: './active-account.component.html',
  styleUrls: ['./active-account.component.css']
})
export class ActiveAccountComponent implements OnInit {

  constructor(
    private activatedRoute: ActivatedRoute,
    private _accountService: AccountService
  ) { }
  showload = false;
  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe((params: Params) => {
      this._accountService.ActiveAccount(params["token"]).subscribe(
        res => {
          this.showload = true;
        },
        err => {
          this.showload = false;
          console.log('Lỗi kích hoạt tài khoản: ' + err);
        }
      );
    })
  }
}
