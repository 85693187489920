import { Component, Input, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import { CookieService } from "ngx-cookie-service";
import { AccountModel } from "src/app/_models";
import { LanguageModel } from "src/app/_models/language.model";
import {
  AccountService,
  AuthenticationService,
  EncryptPasswordService,
  FormValidateService,
  UserService,
} from "src/app/_services";
import { AppComponent } from "src/app/app.component";
import Swal from "sweetalert2";
declare var $: any;

@Component({
  selector: "app-confirm-twofactor",
  templateUrl: "./confirm-twofactor.component.html",
  styleUrls: ["./confirm-twofactor.component.css"],
})
export class ConfirmTwofactorComponent implements OnInit {
  twoFactorForm: FormGroup;
  changePasswordForm: FormGroup;
  @Input() otpCode: string;
  @Input() password: string;
  @Input() oldPassword: string;
  secretKey: string = "";
  langModel = new LanguageModel();
  currAccount: AccountModel = new AccountModel();

  constructor(
    private _fb: FormBuilder,
    public _formValidateService: FormValidateService,
    public authenticationSevice: AuthenticationService,
    private _router: Router,
    private _appComponent: AppComponent,
    private _accountService: AccountService,
    private cookie: CookieService,
    private _encryptService: EncryptPasswordService,
    private _userService: UserService,
  ) {}

  ngOnInit(): void {
    this.createForm();
    //chuyển ngữ
    this._appComponent.translate
      .get("li_confirm_2factor")
      .subscribe((res: string) => {
        this.langModel.title = res;
      });
    this._appComponent.translate
      .get("li_err_2factor")
      .subscribe((res: string) => {
        this.langModel.content = res;
      });

      this._appComponent.translate
      .get("otp_title")
      .subscribe((res: string) => {
        this.langModel.content7 = res;
      });
      this._appComponent.translate
      .get("otpcontent2")
      .subscribe((res: string) => {
        this.langModel.content8 = res;
      });
  }
  createForm() {
    this.twoFactorForm = this._fb.group({
      otpName: [
        "",
        this._formValidateService.twoFactorValidate.otpCodeValidate.validator,
      ],
    });
    this.changePasswordForm = this._fb.group({
      oldPassword: [
        "",
        this._formValidateService.customerFormValidate.oldPasswordValidate
          .validator,
      ],
      matchingPassword: this._fb.group(
        {
          password: [
            "",
            this._formValidateService.customerFormValidate.passwordValidate
              .validator,
          ],
          passwordConfirm: [""],
        },
        {
          validator:
            this._formValidateService.customerFormValidate.matchingPassword
              .validator,
        }
      ),
    });
  }
  confirm() {
    const infoTFA = JSON.parse(this.cookie.get("InfoTFA"));
    this.authenticationSevice
      .checkOtp(
        infoTFA.username,
        this.otpCode,
        infoTFA.deviceId,
        infoTFA.password
      )
      .subscribe(
        (res) => {
          this.cookie.set("twofa","true");
          this.cookie.set(
            "currentUser",
            JSON.stringify({
              username: infoTFA.username,
              accessToken: res.accessToken,
              refreshToken: res.refreshToken,
              deviceId: infoTFA.deviceId,
            })
          );
          this._accountService.getDetail().subscribe(
            (res1) => {
              this.currAccount = res1["account"] as AccountModel;
              if (this.currAccount.requireResetPassword == "ACTIVE") {
                $("#changePasswordModal").modal("show");
              } else {
                this._router.navigate(["/liveview"]);
              }
            },
            (err) => {
              console.log(err);
            }
          );
        },
        (error) => {
          Swal.fire(
            this.langModel.content7,
            this.langModel.content8,
            "error"
          );
        }
      );

  }
  logWithOther() {
    this.cookie.delete("currentUser", "/");
    this._router.navigate(["/login"]);
  }
  updatePassword() {
    const username = JSON.parse(this.cookie.get("currentUser")).username;
    const oldPassword = this._encryptService.encryptSHA512(
      username,
      this.oldPassword
    );
    const newPassword = this._encryptService.encryptSHA512(
      username,
      this.password
    );
    this._userService.changePassword(oldPassword, newPassword).subscribe(
      (res) => {
        $("#changePasswordModal").modal("hide");
        Swal.fire(
          "Thành công",
          "Bạn đã thay đổi mật khẩu thành công",
          "success"
        );
      },
      (err) => {
        if (err === "Invalid data") {
          Swal.fire(
            "Thất bại",
            "Có lỗi xảy ra, vui lòng kiểm tra lại",
            "error"
          );
        } else {
          console.log(err);
        }
      }
    );
  }
}
