import { Injectable } from '@angular/core';
import { Http,URLSearchParams } from '@angular/http';
import { CookieService } from 'ngx-cookie-service';
import { BaseService } from './base.service';
import { ApiUrlService } from './api-url.service'
import { EncryptPasswordService } from './encrypt-password.service'

@Injectable({
  providedIn: 'root'
})
export class AiCameraFaceManagementService extends BaseService {

  constructor(
    public _apiUrl: ApiUrlService,
    public _http: Http,
    public cookie: CookieService,
    private _encryptPasswordService: EncryptPasswordService
  ) {
    super(_http, _apiUrl, cookie);
  }

//Face-ID
getListRole(){
  return this._http.get(this._apiUrl.FACEID_LISTROLE, this.authorizationOption())
  .map(this.extractData)
  .catch(this.handleError);
}
  findAllFaceID(_limit, _page){
    const params: URLSearchParams = new URLSearchParams();
    params.set('limit', `${_limit}`);
    params.set('page', `${_page}`);
    return this._http.get(this._apiUrl.FACEID_FINDALL, this.authorizationOption().merge({ search: params }))
    .map(this.extractData)
    .catch(this.handleError);
  }
  searchFaceID(_keyword, _limit, _page){
    const params: URLSearchParams = new URLSearchParams();
    params.set('keyword', `${_keyword}`);
    params.set('limit', `${_limit}`);
    params.set('page', `${_page}`);
    return this._http.get(this._apiUrl.FACEID_SEARCH, this.authorizationOption().merge({ search: params }))
    .map(this.extractData)
    .catch(this.handleError);
  }
  addNewFaceId(_scenarioId, _name, _roleFaceId,_data){
    return this._http.post(this._apiUrl.FACEID_ADD_NEW, {scenarioId:_scenarioId, name:_name, roleFaceId:_roleFaceId, data: _data}, this.authorizationOption())
    .map(this.extractData)
    .catch(this.handleError);
  }
  updateFaceId(_scenarioId, _name, _roleFaceId,_id, _data){
    return this._http.post(this._apiUrl.FACEID_UPDATE, {scenarioId:_scenarioId, name:_name, roleFaceId:_roleFaceId, id: _id, data: _data}, this.authorizationOption())
    .map(this.extractData)
    .catch(this.handleError);

  }
  updateStatusFaceId(_scenarioId,_status, _id){
    return this._http.post(this._apiUrl.FACEID_UPDATE_STATUS, {scenarioId:_scenarioId, status:_status, id:_id}, this.authorizationOption())
    .map(this.extractData)
    .catch(this.handleError);
  }
}
