import * as d3 from 'd3';
import uuidV4 from 'uuid/v4';
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import Swal from 'sweetalert2';

export class Shape {
  public id: string;
  public points: number[][];

  constructor() {
    this.points = [];
  }
}

@Component({
  selector: 'app-polygon-draw',
  templateUrl: './polygon-draw.component.html',
  styleUrls: ['./polygon-draw.component.css'],
})
export class PolygonDrawComponent implements AfterViewInit {
  @ViewChild('canvas', { static: false }) canvas: ElementRef;

  @Input() imageUrl: string;
  @Input() title?: string;
  @Input() polygons: number[][] = []; // [x1, y1, x2, y2, x3, y3, ...][]
  @Input() disabled: boolean = false;
  @Output() polygonsChange?: EventEmitter<number[][]> = new EventEmitter();
  @Input() containerWidth?: number;
  containerHeight: number;
  private svg: any;
  private dragging: Shape;
  private drawing: Shape;
  private startPoint: [number, number];
  private shapes: Shape[] = [];

  ngAfterViewInit(): void {
    this.svg = d3.select('svg');

    if (this.imageUrl) {
      this.renderPolygons(this.imageUrl, this.polygons);
    }
  }

  renderPolygons(imageUrl: string, polygons: number[][]): void {
    const img = new Image();
    img.onload = () => {
      const parentWidth = this.canvas.nativeElement.parentElement.offsetWidth;

      if (parentWidth > 0) {
        this.containerWidth = parentWidth;
      }

      this.containerHeight = (this.containerWidth * img.height) / img.width;

      polygons.forEach((item) => {
        this.drawPolygon(item);
      });
    };

    img.onerror = () => {
      Swal.fire('Đã có lỗi xảy ra', 'Không thể tải ảnh!', 'error');
    };

    img.src = imageUrl;
  }

  handleMouseMove(event: MouseEvent): void {
    if (!this.drawing || this.disabled) return;

    const g = this.svg.select(`#${this.drawing.id}`);
    g.select('line').remove();

    g.insert('line', ':first-child')
      .attr('x1', this.startPoint[0])
      .attr('y1', this.startPoint[1])
      .attr('x2', event.offsetX)
      .attr('y2', event.offsetY)
      .attr('stroke', '#53DBF3')
      .attr('stroke-width', 1);

    this.drawing.points.splice(this.drawing.points.length - 1, 1, [
      event.offsetX,
      event.offsetY,
    ]);

    this.updateShape(this.drawing);
  }

  handleMouseUp(event: MouseEvent): void {
    if (this.dragging || this.disabled) return;

    if ((event.target as HTMLElement).hasAttribute('is-handle')) {
      return this.closePolygon(this.drawing);
    }

    this.startPoint = [event.offsetX, event.offsetY];

    if (!this.drawing) {
      const shape = new Shape();
      shape.id = `shape_${uuidV4()}`;
      this.shapes.push(shape);
      this.drawing = shape;
      this.svg.append('g').attr('id', shape.id);
      this.drawing.points.push(this.startPoint);
    }

    const g = this.svg.select(`#${this.drawing.id}`);
    this.drawing.points.push(this.startPoint);
    g.select('polyline').remove();

    g.append('polyline')
      .attr('points', this.drawing.points)
      .style('fill', 'none')
      .attr('stroke', '#000');

    this.updateShape(this.drawing);
  }

  handleDrag(self: any): any {
    if (this.disabled) return;

    return function (d: any) {
      const dragCircle = d3.select(this);
      let newPoints: [number, number][] = [];

      const newPoint = [d3.event.x, d3.event.y];
      const poly = d3.select(this.parentNode).select('polygon');
      const circles = d3.select(this.parentNode).selectAll('circle');

      dragCircle.attr('cx', newPoint[0]).attr('cy', newPoint[1]);

      newPoints = circles
        .nodes()
        .map((circle: any) => [
          Number(circle.getAttribute('cx')),
          Number(circle.getAttribute('cy')),
        ]);

      poly.attr('points', newPoints as any);

      self.dragging = self.shapes.find(
        (x: Shape) => x.id === this.parentNode.id
      );
      self.dragging.points = newPoints;
    };
  }

  endDrag(self: any): any {
    return function (d: any) {
      self.dragging = undefined;
      self.emitPolygons('End Drag');
    };
  }

  updateShape(shape: Shape, closed = false): void {
    this.updatePoints(shape, closed);
  }

  closePolygon(shape: Shape): void {
    const g = this.svg.select(`#${shape.id}`);

    g.insert('polygon', ':first-child')
      .attr('points', shape.points)
      .style('fill', 'rgba(255, 0, 0, 0.25)')
      .attr('stroke', 'rgba(255, 0, 0, 0.75)');

    g.select('polyline').remove();
    g.select('line').remove();
    this.drawing.points.splice(0, 1);
    this.updateShape(shape, true);
    this.drawing = undefined;
  }

  drawPolygon(polygon: number[]): void {
    // Convert polygon points from flat array to array of coordinate pairs
    const points = [];
    for (let i = 0; i < polygon.length; i += 2) {
      const x = polygon[i] * this.containerWidth;
      const y = polygon[i + 1] * this.containerHeight;
      points.push([x, y]);
    }

    const shape = new Shape();
    shape.id = `shape_${uuidV4()}`;
    shape.points = points;
    this.shapes.push(shape);

    const g = this.svg.append('g').attr('id', shape.id);

    g.append('polygon')
      .attr('points', points)
      .style('fill', 'rgba(255, 0, 0, 0.25)')
      .attr('stroke', 'rgba(255, 0, 0, 0.75)');

    this.updatePoints(shape, true);
  }

  updatePoints(shape: Shape, closed = false): void {
    const g = this.svg.select(`#${shape.id}`);
    g.selectAll('circle').remove();

    let pointLength = shape.points.length - 1;
    if (closed) {
      pointLength++;
      this.emitPolygons('Closed');
    }

    for (let i = 0; i < pointLength; i++) {
      const point = shape.points[i];
      const circle = g
        .append('circle')
        .attr('cx', point[0])
        .attr('cy', point[1])
        .attr('r', this.disabled ? 2 : 4)
        .attr('fill', '#FF0000')
        .attr('stroke', 'rgba(255, 0, 0, 0.75)');

      if (closed) {
        const dragger = d3
          .drag()
          .on('drag', this.handleDrag(this))
          .on('end', this.endDrag(this));

        circle.call(dragger).style('cursor', 'move');
      } else {
        if (i === 0) {
          circle.attr('is-handle', 'true').style('cursor', 'pointer');
        }
      }
    }
  }

  emitPolygons(where: string): void {
    console.log('Emit polygons:', where);

    if (this.disabled) {
      return;
    }

    const points = this.shapes.map(({ points }) =>
      points.map(([x, y]) => [
        x / this.containerWidth,
        y / this.containerHeight,
      ])
    );

    // Convert array of coordinate pairs to flat array
    const polygons = points.map((point) => {
      return point.reduce((acc, val) => acc.concat(val), []);
    });
    this.polygonsChange.emit(polygons);
  }

  onReset(): void {
    this.svg.selectAll('*').remove();
    this.shapes = [];
    this.drawing = undefined;
    this.emitPolygons('On Reset');
  }
}
