<footer class="footer ">
        <div class="container ">
            <div class="row ">
                <div class="col-md-5 ">
                    <div class="brand-logo ">
                        <a href="# ">
                            <img class="logo " src="assets/logo camera.svg " alt="Cloud Camera " />
                        </a>
                    </div>
                    <ul class="contact-info-list ">
                        <li class="contact-info address ">
                            <p translate>{{'ldp_address_company'}}<br/>{{'ldp_address_company1'}}</p>
                        </li>
                        <li class="contact-info mobile ">
                            <p translate>{{'ldp_telephone'}}</p>
                        </li>
                        <li class="contact-info email ">
                            <p><a href="mailto:support@viettelidc.com.vn" translate>{{'ldp_mail_company'}}</a></p>
                        </li>
                    </ul>
                   
                </div>
                <div class="col-md-7 ">
                    <div class="row my-3 my-sm-0 ">

                        <div class="col-md-6 col-lg-2 ">
                            <div class="footer-block ">
                                <h5 class="collapsed " data-toggle="collapse " href="#collapseCompany" translate>{{'ldp_company'}}</h5>
                                <ul class="footer-list collapse " id="collapseCompany ">
                                    <li>
                                        <a href="https://viettelidc.com.vn/" target="_blank">Viettel IDC</a>
                                    </li>
                                    <li>
                                        <a [routerLink]="['/home/']" fragment="sectionPartners" translate>{{'ldp_customer'}}</a>
                                    </li>
                                    <li>
                                        <a href="https://viettelidc.com.vn/cloud-camera" target="_blank">Viettel Cloud Camera</a>
                                    </li>
                                    <li>
                                        <a [routerLink]="['/home/']" fragment="sectionContact" translate>{{'ldp_contact'}}</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-md-6 col-lg-3 ">
                            <div class="footer-block ">
                                <h5 class="collapsed " data-toggle="collapse " href="#collapseSupport " translate>{{'ldp_support_solution'}}</h5>
                                <ul class="footer-list collapse " id="collapseSupport ">
                                    <li>
                                        <a href="https://viettelidc.com.vn/Home/Support" target="_blank" translate>{{'ldp_support'}}</a>
                                    </li>
                                    <li>
                                        <a [routerLink]="['/home/']" fragment="sectionPrices" translate>{{'ldp_package_table'}}</a>
                                    </li>
                                    <li>
                                        <a href="https://viettelidc.com.vn/cloud-camera" target="_blank" translate>{{'ldp_question'}}</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-md-6 col-lg-5 ">
                            <div class="footer-block ">
                                <h5 class="collapsed " data-toggle="collapse " href="#collapseDownload " translate>{{'ldp_down_app'}}</h5>
                                <div class="footer-list collapse " id="collapseDownload ">
                                    <div class="row" style="display: flex; flex-wrap:wrap">
                                        <div class="col" >
                                            <div class="pb-1">Android QR code</div>
                                            <img src="assets/landing/Android QR Code.png" style="width:85%">
                                        </div>
                                        <div class="col" style="margin-left:0px">
                                            <div class="pb-1">IOS QR code</div>
                                            <img src="assets/landing/IOS QR Code.png" style="width:85%">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <div class="copyright ">
            <p style="color: #000000;" translate>{{'ldp_copyright'}}</p>
        </div>
    </footer>
    <a href="#" id="backToTop"></a>