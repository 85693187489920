<div class="d-flex justify-content-between align-items-center my-3" *ngIf="title">
  <div class="font-weight-bold">{{title}}</div>
  <div class="form-action">
    <button type="button" class="btn btn-primary" (click)="onReset()">Đặt lại</button>
  </div>
</div>

<div id="container" [style.width]="'100%'" [style.height.px]="containerHeight" [style.background-image]="'url(' + imageUrl + ')'">
  <svg #canvas (mouseup)="handleMouseUp($event)" (mousemove)="handleMouseMove($event)" [style.width]="'100%'"
    [style.height]="'100%'"></svg>
</div>
