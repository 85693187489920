import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-tree-play-back-item',
  templateUrl: './tree-play-back-item.component.html',
  styleUrls: ['./tree-play-back-item.component.css']
})
export class TreePlayBackItemComponent implements OnInit {
  @Input() bunch: any;
  @Output() eventChild = new EventEmitter<any>();
  @Output() eventSubChild  = new EventEmitter<any>();
  @Output() eventIsClick = new EventEmitter<any>();
  @Output() camera = new EventEmitter<any>();
  @Input() term = "";
  isClick = false;
  constructor() { }

  ngOnInit(): void {
  }
  selected($event:any){
    this.eventChild.emit($event);
  }

  clickHandle($event){
    this.bunch.isClick = !this.bunch.isClick;
    this.eventSubChild.emit(this.bunch);
    this.eventIsClick.emit(this.bunch.isClick);
  }
  clickCamera($event:any){
    this.camera.emit($event);
  }
  displayLevel(){
    var paddingString = "margin-left: " + this.bunch.level * 20 + "px; padding-right: " + this.bunch.level * 20 + "px;";
    return paddingString;
  }

  displayLevelCam(){
    var paddingString = "margin-left: 0px; padding-right: 0px;";
    return paddingString;
  }

}
