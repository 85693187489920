import { FormValidateService } from './../../_services/form-validate.service';
import { PaymentModel } from './../../_models/package.model';
import { BaseService } from './../../_services/base.service';
import { PackageService } from './../../_services/package.service';
import { AuthenticationService } from './../../_services/authentication.service';
import { Component, OnInit, Input } from '@angular/core';
import { AuthHepler } from '../../_helpers/auth.helper';
import { FormGroup, Validators, FormBuilder, FormControl } from '@angular/forms';
import { CookieService } from 'ngx-cookie-service';
import { AppComponent } from 'src/app/app.component';
import { LanguageModel } from 'src/app/_models/language.model';
declare var $: any;

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.css']
})
export class PaymentComponent extends AuthHepler implements OnInit {
  paymentForm: FormGroup;
  @Input() payment: PaymentModel;
  langModel = new LanguageModel();

  constructor(
    public authenticationSevice: AuthenticationService,
    private _fb: FormBuilder,
    public _paymentValidateService: FormValidateService,
    public _packageService: PackageService,private _appComponent: AppComponent
    ,public cookie: CookieService) {
    super(authenticationSevice, authenticationSevice, cookie);
    this.payment = new PaymentModel();
  }

  ngOnInit(): void {
    //chuyển ngữ
    this._appComponent.translate.get('ad_choose_bank').subscribe((res: string) => {
      this.langModel.title = res;
    });
    if ($('.custom-selectbox').length){
      $('.custom-selectbox').each((function(){
        var placeholder = $(this).attr('data-placeholder');
        $(this).select2({
          minimumResultsForSearch: -1,
          placeholder: placeholder || this.langModel.title
        });
      }));
    }
    this.createForm();
  }
  PaymentByBank() {
    this._packageService.paymentByBank(this.payment).subscribe(
      res => {
        window.open(res.checkoutUrl, "_blank");
       },
      err => {
        if (err === 'Unauthorized') {
          this.unAuthHandle(() => this.PaymentByBank());
        }
        else {
          console.log('Lỗi thanh toán thẻ ngân hàng ' + err);
        }

      }
    );
  }
  createForm() {
    this.paymentForm = this._fb.group({
      amount: ['', this._paymentValidateService.PaymentValidate.amountValidate.validator],
      bankCode: ['', this._paymentValidateService.PaymentValidate.bankValidate.validator]
    });
  }
}
