import {Component, DoCheck, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-goi-cuoc-not-buy',
  templateUrl: './goi-cuoc-not-buy.component.html',
  styleUrls: ['./goi-cuoc-not-buy.component.css']
})
export class GoiCuocNotBuyComponent implements OnInit, DoCheck {
@Input() bunch: any;
@Output() showBuyBunch = new EventEmitter<any>();
@Output() showModal = new EventEmitter<any>();
@Output() sendIdBunch  = new EventEmitter<any>();
@Output() sendIsClick = new EventEmitter<any>();
@Output() showBuyCam = new EventEmitter<any>();

  isClick = false;
  marginLeft: number;

  constructor() { }
  ngDoCheck(): void {
  }
  ngOnInit(): void {
  }


  onClick() {
    this.bunch.isClick = !this.bunch.isClick;
    this.sendIdBunch.emit(this.bunch);
    this.sendIsClick.emit(this.bunch.isClick);
    console.log(this.bunch);
  }

  displayLevel() {
    this.marginLeft = (this.bunch.level * 20) + 15;
    let paddingString = 'margin-left: ' + this.marginLeft + 'px;';
    return paddingString;
  }


  ShowBuyCam($event: any) {
    this.showBuyCam.emit($event);
    console.log($event);
  }

  ShowBuyBunch($event: any) {
    this.showBuyBunch.emit($event);
    console.log('show buy bunch - not bought');
    console.log($event);
  }

  getItemStyle(item) {
    let style = '';
    if (item.parentId > 0) {
      style += ' background-color: #f1f1f5; ';
    }
    return style;
  }

}
