<div class="container-fluid p-0">
    <app-header></app-header>
    <div class="page-container page-account">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-12 col-lg-6">
                    <div class="account-header">
                        <div>
                            <h2 class="page-title">{{'li_active'|translate}}</h2>
                        </div>
                        <div>
                            <button class="btn btn-primary btn-change-password" type="button"
                                data-toggle="modal" data-target="#changePassword">{{'li_change_pass'|translate}}</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-lg-6">
                    <div class="account-information">
                        <form class="account-form" [formGroup]="accForm" (ngSubmit)="accForm.valid && UpdateAccount()">
                            <div class="form-group">
                                <label for="account-name">{{'li_user_name'|translate}}</label>
                                <input type="text" class="form-control" id="account-name" placeholder=""
                                    formControlName="username" value="{{currAccount.username}}" disabled>
                            </div>
                            <div class="form-group">
                                <label for="account-email">Email</label>
                                <input type="email" class="form-control" id="account-email" placeholder=""
                                    formControlName="email" value="{{currAccount.email}}" disabled>
                            </div>
                            <div class="form-group">
                                <label for="account-full-name">{{'li_full_name'|translate}}</label>
                                <input type="text" class="form-control" id="account-full-name"
                                    [(ngModel)]="currAccount.fullName" placeholder="" formControlName="fullName"
                                    value="{{currAccount.fullName}}">
                                <div *ngIf="accForm.controls.fullName.invalid && accForm.controls.fullName.touched"
                                    class="alert alert-danger">
                                    {{_formValidateService.customerFormValidate.fullNameValidate.message(accForm.controls.fullName)}}
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="account-phone">{{'li_phone_number'|translate}}</label>
                                <input type="text" class="form-control" id="account-phone" placeholder=""
                                    [(ngModel)]="currAccount.phoneNumber" formControlName="phoneNumber"
                                    value="{{currAccount.phoneNumber}}">
                            </div>
                            <div class="form-group">
                                <label for="account-identity-card">{{'li_identity'|translate}}</label>
                                <input type="text" class="form-control" id="account-identity-card" placeholder=""
                                    [(ngModel)]="currAccount.personalId" formControlName="personalId"
                                    value="{{currAccount.personalId}}">
                            </div>
                            <div class="form-group">
                                <label for="account-address">{{'li_address'|translate}}</label>
                                <input type="text" class="form-control" id="account-address" placeholder=""
                                    formControlName="address" [(ngModel)]="currAccount.address"
                                    value="{{currAccount.address}}">
                            </div>
                            <div class="form-group">
                                <label for="account-address">{{'li_account_type'|translate}}</label>
                                <input type="text" class="form-control" id="customerType" placeholder=""
                                    formControlName="customerType" value="{{currAccount.customerType}}" disabled>
                            </div>
                            <div class="form-group">
                                <label for="account-address">{{'li_contract'|translate}}</label>
                                <input type="text" class="form-control" id="codeContracts" placeholder=""
                                    formControlName="codeContracts" value="{{currAccount.codeContracts}}" disabled>
                            </div>
                            <div class="form-group">
                                <!-- <label>Mã xác thực hai lớp</label>
                                <img [src]="qrCode" width="400" height="400"> -->
                                <label for="account-twofactor">{{'li_confirm_2factor'|translate}}&emsp;</label>
                                <div class="user-switch-status">
                                    <input type="checkbox" id="switch-stt" [(ngModel)]="isTwoFactor" [ngModelOptions]="{standalone: true}" (change)="ShowConfirmTwoFactor()" style="cursor:pointer;" /><label for="switch-stt"></label><span></span>
                                </div>
                            </div>
                            <div class="form-action mt-5">
                                <button class="btn btn-primary" type="submit" [disabled]="accForm.invalid">{{'li_save'|translate}}</button>
                            </div>
                        </form>
                    </div>
                </div>
                <div class="col-12 col-lg-6">
                    <div class="transaction-history">
                        <div class="transaction-header">
                            <div class="header-title">{{'li_trans_history'|translate}}</div>
                        </div>
                        <div class="transaction-body">
                            <div class="page-direction">
                                {{'li_have'|translate}}<span style="color: #ee0033; font-weight: bold;">{{totalHistory}}</span>{{'li_transaction'|translate}}
                            </div>
                            <table class="table table-striped history-table">
                                <thead>
                                    <tr>
                                        <th>{{'li_time'|translate}}</th>
                                        <th>{{'li_content'|translate}}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                        *ngFor="let his of listHistory| paginate: { itemsPerPage: pageSize || 2, currentPage: currentPage, totalItems: totalHistory }">
                                        <td>{{his.dateCreated|datex:'HH:mm DD/MM/YYYY'}}</td>
                                        <td *ngIf="his.type=='DECREASE'">{{'li_pay'|translate}}
                                            <strong>{{his.amount}}₫</strong> {{'li_pay_1'|translate}}
                                        </td>
                                        <td *ngIf="his.type=='INCREASE'">{{'li_charge'|translate}} <strong>{{his.amount}}₫</strong> {{'li_charge_1'|translate}}</td>
                                    </tr>
                                </tbody>
                            </table>

                            <div class="text-center mt-3">
                                <nav class="nav-pagination">
                                    <pagination-template #p="paginationApi" (pageChange)="currentPage = $event;">
                                        <ul *ngIf="pageSize<=p.getTotalItems()" class="pagination">
                                            <li class="page-item" [class.disabled]="p.isFirstPage()">
                                                <a *ngIf="!p.isFirstPage()" class="page-link" (click)="p.previous()">
                                                    <i class="icon icon-arrow-left"></i></a>
                                            </li>

                                            <li class="page-item" [class.active]="p.getCurrent()===page.value"
                                                *ngFor="let page of p.pages">
                                                <a class="page-link"
                                                    (click)="p.setCurrent(page.value)">{{page.label}}</a>
                                            </li>

                                            <li class="page-item" [class.disabled]="p.isLastPage()">
                                                <a *ngIf="!p.isLastPage()" class="page-link" (click)="p.next()">
                                                    <i class="icon icon-arrow-right"></i></a>
                                            </li>
                                        </ul>
                                    </pagination-template>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Modal -->
<div class="modal fade" id="changePassword" tabindex="-1" role="dialog" aria-labelledby="changePasswordTitle" data-backdrop="static" data-keyboard="false"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered account-modal" role="document">
        <div class="modal-content">
            <div class="modal-header bg-primary">
                <h5 class="modal-title" id="changePasswordTitle">{{'li_change_pass'|translate}}</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <i class="icon icon-close"></i>
                </button>
            </div>
            <div class="modal-body">
                <form [formGroup]="changePasswordForm" (ngSubmit)="changePasswordForm.valid && updatePassword()">
                    <div class="form-group">
                        <label class="control-label" for="current-password">{{'li_curr_pass'|translate}} (<span
                                class="text-danger">*</span>)</label>
                        <input autocomplete="off" [(ngModel)]="oldPassword" class="form-control" type="password"
                            id="current-password" placeholder="{{'li_enter_info'|translate}}" formControlName="oldPassword" required>
                        <div *ngIf="changePasswordForm.controls.oldPassword.invalid &&changePasswordForm.controls.oldPassword.touched"
                            class="alert alert-danger">
                            {{_formValidateService.customerFormValidate.oldPasswordValidate.message(changePasswordForm.controls.oldPassword)}}.
                        </div>
                    </div>
                    <div formGroupName="matchingPassword">
                        <div class="form-group">
                            <label class="control-label" for="new-password">{{'li_new_pass'|translate}} (<span
                                    class="text-danger">*</span>)</label>
                            <input [(ngModel)]="password" class="form-control" type="password" autocomplete="off"
                                formControlName="password" id="new-password" placeholder="{{'li_enter_info'|translate}}" required>
                            <div *ngIf="changePasswordForm.controls.matchingPassword.get('password').invalid &&changePasswordForm.controls.matchingPassword.get('password').touched"
                                class="alert alert-danger">
                                {{_formValidateService.customerFormValidate.passwordValidate.message(changePasswordForm.controls.matchingPassword.get('password'))}}.
                            </div>
                        </div>
                        <div class="form-group">
                            <label class="control-label" for="confirm-password">{{'li_re_pass'|translate}} (<span
                                    class="text-danger">*</span>)</label>
                            <input class="form-control" type="password" formControlName="passwordConfirm"
                                autocomplete="off" id="confirm-password" placeholder="{{'li_enter_info'|translate}}" required />
                            <div *ngIf="changePasswordForm.controls.matchingPassword.invalid && changePasswordForm.controls.matchingPassword.get('passwordConfirm').touched"
                                class="alert alert-danger">
                                {{_formValidateService.customerFormValidate.matchingPassword.message(changePasswordForm.controls.matchingPassword)}}.
                            </div>
                        </div>
                    </div>
                    <!-- <re-captcha (resolved)="resolved($event)" siteKey="6LcGBKkZAAAAAIPfAW0psL2lIPYfW-cEMpga5wnw"></re-captcha> -->
                    <!-- <re-captcha (resolved)="resolved($event)" siteKey="6LcxA6kZAAAAAIIV2SA7sUA-LmklNVEdet8Ba1yz"></re-captcha> -->
                    <!-- <re-captcha (resolved)="resolved($event)" siteKey="6LdKA6kZAAAAANmMBOx0njeanv_bOj-RmB8uvK1a"></re-captcha> -->
                    <div class="form-action">
                        <button class="btn btn-outline-secondary mr-3" type="button" data-dismiss="modal">{{'li_cancel'|translate}}</button>
                        <button class="btn btn-primary" [disabled]="changePasswordForm.invalid " type="submit">{{'li_confirm'|translate}}</button>
                        <!-- <button class="btn btn-primary" [disabled]="changePasswordForm.invalid || !isConfirmCaptcha" type="submit">Xác nhận</button> -->
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
<!--Modal twofactor-->
<div class="modal fade bd-example-modal-lg" id="twoFactorModal" tabindex="-1" role="dialog" data-backdrop="static" data-keyboard="false"
    aria-labelledby="twoFactorModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header bg-primary">
                <h5 class="modal-title" id="twoFactorTitle">{{'li_CONFIRM_ACCOUNT'|translate}}</h5>
                <button type="button" class="close" aria-label="Close" (click)="closeModal()">
                    <i class="icon icon-close"></i>
                </button>
            </div>
            <div class="modal-body">
                <form  [formGroup]="twoFactorForm" (ngSubmit)="twoFactorForm.valid && registerFactor()">
                    <div class="form-group">
                        <div class="form-group">
                            <label class="control-label" for="current-password">{{'li_step_1'|translate}} </label>
                            <div style="display: flex; align-items: center; justify-content: center;">
                                <img [src]="qrCode" width="400" height="400">
                            </div>
                        </div>
                        <div class="form-group">
                            <label class="control-label" for="current-password">{{'li_or_handmade'|translate}}&ensp;</label>
                            <label class="control-label">{{secretCode}}</label>
                        </div>
                        <div class="form-group">
                            <label class="control-label" for="current-password">{{'li_step_2'|translate}}&emsp;</label>
                            <input class="form-control" placeholder="{{'otp_placeholder'|translate}}" formControlName="otpCode" [(ngModel)]="otpCode"/>
                            <div *ngIf="twoFactorForm.controls.otpCode.invalid && twoFactorForm.controls.otpCode.touched"
                            class="alert alert-danger">
                            {{_formValidateService.twoFactorValidate.otpCodeValidate.message(twoFactorForm.controls.otpCode)}}.
                          </div>
                        </div>
                    </div>
                    <div class="form-action" style="text-align: center;">
                        <button class="btn btn-outline-secondary mr-3" type="button" (click)="closeModal()">{{'li_cancel'|translate}}</button>
                        <button class="btn btn-primary" [disabled]="twoFactorForm.invalid" type="submit">{{'li_confirm'|translate}}</button>
                    </div>
                </form>
            </div>

        </div>
    </div>
</div>