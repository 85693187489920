<div class="container-fluid p-3">
  <div class="page-title">
    Quản lý log
  </div>
  <div class="page-action">
    <div class="row">
      <div class="col-12 col-sm-6">
        <div class="row">
          <div class="col-12 col-sm-6">
            <div class="search-form camera-search">
              <label for="search-camera-group" class="sr-only">Nhập từ khóa</label>
              <input type="text" class="form-control" id="search-user-face-id" placeholder="Tìm kiếm"
                style="cursor: pointer" name="keyWord" [(ngModel)]="keySearch">
            </div>
          </div>
          <div class="col-12 col-sm-6">
            <select class="form-control" style="cursor: pointer; padding: 5px 15px; margin-left: 10px; height: 100%;">
              <option value="ALL" selected>Tất cả sự kiện</option>
              <option value="REGISTER_CAMERA_AI">Đăng ký camera</option>
              <option value="CANCEL_REGISTRATION">Hủy đăng ký camera</option>
              <option value="REGISTER_FACEID">Đăng ký FaceID</option>
              <option value="MODIFY_FACEID">Chỉnh sửa FaceID</option>
              <option value="LOCK_FACE_ID">Khóa FaceID</option>
              <option value="MODIFY_AREA">Hủy đăng ký khu vực</option>
            </select>
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-6">
        <div class="row">
          <div class="col-12 col-sm-6">
            <div class="datetime-range-picker-toggle mr-3" style="cursor: pointer; margin-left: 20px;"
              data-toggle="modal" data-target="#selectDate">
              {{ startTime | date : "HH:mm dd/MM/y"}} - {{endTime | date: "HH:mm dd/MM/y" }}
            </div>
          </div>
          <div class="col-12 col-sm-6">
            <button class="btn btn-primary btn-icon-text add-camera-group" data-toggle="modal"
              data-target="#addCameraGroup" (click)="findAllLog()">
              <span>Tìm kiếm</span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="page-table mt-3">
      <div class="container" style="max-width: 100%;">
        <div class="table-responsive">
          <table class="table table-striped user-table">
            <thead>
              <tr class="header-table" style="padding:15px 0px 15px 0px; text-align: center;">
                <th scope="col">STT</th>
                <th scope="col" class="text-left">Phân loại</th>
                <th scope="col" class="text-left">Tên thiết bị/FaceID</th>
                <th scope="col" class="text-left">Người thực hiện</th>
                <th scope="col" class="text-center">Thời gian tạo</th>
                <th scope="col">Trạng thái</th>
              </tr>
            </thead>
            <tbody *ngIf="loading">
              <tr>
                <td colspan="7" class="text-center">
                  <i class="fa fa-spinner fa-pulse"></i>
                  <span>Đang tải dữ liệu...</span>
                </td>
              </tr>
            </tbody>
            <tbody *ngIf="!loading && lstLog">
              <tr
                *ngFor="let log of lstLog|search:{label:['username','name'],keyword:keySearch||''}| paginate: { itemsPerPage: pageSize || 10, currentPage: currPage, totalItems: totalElement };let index=index;">
                <td>{{index + 1 + (pageSize * currPage)}}</td>
                <td>{{log.typeDisplay}}</td>
                <td>{{log.name}}</td>
                <td>{{log.username}}</td>
                <td style="text-align: center;">{{log.dateCreated|date:'h:mm dd/MM/yyyy'}}</td>
                <td>{{log.status}}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="row no-gutters">
          <div class="col-6 text-left" style="display: flex;align-items: center;">
            <label>Số hàng hiển thị mỗi trang</label>
            <select class="border-control" [(ngModel)]="pageSize"
              style="padding: 0.375rem 1rem; margin-left: 0.5rem;cursor: pointer;" (change)="pageSizeChange()">
              <option *ngFor="let size of pageSizes" [ngValue]="size">
                {{ size }}
              </option>
            </select>
          </div>
          <div class="col-6 text-right">
            <div class="text-right">
              <nav class="nav-pagination">
                <pagination-template #p="paginationApi" (pageChange)="onPageChange($event)">
                  <ul *ngIf="pageSize<=p.getTotalItems()" class="pagination">
                    <li class="page-item" [class.disabled]="p.isFirstPage()">
                      <a *ngIf="!p.isFirstPage()" class="page-link" (click)="p.previous()">
                        <i class="icon icon-arrow-left"></i></a>
                    </li>
                    <li class="page-item" [class.active]="p.getCurrent()===page.value" *ngFor="let page of p.pages">
                      <a class="page-link" (click)="p.setCurrent(page.value)">{{page.label}}</a>
                    </li>
                    <li class="page-item" [class.disabled]="p.isLastPage()">
                      <a *ngIf="!p.isLastPage()" class="page-link" (click)="p.next()">
                        <i class="icon icon-arrow-right"></i>
                      </a>
                    </li>
                  </ul>
                </pagination-template>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>


  <div class="modal fade" id="selectDate" tabindex="-1" role="dialog" aria-labelledby="selectDateTitle"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered select-date-modal" role="document">
      <div class="modal-content">
        <div class="modal-header bg-primary">
          <h5 class="modal-title" id="selectDateTitle">Chọn thời gian</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <i class="icon icon-close"></i>
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-md-6">
              <div class="selection-wrapper">
                <i class="icon icon-calendar"></i>
                <div class="form-group">
                  <input class="form-control" id="select-date-first" placeholder="Nhập ngày bắt đầu">
                </div>
              </div>
              <div class="selection-wrapper">
                <i class="icon icon-clock"></i>
                <div class="form-group">
                  <div class="form-control-time">
                    <select class="select-time d-inline-block" id="select-hours-first">
                      <option *ngFor="let h of hours; let i = index" [value]="h" [selected]="i == 0">{{h}}</option>
                    </select>
                  </div>
                  <div class="form-control-time">
                    <select class="select-time d-inline-block" id="select-minutes-first">
                      <option *ngFor="let m of minutes; let i= index" [value]="m" [selected]="i == 0">{{m}}</option>
                    </select>
                  </div>
                  <div class="form-control-time">
                    <select class="select-time d-inline-block" id="select-ampm-first">
                      <option value="AM">AM</option>
                      <option value="PM">PM</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="selection-wrapper">
                <i class="icon icon-calendar"></i>
                <div class="form-group">
                  <input class="form-control" id="select-date-second" placeholder="Nhập ngày kết thúc">
                </div>
              </div>
              <div class="selection-wrapper">
                <i class="icon icon-clock"></i>
                <div class="form-group">
                  <div class="form-control-time">
                    <select class="select-time d-inline-block" id="select-hours-second">
                      <option *ngFor="let h of hours; let i = index" [value]="h" [selected]="i == 0">{{h}}</option>
                    </select>
                  </div>
                  <div class="form-control-time">
                    <select class="select-time d-inline-block" id="select-minutes-second">
                      <option *ngFor="let m of minutes; let i= index" [value]="m" [selected]="i == 0">{{m}}</option>
                    </select>
                  </div>
                  <div class="form-control-time">
                    <select class="select-time d-inline-block" id="select-ampm-second">
                      <option value="AM">AM</option>
                      <option value="PM">PM</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12">
              <input id="date-time-picker5" />
              <div id="date-time-picker-container5"></div>
            </div>
            <div class="col-12 form-action text-center mt-0">
              <button class="btn btn-outline-secondary mr-3" type="button" data-dismiss="modal">Hủy bỏ</button>
              <button class="btn btn-primary" type="button" (click)="filterLog()">Chọn</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
