import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { PaginationComponent } from '../pagination/pagination.component';
import { PageComponent } from '../pagination/page/page.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgxPageScrollModule } from 'ngx-page-scroll';
import { FormsModule } from '@angular/forms';
import { HeaderComponent } from './header/header.component';
import { RouterModule } from '@angular/router';
import { WeeklyTimeRangeComponent } from './weekly-time-range/weekly-time-range.component';
import { PolygonDrawComponent } from './polygon-draw/polygon-draw.component';
import { MultipleSelectComponent } from './multiple-select/multiple-select.component';
import { PolygonImageComponent } from './polygon-image/polygon-image.component';


@NgModule({
  declarations: [
    HeaderComponent,
    PaginationComponent,
    PageComponent,
    WeeklyTimeRangeComponent,
    PolygonDrawComponent,
    PolygonImageComponent,
    MultipleSelectComponent,
  ],
  imports: [
    CommonModule,
    TranslateModule,
    NgxPaginationModule,
    NgxPageScrollModule,
    RouterModule,
    FormsModule
  ],
  exports: [
    HeaderComponent,
    PaginationComponent,
    PageComponent,
    WeeklyTimeRangeComponent,
    PolygonDrawComponent,
    PolygonImageComponent,
    MultipleSelectComponent,
  ]
})
export class SharedModule { }
