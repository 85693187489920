import { Injectable } from '@angular/core';
import { Http, URLSearchParams } from '@angular/http';
import { CookieService } from 'ngx-cookie-service';
import { BaseService } from './base.service';
import { ApiUrlService } from './api-url.service';

@Injectable({
  providedIn: 'root',
})
export class AiCameraIntrusionService extends BaseService {
  constructor(
    public _apiUrl: ApiUrlService,
    public _http: Http,
    public cookie: CookieService
  ) {
    super(_http, _apiUrl, cookie);
  }

  findIntrusionScenarioCameras(_scenarioId: number, keyword: string, _page: number, _limit: number) {
    const params: URLSearchParams = new URLSearchParams();
    params.set('scenarioId', `${_scenarioId}`);
    params.set('page', `${_page}`);
    params.set('limit', `${_limit}`);
    params.set('keyword', keyword);

    return this._http.get(this._apiUrlService.FIND_INTRUSION_SCENARIO_CAMERA, this.authorizationOption().merge({ search: params }))
      .map(this.extractData)
      .catch(this.handleError);
  }

  findIntrusionScenarioCamera(_id: number) {
    const params: URLSearchParams = new URLSearchParams();
    params.set('id', `${_id}`);
    return this._http.get(this._apiUrlService.FIND_INTRUSION_SCENARIO_CAMERA_BY_ID, this.authorizationOption().merge({ search: params }))
      .map(this.extractData)
      .catch(this.handleError);
  }

  updateIntrusionScenarioCamera(payload: any) {
    return this._http.post(this._apiUrlService.UPDATE_INTRUSION_SCENARIO_CAMERA, payload , this.authorizationOption())
      .map(this.extractData)
      .catch(this.handleError);
  }
}
