<div class="page-container page-login">
    <div class="container" style="position:relative">
      <div class="row"  style="position:absolute; left: 30%; top:180px;">
        <div class="col-lg-12">
          <div class="card login-block">
            <a href="javascript:;">
              <img class="logo-login" src="assets/logo camera.svg" alt="Cloud Camera">
            </a>
            <h2 class="card-title">{{'li_restore_pass'|translate}}</h2>
            <form class="form" *ngIf="showload == false">
              <div class="form-group mb-0 form-group-username">
                <div class="alert alert-danger text-center"> 
                    <i class="fa fa-cogs fa-spin">&nbsp;</i>{{'li_token_expire'|translate}}
                </div>
              </div>
              <div>
                <button class="text-center w-100 btn btn-primary btn-login" routerLink="/login">{{'li_LOGIN'|translate}}</button>
              </div>
            </form>
            <form [formGroup]="resetForm" (ngSubmit)="resetForm.valid && ResetPass()" class="form" *ngIf="showload == true">
                <div class="form-group mb-0 form-group-username">
                  <div class="alert alert-success text-center">
                    {{'li_greate_token'|translate}}
                  </div>
                  
                  <div formGroupName="matchingPassword">
                    <div class="form-group">
                        <label class="control-label" for="new-password">{{'li_new_pass'|translate}} (<span
                                class="text-danger">*</span>)</label>
                        <input [(ngModel)]="password" class="form-control" type="password"
                            formControlName="password" id="new-password" placeholder="{{'li_enter_info'|translate}}" required>
                        <div *ngIf="resetForm.controls.matchingPassword.get('password').invalid &&resetForm.controls.matchingPassword.get('password').touched"
                            class="alert alert-danger">
                            {{_formValidateService.customerFormValidate.passwordValidate.message(resetForm.controls.matchingPassword.get('password'))}}.
                        </div>
                    </div>
                    <div class="form-group">
                        <label class="control-label" for="confirm-password">{{'li_re_pass'|translate}} (<span
                                class="text-danger">*</span>)</label>
                        <input class="form-control" type="password" formControlName="passwordConfirm"
                            id="confirm-password" placeholder="{{'li_enter_info'|translate}}" required />
                        <div *ngIf="resetForm.controls.matchingPassword.invalid && resetForm.controls.matchingPassword.get('passwordConfirm').touched"
                            class="alert alert-danger">
                            {{_formValidateService.customerFormValidate.matchingPassword.message(resetForm.controls.matchingPassword)}}.
                        </div>
                    </div>
                </div>
                </div>
                <div>
                  <button class="text-center w-100 btn btn-primary btn-login" [disabled]="resetForm.invalid" type="submit">{{'li_CHANGE_PASSS'|translate}}</button>
                </div>
              </form>
          </div>
        </div>
      </div>
    </div>
  </div>
