import { CameraModel } from './../_models/camera.model';
import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit, ViewChildren, QueryList, Injectable } from '@angular/core';
import { VideojsComponent } from '../_shared/videojs/videojs.component';
import { TreeviewService } from './../_services/treeview.service';
import Swal from 'sweetalert2';
import { WebrtcplayerComponent } from '../_shared/webrtc/webrtcplayer/webrtcplayer.component';
import { CameraService } from '../_services';
import { NotifiService } from '../_services/notifi.service';
import { NotifiModel } from '../_models/notifi.model';
import { AppComponent } from '../app.component';
import { LanguageModel } from '../_models/language.model';
declare var $: any;
@Component({
  selector: 'app-liveview',
  templateUrl: './liveview.component.html',
  styleUrls: ['./liveview.component.css']
})
export class LiveviewComponent implements OnInit {
  @ViewChildren('videojs') videojs: QueryList<VideojsComponent>;
  @ViewChildren('webrtc') webrtc: QueryList<WebrtcplayerComponent>;
  langModel = new LanguageModel();
  listCameraPlaying: CameraModel[] = [];
  listMetaData: String[] = [];
  gridId = "tab2x2";
  gridClass = "col-md-6";
  gridLabel = "2x2-tab";
  lstNotifi: NotifiModel[];
  currNotifi: NotifiModel = new NotifiModel();
  constructor(@Inject(DOCUMENT) private document: any, private _cameraService: CameraService,
  private _appComponent: AppComponent,
    private _notifiService: NotifiService,) { }
  elem;
  elemCam;
  cameraFull;

  ngOnInit(): void {
    this.getListNotifi();
    this.elem = document.getElementsByClassName("tab-content").item(0);
    //chuyển ngữ
    this._appComponent.translate.get('li_notiffi').subscribe((res: string) => {
      this.langModel.title = res;
    });
    this._appComponent.translate.get('li_max_view').subscribe((res: string) => {
      this.langModel.content = res;
    });
  }
  getListNotifi() {
    this.lstNotifi = [];
    this._notifiService.getListNotifiWeb().subscribe(
      res => {
        let data = res;
        data.forEach(element => {
          if (element.notify == true) {
            this.lstNotifi.push(element);
          }
        });
        if (this.lstNotifi.length > 0) {
          this.lstNotifi.forEach(e => {
            this.currNotifi = e;
            $('#mNotifiInfo').modal('show');
          })
        }
      },
      err => {
        console.log('Lỗi lấy danh sách thông báo: getListNotifi');
      }
    );
  }

  liveView(camera: CameraModel) {
    if (this.listCameraPlaying?.length >= 16) {
      Swal.fire(this.langModel.title, this.langModel.content, 'warning');
    }
    else {
      if (!this.listCameraPlaying.find(item => item.id === camera.id)) {
        camera.state = 1;
        this.listCameraPlaying.push(camera);
      }
    }
    if (this.listCameraPlaying?.length < 2) {
      this.ChangeTab(1);
    }
    else if (this.listCameraPlaying?.length >= 2 && this.listCameraPlaying?.length < 5) {
      this.ChangeTab(2);
    }
    else if (this.listCameraPlaying?.length >= 5 && this.listCameraPlaying?.length < 10) {
      this.ChangeTab(3);
    }
    else if (this.listCameraPlaying?.length >= 10 && this.listCameraPlaying?.length < 17) {
      this.ChangeTab(4);
    }
  }
  getInfoMetaData(camera: CameraModel) {
    this._cameraService.getInfoMetaCam(camera.id).subscribe(
      res => {
        this.listMetaData = [];
        this.listMetaData.push('Codec: ' + res.codecName);
        this.listMetaData.push('Resolution: ' + res.width + ' x ' + res.height);
        this.listMetaData.push('FrameRate: ' + res.realFrameRate);
        let bitRate = Math.round(res.variantBitrate / 8192);
        this.listMetaData.push('Bitrate: ' + bitRate + 'kb');
      },
      err => {
        console.log('Lỗi call data getInfoMetaData()');
      }
    );
  }
  cameraTrackById(index, camera: CameraModel) {
    return camera.id;
  }

  deactiveRouter() {
    if (this.listCameraPlaying.length > 0) {
      this.videojs.forEach(video => {
        video.camera.state = 0;
        video.close();
      });
      this.listCameraPlaying = [] as CameraModel[];
    }
  }

  ChangeTab(num: number) {
    switch (num) {
      case 1:
        this.gridId = "tab1x1";
        this.gridClass = "col-md-12";
        this.gridLabel = "1x1-tab";
        $(".nav-link").removeClass("active");
        $("#1x1-tab").addClass("active");
        break;
      case 2:
        this.gridId = "tab2x2";
        this.gridClass = "col-md-6";
        this.gridLabel = "2x2-tab";
        $(".nav-link").removeClass("active");
        $("#2x2-tab").addClass("active");
        break;
      case 3:
        this.gridId = "tab3x3";
        this.gridClass = "col-md-4";
        this.gridLabel = "3x3-tab";
        $(".nav-link").removeClass("active");
        $("#3x3-tab").addClass("active");
        break;
      case 4:
        this.gridId = "tab4x4";
        this.gridClass = "col-md-3";
        this.gridLabel = "4x4-tab";
        $(".nav-link").removeClass("active");
        $("#4x4-tab").addClass("active");
        break;
    }
  }
  //Videojs
  removeVideo(video: VideojsComponent) {
    video.camera.state = 0;
    const videoIndex = this.listCameraPlaying.indexOf(video.camera);
    video.close();
    if (videoIndex !== -1) {
      this.listCameraPlaying.splice(videoIndex, 1);
    }

    if (this.listCameraPlaying?.length < 2) {
      this.ChangeTab(1);
    }
    else if (this.listCameraPlaying?.length >= 2 && this.listCameraPlaying?.length < 5) {
      this.ChangeTab(2);
    }
    else if (this.listCameraPlaying?.length >= 5 && this.listCameraPlaying?.length < 10) {
      this.ChangeTab(3);
    }
    else if (this.listCameraPlaying?.length >= 10 && this.listCameraPlaying?.length < 17) {
      this.ChangeTab(4);
    }
  }
  openFullscreen() {
    this.elem.styl = "height: 100%; display: flex;"
    if (this.elem.requestFullscreen) {
      this.elem.requestFullscreen();
    } else if (this.elem.mozRequestFullScreen) {
      /* Firefox */
      this.elem.mozRequestFullScreen();
    } else if (this.elem.webkitRequestFullscreen) {
      /* Chrome, Safari and Opera */
      this.elem.webkitRequestFullscreen();
    } else if (this.elem.msRequestFullscreen) {
      /* IE/Edge */
      this.elem.msRequestFullscreen();
    }
  }
  /* Close fullscreen */
  closeFullscreen() {
    if (this.document.exitFullscreen) {
      this.document.exitFullscreen();
    } else if (this.document.mozCancelFullScreen) {
      /* Firefox */
      this.document.mozCancelFullScreen();
    } else if (this.document.webkitExitFullscreen) {
      /* Chrome, Safari and Opera */
      this.document.webkitExitFullscreen();
    } else if (this.document.msExitFullscreen) {
      /* IE/Edge */
      this.document.msExitFullscreen();
    }
  }
  ResetListPlaying() {
    this.listCameraPlaying = [] as CameraModel[];
  }

  fullScreenCam(video: VideojsComponent) {
    video.player.requestFullscreen();
  }

  setZoomIn(videojs: VideojsComponent) {
    videojs.zoomInVideo();
  }

  setZoomOut(videojs: VideojsComponent) {
    videojs.zoomOutVideo();
  }

  Snapshot(videojs: VideojsComponent) {
    videojs.takeSnapshot();
  }
  //WebRTC
  fullScreenCamera(camera: WebrtcplayerComponent) {
    this.elemCam = document.getElementById("camera_" + camera.camera.id);
    this.elemCam.styl = "height: 100%; display: flex;"
    if (this.elemCam.requestFullscreen) {
      this.elemCam.requestFullscreen();
    } else if (this.elemCam.mozRequestFullScreen) {
      /* Firefox */
      this.elemCam.mozRequestFullScreen();
    } else if (this.elemCam.webkitRequestFullscreen) {
      /* Chrome, Safari and Opera */
      this.elem.webkitRequestFullscreen();
    } else if (this.elem.msRequestFullscreen) {
      /* IE/Edge */
      this.elemCam.msRequestFullscreen();
    }
  }
  removeCamera(camera: WebrtcplayerComponent) {
    camera.camera.state = 0;
    const videoIndex = this.listCameraPlaying.indexOf(camera.camera);
    camera.Close();
    if (videoIndex !== -1) {
      this.listCameraPlaying.splice(videoIndex, 1);
    }

    if (this.listCameraPlaying?.length < 2) {
      this.ChangeTab(1);
    }
    else if (this.listCameraPlaying?.length >= 2 && this.listCameraPlaying?.length < 5) {
      this.ChangeTab(2);
    }
    else if (this.listCameraPlaying?.length >= 5 && this.listCameraPlaying?.length < 10) {
      this.ChangeTab(3);
    }
    else if (this.listCameraPlaying?.length >= 10 && this.listCameraPlaying?.length < 17) {
      this.ChangeTab(4);
    }
  }
  SnapshotCamera(camera: WebrtcplayerComponent) {
    camera.takeSnapshot();
  }
  deactiveRouter2() {
    if (this.webrtc.length > 0) {
      this.webrtc.forEach(player => {
        player.camera.state = 0;
        player.Close();
      });
      this.listCameraPlaying = [] as CameraModel[];
    }
    if (this.videojs.length > 0) {
      this.videojs.forEach(video => {
        video.camera.state = 0;
        video.close();
      });
      this.listCameraPlaying = [] as CameraModel[];
    }
  }
  ReloadCamera(camera: WebrtcplayerComponent) {
    camera.Close();
    camera.player.playRemote();
  }
  CloseNotifi() {
    $('#mNotifiInfo').modal('hide');
    let checked = $('#cbRemind').prop('checked');
    if (checked == true) {
      this._notifiService.stopNotifi(this.currNotifi.maintenanceNotificationId).subscribe(
        res => {
        },
        err => {
          console.log('Lỗi close notification');
        }
      );
    }
  }
}
