import { Component, OnInit } from '@angular/core';
import { CameraDisconnectModel } from 'src/app/_models/dashboard.model';
import { DashboardService } from 'src/app/_services/dashboard.service';
declare var $: any;
import * as _ from 'lodash';
import { AppComponent } from 'src/app/app.component';
import { LanguageModel } from 'src/app/_models/language.model';

@Component({
  selector: 'app-statistic-camera',
  templateUrl: './statistic-camera.component.html',
  styleUrls: ['./statistic-camera.component.css']
})
export class StatisticCameraComponent implements OnInit {

  constructor(private _dashboardService: DashboardService,
    private _appComponent: AppComponent) { }
  camDisconnects: CameraDisconnectModel[] = [];
  langModel = new LanguageModel();
  term: string = '';
  pageSize: number = 10;
  currentPage: number = 1;
  order: string = 'cameraName';
  titleSelect: string = "Trạng thái Camera";
  titleBunch: string = "Nhóm camera";
  listStatus = [
    { key: 'ALL', value: "Tất cả trạng thái" },
    { key: 'CONNECTED', value: "Đang kết nối" },
    { key: 'DISCONNECTED', value: "Không kết nối" }];
  filterStatus: any = null;
  listBunch: BunchModel[] = [];
  ngOnInit(): void {
    $('#iDashboard').addClass("active");
    //chuyển ngữ
    this._appComponent.translate.get('li_not_bunch').subscribe((res: string) => {
      this.langModel.title = res;
    });
    this._appComponent.translate.get('li_all_status').subscribe((res: string) => {
      this.langModel.content = res;
    });
    this._appComponent.translate.get('ad_connect').subscribe((res: string) => {
      this.langModel.title1 = res;
    });
    this._appComponent.translate.get('ad_no_connect').subscribe((res: string) => {
      this.langModel.content1 = res;
    });
    let b = new BunchModel();
    this.listBunch.push({ bunchId: 0, bunchName: this.langModel.title });
    this.getCameraDisconnect();
    
  this.listStatus = [
    { key: 'ALL', value: this.langModel.content },
    { key: 'CONNECTED', value: this.langModel.title1 },
    { key: 'DISCONNECTED', value: this.langModel.content1 }];
  }
  getCameraDisconnect() {
    this._dashboardService.getDetailCameraDisconnect().subscribe(
      res => {
        this.camDisconnects = res;
        this.camDisconnects.forEach(cam => {
          if (cam.bunchId != null) {
            this.getListBunch(cam.bunchId, cam.bunchName);
          }
        });
        console.log(this.listBunch);
      },
      err => {
        console.log(err);
      }
    );
  }
  getFilter($event: any) {
    this.titleSelect = $event.value;
    this.filterStatus = $event.key;
    this._dashboardService.getDetailCameraDisconnect().subscribe(
      res => {
        this.camDisconnects = res;
        if (this.filterStatus == "CONNECTED") {
          this.camDisconnects = _.filter(this.camDisconnects, function (o) { return o.connectionStatus == "CONNECTED"; });
        }
        else if (this.filterStatus == "DISCONNECTED") {
          this.camDisconnects = _.filter(this.camDisconnects, function (o) { return o.connectionStatus == "DISCONNECTED"; });
        }
      },
      err => {
        console.log(err);
      }
    );
  }
  getFilterBunch($event: any) {
    this.titleBunch = $event.bunchName;
    let idFilter = $event.bunchId;
    this._dashboardService.getDetailCameraDisconnect().subscribe(
      res => {
        this.camDisconnects = res;
        if (idFilter != 0) {
          this.camDisconnects = _.filter(this.camDisconnects, function (o) { return o.bunchId == idFilter; });
        }
        else {
          this.camDisconnects = _.filter(this.camDisconnects, function (o) { return o.bunchId == null; });
        }
      },
      err => {
        console.log(err);
      }
    );
  }
  getListBunch(_bunchId, _bunchName) {
    const found = this.listBunch.some(el => el.bunchId === _bunchId);
    if (!found) {
      this.listBunch.push({ bunchId: _bunchId, bunchName: _bunchName });
    }
  }
}
export class BunchModel {
  bunchId: number = 0;
  bunchName: string = "";
}