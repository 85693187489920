export class PaymentModel {
    totalAmount: number;
    bankCode: string="";
}
export class PackageModel {
    id: number;
    name: string;
    playbackDuration: number;
    price: number;
    startTime: number;
    usagePeriod: number;
    resolution:string;
}
export class BuyModel {
    packageId: number;
    cameraId: number;
    resolution:string;
    cameraName:string;
    packageName:string;
    price:number;
}
export class Buy2Model{
    packageId: number;
    cameraId: number;
}