import { Component, ViewChild, ElementRef, OnInit, Input } from '@angular/core';

@Component({
  selector: 'polygon-image',
  templateUrl: './polygon-image.component.html',
  styleUrls: ['./polygon-image.component.css']
})
export class PolygonImageComponent {
  @ViewChild('canvasElement', { static: true }) canvasElement: ElementRef<HTMLCanvasElement>;

  @Input() imageSrc: string;
  @Input() polygons: any[];
  @Input() width: number;
  @Input() lineWidth: number = 1;

  onImageLoad(event: Event) {
    const image = event.target as HTMLImageElement;
    const canvas = this.canvasElement.nativeElement;
    const ctx = canvas.getContext('2d');

    // Set canvas width and height
    canvas.width = this.width || image.width;
    canvas.height = this.width ? image.height * (this.width / image.width) : image.height;

    this.polygons.forEach(polygon => {
      const points = [];
      for (let i = 0; i < polygon.length; i += 2) {
        points.push([polygon[i], polygon[i + 1]]);
      }

      this.drawPolygons(points, ctx);
    });
  }

  drawPolygons(points: number[][], ctx: CanvasRenderingContext2D) {

    ctx.beginPath();
    ctx.moveTo(points[0][0] * ctx.canvas.width, points[0][1] * ctx.canvas.height);

    for (let i = 1; i < points.length; i++) {
      ctx.lineTo(points[i][0] * ctx.canvas.width, points[i][1] * ctx.canvas.height);
    }

    ctx.closePath();
    ctx.lineWidth = this.lineWidth;
    ctx.strokeStyle = 'red';
    ctx.fillStyle = 'rgba(255, 0, 0, 0.25)';
    ctx.stroke();
    ctx.fill();
  }
}
