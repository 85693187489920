<div class="container-fluid p-2">
  <div class="page-title">
    <p>Cấu hình cảnh báo thông minh</p>
  </div>
  <div class="page-desc">
    Chọn các camera có hiển thị cảnh báo trên trình duyệt hiện tại của bạn
  </div>
  <div class="page-action">
    <div class="row">
      <div class="col-12">
        <div class="d-flex">
          <select [(ngModel)]="selectedScenario" class="select-wrapper form-control mr-3" (change)="changeScenario()">
            <option *ngFor="let option of scenarioOptions" [value]="option.value">{{ option.label }}</option>
          </select>
          <div class="search-form event-search">
            <label for="search-camera" class="sr-only">Tìm kiếm camera</label>
            <input type="text" class="form-control border-control" id="search-camera" placeholder="Tìm kiếm camera"
              [(ngModel)]="searchTxt" (ngModelChange)="onSearchChange($event)">
          </div>
        </div>
      </div>
    </div>
    <div class="page-table" style="padding-left: 0;">
      <div class="container">
        <div style="padding:10px 0; font-weight: bold; display: flex; border-bottom: 1px solid #F2F2F2;">
          <input type="checkbox" id="selectAll" (change)="onSelectAllGroupChange($event)"
            style="width: 18px; height: 18px;" /><span style="margin-left: 15px;" for="selectAll">Chọn tất cả</span>
        </div>
        <div id="tblRegisCam" style="margin-bottom: 20px">
          <div *ngFor="let group of cameraGroups">
            <div style="padding:10px 0; display: flex;border-bottom: 1px solid #F2F2F2;">
              <input id="ipgr_{{group.bunchId}}" type="checkbox" class="gr_{{group.id}}"
                style="width: 18px; height: 18px;" (change)="onSelectGroupChange($event,group.bunchId)"
                name="cb_RegisAI">
              <div id="{{group.bunchId}}" style="cursor: pointer;" (click)="bunchClick(group.bunchId)">
                <span style="font-weight: bold; margin-left: 15px;">{{group.bunchName}}</span>
              </div>
            </div>
            <app-config-camera-list [(cameras)]="group.cameras" id="app_{{group.bunchId}}"
              style="visibility: collapse;"></app-config-camera-list>
          </div>
        </div>
        <div class="form-action d-flex justify-content-end">
          <button class="btn btn-primary m-0" (click)="onSave()">{{'li_save'|translate}}</button>
        </div>
      </div>
    </div>
  </div>
</div>
