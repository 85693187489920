<div class="container-fluid p-0">
  <app-header [balance]="packageBalance"></app-header>
  <div class="page-container page-data-package">
    <div class="container">
      <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
        <li class="nav-item">
          <a class="nav-link active" id="h264-package-tab" data-toggle="pill" href="#h264-package" role="tab"
            aria-controls="h264-package" aria-selected="true">{{'ad_codec'|translate}} H264</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" id="h265-package-tab" data-toggle="pill" href="#h265-package" role="tab"
            (click)="RefreshSlider()" aria-controls="h265-package" aria-selected="true">{{'ad_codec'|translate}}
            H265</a>
        </li>
      </ul>
      <div class="tab-content" id="pills-tabContent">


        <div class="tab-pane fade show active" id="h264-package" role="tabpanel" aria-labelledby="h264-package-tab">

          <div class="page-carousel package-carousel">
            <div class="container">
              <div class="package-wrapper">
                <div class="package-list">
                  <!--Bảng giá với camera chất lượng SD-->
                  <div class="package-item">
                    <div class="block-prices__table">
                      <div class="block-prices__table-inner" data-mh="group-price-table">
                        <div class="card-image">
                          <img src="assets/landing/card-title.png" alt="" />
                          <h4 class="img-text">{{'ad_live_package'|translate}}</h4>
                        </div>
                        <div class="card-content">
                          <h4 class="package-price text-primary">20.000 đ</h4>
                          <span class="" style="margin-bottom:32px; color: #92929d;">/Camera</span>
                          <div class="package-time">
                            <p>{{'ad_0day_save'|translate}}</p>
                            <p>{{'ldp_cycle'|translate}} </p>
                            <p>{{'ad_image_quality'|translate}}: SD480</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="package-item">
                    <div class="block-prices__table">
                      <div class="block-prices__table-inner" data-mh="group-price-table">
                        <div class="card-image">
                          <img src="assets/landing/card-title.png" alt="" />
                          <h4 class="img-text">{{'ad_pack_3d'|translate}}</h4>
                        </div>
                        <div class="card-content">
                          <h4 class="package-price text-primary">35.000 đ</h4>
                          <span class="" style="margin-bottom:32px; color: #92929d;">/Camera</span>
                          <div class="package-time">
                            <p>{{'ldp_timesave_3d'|translate}}</p>
                            <p>{{'ldp_cycle'|translate}}</p>
                            <p>{{'ad_image_quality'|translate}}: SD480</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="package-item">
                    <div class="block-prices__table">
                      <div class="block-prices__table-inner" data-mh="group-price-table">
                        <div class="card-image">
                          <img src="assets/landing/card-title.png" alt="" />
                          <h4 class="img-text">{{'ad_pack_7d'|translate}}</h4>
                        </div>
                        <div class="card-content">
                          <h4 class="package-price text-primary">45.000 đ</h4>
                          <span class="" style="margin-bottom:32px; color: #92929d;">/Camera</span>
                          <div class="package-time">
                            <p>{{'ldp_timesave_7d'|translate}}</p>
                            <p>{{'ldp_cycle'|translate}}</p>
                            <p>{{'ad_image_quality'|translate}}: SD480</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="package-item">
                    <div class="block-prices__table">
                      <div class="block-prices__table-inner" data-mh="group-price-table">
                        <div class="card-image">
                          <img src="assets/landing/card-title.png" alt="" />
                          <h4 class="img-text">{{'ad_pack_14d'|translate}}</h4>
                        </div>
                        <div class="card-content">
                          <h4 class="package-price text-primary">65.000 đ</h4>
                          <span class="" style="margin-bottom:32px; color: #92929d;">/Camera</span>
                          <div class="package-time">
                            <p>{{'ldp_timesave_14d'|translate}}</p>
                            <p>{{'ldp_cycle'|translate}}</p>
                            <p>{{'ad_image_quality'|translate}}: SD480</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="package-item">
                    <div class="block-prices__table">
                      <div class="block-prices__table-inner" data-mh="group-price-table">
                        <div class="card-image">
                          <img src="assets/landing/card-title.png" alt="" />
                          <h4 class="img-text">{{'ad_pack_15d'|translate}}</h4>
                        </div>
                        <div class="card-content">
                          <h4 class="package-price text-primary">70.000 đ</h4>
                          <span class="" style="margin-bottom:32px; color: #92929d;">/Camera</span>
                          <div class="package-time">
                            <p>{{'ldp_timesave_15d'|translate}}</p>
                            <p>{{'ldp_cycle'|translate}}</p>
                            <p>{{'ad_image_quality'|translate}}: SD480</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="package-item">
                    <div class="block-prices__table">
                      <div class="block-prices__table-inner" data-mh="group-price-table">
                        <div class="card-image">
                          <img src="assets/landing/card-title.png" alt="" />
                          <h4 class="img-text">{{'ad_pack_30d'|translate}}</h4>
                        </div>
                        <div class="card-content">
                          <h4 class="package-price text-primary">110.000 đ</h4>
                          <span class="" style="margin-bottom:32px; color: #92929d;">/Camera</span>
                          <div class="package-time">
                            <p>{{'ldp_timesave_30d'|translate}}</p>
                            <p>{{'ldp_cycle'|translate}}</p>
                            <p>{{'ad_image_quality'|translate}}: SD480</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="package-item">
                    <div class="block-prices__table">
                      <div class="block-prices__table-inner" data-mh="group-price-table">
                        <div class="card-image">
                          <img src="assets/landing/card-title.png" alt="" />
                          <h4 class="img-text">{{'ad_pack_60d'|translate}}</h4>
                        </div>
                        <div class="card-content">
                          <h4 class="package-price text-primary">205.000 đ</h4>
                          <span class="" style="margin-bottom:32px; color: #92929d;">/Camera</span>
                          <div class="package-time">
                            <p>{{'ldp_timesave_60d'|translate}} </p>
                            <p>{{'ldp_cycle'|translate}}</p>
                            <p>{{'ad_image_quality'|translate}}: SD480</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="package-item">
                    <div class="block-prices__table">
                      <div class="block-prices__table-inner" data-mh="group-price-table">
                        <div class="card-image">
                          <img src="assets/landing/card-title.png" alt="" />
                          <h4 class="img-text">{{'ad_pack_90d'|translate}}</h4>
                        </div>
                        <div class="card-content">
                          <h4 class="package-price text-primary">300.000 đ</h4>
                          <span class="" style="margin-bottom:32px; color: #92929d;">/Camera</span>
                          <div class="package-time">
                            <p>{{'ldp_timesave_90d'|translate}}</p>
                            <p>{{'ldp_cycle'|translate}}</p>
                            <p>{{'ad_image_quality'|translate}}: SD480</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!--Bảng giá với camera chất lượng HD-->
                  <div class="package-item">
                    <div class="block-prices__table">
                      <div class="block-prices__table-inner" data-mh="group-price-table">
                        <div class="card-image">
                          <img src="assets/landing/card-title.png" alt="" />
                          <h4 class="img-text">{{'ad_live_package'|translate}}</h4>
                        </div>
                        <div class="card-content">
                          <h4 class="package-price text-primary">25.000 đ</h4>
                          <span class="" style="margin-bottom:32px; color: #92929d;">/Camera</span>
                          <div class="package-time">
                            <p>{{'ad_0day_save'|translate}} </p>
                            <p>{{'ldp_cycle'|translate}}</p>
                            <p>{{'ad_image_quality'|translate}}: HD720</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="package-item">
                    <div class="block-prices__table">
                      <div class="block-prices__table-inner" data-mh="group-price-table">
                        <div class="card-image">
                          <img src="assets/landing/card-title.png" alt="" />
                          <h4 class="img-text">{{'ad_pack_3d'|translate}}</h4>
                        </div>
                        <div class="card-content">
                          <h4 class="package-price text-primary">54.000 đ</h4>
                          <span class="" style="margin-bottom:32px; color: #92929d;">/Camera</span>
                          <div class="package-time">
                            <p>{{'ad_3day_save'|translate}}</p>
                            <p>{{'ldp_cycle'|translate}}</p>
                            <p>{{'ad_image_quality'|translate}}: HD720</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="package-item">
                    <div class="block-prices__table">
                      <div class="block-prices__table-inner" data-mh="group-price-table">
                        <div class="card-image">
                          <img src="assets/landing/card-title.png" alt="" />
                          <h4 class="img-text">{{'ad_pack_7d'|translate}}</h4>
                        </div>
                        <div class="card-content">
                          <h4 class="package-price text-primary">85.000 đ</h4>
                          <span class="" style="margin-bottom:32px; color: #92929d;">/Camera</span>
                          <div class="package-time">
                            <p>{{'ad_7day_save'|translate}}</p>
                            <p>{{'ldp_cycle'|translate}}</p>
                            <p>{{'ad_image_quality'|translate}}: HD720</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="package-item">
                    <div class="block-prices__table">
                      <div class="block-prices__table-inner" data-mh="group-price-table">
                        <div class="card-image">
                          <img src="assets/landing/card-title.png" alt="" />
                          <h4 class="img-text">{{'ad_pack_14d'|translate}}</h4>
                        </div>
                        <div class="card-content">
                          <h4 class="package-price text-primary">120.000 đ</h4>
                          <span class="" style="margin-bottom:32px; color: #92929d;">/Camera</span>
                          <div class="package-time">
                            <p>{{'ad_14day_save'|translate}}</p>
                            <p>{{'ldp_cycle'|translate}}</p>
                            <p>{{'ad_image_quality'|translate}}: HD720</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="package-item">
                    <div class="block-prices__table">
                      <div class="block-prices__table-inner" data-mh="group-price-table">
                        <div class="card-image">
                          <img src="assets/landing/card-title.png" alt="" />
                          <h4 class="img-text">{{'ad_pack_15d'|translate}}</h4>
                        </div>
                        <div class="card-content">
                          <h4 class="package-price text-primary">126.000 đ</h4>
                          <span class="" style="margin-bottom:32px; color: #92929d;">/Camera</span>
                          <div class="package-time">
                            <p>{{'ad_15day_save'|translate}}</p>
                            <p>{{'ldp_cycle'|translate}}</p>
                            <p>{{'ad_image_quality'|translate}}: HD720</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="package-item">
                    <div class="block-prices__table">
                      <div class="block-prices__table-inner" data-mh="group-price-table">
                        <div class="card-image">
                          <img src="assets/landing/card-title.png" alt="" />
                          <h4 class="img-text">{{'ad_pack_30d'|translate}}</h4>
                        </div>
                        <div class="card-content">
                          <h4 class="package-price text-primary">210.000 đ</h4>
                          <span class="" style="margin-bottom:32px; color: #92929d;">/Camera</span>
                          <div class="package-time">
                            <p>{{'ad_30day_save'|translate}}</p>
                            <p>{{'ldp_cycle'|translate}}</p>
                            <p>{{'ad_image_quality'|translate}}: HD720</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="package-item">
                    <div class="block-prices__table">
                      <div class="block-prices__table-inner" data-mh="group-price-table">
                        <div class="card-image">
                          <img src="assets/landing/card-title.png" alt="" />
                          <h4 class="img-text">{{'ad_pack_60d'|translate}}</h4>
                        </div>
                        <div class="card-content">
                          <h4 class="package-price text-primary">400.000 đ</h4>
                          <span class="" style="margin-bottom:32px; color: #92929d;">/Camera</span>
                          <div class="package-time">
                            <p>{{'ad_60day_save'|translate}}</p>
                            <p>{{'ldp_cycle'|translate}}</p>
                            <p>{{'ad_image_quality'|translate}}: HD720</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="package-item">
                    <div class="block-prices__table">
                      <div class="block-prices__table-inner" data-mh="group-price-table">
                        <div class="card-image">
                          <img src="assets/landing/card-title.png" alt="" />
                          <h4 class="img-text">{{'ad_pack_90d'|translate}}</h4>
                        </div>
                        <div class="card-content">
                          <h4 class="package-price text-primary">580.000 đ</h4>
                          <span class="" style="margin-bottom:32px; color: #92929d;">/Camera</span>
                          <div class="package-time">
                            <p>{{'ad_90day_save'|translate}}</p>
                            <p>{{'ldp_cycle'|translate}}</p>
                            <p>{{'ad_image_quality'|translate}}: HD720</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!--Bảng giá với camera chất lượng FHD-->
                  <div class="package-item">
                    <div class="block-prices__table">
                      <div class="block-prices__table-inner" data-mh="group-price-table">
                        <div class="card-image">
                          <img src="assets/landing/card-title.png" alt="" />
                          <h4 class="img-text">{{'ad_live_package'|translate}}</h4>
                        </div>
                        <div class="card-content">
                          <h4 class="package-price text-primary">30.000 đ</h4>
                          <span class="" style="margin-bottom:32px; color: #92929d;">/Camera</span>
                          <div class="package-time">
                            <p>{{'ad_0day_save'|translate}}Thời gian lưu trữ: 0 ngày </p>
                            <p>{{'ldp_cycle'|translate}}</p>
                            <p>{{'ad_image_quality'|translate}}: FHD1080</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="package-item">
                    <div class="block-prices__table">
                      <div class="block-prices__table-inner" data-mh="group-price-table">
                        <div class="card-image">
                          <img src="assets/landing/card-title.png" alt="" />
                          <h4 class="img-text">{{'ad_pack_3d'|translate}}</h4>
                        </div>
                        <div class="card-content">
                          <h4 class="package-price text-primary">70.000 đ</h4>
                          <span class="" style="margin-bottom:32px; color: #92929d;">/Camera</span>
                          <div class="package-time">
                            <p>{{'ad_3day_save'|translate}}</p>
                            <p>{{'ldp_cycle'|translate}}</p>
                            <p>{{'ad_image_quality'|translate}}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="package-item">
                    <div class="block-prices__table">
                      <div class="block-prices__table-inner" data-mh="group-price-table">
                        <div class="card-image">
                          <img src="assets/landing/card-title.png" alt="" />
                          <h4 class="img-text">{{'ad_pack_7d'|translate}}</h4>
                        </div>
                        <div class="card-content">
                          <h4 class="package-price text-primary">140.000 đ</h4>
                          <span class="" style="margin-bottom:32px; color: #92929d;">/Camera</span>
                          <div class="package-time">
                            <p>{{'ad_7day_save'|translate}}</p>
                            <p>{{'ldp_cycle'|translate}}</p>
                            <p>{{'ad_image_quality'|translate}}: FHD1080</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="package-item">
                    <div class="block-prices__table">
                      <div class="block-prices__table-inner" data-mh="group-price-table">
                        <div class="card-image">
                          <img src="assets/landing/card-title.png" alt="" />
                          <h4 class="img-text">{{'ad_pack_14d'|translate}}</h4>
                        </div>
                        <div class="card-content">
                          <h4 class="package-price text-primary">175.000 đ</h4>
                          <span class="" style="margin-bottom:32px; color: #92929d;">/Camera</span>
                          <div class="package-time">
                            <p>{{'ad_14day_save'|translate}}</p>
                            <p>{{'ldp_cycle'|translate}}</p>
                            <p>{{'ad_image_quality'|translate}}: FHD1080</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="package-item">
                    <div class="block-prices__table">
                      <div class="block-prices__table-inner" data-mh="group-price-table">
                        <div class="card-image">
                          <img src="assets/landing/card-title.png" alt="" />
                          <h4 class="img-text">{{'ad_pack_15d'|translate}}</h4>
                        </div>
                        <div class="card-content">
                          <h4 class="package-price text-primary">200.000 đ</h4>
                          <span class="" style="margin-bottom:32px; color: #92929d;">/Camera</span>
                          <div class="package-time">
                            <p>{{'ad_15day_save'|translate}}</p>
                            <p>{{'ldp_cycle'|translate}} </p>
                            <p>{{'ad_image_quality'|translate}}: FHD1080</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="package-item">
                    <div class="block-prices__table">
                      <div class="block-prices__table-inner" data-mh="group-price-table">
                        <div class="card-image">
                          <img src="assets/landing/card-title.png" alt="" />
                          <h4 class="img-text">{{'ad_pack_30d'|translate}}</h4>
                        </div>
                        <div class="card-content">
                          <h4 class="package-price text-primary">400.000 đ</h4>
                          <span class="" style="margin-bottom:32px; color: #92929d;">/Camera</span>
                          <div class="package-time">
                            <p>{{'ad_30day_save'|translate}}</p>
                            <p>{{'ldp_cycle'|translate}}</p>
                            <p>{{'ad_image_quality'|translate}}: FHD1080</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="package-item">
                    <div class="block-prices__table">
                      <div class="block-prices__table-inner" data-mh="group-price-table">
                        <div class="card-image">
                          <img src="assets/landing/card-title.png" alt="" />
                          <h4 class="img-text">{{'ad_pack_60d'|translate}}</h4>
                        </div>
                        <div class="card-content">
                          <h4 class="package-price text-primary">770.000 đ</h4>
                          <span class="" style="margin-bottom:32px; color: #92929d;">/Camera</span>
                          <div class="package-time">
                            <p>{{'ad_60day_save'|translate}}</p>
                            <p>{{'ldp_cycle'|translate}}</p>
                            <p>{{'ad_image_quality'|translate}}: FHD1080</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="package-item">
                    <div class="block-prices__table">
                      <div class="block-prices__table-inner" data-mh="group-price-table">
                        <div class="card-image">
                          <img src="assets/landing/card-title.png" alt="" />
                          <h4 class="img-text">{{'ad_pack_90d'|translate}}</h4>
                        </div>
                        <div class="card-content">
                          <h4 class="package-price text-primary">1200.000 đ</h4>
                          <span class="" style="margin-bottom:32px; color: #92929d;">/Camera</span>
                          <div class="package-time">
                            <p>{{'ad_90day_save'|translate}}Thời gian lưu trữ: 90 ngày </p>
                            <p>{{'ldp_cycle'|translate}}</p>
                            <p>{{'ad_image_quality'|translate}}: FHD1080</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


        <div class="tab-pane fade show" id="h265-package" role="tabpanel" aria-labelledby="h265-package-tab">

          <div class="page-carousel package-carousel">
            <div class="container">
              <div class="package-wrapper">
                <div class="list-package">
                  <!--Bảng giá với camera chất lượng SD-->
                  <div class="package-item">
                    <div class="block-prices__table">
                      <div class="block-prices__table-inner" data-mh="group-price-table">
                        <div class="card-image">
                          <img src="assets/landing/card-title.png" alt="" />
                          <h4 class="img-text">{{'ad_live_package'|translate}}</h4>
                        </div>
                        <div class="card-content">
                          <h4 class="package-price text-primary">20.000 đ</h4>
                          <span class="" style="margin-bottom:32px; color: #92929d;">/Camera</span>
                          <div class="package-time">
                            <p>{{'ad_0day_save'|translate}}</p>
                            <p>{{'ldp_cycle'|translate}}</p>
                            <p>{{'ad_image_quality'|translate}}: SD480</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="package-item">
                    <div class="block-prices__table">
                      <div class="block-prices__table-inner" data-mh="group-price-table">
                        <div class="card-image">
                          <img src="assets/landing/card-title.png" alt="" />
                          <h4 class="img-text">{{'ad_pack_3d'|translate}}</h4>
                        </div>
                        <div class="card-content">
                          <h4 class="package-price text-primary">33.000 đ</h4>
                          <span class="" style="margin-bottom:32px; color: #92929d;">/Camera</span>
                          <div class="package-time">
                            <p>{{'ad_3day_save'|translate}}</p>
                            <p>{{'ldp_cycle'|translate}}</p>
                            <p>{{'ad_image_quality'|translate}}: SD480</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="package-item">
                    <div class="block-prices__table">
                      <div class="block-prices__table-inner" data-mh="group-price-table">
                        <div class="card-image">
                          <img src="assets/landing/card-title.png" alt="" />
                          <h4 class="img-text">{{'ad_pack_7d'|translate}}</h4>
                        </div>
                        <div class="card-content">
                          <h4 class="package-price text-primary">40.000 đ</h4>
                          <span class="" style="margin-bottom:32px; color: #92929d;">/Camera</span>
                          <div class="package-time">
                            <p>{{'ad_7day_save'|translate}}</p>
                            <p>{{'ldp_cycle'|translate}}</p>
                            <p>{{'ad_image_quality'|translate}}: SD480</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="package-item">
                    <div class="block-prices__table">
                      <div class="block-prices__table-inner" data-mh="group-price-table">
                        <div class="card-image">
                          <img src="assets/landing/card-title.png" alt="" />
                          <h4 class="img-text">{{'ad_pack_14d'|translate}}</h4>
                        </div>
                        <div class="card-content">
                          <h4 class="package-price text-primary">45.000 đ</h4>
                          <span class="" style="margin-bottom:32px; color: #92929d;">/Camera</span>
                          <div class="package-time">
                            <p>{{'ad_14day_save'|translate}}</p>
                            <p>{{'ldp_cycle'|translate}}</p>
                            <p>{{'ad_image_quality'|translate}}: SD480</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="package-item">
                    <div class="block-prices__table">
                      <div class="block-prices__table-inner" data-mh="group-price-table">
                        <div class="card-image">
                          <img src="assets/landing/card-title.png" alt="" />
                          <h4 class="img-text">{{'ad_pack_15d'|translate}}</h4>
                        </div>
                        <div class="card-content">
                          <h4 class="package-price text-primary">55.000 đ</h4>
                          <span class="" style="margin-bottom:32px; color: #92929d;">/Camera</span>
                          <div class="package-time">
                            <p>{{'ad_15day_save'|translate}}</p>
                            <p>{{'ldp_cycle'|translate}}</p>
                            <p>{{'ad_image_quality'|translate}}: SD480</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="package-item">
                    <div class="block-prices__table">
                      <div class="block-prices__table-inner" data-mh="group-price-table">
                        <div class="card-image">
                          <img src="assets/landing/card-title.png" alt="" />
                          <h4 class="img-text">{{'ad_pack_30d'|translate}}</h4>
                        </div>
                        <div class="card-content">
                          <h4 class="package-price text-primary">70.000 đ</h4>
                          <span class="" style="margin-bottom:32px; color: #92929d;">/Camera</span>
                          <div class="package-time">
                            <p>{{'ad_30day_save'|translate}}</p>
                            <p>{{'ldp_cycle'|translate}}</p>
                            <p>{{'ad_image_quality'|translate}}: SD480</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="package-item">
                    <div class="block-prices__table">
                      <div class="block-prices__table-inner" data-mh="group-price-table">
                        <div class="card-image">
                          <img src="assets/landing/card-title.png" alt="" />
                          <h4 class="img-text">{{'ad_pack_60d'|translate}}</h4>
                        </div>
                        <div class="card-content">
                          <h4 class="package-price text-primary">110.000 đ</h4>
                          <span class="" style="margin-bottom:32px; color: #92929d;">/Camera</span>
                          <div class="package-time">
                            <p>{{'ad_60day_save'|translate}}</p>
                            <p>{{'ldp_cycle'|translate}}</p>
                            <p>{{'ad_image_quality'|translate}}: SD480</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="package-item">
                    <div class="block-prices__table">
                      <div class="block-prices__table-inner" data-mh="group-price-table">
                        <div class="card-image">
                          <img src="assets/landing/card-title.png" alt="" />
                          <h4 class="img-text">{{'ad_pack_90d'|translate}}</h4>
                        </div>
                        <div class="card-content">
                          <h4 class="package-price text-primary">150.000 đ</h4>
                          <span class="" style="margin-bottom:32px; color: #92929d;">/Camera</span>
                          <div class="package-time">
                            <p>{{'ad_90day_save'|translate}}</p>
                            <p>{{'ldp_cycle'|translate}}</p>
                            <p>{{'ad_image_quality'|translate}}: SD480</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!--Bảng giá với camera chất lượng HD-->
                  <div class="package-item">
                    <div class="block-prices__table">
                      <div class="block-prices__table-inner" data-mh="group-price-table">
                        <div class="card-image">
                          <img src="assets/landing/card-title.png" alt="" />
                          <h4 class="img-text">{{'ad_live_package'|translate}}</h4>
                        </div>
                        <div class="card-content">
                          <h4 class="package-price text-primary">25.000 đ</h4>
                          <span class="" style="margin-bottom:32px; color: #92929d;">/Camera</span>
                          <div class="package-time">
                            <p>{{'ad_0day_save'|translate}}</p>
                            <p>{{'ldp_cycle'|translate}}</p>
                            <p>{{'ad_image_quality'|translate}}: HD720</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="package-item">
                    <div class="block-prices__table">
                      <div class="block-prices__table-inner" data-mh="group-price-table">
                        <div class="card-image">
                          <img src="assets/landing/card-title.png" alt="" />
                          <h4 class="img-text">{{'ad_pack_3d'|translate}}</h4>
                        </div>
                        <div class="card-content">
                          <h4 class="package-price text-primary">43.000 đ</h4>
                          <span class="" style="margin-bottom:32px; color: #92929d;">/Camera</span>
                          <div class="package-time">
                            <p>{{'ad_3day_save'|translate}}</p>
                            <p>{{'ldp_cycle'|translate}}</p>
                            <p>{{'ad_image_quality'|translate}}: HD720</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="package-item">
                    <div class="block-prices__table">
                      <div class="block-prices__table-inner" data-mh="group-price-table">
                        <div class="card-image">
                          <img src="assets/landing/card-title.png" alt="" />
                          <h4 class="img-text">{{'ad_pack_7d'|translate}}</h4>
                        </div>
                        <div class="card-content">
                          <h4 class="package-price text-primary">50.000 đ</h4>
                          <span class="" style="margin-bottom:32px; color: #92929d;">/Camera</span>
                          <div class="package-time">
                            <p>{{'ad_7day_save'|translate}}</p>
                            <p>{{'ldp_cycle'|translate}}</p>
                            <p>{{'ad_image_quality'|translate}}: HD720</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="package-item">
                    <div class="block-prices__table">
                      <div class="block-prices__table-inner" data-mh="group-price-table">
                        <div class="card-image">
                          <img src="assets/landing/card-title.png" alt="" />
                          <h4 class="img-text">{{'ad_pack_14d'|translate}}

                          </h4>
                        </div>
                        <div class="card-content">
                          <h4 class="package-price text-primary">60.000 đ</h4>
                          <span class="" style="margin-bottom:32px; color: #92929d;">/Camera</span>
                          <div class="package-time">
                            <p>{{'ad_14day_save'|translate}}</p>
                            <p>{{'ldp_cycle'|translate}}</p>
                            <p>{{'ad_image_quality'|translate}}: HD720</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="package-item">
                    <div class="block-prices__table">
                      <div class="block-prices__table-inner" data-mh="group-price-table">
                        <div class="card-image">
                          <img src="assets/landing/card-title.png" alt="" />
                          <h4 class="img-text">{{'ad_pack_15d'|translate}}</h4>
                        </div>
                        <div class="card-content">
                          <h4 class="package-price text-primary">65.000 đ</h4>
                          <span class="" style="margin-bottom:32px; color: #92929d;">/Camera</span>
                          <div class="package-time">
                            <p>{{'ad_15day_save'|translate}}</p>
                            <p>{{'ldp_cycle'|translate}}</p>
                            <p>{{'ad_image_quality'|translate}}: HD720</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="package-item">
                    <div class="block-prices__table">
                      <div class="block-prices__table-inner" data-mh="group-price-table">
                        <div class="card-image">
                          <img src="assets/landing/card-title.png" alt="" />
                          <h4 class="img-text">{{'ad_pack_30d'|translate}}</h4>
                        </div>
                        <div class="card-content">
                          <h4 class="package-price text-primary">110.000 đ</h4>
                          <span class="" style="margin-bottom:32px; color: #92929d;">/Camera</span>
                          <div class="package-time">
                            <p>{{'ad_30day_save'|translate}}</p>
                            <p>{{'ldp_cycle'|translate}}</p>
                            <p>{{'ad_image_quality'|translate}}: HD720</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="package-item">
                    <div class="block-prices__table">
                      <div class="block-prices__table-inner" data-mh="group-price-table">
                        <div class="card-image">
                          <img src="assets/landing/card-title.png" alt="" />
                          <h4 class="img-text">{{'ad_pack_60d'|translate}}</h4>
                        </div>
                        <div class="card-content">
                          <h4 class="package-price text-primary">210.000 đ</h4>
                          <span class="" style="margin-bottom:32px; color: #92929d;">/Camera</span>
                          <div class="package-time">
                            <p>{{'ad_60day_save'|translate}}</p>
                            <p>{{'ldp_cycle'|translate}}</p>
                            <p>{{'ad_image_quality'|translate}}: HD720</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="package-item">
                    <div class="block-prices__table">
                      <div class="block-prices__table-inner" data-mh="group-price-table">
                        <div class="card-image">
                          <img src="assets/landing/card-title.png" alt="" />
                          <h4 class="img-text">{{'ad_pack_90d'|translate}}</h4>
                        </div>
                        <div class="card-content">
                          <h4 class="package-price text-primary">350.000 đ</h4>
                          <span class="" style="margin-bottom:32px; color: #92929d;">/Camera</span>
                          <div class="package-time">
                            <p>{{'ad_90day_save'|translate}}</p>
                            <p>{{'ldp_cycle'|translate}}</p>
                            <p>{{'ad_image_quality'|translate}}: HD720</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!--Bảng giá với camera chất lượng FHD-->
                  <div class="package-item">
                    <div class="block-prices__table">
                      <div class="block-prices__table-inner" data-mh="group-price-table">
                        <div class="card-image">
                          <img src="assets/landing/card-title.png" alt="" />
                          <h4 class="img-text">{{'ad_live_package'|translate}}</h4>
                        </div>
                        <div class="card-content">
                          <h4 class="package-price text-primary">30.000 đ</h4>
                          <span class="" style="margin-bottom:32px; color: #92929d;">/Camera</span>
                          <div class="package-time">
                            <p>{{'ad_0day_save'|translate}}</p>
                            <p>{{'ldp_cycle'|translate}}</p>
                            <p>{{'ad_image_quality'|translate}}: FHD1080</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="package-item">
                    <div class="block-prices__table">
                      <div class="block-prices__table-inner" data-mh="group-price-table">
                        <div class="card-image">
                          <img src="assets/landing/card-title.png" alt="" />
                          <h4 class="img-text">{{'ad_pack_3d'|translate}}</h4>
                        </div>
                        <div class="card-content">
                          <h4 class="package-price text-primary">50.000 đ</h4>
                          <span class="" style="margin-bottom:32px; color: #92929d;">/Camera</span>
                          <div class="package-time">
                            <p>{{'ad_3day_save'|translate}}</p>
                            <p>{{'ldp_cycle'|translate}}</p>
                            <p>{{'ad_image_quality'|translate}}: FHD1080</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="package-item">
                    <div class="block-prices__table">
                      <div class="block-prices__table-inner" data-mh="group-price-table">
                        <div class="card-image">
                          <img src="assets/landing/card-title.png" alt="" />
                          <h4 class="img-text">{{'ad_pack_7d'|translate}}</h4>
                        </div>
                        <div class="card-content">
                          <h4 class="package-price text-primary">65.000 đ</h4>
                          <span class="" style="margin-bottom:32px; color: #92929d;">/Camera</span>
                          <div class="package-time">
                            <p>{{'ad_7day_save'|translate}}</p>
                            <p>{{'ldp_cycle'|translate}}</p>
                            <p>{{'ad_image_quality'|translate}}: FHD1080</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="package-item">
                    <div class="block-prices__table">
                      <div class="block-prices__table-inner" data-mh="group-price-table">
                        <div class="card-image">
                          <img src="assets/landing/card-title.png" alt="" />
                          <h4 class="img-text">{{'ad_pack_14d'|translate}}</h4>
                        </div>
                        <div class="card-content">
                          <h4 class="package-price text-primary">100.000 đ</h4>
                          <span class="" style="margin-bottom:32px; color: #92929d;">/Camera</span>
                          <div class="package-time">
                            <p>{{'ad_14day_save'|translate}}</p>
                            <p>{{'ldp_cycle'|translate}}</p>
                            <p>{{'ad_image_quality'|translate}}: FHD1080</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="package-item">
                    <div class="block-prices__table">
                      <div class="block-prices__table-inner" data-mh="group-price-table">
                        <div class="card-image">
                          <img src="assets/landing/card-title.png" alt="" />
                          <h4 class="img-text">{{'ad_pack_15d'|translate}}</h4>
                        </div>
                        <div class="card-content">
                          <h4 class="package-price text-primary">110.000 đ</h4>
                          <span class="" style="margin-bottom:32px; color: #92929d;">/Camera</span>
                          <div class="package-time">
                            <p>{{'ad_15day_save'|translate}}</p>
                            <p>{{'ldp_cycle'|translate}}</p>
                            <p>{{'ad_image_quality'|translate}}: FHD1080</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="package-item">
                    <div class="block-prices__table">
                      <div class="block-prices__table-inner" data-mh="group-price-table">
                        <div class="card-image">
                          <img src="assets/landing/card-title.png" alt="" />
                          <h4 class="img-text">{{'ad_pack_30d'|translate}}</h4>
                        </div>
                        <div class="card-content">
                          <h4 class="package-price text-primary">200.000 đ</h4>
                          <span class="" style="margin-bottom:32px; color: #92929d;">/Camera</span>
                          <div class="package-time">
                            <p>{{'ad_30day_save'|translate}}</p>
                            <p>{{'ldp_cycle'|translate}}</p>
                            <p>{{'ad_image_quality'|translate}}: FHD1080</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="package-item">
                    <div class="block-prices__table">
                      <div class="block-prices__table-inner" data-mh="group-price-table">
                        <div class="card-image">
                          <img src="assets/landing/card-title.png" alt="" />
                          <h4 class="img-text">{{'ad_pack_60d'|translate}}</h4>
                        </div>
                        <div class="card-content">
                          <h4 class="package-price text-primary">350.000 đ</h4>
                          <span class="" style="margin-bottom:32px; color: #92929d;">/Camera</span>
                          <div class="package-time">
                            <p>{{'ad_60day_save'|translate}}</p>
                            <p>{{'ldp_cycle'|translate}}</p>
                            <p>{{'ad_image_quality'|translate}}: FHD1080</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="package-item">
                    <div class="block-prices__table">
                      <div class="block-prices__table-inner" data-mh="group-price-table">
                        <div class="card-image">
                          <img src="assets/landing/card-title.png" alt="" />
                          <h4 class="img-text">{{'ad_pack_90d'|translate}}</h4>
                        </div>
                        <div class="card-content">
                          <h4 class="package-price text-primary">700.000 đ</h4>
                          <span class="" style="margin-bottom:32px; color: #92929d;">/Camera</span>
                          <div class="package-time">
                            <p>{{'ad_90day_save'|translate}}</p>
                            <p>{{'ldp_cycle'|translate}}</p>
                            <p>{{'ad_image_quality'|translate}}: FHD1080</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>


        </div>
      </div>
    </div>

    <div class="user-package">
      <div class="container">
        <div class="row">
          <div class="col-12 col-lg-7">
            <div class="package-tab">
              <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
                <li class="nav-item">
                  <a class="nav-link active" id="your-camera-tab" data-toggle="pill" href="#your-camera" role="tab"
                    aria-controls="your-camera" aria-selected="true"
                    (click)="ResetTemp()">{{'ad_pack_not_buy'|translate}}</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" id="camera-purchased-tab" data-toggle="pill" href="#camera-purchased" role="tab"
                    aria-controls="camera-purchased" aria-selected="false"
                    (click)="ResetTemp()">{{'ad_pack_bought'|translate}}</a>
                </li>
              </ul>
              <div class="tab-content" id="pills-tabContent">
                <div class="tab-pane fade show active" id="your-camera" role="tabpanel"
                  aria-labelledby="your-camera-tab">
                  <div class="package-detail">
                    <div class="detail-header">
                      <div class="row">
                        <div class="col-7 col-sm-6">
                          <div class="header-title">{{'ad_pack_camofyou'|translate}}</div>
                        </div>
                        <div class="col-5 col-sm-4">
                          <div class="header-title d-none">{{'ad_pack_end_date'|translate}}</div>
                        </div>
                        <div class="col-12 col-sm-2" style="justify-content: center;">
                          <div class="header-title d-none d-sm-block">{{'ad_pack_buy'|translate}}</div>
                        </div>
                      </div>
                    </div>
                    <app-goi-cuoc-not-buy (sendIdBunch)="getSubIdparent($event)"
                      (sendIsClick)="getIsClickNotBuy($event)" class="bunch-table-item"
                      *ngFor="let bunchNotBuy of listData" [bunch]="bunchNotBuy"
                      (showBuyCam)="showBuyCam($event, 'buyCam')" (showBuyBunch)="showBuyBunch($event)">
                    </app-goi-cuoc-not-buy>
                  </div>
                </div>
                <div class="tab-pane fade" id="camera-purchased" role="tabpanel" aria-labelledby="camera-purchased-tab">
                  <div class="package-detail">
                    <div class="detail-header">
                      <div class="row">
                        <div class="col-7 col-sm-6">
                          <div class="header-title">{{'ad_pack_camofyou'|translate}}</div>
                        </div>
                        <div class="col-5 col-sm-4">
                          <div class="header-title">{{'ad_pack_end_date'|translate}}</div>
                        </div>
                        <div class="col-12 col-sm-2">
                          <div class="header-title d-none d-sm-block">{{'ad_pack_action'|translate}}</div>
                        </div>
                      </div>
                    </div>
                    <app-goi-cuoc-bought (sendIdBunch)="getSubIdparent($event)" (sendIsClick)="getIsClick($event)"
                      class="bunch-table-item" *ngFor="let bunch of listDataBought" [bunch]="bunch"
                      (showBuyCam)="showBuyCam($event, 'extendPackageTime')"
                      (showBuyCamSwitchPackage)="showBuyCam($event, 'changePackage')"
                      (cancelPackage)="cancelPackage($event)">
                    </app-goi-cuoc-bought>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-5">
            <div class="package-cart">
              <div class="cart-header">
                <div class="header-title">{{'ad_pack_cart'|translate}}</div>
              </div>
              <div class="cart-body">
                <table class="table table-striped cart-table">
                  <thead>
                    <tr>
                      <th style="width: 25%;">Camera</th>
                      <th style="width: 15%">{{'ad_resolution'|translate}}</th>
                      <th style="width: 25%">{{'ad_package'|translate}}</th>
                      <th style="width: 25%">{{'ad_pack_into_money'|translate}}</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>

                    <tr *ngFor="let iBuy of listBuy">
                      <td style=" word-break: break-all;">{{iBuy.cameraName}}</td>
                      <td class="text-center">{{iBuy.resolution}}</td>
                      <td style="word-break: break-all">{{'ad_pack_pack'|translate}} {{iBuy.packageName}}</td>
                      <td>{{iBuy.price.toLocaleString()}}</td>
                      <td>
                        <a href="javascript:;" (click)="RemoveFromCart(iBuy)" class="remove-item"
                          title="Bỏ camera khỏi giỏ hàng">
                          <i class="icon icon-remove"></i>
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div class="total-price">
                  <div>
                    <div class="price-title">{{'ad_pack_total_money'|translate}}</div>
                  </div>
                  <div>
                    <div class="price-value">{{totalMonney.toLocaleString()}}</div>
                  </div>
                </div>
                <div class="total-price">
                  <div>
                    <div class="price-title">VAT</div>
                  </div>
                  <div>
                    <div class="price-value">{{totalVAT.toLocaleString()}}</div>
                  </div>
                </div>
                <div class="total-price">
                  <div>
                    <div class="price-title">{{'ad_pack_total_payment'|translate}}</div>
                  </div>
                  <div>
                    <div class="price-value">{{totalSum.toLocaleString()}}</div>
                  </div>
                </div>
                <div class="cart-pay">
                  <button class="btn btn-primary" type="button"
                    (click)="BuyPackage()">{{'ad_pack_payment'|translate}}</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <footer class="idc-footer">
    <p class="copyright">{{'ldp_copyright'|translate}}</p>
  </footer>
</div>



<div class="modal fade" id="buyDataPackage" tabindex="-1" role="dialog" aria-labelledby="buyDataPackageTitle"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered data-package-modal" role="document">
    <div class="modal-content">
      <div class="modal-header bg-primary">
        <h5 class="modal-title" id="buyDataPackageTitle">{{'ad_buy_bunch'|translate}}</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <i class="icon icon-close"></i>
        </button>
      </div>
      <div class="modal-body">
        <form>
          <div class="form-group">
            <label class="control-label" for="group-name">{{'ad_pack_choose_group'|translate}}</label>
            <div class="form-control disabled" id="group-name">
              <p>{{currBunch.name}} <span class="text-blue">({{currBunch?.listCamera?.length}} cam)</span></p>
            </div>
          </div>
          <div class="form-group">
            <label class="control-label">{{'ad_pack_choose_pack'|translate}}</label>
            <div class="form-radio" *ngFor="let iPackage of listPackage;  let i= index;">
              <input class="magic-radio" type="radio" name="cam" id="cam-{{iPackage.id}}" [(ngModel)]="packageSelected"
                [value]="iPackage" />
              <label for="cam-{{iPackage.id}}">{{'ad_pack_pack'|translate}} {{iPackage.name}} <span
                  class="package-val">(<span>{{iPackage.price.toLocaleString()}}</span>
                  {{'ad_pack_unit'|translate}}</span></label>
            </div>
          </div>
          <div class="form-action">
            <button class="btn btn-outline-secondary mr-3" type="button"
              data-dismiss="modal">{{'li_cancel'|translate}}</button>
            <button class="btn btn-primary" (click)="AddBunchToCart()">{{'ad_pack_put_cart'|translate}}</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>




<div class="modal fade" id="buyCam" tabindex="-1" role="dialog" aria-labelledby="buyCamTitle" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered data-package-modal" role="document">
    <div class="modal-content">
      <div class="modal-header bg-primary">
        <h5 class="modal-title" id="buyCamTitle">{{'ad_buy_cam'|translate}}</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <i class="icon icon-close"></i>
        </button>
      </div>
      <div class="modal-body">
        <form>
          <div class="form-group">
            <label class="control-label" for="camera-name">Camera</label>
            <div class="form-control disabled" id="camera-name">
              <p>{{currCamera.name}}</p>
            </div>
          </div>
          <div class="form-group">
            <label class="control-label">{{'ad_pack_choose_pack'|translate}}</label>
            <div class="form-radio" *ngFor="let iPackage of listPackage;  let i= index;">
              <input class="magic-radio" type="radio" name="cam" id="cam-{{iPackage.id}}" [(ngModel)]="packageSelected"
                [value]="iPackage" />
              <label for="cam-{{iPackage.id}}">{{'ad_pack_pack'|translate}} {{iPackage.name}} <span
                  class="package-val">(<span>{{iPackage.price.toLocaleString()}}</span> VNĐ/ camera)</span></label>
            </div>
          </div>
          <div class="form-action">
            <button class="btn btn-outline-secondary mr-3" type="button"
              data-dismiss="modal">{{'li_cancel'|translate}}</button>
            <button class="btn btn-primary" (click)="AddToCart('buyCam')">{{'ad_pack_put_cart'|translate}}</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="extendPackageTime" tabindex="-1" role="dialog" aria-labelledby="extendPackageTimeTitle"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered extend-package-modal" role="document">
    <div class="modal-content">
      <div class="modal-header bg-primary">
        <h5 class="modal-title" id="extendPackageTimeTitle">{{'ad_extend'|translate}}</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <i class="icon icon-close"></i>
        </button>
      </div>
      <div class="modal-body">
        <form>
          <div class="form-table">
            <table>
              <thead>
                <tr>
                  <th>Camera</th>
                  <th>{{'ad_packcurr_pack'|translate}}</th>
                  <th>{{'ad_pack_out_date'|translate}}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{{currCamera.name}}</td>
                  <td>{{currCamera?.package?.name}}</td>
                  <td>{{currCamera.dateExpiration|datex:'DD/MM/YYYY'}}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="form-action">
            <button class="btn btn-outline-secondary mr-3" type="button"
              data-dismiss="modal">{{'li_cancel'|translate}}</button>
            <button class="btn btn-primary"
              (click)="AddToCart('extendPackageTime')">{{'ad_pack_put_cart'|translate}}</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
<div class="modal fade" id="changePackage" tabindex="-1" role="dialog" aria-labelledby="changePackageTitle"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered data-package-modal" role="document">
    <div class="modal-content">
      <div class="modal-header bg-primary">
        <h5 class="modal-title" id="changePackageTitle">{{'ad_pack_trans_pack'|translate}}</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <i class="icon icon-close"></i>
        </button>
      </div>
      <div class="modal-body">
        <form>
          <div class="form-group">
            <label class="control-label" for="group-name">Camera</label>
            <div class="form-control disabled" id="group-name">
              <p>{{currCamera.name}}</p>
            </div>
          </div>
          <div class="form-group">
            <label class="control-label">{{'ad_pack_choose_pack'|translate}}</label>
            <div class="form-radio" *ngFor="let iPackage of listPackage;">
              <input class="magic-radio" type="radio" name="cam" id="cam-{{iPackage.id}}" [(ngModel)]="packageSelected"
                [value]="iPackage" />
              <label for="cam-{{iPackage.id}}">{{'ad_pack_pack'|translate}} {{iPackage.name}} <span
                  class="package-val">(<span>{{iPackage.price.toLocaleString()}}</span> VNĐ/ camera)</span></label>
            </div>
          </div>
          <div class="form-action">
            <button class="btn btn-outline-secondary mr-3" type="button"
              data-dismiss="modal">{{'li_cancel'|translate}}</button>
            <button class="btn btn-primary"
              (click)="AddToCart('changePackage')">{{'ad_pack_put_cart'|translate}}</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>