import { BaseService } from '../_services/base.service';
import { AuthenticationService } from '../_services/authentication.service';
import { CookieService } from 'ngx-cookie-service';

export class AuthHepler {
    constructor(public baseService: BaseService, public authenticationSevice: AuthenticationService, public cookie: CookieService) {

    }
    unAuthHandle(callback: () => any) {
        this.baseService.refreshAuthentication()
            .subscribe(
            authResult => {
                const currentUser = JSON.parse(this.cookie.get('currentUser'));
                currentUser.accessToken = authResult.accessToken;
                this.cookie.set('currentUser', JSON.stringify(currentUser));
            },
            deniedResult => {
                console.log(deniedResult);
                this.authenticationSevice.logout();
            }, 
            () => callback());
    }
}