export class LanguageModel{
    title:string;
    content:string;
    title1:string;
    content1:string;
    title2:string;
    content2:string;
    title3:string;
    content3:string;
    title4:string;
    content4:string;
    title5:string;
    content5:string;
    title6:string;
    content6:string;
    title7:string;
    content7:string;
    title8:string;
    content8:string;
    title9:string;
    content9:string;
    content10:string;
    content11:string;
    content12:string;
    content13:string;
    content14:string;
    content15:string;
    title10:string;
    title11:string;
    title12:string;
    title13:string;
    title14:string;
    title15:string;
    val:string;
    streammin:string; streammax:string;
}