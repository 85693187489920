<div class="container-fluid p-0">
    <app-header></app-header>
    <div class="page-container page-user">
        <div class="container">
            <div class="page-contain">
                <div class="per-title">{{'role_role'|translate}} <span style="color: #e03">{{this.currUsername}}</span>
                </div>
                <p class="per-border" style="margin: 0px;"></p>
                <div class="per-title">{{'role_choose_permission'|translate}}</div>
                <div class="form-radio-group">
                    <div class="form-radio mr-5">
                        <input class="magic-radio" type="radio" name="role-view" id="role-view" [(ngModel)]="isView"
                            [value]="true" />
                        <label for="role-view">{{'ad_view_cam'|translate}}</label>
                    </div>
                    <div class="form-radio">
                        <input class="magic-radio" type="radio" name="role-view" id="role-manage" [(ngModel)]="isView"
                            [value]="false" />
                        <label for="role-manage">{{'ad_manage_cam'|translate}}</label>
                    </div>
                </div>
                <div class="per-title">{{'role_choosecam'|translate}}</div>
                <div class="form-radio-group">
                    <div class="form-radio mr-5">
                        <input class="magic-radio" type="radio" name="role-share" id="role-share"
                            [(ngModel)]="isPermiss" [value]="true"  (click)="getCameraAllows()" />
                        <label for="role-share">{{'ad_share_permiss'|translate}}</label>
                    </div>
                    <div class="form-radio">
                        <input class="magic-radio" type="radio" name="role-copy" id="role-copy" [(ngModel)]="isPermiss"
                            [value]="false" (click)="getCameraAllows()" />
                        <label for="role-copy">{{'role_copy_permis'|translate}}</label>
                    </div>
                </div>
                <div class="col-12" *ngIf="!isPermiss">
                    <div class="per-border box" style="width: 100%; max-height: calc(100vh - 400px); min-height: 150px;">
                        <div class="per-title">{{'ad_user'|translate}}</div>
                        <div class="search-form camera-search">
                            <input type="text" class="form-control search-camera" placeholder="{{'role_search_user'|translate}}" [(ngModel)]="keySubAccount" />
                        </div>
                        <div class="tree-group">
                            <div class="form-radio" *ngFor="let us of lstSubAccount|search:{label:['fullName'],keyword:keySubAccount||''}">
                                <input class="magic-radio" type="radio" name="{{us.username}}" id="{{us.username}}"
                                    [(ngModel)]="accSelected" [value]="us" (ngModelChange)="getCamerasAssigned()" />
                                <label for="{{us.username}}">{{us.fullName}} - {{us.email}}</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12" *ngIf="isPermiss; else blockCopyCamera">
                    <div class="per-border box" style="width: 20%; margin-right: 3%;min-height: 150px;">
                        <div class="per-title">{{'role_cam_group'|translate}}</div>
                        <div class="search-form camera-search">
                            <input type="text" class="form-control search-camera" placeholder="{{'role_search_group'|translate}}" [(ngModel)]="keyBunch" />
                        </div>
                        <div class="tree-group">
                            <!--Gen group camera  [ngClass]="{'active': bunch === selectedBunch}"-->
                            <div class="group" data-toggle="collapse" [attr.data-target]="'#group_' + bunch.id"
                                *ngFor="let bunch of bunchList|search:{label:['name'],keyword:keyBunch||''}"  (click)="getCamerasInBunch(bunch)">
                                {{bunch.name}}<span class="red-column"></span></div>
                        </div>
                    </div>
                    <div class="per-border box" style="height: calc(100vh - 400px); float: left; width: 77%; min-height: 150px;">
                        <div class="per-title">Camera</div>
                        <div class="search-form camera-search">
                            <input type="text" class="form-control search-camera" placeholder="{{'role_search_cam'|translate}}" [(ngModel)]="keyCamera">
                        </div>
                        <div class="tree-camera">
                            <div>
                                <label class="cb-container">{{'role_select_all'|translate}}
                                    <input id="cbAll" class="cb" type="checkbox"  (change)="onSelectAllChange($event)" [(ngModel)]="isCheckedAll">
                                    <span class="checkmark"></span>
                                </label>
                            </div>
                            <div *ngFor="let bunch of bunchList" id="group_{{bunch.id}}" class="collapse show">
                                <label class="cb-container" *ngFor="let cam of bunch.listCamera|search:{label:['name'],keyword:keyCamera||''}">{{cam.name}}
                                    <input id="camera_{{cam.id}}" class="cb" type="checkbox" [(ngModel)]="cam.checked" (change)="onCheckboxChange($event)">
                                    <span class="checkmark"></span>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <ng-template #blockCopyCamera>
                    <div class="col-12"><div class="per-border box" style="width: 20%; margin-right: 3%; min-height: 150px;">
                        <div class="per-title">{{'role_cam_group'|translate}}</div>
                        <div class="search-form camera-search">
                            <input type="text" class="form-control search-camera" placeholder="{{'role_search_group'|translate}}" [(ngModel)]="keyCBunch" />
                        </div>
                        <div class="tree-group">
                            <!--Gen group camera-->
                            <div class="group" data-toggle="collapse" [attr.data-target]="'#group_' + bunch.id"
                                *ngFor="let bunch of bunchCList|search:{label:['name'],keyword:keyCBunch||''}"  (click)="getCamerasInBunch(bunch)">
                                {{bunch.name}}<span class="red-column"></span></div>
                        </div>
                    </div>
                    <div class="per-border box" style="height: calc(100vh - 400px); float: left; width: 77%; min-height: 150px;">
                        <div class="per-title">Camera</div>
                        <div class="search-form camera-search">
                            <input type="text" class="form-control search-camera" placeholder="{{'role_search_cam'|translate}}" [(ngModel)]="keyCCamera">
                        </div>
                        <div class="tree-camera">
                            <div>
                                <label class="cb-container">{{'role_select_all'|translate}}
                                    <input id="cbAll" class="cb" type="checkbox"  (change)="onSelectAllChange($event)" [(ngModel)]="isCheckedAll">
                                    <span class="checkmark"></span>
                                </label>
                            </div>
                            <div *ngFor="let bunch of bunchCList" id="group_{{bunch.id}}" class="collapse show">
                                <label class="cb-container" *ngFor="let cam of bunch.listCamera|search:{label:['name'],keyword:keyCCamera||''}">{{cam.name}}
                                    <input id="camera_{{cam.id}}" class="cb" type="checkbox" [(ngModel)]="cam.checked" (change)="onCheckboxChange($event)">
                                    <span class="checkmark"></span>
                                </label>
                            </div>
                        </div>
                    </div></div>
                </ng-template>
                <div style="text-align: center; margin-bottom: 10px;">
                    <button class="btn btn-outline-secondary mr-3" type="button" routerLink="/account">{{'li_cancel'|translate}}</button>
                  <button class="btn btn-primary" (click)="savePermission()">{{'li_save'|translate}}</button>
                </div>
            </div>
        </div>
    </div>

<!--Modal warning phân quyền vượt quá giới hạn-->
<div class="modal fade" id="shareCameraLimit" tabindex="-1" role="dialog" aria-labelledby="shareCameraLimitTitle"
aria-hidden="true">
<div class="modal-dialog modal-dialog-centered camera-modal" role="document">
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title" style="font-size: 16px; color: unset; font-weight: 600;" id="shareCameraLimitTitle" *ngIf="isPermiss; else blockHeader">{{'role_role'|translate}}</h5>
      <ng-template #blockHeader><h5 class="modal-title" style="font-size: 16px; color: unset; font-weight: 600;" id="shareCameraLimitTitle">{{'role_copy_user_permission'|translate}}</h5></ng-template>
    </div>
    <div class="modal-body">
        <div class="form-group" style="font-weight: 600; text-align: center;">
            <p *ngIf="isPermiss; else blockContent">{{'role_err_content1'|translate}}{{cameraCount}}/{{totalCamera}}{{'role_err_content2'|translate}}</p>
            <ng-template #blockContent>
                <p>{{'role_err_content3'|translate}}{{cameraCount}}/{{totalCamera}}{{'role_err_content4'|translate}}<span style="color: #e03;">{{accSelected.fullName}}</span> {{'role_err_content5'|translate}}</p>
            </ng-template>
            <div>{{'role_err_content6'|translate}}</div>
            <div><a href="javascript:;" (click)="exportToFile()" style="color: unset;">{{'role_download'|translate}}</a> {{'role_err_content7'|translate}}{{cameraCount}} camera</div>
          </div>
        <div class="form-action">
          <button class="btn btn-outline-secondary mr-3" type="button" style="font-weight: 600;"
            data-dismiss="modal">{{'role_close'|translate}}</button>
          <button class="btn btn-primary" style="font-weight: 600;" routerLink="/camera" data-dismiss="modal">{{'role_check'|translate}}</button>
        </div>
    </div>
  </div>
</div>
</div>