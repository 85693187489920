import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ConfigTimeModel, DayModel, ScenarioModel, TimeViewModel } from 'src/app/_models/ai-camera';
import { AiCameraEventService } from 'src/app/_services/ai-camera-event.service';
import Swal from 'sweetalert2';

declare var $: any;

@Component({
  selector: 'app-register-camera',
  templateUrl: './register-camera.component.html',
  styleUrls: ['./register-camera.component.css']
})
export class RegisterCameraComponent implements OnInit {
  lstScenario: ScenarioModel[];
  pageSize: number = 5;
  pageSizes = [5, 10, 20, 50, 100, 1000];
  txtSearch: string = "";
  currentPage: number = 0;
  loading: boolean = false;
  hours: string[] = Array.from({ length: 24 }, (_, i) => i.toString().padStart(2, '0'));
  minutes: string[] = Array.from({ length: 60 }, (_, i) => i.toString().padStart(2, '0'));
  seconds: string[] = Array.from({ length: 60 }, (_, i) => i.toString().padStart(2, '0'));
  timeConfig: ConfigTimeModel = new ConfigTimeModel();
  @Output() eventClickChild1 = new EventEmitter<{ page: string, sce: ScenarioModel }>();
  constructor(private _aiService: AiCameraEventService) { }

  ngOnInit(): void {
    this.getConfigTime();
    this.getData();
  }
  getData() {
    this._aiService.getAllScenario().subscribe(
      res => {
        this.lstScenario = res;
        this._aiService.statisticAICamera().subscribe(
          stt => {
            stt.forEach(s => {
              this.lstScenario.forEach(
                r => {
                  if (s.scenarioId == r.id)
                    r.numberCamera = s.numberCamera;
                }
              )
            });
          }
        )
      },
      error => {
        console.log(error);
      }
    );
  }
  getConfigTime() {
    this._aiService.findTimeKeeping().subscribe(
      res => {
        this.timeConfig = res;
        this.ConvertTime();

      },
      err => { }
    );
  }
  ConvertTime() {
    if (this.timeConfig.mon != null) {
      //time start
      let seconds = Math.floor(this.timeConfig.mon.first / 1000);
      let minutes = Math.floor(seconds / 60);
      let hours = Math.floor(minutes / 60);
      this.timeConfig.mon.hFirst = hours % 24;
      this.timeConfig.mon.mFirst = minutes % 60;
      this.timeConfig.mon.sFirst = seconds % 60;
      //time end
      let eseconds = Math.floor(this.timeConfig.mon.last / 1000);
      let eminutes = Math.floor(eseconds / 60);
      let ehours = Math.floor(eminutes / 60);
      this.timeConfig.mon.hLast = ehours % 24;
      this.timeConfig.mon.mLast = eminutes % 60;
      this.timeConfig.mon.sLast = eseconds % 60;
    }
    if (this.timeConfig.tue != null) {
      let millisecond = this.timeConfig.tue.first - (24 * 60 * 60 * 1000);
      //time start
      let seconds = Math.floor(millisecond / 1000);
      let minutes = Math.floor(seconds / 60);
      let hours = Math.floor(minutes / 60);
      this.timeConfig.tue.hFirst = hours % 24;
      this.timeConfig.tue.mFirst = minutes % 60;
      this.timeConfig.tue.sFirst = seconds % 60;
      //time end
      let emillisecond = this.timeConfig.tue.last - (24 * 60 * 60 * 1000);
      let eseconds = Math.floor(emillisecond / 1000);
      let eminutes = Math.floor(eseconds / 60);
      let ehours = Math.floor(eminutes / 60);
      this.timeConfig.tue.hLast = ehours % 24;
      this.timeConfig.tue.mLast = eminutes % 60;
      this.timeConfig.tue.sLast = eseconds % 60;
    }
    if (this.timeConfig.wed != null) {
      let millisecond = this.timeConfig.wed.first - (2 * 24 * 60 * 60 * 1000);
      //time start
      let seconds = Math.floor(millisecond / 1000);
      let minutes = Math.floor(seconds / 60);
      let hours = Math.floor(minutes / 60);
      this.timeConfig.wed.hFirst = hours % 24;
      this.timeConfig.wed.mFirst = minutes % 60;
      this.timeConfig.wed.sFirst = seconds % 60;
      //time end
      let emillisecond = this.timeConfig.wed.last - (2 * 24 * 60 * 60 * 1000);
      let eseconds = Math.floor(emillisecond / 1000);
      let eminutes = Math.floor(eseconds / 60);
      let ehours = Math.floor(eminutes / 60);
      this.timeConfig.wed.hLast = ehours % 24;
      this.timeConfig.wed.mLast = eminutes % 60;
      this.timeConfig.wed.sLast = eseconds % 60;
    }
    if (this.timeConfig.thur != null) {
      let millisecond = this.timeConfig.thur.first - (3 * 24 * 60 * 60 * 1000);
      //time start
      let seconds = Math.floor(millisecond / 1000);
      let minutes = Math.floor(seconds / 60);
      let hours = Math.floor(minutes / 60);
      this.timeConfig.thur.hFirst = hours % 24;
      this.timeConfig.thur.mFirst = minutes % 60;
      this.timeConfig.thur.sFirst = seconds % 60;
      //time end
      let emillisecond = this.timeConfig.thur.last - (3 * 24 * 60 * 60 * 1000);
      let eseconds = Math.floor(emillisecond / 1000);
      let eminutes = Math.floor(eseconds / 60);
      let ehours = Math.floor(eminutes / 60);
      this.timeConfig.thur.hLast = ehours % 24;
      this.timeConfig.thur.mLast = eminutes % 60;
      this.timeConfig.thur.sLast = eseconds % 60;
    }
    if (this.timeConfig.fri != null) {
      let millisecond = this.timeConfig.fri.first - (4 * 24 * 60 * 60 * 1000);
      //time start
      let seconds = Math.floor(millisecond / 1000);
      let minutes = Math.floor(seconds / 60);
      let hours = Math.floor(minutes / 60);
      this.timeConfig.fri.hFirst = hours % 24;
      this.timeConfig.fri.mFirst = minutes % 60;
      this.timeConfig.fri.sFirst = seconds % 60;
      //time end
      let emillisecond = this.timeConfig.fri.last - (4 * 24 * 60 * 60 * 1000);
      let eseconds = Math.floor(emillisecond / 1000);
      let eminutes = Math.floor(eseconds / 60);
      let ehours = Math.floor(eminutes / 60);
      this.timeConfig.fri.hLast = ehours % 24;
      this.timeConfig.fri.mLast = eminutes % 60;
      this.timeConfig.fri.sLast = eseconds % 60;
    }
    if (this.timeConfig.sat != null) {
      let millisecond = this.timeConfig.sat.first - (5 * 24 * 60 * 60 * 1000);
      //time start
      let seconds = Math.floor(millisecond / 1000);
      let minutes = Math.floor(seconds / 60);
      let hours = Math.floor(minutes / 60);
      this.timeConfig.sat.hFirst = hours % 24;
      this.timeConfig.sat.mFirst = minutes % 60;
      this.timeConfig.sat.sFirst = seconds % 60;
      //time end
      let emillisecond = this.timeConfig.sat.last - (5 * 24 * 60 * 60 * 1000);
      let eseconds = Math.floor(emillisecond / 1000);
      let eminutes = Math.floor(eseconds / 60);
      let ehours = Math.floor(eminutes / 60);
      this.timeConfig.sat.hLast = ehours % 24;
      this.timeConfig.sat.mLast = eminutes % 60;
      this.timeConfig.sat.sLast = eseconds % 60;
    }
    if (this.timeConfig.sun != null) {
      let millisecond = this.timeConfig.sun.first - (6 * 24 * 60 * 60 * 1000);
      //time start
      let seconds = Math.floor(millisecond / 1000);
      let minutes = Math.floor(seconds / 60);
      let hours = Math.floor(minutes / 60);
      this.timeConfig.sun.hFirst = hours % 24;
      this.timeConfig.sun.mFirst = minutes % 60;
      this.timeConfig.sun.sFirst = seconds % 60;
      //time end
      let emillisecond = this.timeConfig.sun.last - (6 * 24 * 60 * 60 * 1000);
      let eseconds = Math.floor(emillisecond / 1000);
      let eminutes = Math.floor(eseconds / 60);
      let ehours = Math.floor(eminutes / 60);
      this.timeConfig.sun.hLast = ehours % 24;
      this.timeConfig.sun.mLast = eminutes % 60;
      this.timeConfig.sun.sLast = eseconds % 60;
    }
  }
  getTimeView(_dayModel: DayModel): TimeViewModel {
    let timeViewModel = new TimeViewModel();
    switch (_dayModel) {
      case null:
        break;

    }
    return timeViewModel;
  }

  onChangePageSize(event: any) { }
  showModal(id) {
    $('#' + id).modal('show');
  }
  closeModal(id) {
    $('#' + id).modal('hide');
  }
  SaveConfigTime() {
    let ipMon = $('#ipMon').is(":checked");
    let ipTue = $('#ipTue').is(":checked");
    let ipWed = $('#ipWed').is(":checked");
    let ipThur = $('#ipThur').is(":checked");
    let ipFri = $('#ipFri').is(":checked");
    let ipSat = $('#ipSat').is(":checked");
    let ipSun = $('#ipSun').is(":checked");

    let tempConfigTime = new ConfigTimeModel();
    tempConfigTime.mon = new DayModel();
    tempConfigTime.tue = new DayModel();
    tempConfigTime.wed = new DayModel();
    tempConfigTime.thur = new DayModel();
    tempConfigTime.fri = new DayModel();
    tempConfigTime.sat = new DayModel();
    tempConfigTime.sun = new DayModel();
    if (ipMon == true) {
      let hFirst = Number($('#sMonhFirst').val());
      let mFirst = Number($('#sMonmFirst').val());
      let sFirst = Number($('#sMonsFirst').val());
      tempConfigTime.mon.first = (hFirst * 60 * 60 + mFirst * 60 + sFirst) * 1000;
      let hLast = Number($('#sMonhLast').val());
      let mLast = Number($('#sMonmLast').val());
      let sLast = Number($('#sMonsLast').val());
      tempConfigTime.mon.last = (hLast * 60 * 60 + mLast * 60 + sLast) * 1000;
      if (tempConfigTime.mon.first > tempConfigTime.mon.last) {
        Swal.fire("Lỗi cấu hình thời gian", "Thời gian bắt đầu thứ 2 không được lớn hơn thời gian kết thúc", "warning");
        return
      }
    }
    else {
      tempConfigTime.mon.first = 0;
      tempConfigTime.mon.last = 0;
    }
    if (ipTue) {
      let hFirst = Number($('#sTuehFirst').val());
      let mFirst = Number($('#sTuemFirst').val());
      let sFirst = Number($('#sTuesFirst').val());
      tempConfigTime.tue.first = (24 * 60 * 60 + hFirst * 60 * 60 + mFirst * 60 + sFirst) * 1000;
      let hLast = Number($('#sTuehLast').val());
      let mLast = Number($('#sTuemLast').val());
      let sLast = Number($('#sTuesLast').val());
      tempConfigTime.tue.last = (24 * 60 * 60 + hLast * 60 * 60 + mLast * 60 + sLast) * 1000;
      if (tempConfigTime.tue.first > tempConfigTime.tue.last) {
        Swal.fire("Lỗi cấu hình thời gian", "Thời gian bắt đầu thứ 3 không được lớn hơn thời gian kết thúc", "warning");
        return;
      }
    }
    else {
      tempConfigTime.tue.first = 0;
      tempConfigTime.tue.last = 0;
    }
    if (ipWed) {
      let hFirst = Number($('#sWedhFirst').val());
      let mFirst = Number($('#sWedmFirst').val());
      let sFirst = Number($('#sWedsFirst').val());
      tempConfigTime.wed.first = (24 * 2 * 60 * 60 + hFirst * 60 * 60 + mFirst * 60 + sFirst) * 1000;
      let hLast = Number($('#sWedhLast').val());
      let mLast = Number($('#sWedmLast').val());
      let sLast = Number($('#sWedsLast').val());
      tempConfigTime.wed.last = (24 * 2 * 60 * 60 + hLast * 60 * 60 + mLast * 60 + sLast) * 1000;
      if (tempConfigTime.wed.first > tempConfigTime.wed.last) {
        Swal.fire("Lỗi cấu hình thời gian", "Thời gian bắt đầu thứ 4 không được lớn hơn thời gian kết thúc", "warning");
        return
      }
    }
    else {
      tempConfigTime.wed.first = 0;
      tempConfigTime.wed.last = 0;
    }
    if (ipThur) {
      let hFirst = Number($('#sThurhFirst').val());
      let mFirst = Number($('#sThurmFirst').val());
      let sFirst = Number($('#sThursFirst').val());
      tempConfigTime.thur.first = (24 * 3 * 60 * 60 + hFirst * 60 * 60 + mFirst * 60 + sFirst) * 1000;
      let hLast = Number($('#sThurhLast').val());
      let mLast = Number($('#sThurmLast').val());
      let sLast = Number($('#sThursLast').val());
      tempConfigTime.thur.last = (24 * 3 * 60 * 60 + hLast * 60 * 60 + mLast * 60 + sLast) * 1000;
      if (tempConfigTime.thur.first > tempConfigTime.thur.last) {
        Swal.fire("Lỗi cấu hình thời gian", "Thời gian bắt đầu thứ 5 không được lớn hơn thời gian kết thúc", "warning");
        return
      }
    } else {
      tempConfigTime.thur.first = 0;
      tempConfigTime.thur.last = 0;
    }
    if (ipFri) {
      let hFirst = Number($('#sFrihFirst').val());
      let mFirst = Number($('#sFrimFirst').val());
      let sFirst = Number($('#sFrisFirst').val());
      tempConfigTime.fri.first = (24 * 4 * 60 * 60 + hFirst * 60 * 60 + mFirst * 60 + sFirst) * 1000;
      let hLast = Number($('#sFrihLast').val());
      let mLast = Number($('#sFrimLast').val());
      let sLast = Number($('#sFrisLast').val());
      tempConfigTime.fri.last = (24 * 4 * 60 * 60 + hLast * 60 * 60 + mLast * 60 + sLast) * 1000;
      if (tempConfigTime.fri.first > tempConfigTime.fri.last) {
        Swal.fire("Lỗi cấu hình thời gian", "Thời gian bắt đầu thứ 6 không được lớn hơn thời gian kết thúc", "warning");
        return
      }
    }
    else {
      tempConfigTime.fri.first = 0;
      tempConfigTime.fri.last = 0;
    }
    if (ipSat) {
      let hFirst = Number($('#sSathFirst').val());
      let mFirst = Number($('#sSatmFirst').val());
      let sFirst = Number($('#sSatsFirst').val());
      tempConfigTime.sat.first = (24 * 5 * 60 * 60 + hFirst * 60 * 60 + mFirst * 60 + sFirst) * 1000;
      let hLast = Number($('#sSathLast').val());
      let mLast = Number($('#sSatmLast').val());
      let sLast = Number($('#sSatsLast').val());
      tempConfigTime.sat.last = (24 * 5 * 60 * 60 + hLast * 60 * 60 + mLast * 60 + sLast) * 1000;
      if (tempConfigTime.sat.first > tempConfigTime.sat.last) {
        Swal.fire("Lỗi cấu hình thời gian", "Thời gian bắt đầu thứ 7 không được lớn hơn thời gian kết thúc", "warning");
        return
      }
    }
    else {
      tempConfigTime.sat.first = 0;
      tempConfigTime.sat.last = 0;
    }
    if (ipSun) {
      let hFirst = Number($('#sSunhFirst').val());
      let mFirst = Number($('#sSunmFirst').val());
      let sFirst = Number($('#sSunsFirst').val());
      tempConfigTime.sun.first = (24 * 6 * 60 * 60 + hFirst * 60 * 60 + mFirst * 60 + sFirst) * 1000;
      let hLast = Number($('#sSunhLast').val());
      let mLast = Number($('#sSunmLast').val());
      let sLast = Number($('#sSunsLast').val());
      tempConfigTime.sun.last = (24 * 6 * 60 * 60 + hLast * 60 * 60 + mLast * 60 + sLast) * 1000;
      if (tempConfigTime.sun.first > tempConfigTime.sun.last) {
        Swal.fire("Lỗi cấu hình thời gian", "Thời gian bắt đầu chủ nhật không được lớn hơn thời gian kết thúc", "warning");
        return
      }
    }
    else {
      tempConfigTime.sun.first = 0;
      tempConfigTime.sun.last = 0;
    }
    this._aiService.registerTimeKeeping(tempConfigTime).subscribe(
      res => {
        Swal.fire("Thành công", "Cấu hình thời gian làm việc thành công", "success");
        this.closeModal('mSetDay');
      },
      err => {
        Swal.fire("Thất bại", "Cấu hình thời gian làm việc lỗi, Vui lòng kiểm tra lại dữ liệu", "error");
        return;
      }
    );
  }
}
