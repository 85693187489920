import { Injectable } from '@angular/core';
import { Validators, FormGroup, AbstractControl } from '@angular/forms';
import { CustomValidators } from '../_services/custom-validators';
import { LanguageModel } from '../_models/language.model';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { messaging } from 'firebase';

@Injectable({
  providedIn: 'root'
})
export class FormValidateService {
  langModel = new LanguageModel();

  constructor(private cookie: CookieService, private translate: TranslateService) {
    var defLang = this.cookie.get('language');

    if (defLang == null || defLang == '') {
      this.translate.setDefaultLang('vi');
      this.translate.use('vi');
      this.cookie.set('language', 'vi');
    } else {
      this.translate.setDefaultLang(defLang);
      this.translate.use(defLang);
    }

    //chuyển ngữ
    this.translate.get('val_username').subscribe((res: string) => {
      this.langModel.title = res;
    });
    this.translate.get('val_pass').subscribe((res: string) => {
      this.langModel.content = res;
    });

    this.translate.get('val_otp').subscribe((res: string) => {
      this.langModel.title1 = res;
    });
    this.translate.get('val_name_group').subscribe((res: string) => {
      this.langModel.content1 = res;
    });
    this.translate.get('val_gr_min3').subscribe((res: string) => {
      this.langModel.title2 = res;
    });
    this.translate.get('val_gr_max30').subscribe((res: string) => {
      this.langModel.content2 = res;
    });
    this.translate.get('val_us_min3').subscribe((res: string) => {
      this.langModel.title3 = res;
    });
    this.translate.get('val_us_max30').subscribe((res: string) => {
      this.langModel.content3 = res;
    });
    this.translate.get('val_fullname').subscribe((res: string) => {
      this.langModel.title4 = res;
    });
    this.translate.get('val_fn_min3').subscribe((res: string) => {
      this.langModel.content4 = res;
    });
    this.translate.get('val_curr_pass').subscribe((res: string) => {
      this.langModel.title5 = res;
    });
    this.translate.get('val_min_pass').subscribe((res: string) => {
      this.langModel.content5 = res;
    });
    this.translate.get('val_pass_min').subscribe((res: string) => {
      this.langModel.title6 = res;
    });
    this.translate.get('val_pas_has_num').subscribe((res: string) => {
      this.langModel.content6 = res;
    });
    this.translate.get('val_pass_has_low').subscribe((res: string) => {
      this.langModel.title7 = res;
    });
    this.translate.get('val_pass_has_upp').subscribe((res: string) => {
      this.langModel.content7 = res;
    });
    this.translate.get('val_pass_has_sym').subscribe((res: string) => {
      this.langModel.title8 = res;
    });
    this.translate.get('val_confirm_pass').subscribe((res: string) => {
      this.langModel.content8 = res;
    });
    this.translate.get('val_email_empty').subscribe((res: string) => {
      this.langModel.title9 = res;
    });
    this.translate.get('val_email_format').subscribe((res: string) => {
      this.langModel.content9 = res;
    });

    this.translate.get('val_us_min_6').subscribe((res: string) => {
      this.langModel.title10 = res;
    });
    this.translate.get('val_fn_min6').subscribe((res: string) => {
      this.langModel.content10 = res;
    });
    this.translate.get('val_camera_name').subscribe((res: string) => {
      this.langModel.title11 = res;
    });
    this.translate.get('val_cam_name_min3').subscribe((res: string) => {
      this.langModel.content11 = res;
    });
    this.translate.get('val_cam_name_max60').subscribe((res: string) => {
      this.langModel.title12 = res;
    });
    this.translate.get('val_cloudid').subscribe((res: string) => {
      this.langModel.content12 = res;
    });
    this.translate.get('val_secure_code').subscribe((res: string) => {
      this.langModel.title13 = res;
    });
    this.translate.get('val_url_stream').subscribe((res: string) => {
      this.langModel.content13 = res;
    });
    this.translate.get('val_money').subscribe((res: string) => {
      this.langModel.title14 = res;
    });
    this.translate.get('val_min_money').subscribe((res: string) => {
      this.langModel.content14 = res;
    });
    this.translate.get('val_max_money').subscribe((res: string) => {
      this.langModel.title15 = res;
    });
    this.translate.get('val_no_bank').subscribe((res: string) => {
      this.langModel.content15 = res;
    });
    this.translate.get('factor_validate').subscribe((res: string) => {
      this.langModel.val = res;
    });
    this.translate.get('val_min').subscribe((res: string) => {
      this.langModel.streammin = res;
    });
    this.translate.get('val_max').subscribe((res: string) => {
      this.langModel.streammax = res;
    });
  }
  LoginValidate = {
    usernameValidate: {
      validator: Validators.compose([Validators.required]),
      message: (control: AbstractControl) => this.langModel.title
    },
    passwordValidate: {
      validator: Validators.compose([Validators.required]),
      message: (control: AbstractControl) => this.langModel.content
    }
  };
  twoFactorValidate = {
    otpCodeValidate: {
      validator: Validators.compose([Validators.required, Validators.minLength(6), Validators.maxLength(6), CustomValidators.patternValidator(/\d/, {hasNumber: true })]),
      message: (control: AbstractControl) => this.langModel.val      
  }
}
  groupCamValidate = {
    nameValidate: {
      validator: Validators.compose([Validators.required, Validators.minLength(3), Validators.maxLength(30)]),
      message: (control: AbstractControl) => {
        if (control.hasError('required')) {
          return this.langModel.content1;
        }
        if (control.hasError('minlength')) {
          return this.langModel.title2;
        }
        if (control.hasError('maxlength')) {
          return this.langModel.content2;
        }
      }
    }
  }
  customerFormValidate = {
    usernameValidate: {
      validator: Validators.compose([Validators.required, Validators.minLength(3), Validators.maxLength(30)]),
      message: (control: AbstractControl) => {
        if (control.hasError('required')) {
          return this.langModel.title;
        }
        if (control.hasError('minlength')) {
          return this.langModel.title3;
        }
        if (control.hasError('maxlength')) {
          return this.langModel.content3;
        }
      }
    },
    fullNameValidate: {
      validator: Validators.compose([Validators.required, Validators.minLength(3)]),
      message: (control: AbstractControl) => {
        if (control.hasError('required')) {
          return this.langModel.title4
        }
        if (control.hasError('minlength')) {
          return this.langModel.content4;
        }
      }
    },
    oldPasswordValidate: {
      validator: Validators.compose([Validators.required, Validators.minLength(6)]),
      message: (control: AbstractControl) => {
        if (control.hasError('required')) {
          return this.langModel.title5;
        }
        if (control.hasError('minlength')) {
          return this.langModel.content5;
        }
      }
    },
    passwordValidate: {
      validator: Validators.compose([Validators.required, Validators.minLength(8),
      // check whether the entered password has a number
      CustomValidators.patternValidator(/\d/, {
        hasNumber: true
      }),
      // check whether the entered password has upper case letter
      CustomValidators.patternValidator(/[A-Z]/, {
        hasCapitalCase: true
      }),
      // check whether the entered password has a lower case letter
      CustomValidators.patternValidator(/[a-z]/, {
        hasSmallCase: true
      }),
      // check whether the entered password has a special character
      CustomValidators.patternValidator(
        /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/,
        {
          hasSpecialCharacters: true
        }
      )]),
      message: (control: AbstractControl) => {
        if (control.hasError('required')) {
          return this.langModel.content;
        }
        if (control.hasError('minlength')) {
          return this.langModel.title6;
        }
        if (control.hasError('hasNumber')) {
          return this.langModel.content6
        }
        if (control.hasError('hasSmallCase')) {
          return this.langModel.title7
        }
        if (control.hasError('hasCapitalCase')) {
          return this.langModel.content7
        }
        if (control.hasError('hasSpecialCharacters')) {
          return this.langModel.title8
        }
      }
    },
    matchingPassword: {
      validator: (group: FormGroup) => {
        let val;
        let valid = true;
        for (let name in group.controls) {
          if (val === undefined) {
            val = group.controls[name].value
          } else {
            if (val !== group.controls[name].value) {
              valid = false;
              break;
            }
          }
        }
        if (valid) {
          return null;
        }
        return {
          areEqual: true
        };
      },
      message: (control: AbstractControl) => {
        return this.langModel.content8;
      }
    },
    emailValidate: {
      validator: Validators.compose([Validators.required, Validators.email]),
      message: (control: AbstractControl) => {
        if (control.hasError('required')) {
          return this.langModel.title9;
        }
        if (control.hasError('email')) {
          return this.langModel.content9;
        }
      }
    },
    phoneNumberValidate: {
      validator: null,
      message: (control: AbstractControl) => {
        return '';
      }
    },
  };
  RegisterValidate = {
    usernameValidate: {
      validator: Validators.compose([Validators.required, Validators.minLength(6), Validators.maxLength(30)]),
      message: (control: AbstractControl) => {
        if (control.hasError('required')) {
          return this.langModel.title;
        }
        if (control.hasError('minlength')) {
          return this.langModel.title10;
        }
        if (control.hasError('maxlength')) {
          return this.langModel.content3;
        }
      }
    },
    fullNameValidate: {
      validator: Validators.compose([Validators.required, Validators.minLength(6)]),
      message: (control: AbstractControl) => {
        if (control.hasError('required')) {
          return this.langModel.title4;
        }
        if (control.hasError('minlength')) {
          return this.langModel.content10;
        }
      }
    },
    passwordValidate: {
      validator: Validators.compose([Validators.required, Validators.minLength(8),
      // check whether the entered password has a number
      CustomValidators.patternValidator(/\d/, {
        hasNumber: true
      }),
      // check whether the entered password has upper case letter
      CustomValidators.patternValidator(/[A-Z]/, {
        hasCapitalCase: true
      }),
      // check whether the entered password has a lower case letter
      CustomValidators.patternValidator(/[a-z]/, {
        hasSmallCase: true
      }),
      // check whether the entered password has a special character
      CustomValidators.patternValidator(
        /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/,
        {
          hasSpecialCharacters: true
        }
      )]),
      message: (control: AbstractControl) => {
        if (control.hasError('required')) {
          return this.langModel.content;
        }
        if (control.hasError('minlength')) {
          return this.langModel.title6;
        }
        if (control.hasError('hasNumber')) {
          return this.langModel.content6
        }
        if (control.hasError('hasSmallCase')) {
          return this.langModel.title7
        }
        if (control.hasError('hasCapitalCase')) {
          return this.langModel.content7
        }
        if (control.hasError('hasSpecialCharacters')) {
          return this.langModel.title8
        }
      }
    },
    matchingPassword: {
      validator: (group: FormGroup) => {
        let val;
        let valid = true;
        for (let name in group.controls) {
          if (val === undefined) {
            val = group.controls[name].value
          } else {
            if (val !== group.controls[name].value) {
              valid = false;
              break;
            }
          }
        }
        if (valid) {
          return null;
        }
        return {
          areEqual: true
        };
      },
      message: (control: AbstractControl) => {
        return this.langModel.content8;
      }
    },
    emailValidate: {
      validator: Validators.compose([Validators.required, Validators.email]),
      message: (control: AbstractControl) => {
        if (control.hasError('required')) {
          return this.langModel.title9;
        }
        if (control.hasError('email')) {
          return this.langModel.content9;
        }
      }
    }
  };
  CameraValidate = {
    nameValidate: {
      validator: Validators.compose([Validators.required, Validators.minLength(3), Validators.maxLength(60)]),
      message: (control: AbstractControl) => {
        if (control.hasError('required')) {
          return this.langModel.title11;
        }
        if (control.hasError('minlength')) {
          return this.langModel.content11;
        }
        if (control.hasError('maxlength')) {
          return this.langModel.title12;
        }
      }
    },
    cloudIDValidate: {
      validator: Validators.compose([Validators.required]),
      message: (control: AbstractControl) => {
        if (control.hasError('required')) {
          return this.langModel.content12;
        }
      }
    },
    codeValidate: {
      validator: Validators.compose([Validators.required]),
      message: (control: AbstractControl) => {
        if (control.hasError('required')) {
          return this.langModel.title13;
        }
      }
    },
    streamValidate: {
      validator: Validators.compose([Validators.required, Validators.minLength(6), Validators.maxLength(500)]),
      message: (control: AbstractControl) => {
        if (control.hasError('required')) {
          return this.langModel.content13;
        }
        if (control.hasError('minlength')) {
          return this.langModel.streammin;
        }
        if (control.hasError('maxlength')) {
          return this.langModel.streammax;
        }
      }
    }

  }
  PaymentValidate = {
    amountValidate: {
      validator: Validators.compose([Validators.required, Validators.min(10000), Validators.max(100000000)]),
      message: (control: AbstractControl) => {
        if (control.hasError('required')) {
          return this.langModel.title14;
        }
        if (control.hasError('min')) {
          return this.langModel.content14;
        }
        if (control.hasError('max')) {
          return this.langModel.title15;
        }
      }
    },
    bankValidate: {
      validator: Validators.compose([Validators.required]),
      message: (control: AbstractControl) => this.langModel.content15
    }
  }
}
