import { Component, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
declare var $: any;

@Component({
  selector: 'app-landingpage-new',
  templateUrl: './landingpage-new.component.html',
  styleUrls: ['./landingpage-new.component.css']
})
export class LandingpageNewComponent implements OnInit {
  isLogIn: boolean;

  constructor(public cookie: CookieService) { }

  ngOnInit(): void {
    if (this.cookie.get('currentUser') != null)
      this.isLogIn = true;
    else this.isLogIn = false;

    if ($(".block-solutions").length) {
      $(".block-solutions").slick({
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
          {
            breakpoint: 1025,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
              dots: true,
              arrows: false,
            }
          },
          {
            breakpoint: 769,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              dots: true,
              arrows: false,
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              dots: true,
              arrows: false,
            }
          }
        ]
      });
    }
    
    $('.news-slide').slick({
      dots: true,
      infinite: true,
      arrows: true,
      autoplay: true,
      initialSlide: 1,
      autoplaySpeed: 5000,
      prevArrow: "<button type='button' class='slick-prev pull-left'><i class='icon icon-arrow_prev'></i></button>",
      nextArrow: "<button type='button' class='slick-next pull-right'><i class='icon icon-arrow_next'></i></button>",
      slidesToShow: 1,
      slidesToScroll: 1,
    });

  if ($("#backToTop").length){
    $(window).scroll((function () {
      if ($(this).scrollTop() >= 50) {        // If page is scrolled more than 50px
        $('#backToTop').fadeIn(200);    // Fade in the arrow
      } else {
        $('#backToTop').fadeOut(200);   // Else fade out the arrow
      }
    }));
    $("#backToTop").click((function (e) {
      e.preventDefault();
      $("html, body").animate({scrollTop: 0}, 1000);
    }));
  }
  }

}
