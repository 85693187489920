<header>
    <div class="header">
        <div><a href="/home">
                <img class="logo" src="assets/logo camera.svg" alt="Cloud Camera" />
            </a>
        </div>
    </div>
</header>

<body style="background: #F5F5F5;">
<div class="page-container page-login">
    <div class="container" style="position:relative">
        <div class="row"  style="position:absolute; left: 30%; top:32px;">
            <div class="col-lg-12">
                <div class="card login-block register-block">
                    <a href="javascript:;" (click)="backClicked()" class="back-button icon icon-back"
                        title="Quay lại"></a>
                        <a href="javascript:;">
                          <img class="logo-login" src="assets/logo camera.svg" alt="Cloud Camera">
                        </a>
                    <h2 class="card-title" style="font-weight: bold; margin-bottom: 5%;">{{'li_forgot'|translate}}</h2>
                    <div *ngIf="!acceptRequest">
                        <p class="card-text">{{'li_enter_email'|translate}}</p>
                        <form class="form" [formGroup]="forgotPasswordForm"
                            (ngSubmit)="forgotPasswordForm.valid && sendEmail()">
                            <div class="form-group form-group-email">
                                <input placeholder="Email" [(ngModel)]="email" class="form-control" type="email"
                                    formControlName="email">
                                <div *ngIf="forgotPasswordForm.controls.email.invalid && forgotPasswordForm.controls.email.touched"
                                    class="alert alert-danger">
                                    {{'li_format_error'|translate}}</div>
                                <re-captcha (resolved)="resolved($event)"
                                    siteKey="6LdKA6kZAAAAANmMBOx0njeanv_bOj-RmB8uvK1a"></re-captcha>
                            </div>
                            <button class="text-center w-100 mt-3 btn btn-primary btn-login" type="submit"
                                [disabled]="forgotPasswordForm.invalid || !isConfirmCaptcha">{{'li_confirm'|translate}}</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</body>


<footer>
    <div class="footer">
        <p translate>{{'li_copyright'}}<span class="text-red" translate>{{'li_phone'}}</span> {{'li_copyright1'}}
          <span class="text-red" translate>{{'li_email'}}</span>
        </p>
    </div>
</footer>