<div class="container-fluid p-2">
  <div class="page-title">
    <p>Đăng ký camera cho kịch bản <span style="color: #ee0033;" *ngIf="scenario">{{scenario.name}}</span></p>
  </div>
  <div class="page-action">
    <div class="row">
      <div class="col-12">
        <div class="d-flex">
          <div class="search-form event-search mr-3">
            <label for="search-camera" class="sr-only">Nhập từ khóa tìm kiếm</label>
            <input type="text" class="form-control border-control" id="search-camera"
              placeholder="Nhập từ khóa tìm kiếm" [(ngModel)]="txtSearch">
          </div>
        </div>
      </div>
    </div>
    <div class="page-table" style="padding-left: 0;">
      <div class="container">
        <div style="padding-bottom: 15px; margin-left: 1%; font-weight: bold; display: flex;">
          <input type="checkbox" id="selectAll" (change)="changeSelectAll($event)" style="width: 18px; height: 18px;" /><span style="margin-left: 15px;" for="selectAll">Chọn tất cả</span>
        </div>
        <div id="tblRegisCam" style="margin-bottom: 20px">
          <div *ngFor="let grp of lstGrpCam">
            <div style="padding-left: 1%; padding-bottom: 12px; display: flex;border-bottom: 1px solid #F2F2F2;">
              <input id="ipgr_{{grp.id}}" type="checkbox" class="gr_{{grp.id}}" style="width: 18px; height: 18px;"
                (change)="changeAssignGroup($event,grp.id)" name="cb_RegisAI">
              <div id="{{grp.id}}" style="cursor: pointer;" (click)="bunchClick($event)"> <span
                  style="font-weight: bold; margin-left: 15px;">{{grp.name}}</span></div>
            </div>
            <app-camera-for-scenario id="app_{{grp.id}}" style="visibility: collapse;" [groupCam]="grp"
              [lstIdLoad]="lstLoadCamAI" [scenarioModel]="scenario"></app-camera-for-scenario>
          </div>
        </div>
        <div class="form-action d-flex justify-content-end">
          <a class="btn btn-outline-secondary mr-3" type="button" routerLink="/aicamera/register-scenarios">{{'li_cancel'|translate}}</a>
          <button class="btn btn-primary m-0" (click)="saveListCameraRegister()">{{'li_save'|translate}}</button>
        </div>

        <div class="row no-gutters">
          <div class="col-6 text-left" style="display: flex;align-items: center;">
            <label>Số hàng hiển thị mỗi trang</label>
            <select class="border-control" [(ngModel)]="pageSize"
              style="padding: 0.375rem 1rem; margin-left: 0.5rem; cursor: pointer;" (change)="onChangePageSize($event)">
              <option *ngFor="let size of pageSizes" [ngValue]="size">
                {{ size }}
              </option>
            </select>
          </div>
          <div class="col-6 text-right">
            <div class="text-right">
              <nav class="nav-pagination">
                <pagination-template #p="paginationApi" (pageChange)="currentPage = $event;">
                  <ul *ngIf="pageSize<=p.getTotalItems()" class="pagination">
                    <li class="page-item" [class.disabled]="p.isFirstPage()">
                      <a *ngIf="!p.isFirstPage()" class="page-link" (click)="p.previous()">
                        <i class="icon icon-arrow-left"></i></a>
                    </li>

                    <li class="page-item" [class.active]="p.getCurrent()===page.value" *ngFor="let page of p.pages">
                      <a class="page-link" (click)="p.setCurrent(page.value)">{{page.label}}</a>
                    </li>

                    <li class="page-item" [class.disabled]="p.isLastPage()">
                      <a *ngIf="!p.isLastPage()" class="page-link" (click)="p.next()">
                        <i class="icon icon-arrow-right"></i></a>
                    </li>
                  </ul>
                </pagination-template>
              </nav>
            </div>
          </div>
        </div>





      </div>
    </div>
