import { Injectable } from "@angular/core";
import { Http, Response, URLSearchParams } from '@angular/http';
import { BaseService } from '../_services/base.service';
import { ApiUrlService } from '../_services/api-url.service';
import { CookieService } from "ngx-cookie-service";
@Injectable({
    providedIn: 'root'
  })

export class NotifiService extends BaseService{
    constructor(public _apiUrl: ApiUrlService, public _http: Http,public cookie: CookieService) {
      super(_http, _apiUrl, cookie);
    }
    getListNotifiWeb(){
        return this._http.get(this._apiUrl.getListNotifiWeb, this.authorizationOption())
           .map(this.extractData)
           .catch(this.handleError);
    }
    stopNotifi(_id:number){
        const params: URLSearchParams = new URLSearchParams();
        params.set('notificationId', `${_id}`);
        return this._http.get(this._apiUrl.stopNotifi, this.authorizationOption().merge({ search: params }))
        .map(this.extractData)
        .catch(this.handleError);
    }

}