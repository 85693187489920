import { Injectable } from '@angular/core';
import { Http, Response, URLSearchParams } from '@angular/http';
import { BaseService } from '../_services/base.service';
import { ApiUrlService } from '../_services/api-url.service';
import { CookieService } from 'ngx-cookie-service';
import {UsernameCameraIdsModel } from '../_models';

@Injectable({
  providedIn: 'root'
})
export class CopyPermissionService extends BaseService {
  constructor(public _apiUrl: ApiUrlService, public _http: Http,public cookie: CookieService) {
    super(_http, _apiUrl, cookie);
  }
  getListAccount(_camId: number) {
    const params: URLSearchParams = new URLSearchParams();
    params.set("cam-id", `${_camId}`);
    return this._http
      .get(
        this._apiUrlService.Account_camera,
        this.authorizationOption().merge({ search: params })
      )
      .map(this.extractData)
      .catch(this.handleError);
  }
  getOwnerCamera(_camId: number) {
    const params: URLSearchParams = new URLSearchParams();
    params.set("cam-id", `${_camId}`);
    return this._http
      .get(
        this._apiUrlService.Owner_camera,
        this.authorizationOption().merge({ search: params })
      )
      .map(this.extractData)
      .catch(this.handleError);
  }
  removeListCamera(_accCamIds: number[]){
    return this._http
      .post(this._apiUrlService.Remove_List_camera,_accCamIds, this.authorizationOption())
      .map(this.extractData)
      .catch(this.handleError);
  }
  removeListByUsername(_accCameraModel:UsernameCameraIdsModel){
    return this._http
      .post(this._apiUrlService.Remove_List_By_Username,_accCameraModel, this.authorizationOption())
      .map(this.extractData)
      .catch(this.handleError);
  }
}