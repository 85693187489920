import { CameraModel } from './../_models/camera.model';
import { PaymentModel, BuyModel, Buy2Model } from './../_models/package.model';
import { Injectable } from '@angular/core';
import { Http, Response, URLSearchParams } from '@angular/http';
import { BaseService } from '../_services/base.service';
import { ApiUrlService } from '../_services/api-url.service';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class PackageService extends BaseService {
  constructor(public _apiUrl: ApiUrlService, public _http: Http,public cookie: CookieService) {
    super(_http, _apiUrl, cookie);
  }
  paymentByBank(_payment: PaymentModel) {
   return this._http.post(this._apiUrl.Payment_by_bank_card,_payment, this.authorizationOption())
      .map(this.extractData)
      .catch(this.handleError);
  }
  findAll() {
    return this._http.get(this._apiUrl.Package_find_all, this.authorizationOption())
      .map(this.extractData)
      .catch(this.handleError);
  }
  getTreeViewNotBuy() {
    return this._http.get(this._apiUrl.Package_tree_view_not_buy_package, this.authorizationOption())
      .map(this.extractData)
      .catch(this.handleError);
  }
  getTreeViewBought() {
    return this._http.get(this._apiUrl.Package_tree_view_already_bought_package, this.authorizationOption())
      .map(this.extractData)
      .catch(this.handleError);
  }

  getCameraInBunchNoBuy(_bunchId: number) {
    const params: URLSearchParams = new URLSearchParams();
    params.set('bunch-id', `${_bunchId}`);
    return this._http.get(this._apiUrl.Package_find_camera_not_buy_package_by_bunch_id, this.authorizationOption().merge({ search: params }))
      .map(this.extractData)
      .catch(this.handleError);
  }

  getCameraInBunchBought(_bunchId: number) {
    let reqParams = {};
    const params: URLSearchParams = new URLSearchParams();
    params.set('bunch-id', `${_bunchId}`);
    return this._http.get(this._apiUrl.Package_find_camera_already_bought_package_by_bunch_id, this.authorizationOption().merge({ search: params }))
      .map(this.extractData)
      .catch(this.handleError);
  }
  cancelPackage(_camera: CameraModel){
    const params: URLSearchParams = new URLSearchParams();
    params.set('camera-id', `${_camera.id}`);
    params.set('package-id', `${_camera.package.id}`);
    return this._http.get(this._apiUrl.Package_remove_camera, this.authorizationOption().merge({ search: params }))
      .map(this.extractData)
      .catch(this.handleError);
  }
  buyPackage(_listBuy: BuyModel[]) {
    let listBuy = _listBuy.map(this.cloneData);
    return this._http.post(this._apiUrl.Package_buy, listBuy, this.authorizationOption())
      .map(this.extractData)
      .catch(this.handleError);
  }
  cloneData(model: BuyModel){
    let obj = new Buy2Model();
    obj.cameraId = model.cameraId;
    obj.packageId = model.packageId;
    return obj;
  }
}
