import { AuthHepler } from './../../_helpers/auth.helper';
import { AuthenticationService } from './../../_services/authentication.service';
import { Component, OnInit, Input } from '@angular/core';
import Swal from 'sweetalert2';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent extends AuthHepler implements OnInit {
  @Input() balance: any;
  isActiveAI: boolean = false;
  constructor(public _authenticationService: AuthenticationService,public cookie: CookieService) {
    super(_authenticationService, _authenticationService, cookie);
  }
  logout() {
    this._authenticationService.logout();
  }

  ngOnInit(): void {
    this.getBalance();
    this.hasPluginAI();
  }
  getBalance() {
    this._authenticationService.getbalance().subscribe(res => {
      this.balance = res.amount.toLocaleString(); 
    },
      err => {
        if(err ==='Unauthorized'){
          this.unAuthHandle(() => this.getBalance());
        }
        else{
          console.log('Lỗi lấy số dư tài khoản '+ err);
        }
      });
  }
  hasPluginAI(){
    this._authenticationService.hasPluginAI().subscribe(res => {
      this.isActiveAI = res.isActive;
    },
      err => {
          console.log('Lỗi check plugin AI: '+ err);
      });
  }
  NotifiConfig(){
    Swal.fire({
      title: 'Camera cấu hình sai độ phân giải',
      text: 'Một số camera đang được cấu hình sai độ phân giải so với gói cước đã mua. Chi tiết xem tại email test123@gmail.com. Nếu cảnh báo quá 2 lần, hệ thống sẽ ngắt luồng lưu trữ file xem lại của các camera trên. Vui lòng điều chỉnh lại cấu hình!',
      icon: 'error',
      confirmButtonColor: '#3085d6',
      cancelButtonText: 'Đóng'
    })
  }
  NotifiPendingRecord(){
    Swal.fire({
      title: 'Thông báo ngắt luồng lưu file xem lại',
      text: 'Hệ thống đã ngắt luồng lưu trữ file xem lại của các camera cấu hình sai độ phân giải so với gói cước đã mua. Chi tiết xem tại email test123@gmail.com Vui lòng điều chỉnh lại cấu hình để tiếp tục sử dụng gói cước lưu trữ!',
      icon: 'error',
      confirmButtonColor: '#3085d6',
      cancelButtonText: 'Đóng'
    })
  }
}
