import { AuthHepler } from './../../_helpers/auth.helper';
import { AuthenticationService } from './../../_services/authentication.service';
import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, Validators, FormBuilder, FormControl } from '@angular/forms';
import { UserService } from '../../_services/user.service';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { CookieService } from 'ngx-cookie-service';
import { AppComponent } from 'src/app/app.component';
import { LanguageModel } from 'src/app/_models/language.model';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent extends AuthHepler implements OnInit {
  @Input() email: string;
  loading: boolean = false;
  forgotPasswordForm: FormGroup;
  acceptRequest: boolean = false;
  isConfirmCaptcha: boolean = false;
  langModel = new LanguageModel();

  constructor(
    private _fb: FormBuilder,
    private _router: Router,
    private _location: Location, private _appComponent: AppComponent,
    private _userService: UserService, public _authenticationService: AuthenticationService, public cookie: CookieService) {
    super(_userService, _authenticationService, cookie);
  }

  ngOnInit(): void {
    this.createForm();
    //chuyển ngữ
    this._appComponent.translate.get('li_notiffi').subscribe((res: string) => {
      this.langModel.title = res;
    });
    this._appComponent.translate.get('li_had_error').subscribe((res: string) => {
      this.langModel.title1 = res;
    });
    this._appComponent.translate.get('li_check_new_mail').subscribe((res: string) => {
      this.langModel.content = res;
    });
    this._appComponent.translate.get('li_no_found_mail').subscribe((res: string) => {
      this.langModel.content1 = res;
    });
  }

  createForm() {
    this.forgotPasswordForm = this._fb.group({
      email: ['', [Validators.required, Validators.email]]
    });
  }

  sendEmail() {
    this.acceptRequest = false;
    this._userService.forgotPassword(this.email)
      .subscribe(
        res => {
          if (res.data === 'Request reset password successfully') {
            this.acceptRequest = true;
            let notifi = this.langModel.content + `${this.email}`;
            Swal.fire(
              this.langModel.title,
              notifi,
              'success'
            );
            this._router.navigate(['/login']);
          }
        },
        err => {
          if (err === 'Unauthorized') {
            this.unAuthHandle(() => this.sendEmail());
          }
          else {
            let notifi = this.langModel.content1 + `${this.email}`;
            Swal.fire(
              this.langModel.title1,
              notifi,
              'error'
            )
          }
        });
  }
  backClicked() {
    this._location.back();
  }
  resolved(captchaResponse: string) {
    if (captchaResponse.length == 0) {
      this.isConfirmCaptcha = false;
    }
    else {
      this.isConfirmCaptcha = true;
    }
  }
}
