import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { CameraModel } from 'src/app/_models';
import { WowzaWebRTCPlayer } from 'wowza-webrtc-player';
declare var document: any;

@Component({
  selector: 'app-webrtcplayer',
  templateUrl: './webrtcplayer.component.html',
  styleUrls: ['./webrtcplayer.component.css']
})
export class WebrtcplayerComponent implements OnInit, AfterViewInit {
  @Input() camera: CameraModel;
  @ViewChild('cameraElement') public cameraElement: ElementRef;
  player: any;

  constructor() { }

  ngOnInit(): void {
  }
  ngAfterViewInit() {
    let cam = document.querySelector('#camera_' + this.camera.id.toString());
    this.player = new WowzaWebRTCPlayer(cam, {
      sdpUrl: this.camera.streamUrl,
      applicationName: 'cloud-camera',
      streamName: this.camera.StreamFileWowza,
      // streamName: this.camera.cloudId + '.stream',
    });
    // player.videoConfigs={ bitRate:360, codec:'VP8', frameRate: 15 };
    this.player.playRemote();
  }
  Close(){
    this.player.stopLocal();
  }
  takeSnapshot() {
    const canvasElement = <HTMLCanvasElement>document.createElement('CANVAS');
    const objVideo = document.getElementById(this.cameraElement.nativeElement.id);
    const context = canvasElement.getContext('2d');
    canvasElement.width = 1280;
    canvasElement.height = 768;
    context.fillRect(0, 0, 1280, 768);
    context.drawImage(objVideo, 0, 0, 1280, 768);
    const link = document.createElement('a');
    link.setAttribute('download', 'Snapshot.png');
    const dataURL = canvasElement.toDataURL();
    link.href = dataURL;
    document.body.appendChild(link);
    link.click();
  }
}
