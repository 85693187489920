import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
} from "@angular/router";
import { CookieService } from "ngx-cookie-service";
import { Observable } from "rxjs";
import { MessagingService } from "../_services";

@Injectable({
  providedIn: "root",
})
export class AuthGuard implements CanActivate {
  constructor(
    private _router: Router,
    private cookie: CookieService,
    private messagingService: MessagingService
  ) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    // if (
    //   (this.cookie.get("currentUser") &&
    //     this.cookie.get("currentUser") != "" &&
    //     this.cookie.get("twofactor")) ||
    //   (this.cookie.get("currentUser") &&
    //     this.cookie.get("currentUser") != "" &&
    //     this.cookie.get("passtwofactor"))

    if (this.cookie.get("currentUser")) {
      if (this.cookie.get("twofa") != "") {
        return true;
      } else {
        // this.messagingService.unsubscribeTopic();
        this._router.navigate(["/login"]);
        return false;
      }
    } else {
      // this.messagingService.unsubscribeTopic();
      this._router.navigate(["/login"]);
      return false;
    }
  }
}
