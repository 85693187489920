<div class="container-fluid p-0">
  <app-header></app-header>
  <div class="page-container page-rewatch">
    <div class="row no-gutters w-100">
      <div class="col-12 col-md-2 col-lg-2 sidebar-wrapper">
        <app-tree-playback #cameraTree (onPlay)="findRecord($event)"></app-tree-playback>
      </div>
      <div class="col-12 col-md-10 col-lg-10 content-wrapper">
        <div class="main-content">
          <div class="container">
            <div class="row py-4 row-control">
              <div class="col-md-3 d-flex align-items-center">
                <h1 class="page-title pl-3 pl-xlg-0"><span>{{'ldp_playback'|translate}}</span><span
                    *ngIf="cameraSelected">/ {{cameraSelected.name}}</span></h1>
              </div>
              <div class="col-md-9 text-right">
                <span class="date-range-label">{{'ad_choose_timespan'|translate}}:</span>
                <div class="datetime-range-picker-toggle" data-toggle="modal" data-target="#selectDate">
                  {{ startTime | date : "HH:mm dd/MM/y"}} - {{endTime | date: "HH:mm dd/MM/y" }}
                </div>
                  <select style="padding: 0.375rem 1rem; margin-left: 1rem; margin-right: 1rem;"
                    [(ngModel)]="selectedTypeView" (change)="ChangeTypeView()">
                    <option [ngValue]="lstTypeView[0]">One View</option>
                    <option [ngValue]="lstTypeView[1]">Multi View</option>
                  </select>
                  <a *ngIf="listRecord?.length>0 && selectedTypeView=='Multi View'" class="close-all"
                    (click)="deactiveRouter()">
                    <i class="icon icon-close-gray"></i>
                    <span>{{'ad_closeall'|translate}}</span>
                  </a>
              </div>
            </div>

            <div *ngIf="cameraSelected&&loading" class="load-data text-center"
              style="border-radius:0;font-size:18px;">
              <strong>{{'ad_loading'|translate}}</strong> {{'ad_waiting'|translate}}
            </div>
            <div class="card border-0 br-10 py-5" *ngIf="listRecord.length==0 && cameraSelected && !loading">
              <p class="text-center mb-0"> {{'ad_no_record'|translate}}</p>
            </div>
            <div *ngIf="!loading && listRecord.length>0">
              <div *ngIf="selectedTypeView=='One View'" class="video-item">
                <div class="video-frame" style="padding-bottom: unset;">
                  <app-multivideojs [camera]="cameraSelected" [lstRecord]="listRecord" #multivideojs class="camera-media">
                  </app-multivideojs>
                  <div *ngIf="!multivideojs.loaded" class="startplay">
                    <a class="icon icon-play" (click)="playAllRecord(multivideojs)" title="{{'ad_play_video'|translate}}" href="javascript:;"></a>
                  </div>
                  <div *ngIf="multivideojs.loaded" class="meta">
                    <div style="display:inline-flex; width: 100%;">
                      <a *ngIf="isPause" class="icon icon-play1" (click)="play(multivideojs)" title="{{'ad_play_video'|translate}}"
                        href="javascript:;"></a>
                      <a *ngIf="!isPause" class="icon icon-pause" (click)="pause(multivideojs)" title="{{'ad_pause'|translate}}"
                        href="javascript:;"></a>
                      <input type="range" min="0" max="{{totalTime}}" value="0" id="mySlider" [(ngModel)]="time"
                        (input)="valueChanged($event, multivideojs)" style="width: 100%; cursor: pointer;">
                    </div>
                    <span class="video-time">{{startTime?.getTime()|datex:'HH:mm DD/MM/YYYY'}} -
                      {{endTime?.getTime()|datex:'HH:mm DD/MM/YYYY'}}</span>
                  </div>
                </div>
              </div>
              <div class="row" *ngIf="selectedTypeView=='Multi View'">
                <div class="col-md-4" *ngFor="let record of listRecord; trackBy:recordTrackById;">
                  <div class="video-item">
                    <div class="video-frame" style="padding-bottom: unset;">
                      <app-videojs [camera]="cameraSelected" [type]="'PLAY_BACK'" [record]="record" #videojs
                        class="camera-media"></app-videojs>
                      <div *ngIf="!videojs.loaded" class="action">
                        <div class="action-left">
                          <a class="icon icon-play" (click)="playRecord(videojs)" title="{{'ad_play_video'|translate}}"
                            href="javascript:;"></a>
                        </div>
                      </div>
                      <div *ngIf="videojs.loaded" class="group-zoom">
                        <a href="javascript:;" title="Phóng to" (click)="setZoomIn(videojs)"
                          style="margin-right: 3px;"><i class="icon icon-view-zoomin"></i></a>
                        <a href="javascript:;" title="Thu nhỏ" (click)="setZoomOut(videojs)"><i
                            class="icon icon-view-zoomout"></i></a>
                      </div>
                    </div>
                    <div class="meta">
                      <span class="video-time">{{record.startTime|datex:'HH:mm DD/MM/YYYY'}} -
                        {{record.endTime|datex:'HH:mm DD/MM/YYYY'}}</span>
                      <a *ngIf="videojs.loaded" class="icon icon-snapshot" (click)="Snapshot(videojs)" title="{{'ad_snapshot'|translate}}"
                        href="javascript:;"></a>
                      <a class="icon icon-download" (click)="downloadRecord(record)" title="{{'ad_down'|translate}}" href="javascript:;"></a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div> 

<div class="modal fade" id="selectDate" tabindex="-1" role="dialog" aria-labelledby="selectDateTitle"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered select-date-modal" role="document">
    <div class="modal-content">
      <div class="modal-header bg-primary">
        <h5 class="modal-title" id="selectDateTitle">{{'ad_choose_timespan'|translate}}</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <i class="icon icon-close"></i>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-6">
            <div class="selection-wrapper">
              <i class="icon icon-calendar"></i>
              <div class="form-group">
                <input class="form-control" id="select-date-first" placeholder="{{'ad_enter_starttime'|translate}}">
              </div>
            </div>
            <div class="selection-wrapper">
              <i class="icon icon-clock"></i>
              <div class="form-group">
                <div class="form-control-time">
                  <select class="select-time d-inline-block" id="select-hours-first">
                    <option *ngFor="let h of hours; let i = index" [value]="h" [selected]="i == 0">{{h}}</option>
                  </select>
                </div>
                <div class="form-control-time">
                  <select class="select-time d-inline-block" id="select-minutes-first">
                    <option *ngFor="let m of minutes; let i= index" [value]="m" [selected]="i == 0">{{m}}</option>
                  </select>
                </div>
                <div class="form-control-time">
                  <select class="select-time d-inline-block" id="select-ampm-first">
                    <option value="AM">AM</option>
                    <option value="PM">PM</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="selection-wrapper">
              <i class="icon icon-calendar"></i>
              <div class="form-group">
                <input class="form-control" id="select-date-second" placeholder="{{'ad_enter_endtime'|translate}}">
              </div>
            </div>
            <div class="selection-wrapper">
              <i class="icon icon-clock"></i>
              <div class="form-group">
                <div class="form-control-time">
                  <select class="select-time d-inline-block" id="select-hours-second">
                    <option *ngFor="let h of hours; let i = index" [value]="h" [selected]="i == 0">{{h}}</option>
                  </select>
                </div>
                <div class="form-control-time">
                  <select class="select-time d-inline-block" id="select-minutes-second">
                    <option *ngFor="let m of minutes; let i= index" [value]="m" [selected]="i == 0">{{m}}</option>
                  </select>
                </div>
                <div class="form-control-time">
                  <select class="select-time d-inline-block" id="select-ampm-second">
                    <option value="AM">AM</option>
                    <option value="PM">PM</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12">
            <input id="date-range-picker" />
            <div id="date-range-picker-container"></div>
          </div>
          <div class="col-12 form-action text-center mt-0">
            <button class="btn btn-outline-secondary mr-3" type="button" data-dismiss="modal">{{'li_cancel'|translate}}</button>
            <button class="btn btn-primary" type="button" (click)="findRecord(cameraSelected)">{{'ad_choose'|translate}}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
