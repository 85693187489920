<header class="idc-header">
    <nav class="navbar navbar-expand-lg navbar-dark p-0">
      <div class="row no-gutters w-100">
        <div class="col-12 col-md-2 col-lg-2">
          <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#idcMenu" aria-controls="idcMenu" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="brand-logo" style="padding: unset;">
            <a href="javascript:;" routerLink="/home">
              <img style="width: 100%; height: 100%; padding: 5%;" class="logo" src="assets/logo camera.svg" alt="Viettel Cloud Camera"/>
            </a>
          </div>
        </div>
        <div class="col-12 col-md-10 col-lg-10 clearfix">
          <div class="collapse navbar-collapse" id="idcMenu">
            <ul>
              <li routerLinkActive="active">
                <a routerLink="/liveview">
                  <i class="nav-icon icon-watch"></i>
                  <span>{{'ad_online'|translate}}</span>
                </a>
              </li>
              <li routerLinkActive="active">
                <a routerLink="/playback">
                  <i class="nav-icon icon-rewatch"></i>
                  <span>{{'ldp_playback'|translate}}</span>
                </a>
              </li>
              <li id="iCamera" routerLinkActive="active">
                <a routerLink="/camera">
                  <i class="nav-icon icon-camera"></i>
                  <span>Camera</span>
                </a>
              </li>
              <li routerLinkActive="active">
                <a routerLink="/bunch">
                  <i class="nav-icon icon-camera-group"></i>
                  <span>{{'ad_BUNCH'|translate}}</span>
                </a>
              </li>
              <li id="iAccount" routerLinkActive="active">
                <a routerLink="/account">
                  <i class="nav-icon icon-user"></i>
                  <span>{{'ad_user'|translate}}</span>
                </a>
              </li>
              <li routerLinkActive="active">
                <a routerLink="javascript:;" routerLink="/package">
                  <i class="nav-icon icon-package"></i>
                  <span>{{'ad_package'|translate}}</span>
                </a>
              </li>
              <li id="iDashboard" routerLinkActive="active">
                <a routerLink="/dashboard">
                  <i class="nav-icon icon-dashboard"></i>
                  <span>{{'ad_dashboard'|translate}}</span>
                </a>
              </li>
              <!-- <li routerLinkActive="active">
                <a routerLink="javascript:;" routerLink="/map">
                  <i class="nav-icon icon-package"></i>
                  <span>Bản đồ</span>
                </a>
              </li> -->
              <!-- <li routerLinkActive="active">
                <a href="http://tinhthanvn.tpddns.cn:8000/stream/statistic" target="_blank">
                  <i class="nav-icon icon-syn"></i>
                  <span>Đồng bộ dữ liệu</span>
                </a>
              </li> -->
              <li routerLinkActive="active" *ngIf="isActiveAI">
                <a routerLink="/aicamera">
                  <i class="nav-icon icon-aicamera"></i>
                  <span>AI camera</span>
                </a>
              </li>
              <li routerLinkActive="active">
                <a href="http://vddns.vn/" target="_blank">
                  <i class="nav-icon icon-vddns"></i>
                  <span>vDDNS</span>
                </a>
              </li>
            </ul>
          </div>
          <div class="user-menu">
            <div class="user-summary">
              <div class="dropdown dropdown-user" style="margin-bottom: 2px;">
                <a class="dropdown-toggle" href="javascript:;" role="button" id="dropdownUser" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <i class="user-icon"></i><span>{{_authenticationService.currentUser.username}}</span>
                </a>
                <div class="dropdown-menu" aria-labelledby="dropdownUser">
                  <a class="dropdown-item"  href="/infoaccount">{{'li_info_acc'|translate}}</a>
                  <a class="dropdown-item" (click)="logout();" href="javascript:;">{{'ad_exit'|translate}}</a>
                </div>
              </div>
              <span class="balance">Số dư: <span class="text-primary">{{balance}} VND</span></span>
            </div>
            <button class="btn btn-primary btn-topup" routerLink="/payment">{{'ad_recharge'|translate}}</button>
          </div>
        </div>
      </div>
    </nav>
  </header>
