<div class="container">
    <div class="row custom-row header-table" style="padding:15px 0px 15px 0px; text-align: center;">
      <a class="row col-sm">
        <div class="col-sm">STT</div>
        <div class="col-sm">Tên Nhóm</div>
        <div class="col-sm">Camera</div>
      </a>
      <div class="col-sm-2">Thao tác</div>
    </div>
    <app-bunch-tables-action
      [disableClickBunch]="disableClickBunch"
      (sendIdBunch)="getSubIdparent($event)"
      (sendIsClick)="getIsClick($event)"
      (removeModal)="getRemoveId($event)"
      (editModal)="getEditModal($event)"
      [style]="getItemStyle(bunch)"
      class="bunch-table-item"
      *ngFor="let bunch of listBunch"
      [bunch]="bunch"
    ></app-bunch-tables-action>
  </div>

