<div class="sidebar">
  <a href="javascript:;" class="sidebar-toggle"></a>
  <h3 class="sidebar-title">{{'ad_BUNCH'|translate}}</h3>
  <div class="sidebar-content">
    <div class="list-item">
      <i class="icon icon-search"></i>
      <input class="search-input" placeholder="{{'ad_search_bunch'|translate}}" (keyup)="searching($event)" />
    </div>
    <div class="list-item">
      <a data-toggle="collapse" href="#groupCollapse" aria-expanded="false" aria-controls="groupCollapse">
        <i class="icon icon-arrow-down"></i>
        {{'ad_choose_bunch'|translate}}
      </a>
    </div>
    <div class="collapse show" id="groupCollapse">
      <div id="camera-accordion">
        <app-tree-view-group [listBunch]="cloneData"   
        (bunchOutput)="getBunchLive($event)"
        (camera)="getCameraLive($event)" 
        [search]="search"></app-tree-view-group>
      </div>
    </div>
  </div>
</div>