import { Injectable } from '@angular/core';
import { Http, Response, URLSearchParams } from '@angular/http';
import { BaseService } from '../_services/base.service';
import { ApiUrlService } from '../_services/api-url.service';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class BunchService extends BaseService {
  constructor(public _apiUrl: ApiUrlService, public _http: Http,public cookie: CookieService) {
    super(_http, _apiUrl, cookie);
  }
  findAll() {
    return this._http.get(this._apiUrl.BUNCH_FIND_ALL, this.authorizationOption())
      .map(this.extractData)
      .catch(this.handleError);
  }
  addBunch(_bunchName: string,_parentId: number) {
    return this._http.post(this._apiUrl.BUNCH_CREATE,{name:_bunchName , parentId: _parentId}, this.authorizationOption())
      .map(this.extractData)
      .catch(this.handleError);
  }
  updateBunch(_bunchId: number, _bunchName:string, _parentId: number) {    
    return this._http.post(this._apiUrl.BUNCH_UPDATE,{id:_bunchId, name:_bunchName,parentId: _parentId}, this.authorizationOption())
      .map(this.extractData)
      .catch(this.handleError);
  }
  addCameraToBunch(_bunchId:number,_listCamId:any[]){
    return this._http.post(this._apiUrl.Bunch_add_camera,{bunchId:_bunchId,listCameraId:_listCamId},this.authorizationOption())
    .map(this.extractData)
    .catch(this.handleError);
  }
  removeCameraInBunch(_bunchId:number,_listCamId:any[]){
    return this._http.post(this._apiUrl.Bunch_remove_camera,{bunchId:_bunchId,listCameraId:_listCamId},this.authorizationOption())
    .map(this.extractData)
    .catch(this.handleError);
  }
  getAllCamNoBunch() {
    return this._http.get(this._apiUrl.Management_find_camera_no_bunch, this.authorizationOption())
      .map(this.extractData)
      .catch(this.handleError);
  }
  remove(bunchId: number) {
    const params: URLSearchParams = new URLSearchParams();
    params.set('id', `${bunchId}`);
    return this._http.get(this._apiUrl.BUNCH_REMOVE, this.authorizationOption().merge({ search: params }))
      .map(this.extractData)
      .catch(this.handleError);
  }
  getCameraInBunch(_bunchId: number) {
    let reqParams = {};
    const params: URLSearchParams = new URLSearchParams();
    params.set('bunch-id', `${_bunchId}`);
    return this._http.get(this._apiUrl.MANAGEMENT_LIST_CAMERA_BY_BUNCH_ID, this.authorizationOption().merge({ search: params }))
      .map(this.extractData)
      .catch(this.handleError);
  }
}
